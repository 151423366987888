import { CheckoutTab } from 'src/components/domain/checkout/CheckoutTabs';

export type CheckoutTabType =
    | 'package'
    | 'extras'
    | 'contactinfo'
    | 'intent'
    | 'payment'
    | 'relatedproducts';

export type IdxOpts = {
    hasPackage: boolean;
    display: boolean;
    isUsingPaymentPlan: boolean;
    hasExtras: boolean;
    hasRelatedProducts: boolean;
};

export type CheckoutTabSetTab = CheckoutTab & {
    // TODO: maybe get the identifier into CheckoutTab type instead?
    identifier: CheckoutTabType;
};

export type CheckoutTabSet = {
    ordered: boolean;
    tabs: CheckoutTabSetTab[];
};

export function getTabIndex(tabType: CheckoutTabType, opts: Partial<IdxOpts>) {
    const format = (n: number) => {
        if (opts.display) {
            return n + 1;
        } else {
            return n;
        }
    };

    let tabs = ['package', 'relatedproducts', 'extras', 'contactinfo', 'intent', 'payment'];
    if (!opts.hasPackage) tabs = tabs.filter((t) => t !== 'package');
    if (!opts.hasExtras) tabs = tabs.filter((t) => t !== 'extras');
    if (!opts.hasRelatedProducts) tabs = tabs.filter((t) => t !== 'relatedproducts');
    if (!opts.isUsingPaymentPlan) tabs = tabs.filter((t) => t !== 'intent');

    return format(tabs.indexOf(tabType));
}
