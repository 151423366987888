import { useAtom } from 'ximple';
import { capitalize } from '@mui/material';
import { CheckoutInfo, GiftcardPaymentInfo, PaymentInfo, Translations } from '@repo/types';
import { checkoutInfoAtom } from 'src/state/checkout-info/checkoutInfoAtom';
import {
    CheckoutTabSet,
    CheckoutTabSetTab,
    CheckoutTabType,
    getTabIndex,
    IdxOpts,
} from './views/getTabIndex';
import PackageView from './views/PackageView';
import { PaymentView } from './views/PaymentView';
import IntentView from 'src/components/domain/timeslots-views/IntentView';
import { ContactView } from './views/ContactView';
import { ExtrasView } from './views/ExtrasView';
import { RelatedProductsView } from './views/RelatedProductsView';
import { usePayment } from './usePayment';
import { useCartCheckoutContext } from './CartCheckoutContext';
import { useCartContext } from 'src/widgets/CartContext';
import { useLocale } from '@repo/i18n';
import CheckoutTabs from 'src/components/domain/checkout/CheckoutTabs';
import EmptyCartView from './views/EmptyCartView';

export function CartCheckoutTabs(): JSX.Element {
    const { t } = useLocale();
    const { cartItems, isUsingPaymentPlan } = useCartContext();
    const [checkoutInfoData] = useAtom(checkoutInfoAtom);
    const {
        setActiveTab,
        idxOpts,
        activeTab,
        onConfirmationPage,
        isCheckoutInfoDataValid,
        completedPaymentInfo,
    } = useCartCheckoutContext();
    const {
        handleGoPayClicked,
        onPaymentCancelled,
        onPaymentCompleted,
        clickedNext,
        setClickedNext,
    } = usePayment();

    if (cartItems.length === 0 && !completedPaymentInfo) {
        return <EmptyCartView />;
    }

    const contactInfoTab = createContactInfoTab(
        idxOpts,
        t,
        setActiveTab,
        onConfirmationPage,
        checkoutInfoData,
        clickedNext,
        setClickedNext,
        isUsingPaymentPlan,
        isCheckoutInfoDataValid,
        handleGoPayClicked,
    );

    const paymentTab = createPaymentTab(
        idxOpts,
        t,
        onConfirmationPage,
        onPaymentCancelled,
        isCheckoutInfoDataValid,
        onPaymentCompleted,
    );

    const tabset: CheckoutTabSet = {
        ordered: false,
        tabs: [],
    };

    if (idxOpts.hasPackage) {
        tabset.tabs.push(createPackageTab(idxOpts, t, onConfirmationPage, setActiveTab));
    }

    if (idxOpts.hasRelatedProducts) {
        tabset.tabs.push(createRelatedProductsTab(idxOpts, t, setActiveTab, onConfirmationPage));
    }

    if (idxOpts.hasExtras) {
        tabset.tabs.push(createExtrasTab(idxOpts, t, setActiveTab, onConfirmationPage));
    }

    tabset.tabs.push(contactInfoTab);

    if (isUsingPaymentPlan) {
        tabset.tabs.push(createIntentTab(idxOpts, t, onConfirmationPage, handleGoPayClicked));
    }

    tabset.tabs.push(paymentTab);

    const activeTabNumber = getTabIndex(activeTab, { ...idxOpts, display: false });

    return (
        <CheckoutTabs
            tabset={tabset}
            currentTab={activeTabNumber}
            onChange={(e) => setActiveTab(tabset.tabs[e].identifier)}
        />
    );
}

function createRelatedProductsTab(
    idxOpts: IdxOpts,
    t: Translations,
    setActiveTab: (activeTab: CheckoutTabType) => void,
    onConfirmationPage: boolean,
): CheckoutTabSetTab {
    return {
        name: `${getTabIndex('relatedproducts', idxOpts)} ${t.related_products}`,
        title: capitalize(t.related_products),
        description: capitalize(t.please_select),
        backButtonText: t.back_to_webshop,
        identifier: 'relatedproducts',
        content: (
            <RelatedProductsView
                onNextClicked={() => setActiveTab(idxOpts.hasExtras ? 'extras' : 'contactinfo')}
            />
        ),
        disabled: onConfirmationPage,
    };
}

function createExtrasTab(
    idxOpts: IdxOpts,
    t: Translations,
    setActiveTab: (activeTab: CheckoutTabType) => void,
    onConfirmationPage: boolean,
): CheckoutTabSetTab {
    const disabled = onConfirmationPage || !idxOpts.hasExtras;

    return {
        name: `${getTabIndex('extras', idxOpts)} ${t.extras}`,
        title: capitalize(t.extras),
        description: capitalize(t.please_select),
        backButtonText: t.back_to_webshop,
        identifier: 'extras',
        content: <ExtrasView onNextClicked={() => setActiveTab('contactinfo')} />,
        disabled: disabled,
        sx: {
            ...(!idxOpts.hasExtras && { textDecoration: 'line-through' }),
        },
    };
}

function createContactInfoTab(
    idxOpts: IdxOpts,
    t: Translations,
    setActiveTab: (activeTab: CheckoutTabType) => void,
    onConfirmationPage: boolean,
    checkoutInfoData: CheckoutInfo | null,
    clickedNext: boolean,
    setClickedNext: React.Dispatch<React.SetStateAction<boolean>>,
    isUsingPaymentPlan: boolean,
    isCheckoutInfoDataValid: boolean,
    handleGoPayClicked: (paymentMethod?: 'invoice') => void,
): CheckoutTabSetTab {
    return {
        name: `${getTabIndex('contactinfo', idxOpts)} ${t.contact_details}`,
        identifier: 'contactinfo',
        title: capitalize(t.contact_details),
        description: capitalize(t.please_register),
        backButtonText: t.back_to_webshop,
        content: (
            <ContactView
                clickedNext={clickedNext}
                handleGoPayClickedInvoice={
                    isUsingPaymentPlan || checkoutInfoData?.contactType !== 'company'
                        ? undefined
                        : () => handleGoPayClicked('invoice')
                }
                handleGoPayClicked={
                    // TODO: Refactor this stuff?
                    isUsingPaymentPlan
                        ? () =>
                              isCheckoutInfoDataValid
                                  ? Promise.resolve(setActiveTab('intent'))
                                  : setClickedNext(true)
                        : () => handleGoPayClicked()
                }
                nextButtonText={isUsingPaymentPlan ? t.choose_payment_method : t.go_and_pay}
            />
        ),
        disabled: onConfirmationPage,
    };
}

function createIntentTab(
    idxOpts: IdxOpts,
    t: Translations,
    onConfirmationPage: boolean,
    handleGoPayClicked: () => void,
): CheckoutTabSetTab {
    return {
        name: `${getTabIndex('intent', idxOpts)} ${t.choose_payment_method}`,
        identifier: 'intent',
        title: capitalize(t.choose_payment_method),
        description: '',
        backButtonText: t.back_to_webshop,
        content: <IntentView handleGoPayClicked={handleGoPayClicked} />,
        disabled: onConfirmationPage,
    };
}

function createPaymentTab(
    idxOpts: IdxOpts,
    t: Translations,
    onConfirmationPage: boolean,
    onPaymentCancelled: () => void,
    isCheckoutInfoDataValid: boolean,
    onPaymentCompleted: (
        IdxOpts: IdxOpts,
    ) => (paymentInfo: PaymentInfo | GiftcardPaymentInfo, paymentGatewayResponse: any) => void, // TODO: Refactor this stuff?
): CheckoutTabSetTab {
    return {
        name: `${getTabIndex('payment', idxOpts)} ${t.payment}`,
        identifier: 'payment',
        title: capitalize(t.payment),
        description: capitalize(t.please_select),
        backButtonText: t.back_to_webshop,
        hideTitle: onConfirmationPage ? true : false,
        content: (
            <PaymentView
                onPaymentCancelled={onPaymentCancelled}
                onPaymentCompleted={onPaymentCompleted(idxOpts)}
            />
        ),
        disabled: !isCheckoutInfoDataValid || onConfirmationPage,
    };
}

function createPackageTab(
    idxOpts: IdxOpts,
    t: Translations,
    onConfirmationPage: boolean,
    setActiveTab: (activeTab: CheckoutTabType) => void,
): CheckoutTabSetTab {
    return {
        name: `${getTabIndex('package', idxOpts)} ${t.packages}`,
        identifier: 'package',
        title: capitalize(t.package),
        description: capitalize(t.pleaseChoose),
        backButtonText: t.back_to_webshop,
        content: (
            <PackageView
                onNextClicked={() =>
                    setActiveTab(
                        idxOpts.hasRelatedProducts
                            ? 'relatedproducts'
                            : idxOpts.hasExtras
                              ? 'extras'
                              : 'contactinfo',
                    )
                }
                nextButtonText={
                    idxOpts.hasRelatedProducts
                        ? t.related_products
                        : idxOpts.hasExtras
                          ? t.extras
                          : t.contact_details
                }
            />
        ),
        disabled: onConfirmationPage,
    };
}
