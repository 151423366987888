import { Box, SxProps, Tab as MuiTab, Tabs as MuiTabs, useTheme } from '@mui/material';
import { createRef, useEffect } from 'react';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

type Tab = {
    name: string;
    content: React.ReactNode[] | React.ReactNode;
    disabled?: boolean;
    sx?: SxProps;
};
type TabSet = {
    ordered?: boolean;
    tabs: Tab[];
};

type TabsProps = {
    tabset: TabSet;
    currentTab: number;
    onChange: (index: number) => void;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function Tabs({ tabset: { tabs, ordered }, currentTab, onChange }: TabsProps) {
    const theme = useTheme();
    const config = useConfigurations();
    const customizations = useCustomizations();

    const sentinelRef = createRef<HTMLDivElement>();

    useEffect(() => {
        sentinelRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headerSize = `${50 + 48 + 8 * 2}px`;

    const handleChangeTab = (_e: any, value: number) => {
        onChange(value);
    };

    const logoString = config.logo !== '' ? config.logo : customizations.iconUrl;

    const tabToRender = tabs[currentTab];

    return (
        <Box sx={{ marginBottom: theme.spacing(15) }}>
            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: customizations.checkoutHeaderColor, //theme.palette.grey[50],
                    color: customizations.checkoutHeaderColorContrast,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: headerSize,
                    '& .MuiTabs-flexContainer': {
                        borderBottom: customizations.navigationTabBorderBottom,
                    },

                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        '& img': {
                            marginLeft: theme.spacing(3),
                        },
                    },
                    [theme.breakpoints.down('md')]: {
                        '& img': {
                            marginTop: theme.spacing(2),
                        },
                    },
                    [theme.breakpoints.up('lg')]: {
                        flexDirection: 'row',
                        height: null,

                        '& img': {
                            position: 'absolute',
                            marginLeft: theme.spacing(3),
                        },
                    },
                    [theme.breakpoints.down('lg')]: {
                        '& img': {
                            height: theme.spacing(6.25),
                            marginLeft: theme.spacing(2),
                        },
                    },
                }}
            >
                {logoString && (
                    <img
                        onClick={() => history.back()}
                        src={logoString}
                        style={{
                            cursor: 'pointer',
                            alignSelf: 'center',
                            maxHeight: theme.spacing(10),
                        }}
                    />
                )}
                <MuiTabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    textColor="inherit"
                    sx={{
                        color: customizations.checkoutHeaderColorContrast,
                        margin: '0 auto',
                        maxWidth: `calc(960px + ${theme.spacing(2)})`,
                        overflowX: 'auto',
                        width: '100%',
                        '& .Mui-selected': {
                            color: customizations.checkoutHeaderPrimaryColor,
                        },
                        '& .Mui-disabled': {
                            color: customizations.checkoutHeaderColorContrast,
                            opacity: 0.5,
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: customizations.checkoutHeaderPrimaryColor,
                        },
                        '& .MuiTabs-flexContainer': {
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            maxWidth: `calc(960px + ${theme.spacing(2)})`,
                            overflowX: 'auto',
                        },
                        [`@media screen and (max-width: calc(1450px + ${theme.spacing(2)}))`]: {
                            maxWidth: `calc(850px + ${theme.spacing(2)})`,
                        },
                        '@media print': {
                            '& .no-print': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    {tabs.map((tab, i) => (
                        <MuiTab
                            key={`tab-header-${tab.name}`}
                            className="no-print"
                            sx={{
                                opacity: 1.0,
                            }}
                            disabled={tab.disabled || (ordered && currentTab < i)}
                            label={tab.name}
                            {...a11yProps(i)}
                        />
                    ))}
                </MuiTabs>
            </Box>
            <div ref={sentinelRef} tabIndex={0} />
            <Box position="relative" m="0 auto" maxWidth={`calc(1100px + ${theme.spacing(2)})`}>
                {tabToRender && (
                    <Box
                        key={`tab-panel-${tabToRender.name}`}
                        role="tabpanel"
                        id={`tabpanel-${currentTab}`}
                        aria-labelledby={`tab-${currentTab}`}
                    >
                        <Box
                            sx={{
                                px: theme.spacing(1),
                                mt: theme.spacing(4),
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {tabToRender.content}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export { Tab, TabSet, TabsProps };
