import { Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { getCustomOrDefaultText, useLocale, formatCurrency } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import CreateGiftcardValue from './CreateGiftcardValue';
import { TZDate } from '@repo/tzdate';
import { GiftCardPromoCode } from './GiftCardPromoCode';
import { useCartContext } from 'src/widgets/CartContext';

interface IProps {
    largeScreen: boolean;
    giftcardValue: number;
    totalPrice: number;
    onGiftcardValueChanged: (giftcardValue: number) => void;
    showError?: boolean;
    expiryDate?: TZDate;
}

const giftCardPriceDescriptionKey = 'gift_card_price_description';

export default function CreateGiftcard(props: IProps): JSX.Element {
    const {
        largeScreen,
        giftcardValue,
        totalPrice,
        onGiftcardValueChanged,
        showError = false,
        expiryDate,
    } = props;
    const { t, locale } = useLocale();
    const config = useConfigurations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const { appliedPromoCode, setAppliedPromoCode } = useCartContext();

    const onValueChange = (value: number): void => {
        onGiftcardValueChanged(value);
    };

    const giftCardPriceDescription = getCustomOrDefaultText(
        config.textCustomizations,
        giftCardPriceDescriptionKey as any,
        locale,
        '',
    ).trim();

    return (
        <Stack
            component={Paper}
            variant="outlined"
            mt={theme.spacing(2)}
            mr={largeScreen ? 0 : theme.spacing(2)}
            padding={theme.spacing(3, 3, 2, 3)}
            minHeight="400px"
            minWidth={largeScreen ? '400px' : '300px'}
            width="100%"
            color={theme.palette.primary.main}
            border={`1px solid ${theme.palette.grey[300]}`}
            sx={{
                backgroundColor: theme.palette.grey[50],
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    maxWidth: '440px',
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                },
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
        >
            <Typography color="textSecondary" variant="h4">
                <Box component="span" fontSize="20px" fontWeight="600">
                    {capitalize(t.gift_card)}
                </Box>
            </Typography>
            {giftCardPriceDescription && (
                <Box
                    sx={{
                        ...theme.typography.body1,
                        color: `${theme.palette.text.secondary}`,
                    }}
                    mt={2}
                >
                    {parseHtmlToMui(giftCardPriceDescription)}
                </Box>
            )}
            <Box flex={1}>
                <CreateGiftcardValue
                    giftcardValue={giftcardValue}
                    onChange={onValueChange}
                    showError={showError}
                />
            </Box>
            <GiftCardPromoCode
                onPromoCodeApplied={setAppliedPromoCode}
                appliedPromoCode={appliedPromoCode}
                currentPriceReduction={
                    appliedPromoCode?.coupon_type === 'percent'
                        ? giftcardValue * (appliedPromoCode?.coupon_value / 100)
                        : (appliedPromoCode?.coupon_value ?? 0)
                }
                writable
            />
            <Typography align="right" color="textSecondary" variant="body1" pt={2}>
                <Box component="span" fontSize="14px">
                    {expiryDate && (
                        <Fragment>
                            {capitalize(t.valid_until)}: {expiryDate.format('YYYY-MM-DD')}
                        </Fragment>
                    )}
                </Box>
            </Typography>
            <Grid
                container
                direction="row"
                alignItems="baseline"
                justifyContent="flex-end"
                borderTop={`1px solid ${theme.palette.grey[400]}`}
            >
                <Grid item>
                    <Typography color="textSecondary" variant="h6" pr={'12px'}>
                        <Box component="span" fontSize="18px">
                            {t.total.toUpperCase()}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="textSecondary" variant="h4" mt={theme.spacing(2)}>
                        <Box component="span" fontSize="28px" fontWeight="600">
                            {formatCurrency(locale, currency, totalPrice)}
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
    );
}
