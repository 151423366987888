import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Radio, Stack, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';

type RadioPanelProps = {
    Icon: typeof SvgIcon;
    text: string;
    subtext?: string | React.ReactNode;
    subtitle?: string;
    children: React.ReactNode[] | React.ReactNode;
    isOpen?: boolean;
    setIsOpen?: (val: boolean) => void;
    isChecked?: boolean;
    variant?: 'radio' | 'normal';
    onChange?: (param?: boolean) => void;
    disabled?: boolean;
    notExpandable?: boolean;
    startsOpen?: boolean;
    expandIfChecked?: boolean;
};

export default function RadioPanel(props: RadioPanelProps) {
    const {
        Icon,
        text,
        subtext,
        subtitle,
        children,
        isChecked = false,
        isOpen = false,
        setIsOpen,
        variant = 'radio',
        notExpandable = false,
        onChange,
        disabled = false,
        expandIfChecked = false,
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleRadioClicked = () => onChange && onChange(!isChecked);
    const [open, setOpen] = useState(expandIfChecked ? isChecked : isOpen);
    const handleExpand = (e: any) => {
        e.stopPropagation();
        setOpen(!open);
        setIsOpen && setIsOpen(!open);
    };

    const subTextNode =
        subtext && typeof subtext === 'string' ? (
            <Text variant="small" alignment="right" sx={{ whiteSpace: 'nowrap' }}>
                {subtext}
            </Text>
        ) : subtext ? (
            <>{subtext}</>
        ) : undefined;

    return (
        <Panel
            sx={{
                padding: theme.spacing(1, 2),
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? 'default' : 'pointer',
            }}
        >
            <Box
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                onClick={variant === 'normal' ? handleExpand : handleRadioClicked}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter')
                        variant === 'normal' ? handleExpand(e) : handleRadioClicked();
                }}
                tabIndex={0}
            >
                <Layout
                    justify="flex-start"
                    alignItems="center"
                    spacing={isMobile ? 'dense' : 'normal'}
                >
                    {variant === 'radio' && (
                        <Radio
                            checked={isChecked}
                            color="primary"
                            value={text}
                            name={`${text}-radio-button`}
                            inputProps={{ 'aria-label': text }}
                            disabled={disabled}
                        />
                    )}
                    <Stack justifyItems="center">
                        <Icon fontSize="large" />
                    </Stack>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <PanelTitle>
                            <span>{text}</span>
                        </PanelTitle>
                        {subtitle && (
                            <Text sx={{ fontSize: theme.typography.body2.fontSize }}>
                                <span>{subtitle}</span>
                            </Text>
                        )}
                    </Box>
                </Layout>
                {!notExpandable && !expandIfChecked && (
                    <Layout
                        sx={{ marginLeft: theme.spacing(1) }}
                        justify="center"
                        alignItems="center"
                    >
                        {open ? (
                            <ExpandLessIcon fontSize="large" onClick={handleExpand} />
                        ) : (
                            <Fragment>
                                {subTextNode}
                                <ExpandMoreIcon fontSize="large" onClick={handleExpand} />
                            </Fragment>
                        )}
                    </Layout>
                )}
            </Box>
            <Collapse in={expandIfChecked ? isChecked : open} sx={{ width: '100%' }}>
                <Layout
                    flexDirection="column"
                    justify="center"
                    alignItems="center"
                    sx={{ padding: theme.spacing(4) }}
                >
                    {children}
                </Layout>
            </Collapse>
        </Panel>
    );
}
