import { Translations } from '@repo/types';
import { MarkType } from 'src/components/common/duration-slider/DurationSlider';

export function getDurationMarks(t: Translations): MarkType[] {
    return [
        {
            value: -1,
            minuteValue: -1,
            compareValue: 0,
            labelValue: '15',
            label: t.minutes.num(15),
        },
        {
            value: 1,
            minuteValue: 30,
            compareValue: 30,
            labelValue: '30',
            label: t.minutes.num(30),
        },
        {
            value: 2,
            minuteValue: 45,
            compareValue: 45,
            labelValue: '45',
            label: t.minutes.num(45),
        },
        ...new Array(12).fill(1).map((_, i) => ({
            value: 2 + (i + 1),
            minuteValue: (i + 1) * 60,
            compareValue: (i + 1) * 60,
            labelValue: `${i + 1}`,
            label: t.hours.num(i + 1),
        })),
        ...new Array(5).fill(1).map((_, i) => ({
            value: 2 + 12 + (i + 1),
            minuteValue: (i + 1) * 60 * 24,
            compareValue: (i + 1) * 60 * 24,
            labelValue: `${i + 1}`,
            label: t.days.num(i + 1),
        })),
        {
            value: 2 + 12 + 6,
            minuteValue: 7 * 60 * 24,
            compareValue: 7 * 60 * 24,
            labelValue: '7',
            label: t.days.num(7),
        },
        {
            value: 2 + 12 + 7,
            minuteValue: 10 * 60 * 24,
            compareValue: 10 * 60 * 24,
            labelValue: '10',
            label: t.days.num(10),
        },
        {
            value: 2 + 12 + 8,
            minuteValue: 14 * 60 * 24,
            compareValue: 14 * 60 * 24,
            labelValue: '14',
            label: t.days.num(14),
        },
        {
            value: 2 + 12 + 9,
            minuteValue: 21 * 60 * 24,
            compareValue: 21 * 60 * 24,
            labelValue: '21',
            label: t.days.num(21),
        },
        {
            value: 2 + 12 + 10,
            minuteValue: 28 * 60 * 24,
            compareValue: 28 * 60 * 24,
            labelValue: '28',
            label: t.days.num(28),
        },
        {
            value: Number.MAX_VALUE,
            compareValue: 31 * 60 * 24,
            minuteValue: Number.MAX_VALUE,
            labelValue: '31+',
            label: t.days.plural,
        },
    ];
}
