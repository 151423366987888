import { SxProps } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { TicketOptionWithQuantity, TicketType } from '@repo/types';
import { useAtom } from 'ximple';
import { useCartContext } from 'src/widgets/CartContext';
import { useMemo } from 'react';
import { mapQuantityToSummaryPriceLine, PromoCodeUsageContext } from './summary-mapping';
import { SummaryPriceLine, SummaryPriceLineSkeleton } from './SummaryPriceLine';

export function SummaryInfoFromQuantities({
    quantities,
    ticketType,
    invertedColorsClass,
    isDetailed,
    isLoadingPrices,
}: {
    quantities: TicketOptionWithQuantity[];
    ticketType?: TicketType;
    invertedColorsClass?: SxProps;
    isDetailed: boolean;
    isLoadingPrices: boolean;
}) {
    const { locale, t } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const { appliedPromoCode } = useCartContext();
    const numSelectedQuantities = quantities.filter((q) => q.quantity > 0).length;

    const lines = useMemo(() => {
        const quantitiesWithQuantity = quantities.filter((quantity) => quantity.quantity > 0);

        const promoCodeUsageContext: PromoCodeUsageContext = {
            promoCodeUsages: new Map<string, number>(),
            promoCodeUsagesPerOrder: new Map<string, number>(),
        };

        return quantitiesWithQuantity.flatMap((x) => {
            const mapped = mapQuantityToSummaryPriceLine(
                x,
                appliedPromoCode,
                promoCodeUsageContext,
                locale,
                currency,
                t,
                isDetailed,
                ticketType,
            );
            return mapped;
        });
    }, [quantities, ticketType, appliedPromoCode, locale, currency, t, isDetailed]);

    return (
        <>
            {isLoadingPrices && (
                <SkeletonPriceLines numLines={numSelectedQuantities}></SkeletonPriceLines>
            )}

            {!isLoadingPrices &&
                lines.map((line, i) => {
                    return (
                        <SummaryPriceLine
                            key={i}
                            data={line}
                            typographySx={invertedColorsClass}
                        ></SummaryPriceLine>
                    );
                })}
        </>
    );
}

function SkeletonPriceLines(props: { numLines: number }) {
    return (
        <>
            {new Array(props.numLines).fill(false).map((_x, i) => (
                <SummaryPriceLineSkeleton key={i}></SummaryPriceLineSkeleton>
            ))}
        </>
    );
}
