import { formatCurrency, getLocaleNumberFormatNoDecimals } from '@repo/i18n';
import {
    BilberryPromoCodeStatus,
    Currency,
    MembershipPaymentPlan,
    PackageTicketOptionWithQuantity,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
    Translations,
} from '@repo/types';
import { getPriceFromTicketOption } from '@repo/widget-utils/price-helper';
import { groupBy, sumBy } from 'lodash-es';

export type PromoCodeUsageContext = {
    promoCodeUsages: Map<string, number>;
    promoCodeUsagesPerOrder: Map<string, number>;
};

export type SummaryPriceLineData = {
    ticketName: string;
    ticketDetail?: string;
    ticketDetailStrikeThrough: boolean;
    priceText: string;
    depositMessage?: string;
};

function getPriceText(
    isFree: boolean,
    creditCost: number,
    currencyCost: number,
    locale: string,
    t: Translations,
) {
    if (isFree) return t.inclInMembership;

    if (creditCost > 0) {
        return `${creditCost} ${t.credit.num(creditCost).toLowerCase()}`;
    }

    return getLocaleNumberFormatNoDecimals(locale, currencyCost);
}

export function mapQuantityToDetailedSummaryPriceLineForMembership(
    paymentPlans: MembershipPaymentPlan[],
    isAccommodation: boolean,
    locale: string,
    t: Translations,
    overrideQuantity?: number,
): SummaryPriceLineData[] {
    const byAggregateKey = groupBy(paymentPlans, (x) => {
        const creditCost = sumBy(x.valueCardUsages, (u) => u.creditCost);
        const priceReductionPercentage = sumBy(
            x.valueCardUsages,
            (u) => u.priceReductionPercentage,
        );

        return overrideQuantity
            ? `` // We don't want to differentitate group at all if quantity is overriden, because that will make the aggregation incorrect
            : `${x.ticketName}|${creditCost}|${priceReductionPercentage}|${x.currencyCost?.price}`;
    });

    const aggregatedUsage = Object.entries(byAggregateKey).map(([key, x]) => {
        const [firstPlan] = x;
        const usages = x.flatMap((v) => v.valueCardUsages);
        const [firstUsage] = usages;

        const creditCostSum = sumBy(usages, (x) => x.creditCost);
        const currencyCostSum = sumBy(x, (x) => x.currencyCost?.price ?? 0);

        const isFree = creditCostSum === 0 && currencyCostSum === 0;

        const allRates = x.flatMap((x) => x.currencyCost?.rates ?? []);
        const originalCostSum = sumBy(allRates, (x) => x.originalPrice);

        return {
            key: key,
            ticketQuantity: overrideQuantity ?? x.length,
            ticketName: firstPlan.ticketName,
            creditCost: creditCostSum / 100,
            currencyCost: currencyCostSum / 100,
            priceReductionPercentage: firstUsage?.priceReductionPercentage ?? 0,
            isFree,
            originalCost: originalCostSum / 100,
        };
    });

    return aggregatedUsage.map((x) => {
        const isDiscounted = x.currencyCost < x.originalCost;

        return {
            key: x.key,
            ticketName: getTicketQuantityText(x.ticketQuantity, x.ticketName, isAccommodation, t),
            priceText: getPriceText(x.isFree, x.creditCost, x.currencyCost, locale, t),
            ticketDetail: isDiscounted
                ? `${getLocaleNumberFormatNoDecimals(locale, x.originalCost)}`
                : undefined,
            ticketDetailStrikeThrough: isDiscounted,
        };
    });
}

export function mapQuantityToSummaryPriceLineForMembership(
    paymentPlans: MembershipPaymentPlan[],
    isAccommodation: boolean,
    locale: string,
    t: Translations,
    overrideQuantity?: number,
): SummaryPriceLineData[] {
    if (!paymentPlans.length) return [];

    const [firstPlan] = paymentPlans;
    const usages = paymentPlans.flatMap((v) => v.valueCardUsages);

    const creditCostSum = sumBy(usages, (x) => x.creditCost);
    const currencyCostSum = sumBy(paymentPlans, (x) => x.currencyCost?.price ?? 0);

    const isFree = creditCostSum === 0 && currencyCostSum === 0;

    const ticketQuantity = overrideQuantity ?? paymentPlans.length;
    const { ticketName } = firstPlan;
    const creditCost = creditCostSum / 100;
    const currencyCost = currencyCostSum / 100;

    const allRates = paymentPlans.flatMap((x) => x.currencyCost?.rates ?? []);
    const originalCost = sumBy(allRates, (x) => x.originalPrice) / 100;
    const isDiscounted = currencyCost < originalCost;

    return [
        {
            ticketName: getTicketQuantityText(ticketQuantity, ticketName, isAccommodation, t),
            priceText: getPriceText(isFree, creditCost, currencyCost, locale, t),
            ticketDetail: isDiscounted
                ? `${getLocaleNumberFormatNoDecimals(locale, originalCost)}`
                : undefined,
            ticketDetailStrikeThrough: isDiscounted,
        },
    ];
}

function mapQuantityToSummaryPriceLineForNonMembership(
    quantity: TicketOptionWithQuantity,
    appliedPromoCode: BilberryPromoCodeStatus | null,
    promoCodeUsageContext: PromoCodeUsageContext,
    locale: string,
    currency: Currency,
    t: Translations,
    ticketType?: TicketType,
): SummaryPriceLineData[] {
    const { totalPriceExDiscounts } = getPriceFromTicketOption(
        quantity,
        ticketType,
        appliedPromoCode,
        promoCodeUsageContext,
    );

    const depositMessage = getDepositMessageForQuantity(quantity, t, locale);
    const ticketName = getTicketNameForQuantity(quantity, t);
    const priceText = getPriceTextForQuantity(locale, totalPriceExDiscounts);
    const ticketDetail = getTicketDetailForQuantity(quantity, locale, currency, t);

    return [
        {
            ticketName: ticketName,
            ticketDetail: ticketDetail,
            ticketDetailStrikeThrough: false,
            priceText: priceText,
            depositMessage: depositMessage,
        },
    ];
}

export function mapQuantityToSummaryPriceLine(
    quantity: TicketOptionWithQuantity,
    appliedPromoCode: BilberryPromoCodeStatus | null,
    promoCodeUsageContext: PromoCodeUsageContext,
    locale: string,
    currency: Currency,
    t: Translations,
    isDetailed: boolean,
    ticketType?: TicketType,
): SummaryPriceLineData[] {
    if (quantity.membership) {
        const productType = getProductTypeForQuantity(quantity);
        const isAccommodation = productType === 'accommodation';
        const isDaysOrNights = productType === 'nights' || productType === 'days';

        const overrideQuantity = isDaysOrNights
            ? getDurationCountForQuantity(quantity, true)
            : undefined; // Special case where we don't want to count the tickets, but use ticket option's quantity as it reflects number of days/nights

        return isDetailed
            ? mapQuantityToDetailedSummaryPriceLineForMembership(
                  quantity.membership,
                  isAccommodation,
                  locale,
                  t,
                  overrideQuantity,
              )
            : mapQuantityToSummaryPriceLineForMembership(
                  quantity.membership,
                  isAccommodation,
                  locale,
                  t,
                  overrideQuantity,
              );
    }

    return mapQuantityToSummaryPriceLineForNonMembership(
        quantity,
        appliedPromoCode,
        promoCodeUsageContext,
        locale,
        currency,
        t,
        ticketType,
    );
}

function getTicketDetailForQuantity(
    quantity: TicketOptionWithQuantity,
    locale: string,
    currency: Currency,
    t: Translations,
) {
    const productType = getProductTypeForQuantity(quantity);
    const isTimeslot = productType === 'timeslot';
    const isTimepoint = productType === 'timepoint';
    const isNights = productType === 'nights';
    const isDays = productType === 'days';
    const isAccommodation = productType === 'accommodation';
    const isPackage = !!(quantity as PackageTicketOptionWithQuantity).products;

    const countNights = isNights || isAccommodation;
    const durationCount = getDurationCountForQuantity(quantity, countNights);

    if (isTimeslot) return '';

    if (isTimepoint || isPackage) return `${formatCurrency(locale, currency, quantity.price)}`;

    if (countNights) return `${durationCount} ${t.night.num(durationCount)}`;

    if (isDays) return `${durationCount} ${t.days.num(durationCount)}`;
}

function getDurationCountForQuantity(quantity: TicketOptionWithQuantity, countNights: boolean) {
    if (countNights) {
        return getDuration(quantity.productInstances, 'nights');
    }

    return getDuration(quantity.productInstances);
}

function getPriceTextForQuantity(locale: string, totalPriceExDiscounts: number) {
    return getLocaleNumberFormatNoDecimals(locale, totalPriceExDiscounts);
}

function getProductTypeForQuantity(quantity: TicketOptionWithQuantity) {
    return quantity.productInstances[0]?.product?.type ?? '';
}

function getTicketNameForQuantity(quantity: TicketOptionWithQuantity, t: Translations) {
    const productType = getProductTypeForQuantity(quantity);
    const isAccommodation = productType === 'accommodation';
    return getTicketQuantityText(quantity.quantity, quantity.name, isAccommodation, t);
}

function getTicketQuantityText(
    quantity: number,
    ticketName: string,
    isAccommodation: boolean,
    t: Translations,
) {
    if (isAccommodation) {
        return t.rooms.num(1);
    } else {
        return `${quantity}x ${ticketName}`;
    }
}

function getDepositMessageForQuantity(
    quantity: TicketOptionWithQuantity,
    t: Translations,
    locale: string,
) {
    const depositAmount = quantity.depositAmount ? quantity.depositAmount * quantity.quantity : 0;
    return depositAmount
        ? t.only_deposit_amount_to_pay_now.parsed(
              getLocaleNumberFormatNoDecimals(locale, depositAmount),
          )
        : '';
}

function getDuration(productInstances: ProductInstance[], type: 'nights' | 'days' = 'days') {
    const grouped = groupBy(productInstances, (instance) => instance.start.format('YYYY-MM-DD'));
    return Object.keys(grouped).length - (type === 'nights' ? 1 : 0);
}
