import { createRoot } from 'react-dom/client';
import { useEffect, useState, createRef } from 'react';
import {
    getStringAttribute,
    getYesAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';
import { history } from 'src/utils/widget/setup';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import Booking from './booking/Booking';
import { BookingContextProvider } from 'src/widgets/BookingContext';

export function BookingWidget(props: {
    productCatalogId: string;
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    expandArrowInside: boolean;
    positionOffscreen: boolean;
    isAccommodation: boolean;
    isTimeslots: boolean;
}): JSX.Element {
    const [showBookingWidget, setShowBookingWidget] = useState(
        window.location.hash !== '#/checkout',
    );
    const ref = createRef<HTMLDivElement>();
    const [popoverMountPoint, setPopoverMountPoint] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current) setPopoverMountPoint(ref.current);
    }, [ref]);

    history.listen((event) => {
        setShowBookingWidget(event.location.pathname !== '/checkout');
    });

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <div ref={ref} style={{ position: 'relative', zIndex: 9999999999 }} />
            {showBookingWidget && (
                <BookingContextProvider initialTicketOptions={[]}>
                    <Booking
                        productCatalogId={props.productCatalogId}
                        expandArrowInside={props.expandArrowInside}
                        positionOffscreen={props.positionOffscreen}
                        isAccommodation={props.isAccommodation}
                        isTimeslots={props.isTimeslots}
                    />
                </BookingContextProvider>
            )}
        </WidgetWrapper>
    );
}
class BilberryBooking extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getStringAttribute(this, 'product-catalog-id', false) ?? '';

        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');

        const positionOffscreen = getYesAttribute(this, 'position-offscreen');
        const isAccommodation = getYesAttribute(this, 'accommodation');
        const isTimeslots = getYesAttribute(this, 'timeslots');

        createRoot(this._mountPoint).render(
            <BookingWidget
                productCatalogId={productCatalogId}
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                expandArrowInside={expandArrowInside}
                positionOffscreen={positionOffscreen}
                isAccommodation={isAccommodation}
                isTimeslots={isTimeslots}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-widget', BilberryBooking);
}
