/* eslint-disable max-len */

import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'vis vilkår',
    hide_cancellation_policy: 'skjul vilkår',
    departure: 'Avgang',
    price: 'Pris',
    difficulty: 'Vanskegrad',
    duration: 'Varigheit',
    starting_time: 'Starttidspunkt',
    about: 'Om',
    book_now: 'Bestill no',
    book: 'Bestill',
    buy_as_gift: 'Kjøp som gåve',
    from: 'Frå',
    your_booking_reference_is: 'Di referanse er',
    booking_details_in_email: 'Du vil snart få ein e-post med ytterlegare informasjon',
    see_more_activities: 'Sjå fleire aktivitetar',
    your_cart: 'Di handlekorg',
    in_your_cart: 'I handlekorga di',
    when_are_you_going: 'Vel dato og tid',
    how_many_are_going: 'Kor mange i følgjet?',
    contact_details: 'Kontaktinfo',
    traveler: 'Deltakar',
    extras: 'Tillegg',
    please_select: 'Ver venleg å velje',
    no_items_in_cart: 'Du har ingen produkt i handlekorga',
    payment: 'Betaling',
    receipt: 'Kvittering',
    do_you_have_any_comments_or_special_needs: 'Har du nokre kommentarar eller spesielle behov?',
    i_have_read_and_accepted_the: 'Eg har lese og forstått',
    privacy_policy: 'personvernerklæringa',
    terms_and_conditions: 'Vilkår og Føresetnader',
    first_name: 'Fornamn',
    last_name: 'Etternamn',
    phone_number: 'Telefon',
    dial_code: 'Landskode',
    email: 'E-post',
    please_register: 'Ver venleg å registrere',
    main_contact: 'Kontaktperson',
    additional_information: 'Praktisk informasjon',
    remove_cart_item: 'Fjern frå handlekorg',
    back_to_webshop: 'Hald fram å handle',
    excl: 'eks.',
    vat: 'MVA',
    total: 'Totalt',
    payNow: 'Betal no',
    payLater: 'Betal seinare',
    only_deposit_amount_to_pay_now: {
        template: 'Berre <NAME> å betale i depositum no.',
    } as any,
    next: 'Neste',
    go_and_pay: 'Til betaling',
    print: 'Skriv ut',
    close: 'Lukk',
    checkout: 'gå til kassa',
    summary: 'Oppsummering',
    cancel: 'Avbryt',
    save: 'Lagre',
    select_time: 'Vel tidspunkt',
    selected_time: 'Valt tidspunkt',
    time: 'Tidspunkt',
    go_back: 'Tilbake',
    thank_you_for_booking_with_us: 'Takk for at du bestilte hos oss!',
    please_fill_in_all_required_information: 'ver venleg å leggje inn all naudsynt informasjon',
    error_occurred_when_creating_reservation:
        'beklagar, det oppstod ein feil ved oppretting av reservasjonen',
    error_occurred_when_creating_giftcard:
        'beklagar, det oppstod ein feil ved oppretting av gåvekortet',
    couldnt_apply_promocode_is_the_code_correct: 'Kunne ikkje bruke rabattkoden. Er koden rett?',
    choose_a_country: 'Vel land',
    select_date: 'Vel dato',
    select_travelers: 'Vel deltakarar',
    hourShort: 't',
    dayShort: 'd',
    minuteShort: 'm',
    years: 'år',
    age: 'Alder',
    more_information: 'meir informasjon',
    same_as_main_contact: 'same som kontaktperson',
    easy: 'Lett',
    medium: 'Middels',
    hard: 'Krevjande',
    please_select_date: 'Ver venleg å velje dato',
    please_select_date_and_time: 'ver venleg å velje dato og tidspunkt',
    name: 'Namn',
    comment: 'Kommentar',
    submit_request: 'Send førespurnad',
    any_special_requests_etc: 'Nokre spesielle førespurnader, allergiar...?',
    request_tour_comment: 'Beskriv førespurnaden din',
    thank_you_for_your_request: 'Takk for førespurnaden din, vi kjem raskt tilbake til deg.',
    got_it: 'Det er greitt!',
    continue_shopping: 'Hald fram å handle',
    continue_travelers: 'Reisande',
    please_provide_your_name: 'Ver venleg å oppgje namnet ditt',
    please_provide_your_email: 'Ver venleg å oppgje ei gyldig e-postadresse',
    please_provide_a_description: 'Ver venleg å leggje til ei skildring',
    an_error_occurred_when_submitting_your_request:
        'Ein feil oppstod under innsendinga av skjemaet. Ver venleg å prøve igjen seinare.',
    private_tour: 'Privat tur',
    read_more_and_book: 'Les meir og bestill',
    read_more: 'Les meir',
    prices_from: 'Prisar frå',
    about_the_trip: 'Om turen',
    prices: 'Prisar',
    whats_included: 'Inkludert i turen',
    any: 'Alle',
    duration_up_to_1_hour: 'Opptil 1 time',
    duration_1_2_hours: '1 - 2 timar',
    duration_2_6_hours: '2 - 6 timar',
    duration_6_12_hours: '6 - 12 timar',
    duration_12_24_hours: '12 - 24 timar',
    duration_1_2_days: '1 - 2 dagar',
    duration_more_than_2_days: 'Meir enn 2 dagar',
    find_activities: 'Finn aktivitetar',
    ok: 'OK',
    clear: 'Tøm',
    filterActivities: 'Filtrer aktivitetar',
    hideFilters: 'Skjul filter',
    shopMore: 'Handle meir',
    noSearchResults: 'Beklagar, vi fann ingen ledige aktivitetar i søket ditt.',
    update: 'Oppdater',
    select_date_to_view_timeslots: 'Vel ein dato for å sjå ledige tidspunkt',
    your_reservation_timed_out: 'Bestillinga di fekk eit tidsavbrot. Ver venleg å prøve igjen',
    aria_calendar_input_field_label: 'Kalenderfelt. Trykk Enter for å opne.',
    aria_book_now_button: 'Opne eit vindauge for bestilling av denne aktiviteten',
    aria_next_image: 'Neste bilete',
    aria_previous_image: 'Førre bilete',
    aria_open_booking_widget: 'Opne bestillingsvindauge',
    aria_close_booking_widget: 'Lukk bestillingsvindauge',
    aria_open_request_tour_widget: 'Opne førespurnadsvindauge',
    aria_close_request_tour_widget: 'Lukk førespurnadsvindauge',
    aria_open_find_activity: 'Opne finn aktivitetar',
    aria_close_find_activity: 'Lukk finn aktivitetar',
    aria_show_giftcard_input: 'Vis felt for gåvekort',
    aria_hide_giftcard_input: 'Gøym felt for gåvekort',
    fill_giftcard_code: 'Skriv gåvekortkode',
    aria_show_promocode_input: 'Vis felt for rabattkode',
    aria_hide_promocode_input: 'Gøym felt for rabattkode',
    aria_price_to_pay_summary: 'Oppsummering av pris å betale',
    fill_promocode: 'Skriv rabattkode',
    request_tour: 'send førespurnad',
    giftcard_message: 'melding',
    giftcard_message_label: 'personleg melding til mottakar (valfritt)',
    recipient_details: 'mottakar si kontaktinformasjon',
    gift_card: 'gåvekort',
    value: 'verdi',
    create: 'opprett',
    create_your_gift: 'opprett gåvekort',
    your_giftcard_reference_is: 'referansen din er',
    giftcard_voucher_and_receipt:
        'Vi har sendt deg eit gåvekort som er klart for utskrift, saman med ei kvittering til',
    giftcard_voucher: 'vi har sendt gåvekortet til:',
    giftcard_and_receipt: 'og ei kvittering til:',
    thank_you_for_your_purchase: 'Takk for handelen!',
    valid_until: 'gyldig til',
    print_receipt: 'skriv ut',
    select_gift_type: 'vel type gåvekort',
    please_select_gift_type: 'ver venleg å velje type gåvekort',
    select_gift_print_description: 'motta ein PDF i innboksen din som kan skrivast ut',
    select_gift_email_description: 'send gåvekortet på e-post direkte til mottakaren',
    enter_giftcard_code: 'Bruk gåvekort',
    enter_promo_code: 'Bruk rabattkode',
    apply: 'bruk',
    giftcard_code_error: 'gåvekortkoden er ugyldig',
    remaining_value: 'resterande beløp',
    giftcard_code_not_found: 'fann ikkje gåvekort med koden:',
    giftcard_zero_balance: 'gåvekortet er allereie oppbrukt',
    giftcard_is_expired: 'gåvekortet er utløpt',
    purchased_giftcard: {
        self: 'gåvekort du kan skrive ut',
        recipient: 'gåvekort sendt til mottakar',
    },
    contact_information: {
        short: 'kontaktinfo',
        long: 'kontaktinformasjon',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minutt',
    } as any,
    hours: {
        singular: 'time',
        plural: 'timar',
    } as any,
    days: {
        singular: 'dag',
        plural: 'dagar',
    } as any,
    children: {
        singular: 'barn',
        plural: 'barn',
    } as any,
    adult: {
        singular: 'vaksen',
        plural: 'vaksne',
    } as any,
    card: {
        singular: 'kort',
        plural: 'kort',
    } as any,
    too_few_travelers: 'For få deltakarar',
    too_few_travelers_quantity: {
        template: 'Minimum <COUNT:billett:billetter> påkravd vald dag',
    },
    number_of_travelers_exceeded: {
        template: 'Maks <COUNT:billett:billetter> tilgjengeleg vald dag',
    } as any,
    booking_is_closed: {
        template: 'Booking for <NAME> er no stengd',
    } as any,
    rooms: {
        singular: 'rom',
        plural: 'rom',
    } as any,
    person: {
        singular: 'person',
        plural: 'personar',
    } as any,
    select_room: 'vel rom',
    change_room: 'endre rom',
    less_than: 'mindre enn',
    year: {
        singular: 'år',
        plural: 'år',
    } as any,
    select_age: 'vel alder',
    done: 'ferdig',
    number_of_rooms: 'tal rom',
    select_bed: 'vel seng',
    room_info: 'rominformasjon',
    price_one_night_one_guest: {
        template: 'pris for <COUNT:natt:netter>, <COUNT:gjest:gjester>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:natt:netter>, <COUNT:gjest:gjester>',
    } as any,
    price_nights: {
        template: 'pris for <COUNT:natt:netter>',
    } as any,
    night: {
        singular: 'natt',
        plural: 'netter',
    } as any,
    select_room_for_total_price: 'vel rom for å sjå totalpris',
    find_rooms: 'finn rom',
    check_in_check_out: 'Innsjekk og Utsjekk',
    guest_and_rooms: 'Gjestar og rom',
    no_accommodations_found:
        'Vi beklagar, men vi kunne ikkje finne nokon rom som passar søkjekriteria dine. Har du vurdert å justere søket ditt for å finne ledige rom?',
    facilities_included: 'Inkluderte fasilitetar',
    price_per_night: 'Pris per natt',
    season: 'Sesong',

    has_airconditioning: 'Aircondition',
    has_balcony: 'Balkong',
    has_bicycles: 'Syklar inkludert',
    has_boat: 'Båt inkludert',
    has_coffee_maker: 'Kaffimaskin',
    has_dishwasher: 'Oppvaskmaskin',
    has_dryer: 'Tørketrommel',
    has_fireplace: 'Peis',
    has_garage: 'Garasje',
    has_garden_furniture: 'Hagemøblar',
    has_grill: 'Grill',
    has_kitchen: 'Kjøken',
    has_minibar: 'Minibar',
    has_mooring_space: 'Område for fortøying',
    has_pool: 'Badebasseng',
    has_roomservice: 'Room Service',
    has_sauna: 'Badstu',
    has_tv: 'TV',
    has_washing_machine: 'Vaskemaskin',
    has_wifi: 'Trådlaust internett',
    is_breakfast_included: 'Frukost',
    is_cancellation_included: 'Høve til kansellering',
    is_pets_allowed: 'Kjæledyr tillate',
    is_smoking_allowed: 'Røyking tillate',
    is_towels_and_bedlines_included: 'Handklede og sengetøy',
    is_wheelchair_accessible: 'Tilgjengeleg for rullestolbrukarar',
    wifi: 'WiFi',
    breakfast: 'Frukost',
    accessible: 'Tilgjengeleg',
    pets: 'Kjæledyr',
    kitchen: 'Kjøken',
    smoking: 'Røyking',
    unknown: 'Ukjend',
    accommodation_cancellation_policy: '',
    accommodations: 'Overnatting',
    activities: 'Aktivitetar',
    city: 'By',
    post_code: 'Postnummer',
    to: 'til',
    date_range_start: 'start',
    date_range_end: 'slutt',
    flag_of: 'flagget til',
    monday: 'måndag',
    tuesday: 'tysdag',
    wednesday: 'onsdag',
    thursday: 'torsdag',
    friday: 'fredag',
    saturday: 'laurdag',
    sunday: 'sundag',
    today: 'i dag',
    tomorrow: 'i morgon',
    january: 'januar',
    february: 'februar',
    march: 'mars',
    april: 'april',
    may: 'mai',
    june: 'juni',
    july: 'juli',
    august: 'august',
    september: 'september',
    october: 'oktober',
    november: 'november',
    december: 'desember',
    few_left: 'få att',
    tickets_left: {
        template: '<COUNT:billett:billetter> att',
    } as any,
    available: 'ledig',
    sold_out: 'utseld',
    upcoming_tours_title: 'Kommande turar',
    unavailable: 'Ikkje tilgjengeleg',
    notes_regarding_your_stay: 'Notat til ditt opphald',
    notes_for_accommodation_name_with_guests: {
        template: 'Notat til <NAME> med <COUNT:gjest:gjester>',
    } as any,
    select_price_type: 'Vel pristype',
    finding_available_rooms: 'Finn ledige rom',
    total_price: 'Totalpris',
    of: 'av',
    select_dates: 'Vel datoar',
    please_select_price_type: 'Ver venleg å velje pristype',
    a_minimum_of_participants_is_required_to_book_this_product:
        'Det krevst fleire deltakarar for å bestille dette produktet',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: 'Det krevst minst <COUNT> deltakarar for å bestille dette produktet',
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Ei ordrestadfesting vil bli sendt til di e-postadresse så snart vi har handsama ordren din.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Ei ordrestadfesting vil bli sendt til di e-postadresse så snart vi har handsama ordren din.',
    address: 'Adresse',
    add: 'Legg til',
    amount: 'Tal',
    areYouAMemberOrHaveValueCard: 'Er du medlem eller har klippekort?',
    areYouSureYouWantToCancelYourBooking: 'Er du sikker på at du vil kansellere bookinga?',
    areYouSureYouWantToCancelYourMembership:
        'Er du sikker på at du vil avslutte medlemskapet ditt?',
    automaticallyRenewed: 'Automatisk fornya',
    at: 'Hos',
    backToWebsite: 'Tilbake til nettsida',
    belayCertification: 'Sikringskort',
    belayCertificationConsent: 'Eg har brattkort og/eller topptaukort',
    buyMembership: 'Kjøp periodebillett',
    buyNow: 'Kjøp no',
    canBeCancelledFrom: 'Kan bli kansellert frå',
    cancelled: 'Kansellert',
    cancellation: 'Avbestilling',
    choose_payment_method: 'Vel betalingsmåte',
    bookNow: 'Kjøp no',
    bookTimeslot: 'Kjøp billettar',
    chooseACountry: 'Vel eit land',
    choose: 'Vel',
    chooseDateAndTime: 'Vel dato og tid',
    chooseCreditsType: 'Vel klippekortype',
    chooseNumberOfCredits: 'Vel tal på klipp',
    chooseStartDate: 'Vel startdato',
    chooseType: 'Vel type',
    ticketCode: 'Ordrereferanse',
    completed: 'Fullført',
    consents: 'Samtykkje',
    contact: 'Kontakt',
    contactByEmail: 'Kontakt på e-post',
    contactBySms: 'Kontakt på SMS',
    couldntApplyGiftcardIsTheCodeCorrect: 'Kunne ikkje hente gåvekortet. Er koden rett?',
    couldntApplyPromocodeIsTheCodeCorrect: 'Kunne ikkje bruke rabattkoden. Er koden rett?',
    couldntGetCompanyInformation: 'Kunne ikkje hente informasjon om firmaet',
    couldntGetPaymentPlan:
        'Kunne ikkje hente betalingsplan. Ver venleg å prøve å laste nettsida på nytt.',
    couldntGetPaymentPlanUsingPreviousPlan: 'Kunne ikkje hente betalingsplan, brukar førre plan',
    couldntGetValueCards: 'Kunne ikkje hente verdikort',
    couldntGetValueCardProducts: 'Kunne ikkje hente verdikortprodukt',
    couldntGetProductPleaseTryAgain: 'Kunne ikkje hente produkt, ver venleg å prøve igjen',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'Kunne ikkje opne checkout for bookingar, ikkje all booking-informasjon har blitt spesifisert.',
    couldntOpenProfilePleaseSignInFirst: 'Kunne ikkje opne checkout for bookingar, logg inn først.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'Kunne ikkje opne checkout for verdikort, ikkje all verdikort-informasjon har blitt spesifisert.',
    country: 'Land',
    createAccount: 'Opprett konto',
    credit: {
        singular: 'Klipp',
        plural: 'Klipp',
    },
    credits: 'Klippekort',
    creditsLeft: 'Klipp att',
    creditDiscount: 'Klipperabatt',
    discount: 'Rabatt',
    free: 'Gratis',
    date: 'Dato',
    delete: 'Slett',
    doYouHaveAnAccount: 'Har du konto?',
    doYouHaveValueCards: 'Har du ein konto, eller verdikort?',
    doYouHaveBrattkort: 'Har du brattkort og / eller topptaukort?',
    doYouHaveClimbingCertification: 'Har du ei klatresertifisering?',
    edit: 'Endre',
    editTimeslot: 'Rediger tidsluke',
    failed: 'Feila',
    firstName: 'Fornamn',
    giftcard: 'Gåvekort',
    goToMyPage: 'Gå til mi side',
    ifYouWantToDeleteYourProfile: 'Dersom du vil slette profilen din',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Du kan kjøpe billettar frå butikken på denne nettstaden.',
    yourOrderReferenceIs: 'Din ordrereferanse er',
    iHaveReadAndAccepted: 'Eg har lese og godteke',
    lastName: 'Etternamn',
    postalLocation: 'Poststad',
    location: 'Stad',
    loggedInAs: 'Innlogga som',
    login: 'Logg inn',
    logout: 'Logg ut',
    membershipCard: 'Medlemskap',
    membershipNumber: 'Medlemsnummer',
    membershipSlashCredits: 'Medlemskap / Klippekort',
    monthlyCharge: 'Månadstrekk',
    myAddress: 'Mi adresse',
    myPage: 'Mi side',
    myProfile: 'Min profil',
    nextCreateAccount: 'Neste: Opprett konto',
    no: 'Nei',
    noneSelected: 'Ingen vald',
    noSelectedDate: 'Ingen dato vald',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Ikkje alle felt er utfylte, ver venleg å fylle ut alle.',
    noUserInfoFound: 'Ingen brukarinformasjon funnen.',
    numberOfPersons: {
        singular: 'person',
        plural: 'personar',
        template: '<COUNT:person:personer>',
    } as any,
    pay: 'Betal',
    payWithCreditCard: 'Betal med kort',
    phoneNumber: 'Telefonnummer',
    pleaseChoose: 'Ver venleg å velje',
    pleaseContact: 'Ver venleg å kontakte',
    pleaseFillOutUserInfoInProfile: 'Fyll ut brukarinformasjon i profilen din.',
    pleaseLogInToBook: 'Ver venleg å logge inn for å kjøpe med desse',
    pleaseLogInToPurchase: 'Ver venleg å logge inn så fyller vi inn dataa dine for deg',
    pleaseProvideAllValueCardInformation: 'Ver venleg å oppgje all informasjon om verdikortet',
    pleaseProvideProperBookingInformation: 'Ver venleg å oppgje korrekt kjøpsinformasjon',
    pleaseRegister: 'Ver venleg å registrere',
    postalCodeAndLocation: 'Postnummer / poststad',
    prefix: 'Landskode',
    privacyPolicy: 'Personvernerklæring',
    problemLoadingData: 'Feil ved innlasting av data',
    processing: 'Prosesserer',
    purchaseValueCard: 'Kjøp periodebillett eller klippekort',
    readMoreAbout: 'Les meir om vår',
    readMoreAndBook: 'Les meir og kjøp',
    readMoreAndBuy: 'Les meir og betal',
    refillCredits: 'Fyll på klippekort',
    returnToWebsite: 'Returner til nettsida',
    scanTicket: 'Skann billett',
    signUp: 'Opprett konto',
    shoesAndHarnessAlwaysIncluded: 'Sele og sko er alltid inkludert.',
    shoesAndHarnessNotIncluded: 'Sele og sko ikkje inkludert.',
    somethingWentWrongUpdatingUserInfo: 'Noko gjekk gale ved oppdateringa av brukarinformasjon',
    subtract: 'Trekk frå',
    terminate: 'Avslutt',
    termsOfUse: {
        template: 'Vilkår for bruk av <NAME>',
    } as any,
    upcomingBookings: 'Kommande bookingar',
    updateAddress: 'Oppdater adresse',
    thankYouForBookingWithUs: 'Takk for at du booka hos oss.',
    thePrivacyPolicy: 'Personvernerklæringa',
    thankYouForShoppingWithUs: 'Takk for at du handla hos oss',
    thisGiftcardIsEmpty: 'Dette gåvekortet er tomt.',
    tHours: 't',
    totalCredits: 'Totalt tal på klipp',
    totalNOK: 'Total NOK',
    updateBooking: 'Oppdater booking',
    updateProfile: 'Oppdater profil',
    useGiftcard: 'Løys inn gåvekort',
    useGiftcardOrPromocode: 'Løys inn gåvekort / rabattkode',
    validationNotANumber: 'Må vere eit tal',
    validationMaxNumber: {
        template: 'Ingen tal over <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Ingen tal under <NUM>',
    } as any,
    validForThePeriod: 'Gyldig i perioden',
    validUntil: 'Gyldig til',
    validFrom: 'Gyldig frå',
    valueCard: 'Verdikort',
    visitors: 'Besøkjande',
    withoutVat: 'Utan MVA',
    yes: 'Ja',
    youDontHaveAnyUpcomingBookings: 'Du har ingen kommande bookingar',
    youDontHaveAnyValueCards: 'Du har ingen verdikort',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'Du har ikkje ein gyldig periodebillett, vil du kjøpe ein?',
    youMustSpecifyAValueCard: 'Du må velje eit verdikort.',
    yourReservationTimedOut: 'Reservasjonen din har gått ut.',
    zipcode: 'Postnummer',
    payByMembershipCard: 'Betal med periodebillett',
    payWith: 'Betal med',
    deleteMyProfile: 'Slett min profil',
    areYouSureYouWantToDeleteYourProfile: 'Er du sikker på at du vil slette profilen din?',
    deleteYourProfileInformation:
        'Du er i ferd med å slette og kople frå profilinformasjonen din frå eventuelle bestillingar og kjøpte produkt. Denne handlinga kan ikkje angrast. Nokre opplysningar kan bli lagra av juridiske årsaker.',
    choosePaymentOtherMethods: 'Vel andre betalingsmåtar',
    dontUseMembershipCardForThisBooking: 'Ikkje bruk periodebillett for denne bookinga',
    nameNotProvided: 'Namn er ikkje oppgjeve',
    phoneNumberNotProvided: 'Telefonnummer er ikkje oppgjeve',
    emailNotProvided: 'E-post er ikkje oppgjeve',
    addressNotProvided: 'Adresse er ikkje oppgjeve',
    promocode: 'Rabattkode',
    show_usage_terms: 'vis vilkår',
    hide_usage_terms: 'skjul vilkår',
    about_the_product: 'om produktet',
    successful: 'Vellukka',
    selectProduct: 'Vel produkt',
    withCreditCard: 'Med kredittkort',
    paymentMethod: 'Betalingsmåte',
    noAvailableTimeslots: 'Ingen ledige timar',
    orderReference: 'Ordrereferanse',
    iWishToRecieveNewsletters: 'Eg ønskjer å motta nyheitsbrev',
    mySite: 'Mi Side',
    pleaseConfirm: {
        template: 'Ver venleg å stadfest "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Ver venleg å oppgje tal på deltakarar',
    pleaseSelectTheDateAndTimeOfYourBooking: 'Ver venleg å velje dato og tid for besøket ditt',
    pleaseSelectNumberOfCredits: 'Ver venleg å velje tal på klipp',
    pleaseSelectTypeOfCreditcard: 'Ver venleg å velje type klippekort',
    pleaseSelectAStartDate: 'Ver venleg å velje startdato',
    pleaseSelectAMembershipType: 'Ver venleg å velje type årskort',
    contactCustomerServiceToDeleteYourProfile: 'Kontakt kundeservice for å slette profilen din',
    inclInMembership: 'Inkl. i medlemskap',
    maxAmount: 'Maks tal',
    anErrorOccurredDuringPayment: 'Noko gjekk gale med betalinga',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Det har skjedd ein feil, last sida på nytt for å prøve igjen. Feilmelding',
    share: 'Del',
    copyLink: 'Kopier lenkje',
    howDoYouWantToShare: 'Korleis vil du dele?',
    checkIn: 'Innsjekk',
    checkOut: 'Utsjekk',

    shoppingCart: 'Handlekorg',
    heresMyShoppingCart: 'Her er handlekorga mi',
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'Det er ikkje nok ledig kapasitet for produkta i denne handlekorga',

    full: 'Fullt',
    no_available_capacity_for_this_tour: 'Ingen ledig kapasitet på denne turen',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Det er ikkje nok ledige plassar på ein eller fleire av aktivitetane og/eller overnattingane',

    pick_a_date: 'Vel ein dato',
    package: 'Pakke',
    packages: 'Pakkar',
    customize_activities: 'Skreddarsy aktivitetar',
    check_in_at: 'Innsjekk kl.',
    activity_starts_at: 'Aktiviteten startar kl.',
    other_times_are_available: 'Andre tidspunkt er tilgjengelege',
    press_to_select_other_options: 'Trykk for å velje andre alternativ',
    unavailableForSelectedTime: 'Ikkje ledig på valt tidspunkt',
    order_status_awaiting_payment: 'Betaling pågår',
    order_status_payment_failed: 'Betaling feila',
    awaiting_payment_info: 'Betaling pågår... dette tek vanlegvis ikkje meir enn nokre minutt.',
    cancel_membership: 'Avslutt medlemskap',
    update_subscription_payment_details: 'Oppdater betalingsinformasjonen',
    payment_failed_info:
        'Det ser ut til å vere eit problem med betalinga. Du kan prøve å oppdatere betalingsinformasjonen.',
    retry_subscription_payment: 'Prøv betaling på nytt no',

    entrance: 'Inngang',
    related_products: 'Relaterte produkt',
    some_tickets_are_unavailable_the_selected_day: 'Nokre billettar er utilgjengelege på vald dag',
    company: 'Bedrift',
    company_name: 'Firmanamn',
    org_number: 'Organisasjonsnummer',
    pay_with_invoice: 'Betal med faktura',
    continueWithRebooking: 'Hald fram med ombookinga',
    searchAndBookAccommodations: 'Søk og bestill overnatting',
    selectNumberOfGuests: 'Vel tal på gjestar',
    checkAvailability: 'Sjekk tilgjengelegheit',
    updateAvailability: 'Oppdater tilgjengelegheit',
    you_will_have_to_switch_rooms_during_your_stay:
        'Du må byte rom under opphaldet. Kontakt betjeninga for å bestille.',
    selected: 'Vald',
    remove: 'Fjern',
    aria_increase: 'Auking',
    aria_decrease: 'Nedgang',
    promo_code_not_supported_for_gift_cards: 'Rabattkoden er ikkje gyldig for gåvekort',
    select_products: 'Vel produkt',
    change_selected_products: 'Endre valde produkt',
    cartCompatibilityWarning:
        'Det finst produkt i handlekorga di som ikkje er kompatible med dette produktet. Ver venleg å kjøpe dei separat, eller tøm handlekorga di for å halde fram.',
    membershipBookingDisabledWarning:
        'Membership Booking er deaktivert på akkurat denne nettsida, men dette produktet krev det. Dette er mest sannsynleg ein feilkonfigurasjon. Ver venleg å kontakte kundeservice.',
};

export default translations;
