import createCache from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import merge from 'lodash-es/merge';

import { MountPointContext } from '@repo/common-utils/mountPoint';
import {
    customizationAtom,
    CustomizationsContext,
} from 'src/components/utils/theme/customizations';
import { MUITheme } from 'src/components/utils/theme/Theme';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { StateProvider } from 'src/state/StateProvider';
import { useAtom } from 'ximple/atoms';
import { AuthenticationContextProvider } from './timeslots/timeslots/AuthenticationContext';
import { MemberContextProvider } from './timeslots/timeslots/MemberContext';
import { CartContextProvider } from './CartContext';

export function WidgetWrapper(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    customizationOverrides?: Partial<{ [key in keyof ThemeType]: ThemeType[keyof ThemeType] }>;
    children: any;
}): JSX.Element {
    const [customizations] = useAtom(customizationAtom);
    const customizationsWithOverrides = merge(customizations, props.customizationOverrides);

    const cache = createCache({
        key: 'css',
        prepend: true,
        container: props.mountPoint,
    });

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={MUITheme(customizationsWithOverrides)}>
                <CustomizationsContext.Provider value={customizationsWithOverrides}>
                    <MountPointContext.Provider
                        value={{
                            popover: props.popoverMountPoint,
                            shadowRoot: props.shadowRoot,
                        }}
                    >
                        <AuthenticationContextProvider>
                            <MemberContextProvider>
                                <CartContextProvider>
                                    {/* TODO:  Maybe we don't need the Cart Context here, but can use it where it's actually needed (if not needed in some widgets) */}
                                    <StateProvider>{props.children}</StateProvider>
                                </CartContextProvider>
                            </MemberContextProvider>
                        </AuthenticationContextProvider>
                    </MountPointContext.Provider>
                </CustomizationsContext.Provider>
            </ThemeProvider>
        </CacheProvider>
    );
}

function FocusStyles() {
    const theme = useTheme();
    return (
        <Global
            styles={{
                '*:focus': {
                    boxShadow: `0 0 0 1px ${theme.palette.primary.main}, \
                                0 0 0 2px ${theme.palette.primary.contrastText} !important`,
                },
            }}
        />
    );
}
