import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';

export type PaymentRedirectContext = {
    paymentId: string | null;
};

type PaymentRedirectResult = {
    isSuccess: boolean;
    paymentId: string | null;
};

export function tryFindPaymentRedirectContext(): PaymentRedirectContext | null {
    const vippsRedirectResult = getVippsRedirectResult();
    if (vippsRedirectResult.isSuccess) {
        return {
            paymentId: vippsRedirectResult.paymentId,
        };
    }

    const klarnaRedirectResult = getKlarnaRedirectResult();
    if (klarnaRedirectResult.isSuccess) {
        return {
            paymentId: klarnaRedirectResult.paymentId,
        };
    }

    return null;
}

function getVippsRedirectResult(): PaymentRedirectResult {
    const paymentId = findUrlQueryParam('paymentId');
    const status = findUrlQueryParam('status');
    const isRedirectFromSuccessfulPayment = !!paymentId && status === '100';

    return { isSuccess: isRedirectFromSuccessfulPayment, paymentId: paymentId };
}

function getKlarnaRedirectResult(): PaymentRedirectResult {
    const paymentId = findUrlQueryParam('payment_intent');
    const status = findUrlQueryParam('redirect_status');
    const isRedirectFromSuccessfulPayment = !!paymentId && status === 'succeeded';

    return { isSuccess: isRedirectFromSuccessfulPayment, paymentId: paymentId };
}
