import {
    BilberryGiftcardStatus,
    BilberryPromoCodeStatus,
    CartItem,
    MembershipIntentMultiResponse,
} from '@repo/types';
import { createContext, PropsWithChildren, useContext, useState, useCallback } from 'react';
import { useAtom } from 'ximple';
import { cartAtom } from 'src/state/cart/cartAtom';
import { checkoutInfoAtom } from 'src/state/checkout-info/checkoutInfoAtom';
import { useMemberContext } from './timeslots/timeslots/MemberContext';
import { useIntentMulti } from '@repo/widget-utils/services/hooks/membership';
import useIntentMultiRequest from 'src/hooks/domain/cart/useIntentMultiRequest';
import useCartItemsWithMembershipAddedOrRemoved from 'src/hooks/domain/cart/useCartItemsWithMembershipAddedOrRemoved';

export type IgnoredValueCards = {
    valueCardIdsToIgnore: number[];
    valueCardProductIdsToIgnore: number[];
    valueCardProductTypeIdsToIgnore: number[];
};

const initialIgnoredValueCards: IgnoredValueCards = {
    valueCardIdsToIgnore: [],
    valueCardProductIdsToIgnore: [],
    valueCardProductTypeIdsToIgnore: [],
};

type CartContextState = {
    cartItems: CartItem[];
    appliedPromoCode: BilberryPromoCodeStatus | null;
    setAppliedPromoCode: (promoCode: BilberryPromoCodeStatus | null) => void;
    giftcards: BilberryGiftcardStatus[];
    setGiftcards: (giftCards: BilberryGiftcardStatus[]) => void;
    isCompanyContact: boolean;
    isUsingPaymentPlan: boolean;
    isLoadingPaymentPlan: boolean;
    paymentPlanResponse: MembershipIntentMultiResponse | null;
    ignoredValueCards: IgnoredValueCards;
    setIgnoredValueCards: (ignoredValueCards: IgnoredValueCards) => void;
    resetIgnoredValueCards: () => void;
    isLoadingPrices: boolean;
};

const CartContext = createContext<CartContextState | undefined>(undefined);

export type CartContextProps = PropsWithChildren;
export function CartContextProvider(props: CartContextProps) {
    const { loggedInUser, currentSite } = useMemberContext();
    const [cartItemsFromStorage] = useAtom(cartAtom);
    const [giftcards, setGiftcards] = useState<BilberryGiftcardStatus[]>([]);
    const [appliedPromoCode, setAppliedPromoCode] = useState<BilberryPromoCodeStatus | null>(null);
    const [checkoutInfo] = useAtom(checkoutInfoAtom);
    const isCompanyContact = checkoutInfo?.contactType === 'company';

    const [ignoredValueCards, setIgnoredValueCards] =
        useState<IgnoredValueCards>(initialIgnoredValueCards);

    const resetIgnoredValueCards = useCallback(() => {
        setIgnoredValueCards(initialIgnoredValueCards);
    }, []);

    const {
        request: currentPaymentPlanRequest,
        isUsingPaymentPlan,
        isRequestChanging,
    } = useIntentMultiRequest(
        isCompanyContact,
        cartItemsFromStorage,
        appliedPromoCode,
        ignoredValueCards,
    );

    const { data: paymentPlanResponse, isLoading: isLoadingPaymentPlan } = useIntentMulti(
        currentPaymentPlanRequest,
        loggedInUser,
        currentSite?.key,
    );

    const cartItems = useCartItemsWithMembershipAddedOrRemoved(
        cartItemsFromStorage,
        isUsingPaymentPlan,
        paymentPlanResponse ?? null,
    );

    const isLoadingPrices = isUsingPaymentPlan && (isLoadingPaymentPlan || isRequestChanging);

    return (
        <>
            <CartContext.Provider
                value={{
                    cartItems,
                    appliedPromoCode,
                    setAppliedPromoCode,
                    giftcards,
                    setGiftcards,
                    isCompanyContact,
                    isUsingPaymentPlan,
                    isLoadingPaymentPlan,
                    paymentPlanResponse: paymentPlanResponse ?? null,
                    ignoredValueCards,
                    setIgnoredValueCards,
                    resetIgnoredValueCards,
                    isLoadingPrices,
                }}
            >
                {props.children}
            </CartContext.Provider>
        </>
    );
}

export function useCartContext(): CartContextState {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCartContext must be used within a CartContextProvider');
    }
    return context;
}
