import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { RefObject, useCallback } from 'react';
import { route } from 'src/components/common/router/Router';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { Product, ProductInstance, TicketOptionWithQuantity, TicketType } from '@repo/types';
import { updateQuantityData } from '@repo/widget-utils/price-helper';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import {
    getCutoffDateFromStartAndCutoffTime,
    productDisablePaymentPlans,
    productInstancesHasDepositPrices,
    productRequiresPaymentPlans,
} from '@repo/widget-utils/cart/cartUtils';

export function useOnClickBook(
    quantities: TicketOptionWithQuantity[],
    attemptedBooking: boolean,
    onBookingAttempt: React.Dispatch<React.SetStateAction<boolean>>,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onQuantitiesChange: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>,
    hasChosenDate: boolean,
    setHasChosenDate: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    selectedProducts: ProductInstance[] | undefined,
    setSelectedProducts: React.Dispatch<React.SetStateAction<ProductInstance[] | undefined>>,
    setSelectedTimeslot: React.Dispatch<React.SetStateAction<TimeSlotType | undefined>>,
    product: Product | null,
    setDateRange: React.Dispatch<React.SetStateAction<DateRange<TZDate>>>,
    setSelectedTicketType: (ticketType?: TicketType) => void,
    selectedTicketType?: TicketType,
    selectedTimeslot?: TimeSlotType | undefined,
) {
    const configuration = useConfigurations();

    return useCallback(() => {
        const fn = async () => {
            if (!attemptedBooking) onBookingAttempt(true);
            if (
                !selectedProducts ||
                !hasChosenDate ||
                !quantities.some((q) => q.quantity > 0) ||
                selectedProducts.length === 0
            )
                return;

            const selected: ProductInstance[] = [...(selectedProducts as any)];
            if (product?.type === 'accommodation' && !selectedTicketType) return;
            if (product?.type === 'timeslot') {
                if (!selectedTimeslot?.products) return;
                const [firstTimeslot] = selectedTimeslot.products;
                selected[0] = {
                    ...selected[0],
                    timeslots: selectedTimeslot.products,
                    orignalTimeslots: selectedProducts[0].timeslots,
                    start: firstTimeslot.start,
                    end: selectedTimeslot.products[selectedTimeslot.products.length - 1].end,
                    cutoffDate: getCutoffDateFromStartAndCutoffTime(
                        firstTimeslot.start,
                        firstTimeslot.cutoffTime,
                    ),
                    cutoffTime: firstTimeslot.cutoffTime,
                } as (typeof selected)[0];
            }

            const hasDepositPrices = productInstancesHasDepositPrices(selectedProducts);
            const disablePaymentPlans = productDisablePaymentPlans(
                product,
                false,
                hasDepositPrices,
                configuration.disableMembershipBooking,
            );
            const requiresPaymentPlans = productRequiresPaymentPlans(product);

            await cartAtom.update(
                createAddProductsToCartEvent(
                    selected,
                    quantities,
                    disablePaymentPlans,
                    requiresPaymentPlans,
                    undefined,
                    selectedTicketType,
                ),
            );
            if (shouldShowBasketOnBook) {
                showBasketAtom.update({
                    visible: true,
                    refocusElementOnClose: boxRef,
                });
            }

            toggleBookingAtom.update({ visible: false });
            setDateRange([null, null]);
            setSelectedTimeslot(undefined);
            setSelectedProducts(undefined);
            setSelectedTicketType(undefined);
            onBookingAttempt(false);
            setHasChosenDate?.(false);
            onQuantitiesChange(
                updateQuantityData(selected ?? [], product?.ticketOptions ?? [], quantities),
            );

            if (!shouldShowBasketOnBook) route('/checkout');
        };
        fn();
    }, [
        attemptedBooking,
        selectedProducts,
        hasChosenDate,
        setHasChosenDate,
        quantities,
        boxRef,
        product,
        setDateRange,
        setSelectedTicketType,
        selectedTicketType,
        selectedTimeslot,
        onQuantitiesChange,
        shouldShowBasketOnBook,
        onBookingAttempt,
        setSelectedProducts,
        setSelectedTimeslot,
        configuration.disableMembershipBooking,
    ]);
}
