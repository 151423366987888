import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import TourAvailability from 'src/components/domain/upcoming-tours/tour-availability/TourAvailability';
import { useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { upcomingSelectedTourAtom } from 'src/state/upcomingSelectedTour.atom';
import { ProductInstance } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';

export default function UpcomingTour(props: {
    tour: ProductInstance;
    fewLeft: number;
    showRemaining?: boolean;
}) {
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const { url: linkUrl = '', fromPrice } = props.tour.product ?? {};

    return (
        <Box
            display="flex"
            flex="stretch"
            flexDirection="row"
            alignItems="center"
            width="100%"
            border="1px solid lightgrey"
            borderRadius={`${theme.shape.borderRadius}px`}
            mb={theme.spacing(1)}
            sx={{
                backgroundColor: '#f5f5f5',
                justify: 'space-between',
                [theme.breakpoints.down('sm')]: {
                    minHeight: '80px',
                    padding: '0',
                },
            }}
        >
            <Button
                component={Link}
                href="#"
                aria-label={`Link to book ${props.tour.product?.title} at ${props.tour.start.format(
                    'LT',
                )} on the ${props.tour.start.format('LLL')}`}
                onClick={async () => {
                    await upcomingSelectedTourAtom.update(props.tour);
                    window.location.href = linkUrl;
                }}
                display="flex"
                flex="stretch"
                flexDirection="row"
                alignItems="center"
                width="100%"
                height="100%"
                sx={{ justify: 'space-between', gap: theme.spacing(2) }}
            >
                <Box
                    component={'img'}
                    sx={{
                        height: theme.spacing(10),
                        width: 'auto',
                        aspectRatio: '16/9',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    }}
                    src={props.tour.product?.coverImage.src}
                    alt={props.tour.product?.coverImage.alt}
                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    width="100%"
                    margin="auto"
                    justifyContent="space-between"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    gap={1}
                >
                    <Box>
                        <UpcomingTourTypography variant="title">
                            {props.tour.product?.title ?? ''}
                        </UpcomingTourTypography>
                        <Stack direction="row">
                            <UpcomingTourTypography variant="normal">
                                {fromPrice ? t.from : ''}
                            </UpcomingTourTypography>
                            <UpcomingTourTypography variant="emphasis">
                                &nbsp;
                                {fromPrice
                                    ? formatCurrencyNoDecimalsIfPossible(
                                          locale,
                                          currency,
                                          fromPrice,
                                      )
                                    : '-'}
                            </UpcomingTourTypography>
                        </Stack>
                    </Box>
                    <Stack
                        height="100%"
                        p={{ sm: 1, md: 2 }}
                        alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
                    >
                        <TourAvailability
                            fewLeft={props.fewLeft}
                            capacity={
                                props.tour.isClosedForBooking ? 0 : (props.tour?.capacity ?? 0)
                            }
                            showRemaining={props.showRemaining}
                        />
                        <Stack
                            mt={{ xs: 0.5, sm: 1 }}
                            gap={0.5}
                            direction="row"
                            alignItems="flex-end"
                        >
                            <UpcomingTourTypography variant="normal" noWrap>
                                {props.tour.start.format('D. MMM YYYY')}
                            </UpcomingTourTypography>
                            <UpcomingTourTypography variant="normal" noWrap>
                                -
                            </UpcomingTourTypography>
                            <UpcomingTourTypography variant="emphasis" noWrap>
                                {props.tour.start.format('HH:mm')}
                            </UpcomingTourTypography>
                        </Stack>
                    </Stack>
                </Stack>
            </Button>
        </Box>
    );
}

function UpcomingTourTypography(props: {
    children: JSX.Element | string | string[];
    variant: 'title' | 'normal' | 'emphasis';
    noWrap?: boolean;
}) {
    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Typography
            noWrap={props.noWrap}
            m={0}
            sx={{
                fontSize:
                    props.variant === 'title'
                        ? { md: '20px', xs: '15px' }
                        : { md: '16px', xs: '12px' },
                fontWeight: props.variant === 'emphasis' ? customizations.boldFontWeight : 'normal',
                [theme.breakpoints.down(340)]: {
                    fontSize: props.variant === 'title' ? '12px' : undefined,
                },
            }}
        >
            {props.children}
        </Typography>
    );
}
