import { Alert, Box, Typography, useTheme } from '@mui/material';
import { createRoot, Root } from 'react-dom/client';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryStripeAlertWidget extends BilberryCustomElement {
    private root: Root | null = null;

    static get observedAttributes() {
        return ['errormessage'];
    }

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this.root = createRoot(this._mountPoint);
        this.renderComponent();
    }

    attributeChangedCallback() {
        this.renderComponent();
    }

    renderComponent() {
        const errorMessage = this.getAttribute('errormessage') ?? '';

        // finally we need to wrap our application in a StylesProvider
        this.root?.render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <StripeAlert errorMessage={errorMessage}></StripeAlert>
            </WidgetWrapper>,
        );
    }
}

function StripeAlert({ errorMessage }: { errorMessage: string }) {
    const theme = useTheme();
    const errorLines = errorMessage.split('\n');

    return (
        <Box sx={{ my: theme.spacing(2) }}>
            <Alert
                severity="error"
                sx={{
                    '& .MuiAlert-message': {
                        width: '100%',
                    },
                }}
            >
                {errorLines.map((line, i) => (
                    <Typography key={i} component="div">
                        {line}
                    </Typography>
                ))}
            </Alert>
        </Box>
    );
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-stripe-alert', BilberryStripeAlertWidget);
}
