import { valueCardActionLog$ } from 'src/state/valueCard';
import { dispatchWidgetEvent } from './eventDispatcher';

valueCardActionLog$.subscribe(({ state, newState, action }) => {
    switch (action.type) {
        case 'INITIALIZE':
            dispatchWidgetEvent({
                eventType: 'startCheckout',
                purchaseProduct: newState,
            });
            break;
        case 'CANCEL':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Cancel',
                purchaseProduct: state,
            });
    }
});
