import {
    MembershipBooking,
    MembershipSite,
    MembershipUser,
    MembershipUserValueCard,
    MembershipValueCardProduct,
} from '@repo/types';
import {
    useAvailableValueCardProductsForUser,
    useBookingsForUser,
    useLoggedInUser,
    useValueCardsForUser,
} from '@repo/widget-utils/services/hooks/membership';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { createContext, PropsWithChildren, useContext } from 'react';
import { KeyedMutator } from 'swr';
import { useAuthenticationContext } from './AuthenticationContext';
import { useAtom } from 'ximple';
import { companyAtom } from 'src/state/company';

type MemberContextState = {
    currentSite: MembershipSite | null;
    loggedInUser: MembershipUser | null;
    mutateLoggedInUser: KeyedMutator<MembershipUser>;
    availableValueCardProductsForUser: MembershipValueCardProduct[];
    valueCardsForUser: MembershipUserValueCard[];
    mutateValueCardsForUser: KeyedMutator<MembershipUserValueCard[]>;
    isLoadingValueCardsForUser: boolean;
    bookingsForUser: MembershipBooking[];
    mutateBookingsForUser: KeyedMutator<MembershipBooking[]>;
    isLoadingBookingsForUser: boolean;
};

const MemberContext = createContext<MemberContextState | undefined>(undefined);

export function MemberContextProvider(props: PropsWithChildren) {
    const [{ currentSite }] = useAtom(companyAtom);

    const config = useConfigurations();
    const { apiToken } = useAuthenticationContext();

    const { data: user, mutate: mutateLoggedInUser } = useLoggedInUser(apiToken);
    const loggedInUser = user ?? null;

    const { data: availableValueCardProductsForUser } = useAvailableValueCardProductsForUser(
        apiToken,
        config.siteKey,
    );

    const {
        data: valueCardsForUser,
        isLoading: isLoadingValueCardsForUser,
        mutate: mutateValueCardsForUser,
    } = useValueCardsForUser(apiToken);

    const {
        data: bookingsForUser,
        isLoading: isLoadingBookingsForUser,
        mutate: mutateBookingsForUser,
    } = useBookingsForUser(loggedInUser);

    return (
        <>
            <MemberContext.Provider
                value={{
                    currentSite: currentSite ?? null,
                    loggedInUser: loggedInUser,
                    mutateLoggedInUser,
                    availableValueCardProductsForUser: availableValueCardProductsForUser ?? [],
                    valueCardsForUser: valueCardsForUser ?? [],
                    mutateValueCardsForUser,
                    isLoadingValueCardsForUser,
                    bookingsForUser: bookingsForUser ?? [],
                    mutateBookingsForUser,
                    isLoadingBookingsForUser,
                }}
            >
                {props.children}
            </MemberContext.Provider>
        </>
    );
}

export function useMemberContext(): MemberContextState {
    const context = useContext(MemberContext);
    if (context === undefined) {
        throw new Error('useMemberContext must be used within a MemberContextProvider');
    }
    return context;
}
