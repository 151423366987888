import { SxProps, Theme, Typography, useTheme } from '@mui/material';

type TextProps = {
    children: React.ReactNode[] | React.ReactNode;
    variant?: 'small' | 'large';
    alignment?: 'left' | 'center' | 'right';
    fontWeight?: 'bold' | 'normal';
    sx?: SxProps<Theme>;
};

export default function Text({
    children,
    variant = 'large',
    alignment = 'left',
    fontWeight = 'normal',
    sx,
}: TextProps) {
    const theme = useTheme();
    const getBodyTextVariant = () => (variant === 'large' ? 'body1' : 'body2');
    return (
        <Typography
            variant={getBodyTextVariant()}
            textAlign={alignment}
            fontWeight={
                fontWeight === 'bold' ? (theme.typography.fontWeightBold ?? fontWeight) : fontWeight
            }
            sx={sx}
        >
            {children}
        </Typography>
    );
}
