import { TodayRounded } from '@mui/icons-material';
import {
    alpha,
    darken,
    FormControl,
    InputAdornment,
    InputLabel,
    SxProps,
    TextField,
    useTheme,
} from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { createRef, useMemo } from 'react';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';

type IProps = {
    labelColor: string | undefined;
    label: string;
    showError: boolean;
    required: boolean;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLInputElement | HTMLDivElement | null>>;
    dateRange: DateRange<TZDate>;
    variant?: 'filled' | 'outlined';
    color?: 'primary' | 'secondary';
    sx?: SxProps;
    textColor?: string;
    backgroundColor?: string;
};

export default function DateRangePickerInput(props: IProps) {
    const {
        labelColor,
        label,
        showError,
        required,
        setAnchorEl,
        dateRange,
        variant = 'filled',
        sx,
        color = 'primary',
        textColor,
        backgroundColor,
    } = props;
    const refocusElement = createRef<HTMLInputElement>();
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t } = useLocale();

    const selectedDateText = useMemo(() => getSelectedDateText(dateRange), [dateRange]);
    return (
        <FormControl fullWidth sx={sx}>
            <InputLabel
                id="date-range-picker-label"
                sx={{
                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                    color: labelColor,
                    whiteSpace: 'nowrap',
                }}
            >
                {label}
            </InputLabel>
            <TextField
                id="bilberry-date-range-picker-input"
                inputRef={refocusElement}
                error={showError}
                helperText={showError ? capitalize(t.please_fill_in_all_required_information) : ''}
                required={required}
                sx={{
                    '& .MuiFilledInput-root.Mui-focused': {
                        borderColor: alpha(customizations.secondaryColor, 0.5),
                        boxShadow: `${darken(
                            theme.palette.primary.contrastText,
                            0.25,
                        )} 0 0 0 0.2rem`,
                    },
                    '& .MuiFilledInput-input': {
                        cursor: 'pointer',
                        color: textColor,
                    },
                    '& .MuiInputBase-root': {
                        cursor: 'pointer',
                        backgroundColor: backgroundColor ?? theme.palette.common.white,
                    },
                    '& .MuiInputAdornment-root > *': {
                        color: textColor,
                    },
                }}
                onClick={(e: React.MouseEvent) => {
                    if (
                        e.currentTarget instanceof HTMLInputElement ||
                        e.currentTarget instanceof HTMLDivElement
                    )
                        setAnchorEl(e.currentTarget);
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                    if (
                        (e.currentTarget instanceof HTMLInputElement ||
                            e.currentTarget instanceof HTMLDivElement) &&
                        e.key === 'Enter'
                    )
                        setAnchorEl(e.currentTarget);
                }}
                onFocus={deselectTextInput}
                variant={variant}
                color={color}
                margin="dense"
                size="small"
                value={selectedDateText.length > 0 ? selectedDateText : capitalize(t.select_date)}
                InputProps={{
                    readOnly: true,

                    endAdornment: (
                        <InputAdornment position="end">
                            <TodayRounded
                                sx={[
                                    showError && {
                                        color: theme.palette.error.main,
                                        '& > *': {
                                            color: theme.palette.error.main,
                                        },
                                    },
                                ]}
                            />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    'aria-labelledby': 'date-range-picker-label',
                }}
            />
        </FormControl>
    );
}

function getSelectedDateText(dateRange: DateRange<TZDate>) {
    const [start, end] = dateRange;
    if (!start) return '';

    if (!end || end.isSame(start, 'date')) return start.forceLocalTz().format('ll');

    return `${start.forceLocalTz().format('ll')} - ${end.forceLocalTz().format('ll')}`;
}
