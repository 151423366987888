import { decycle, retrocycle } from './cycle';
import { TZDate, tzdate } from '@repo/tzdate';

export function serialize(obj: any): any {
    const decycled = decycle(obj, dayjsReplacer);
    return JSON.stringify(decycled);
}

export function deserialize(obj: any): any {
    const retrocycled = retrocycle(JSON.parse(obj), dayjsReviver);
    return retrocycled;
}

export function dayjsReplacer(value: any) {
    if (value instanceof TZDate) {
        return value.toLocalStorage();
    } else {
        return value;
    }
}

export function dayjsReviver(value: any) {
    if (typeof value === 'object' && '$dayjs' in value) return tzdate(value);

    return value;
}
