import { BilberryPackageReservation } from './bilberry-api-types';
import { BilberryAccommodationReservationRequest } from './bilberry-hotels-api-types';

export type MembershipCompany = {
    company: {
        key: string;
        name: string;
        valueCardProducts: MembershipValueCardProduct[];
        valueCardProductTypes: MembershipValueCardProductType[];
    };
    sites: MembershipSite[];
};

export type Company = MembershipCompany & { currentSite: MembershipSite };

export type MembershipSite = {
    key: string;
    name: string;
    email: string;
    companyKey: string;
    bilberryAccessToken: string;
    bilberryBaseAddress: string;
    netsCheckoutKey: string;
};

export type MembershipValueCardProduct = {
    id: number;
    name: string;
    description: string;
    reference: string;
    availableFrom: string | null;
    availableTo: string | null;
    netPrice: number;
    price: number;
    originalPrice: number;
    taxRate: number;
    taxAmount: number;
    companyId: number;
    productTypeId: number;
    productType: MembershipValueCardProductType;
    valueCardProductCategory: MembershipValueCardProductCategory;
    campaign: {
        id: number;
        name: string;
        description: string;
        siteKey: string;
        price: number;
    };
    configuration: {
        restrictedToSiteKeys: string[];
        purchaseRestrictedToSiteKeys: string[];
        creditLimit: number;
        maxUsesPerBooking: number;
        validForDurationInDays: number;
        dateRestrictions: MembershipDateRange[];
        isRestrictedToPurchaseSite: boolean;
    };
};

export type MembershipValueCardProductCategory = {
    id: number;
    name: string;
    valueCardProductTypeId: number;
    valueCardProductType?: MembershipValueCardProductType;
    valueCardProducts?: MembershipValueCardProduct[];
};

export type MembershipValueCardProductType = {
    id: number;
    name: string;
    description: string;
    cardImageUrl: string;
    productPageUrl: string;
    valueCardProductCategories: MembershipValueCardProductCategory[];
};

export type MembershipDateRange = {
    start: string;
    end: string;
};

export type MembershipConsumer = {
    firstName: string;
    lastName: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    country: string;
    receiveNewsletter: boolean;
};

export type MembershipUser = {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    country: string;
    receiveNewsletter: boolean;
};

export type MembershipUserValueCard = {
    id: number;
    validFrom: string;
    validTo: string;
    subscription: MembershipValueCardSubscription;
    valueCardProduct: MembershipValueCardProduct;
    purchaseSite: string;
    purchaseDate: string;
    orderReference: string;
    qrReference: string;
    purchaseOrderStatus: OrderStatusEnum;
    balance: MembershipCreditBalanceResponse | null;
};

export enum OrderStatusEnum {
    AwaitingPayment = 'AwaitingPayment',
    Completed = 'Completed',
    PaymentFailed = 'PaymentFailed',
}

export type MembershipValueCardSubscription = {
    cancelSubscriptionAllowedFrom: string;
    nextSubscriptionChargeAt: string | null;
    subscriptionCancelledFrom: string | null;
};

export type MembershipCreditBalanceResponse = {
    valueCardId: number;
    creditsSpent: number;
    creditsAvailable: number;
    creditLimit: number;
};

export type UpdateValueCardSubscriptionResponse = {
    paymentId: string;
};

export type MembershipOrder = {
    id: number;
    status: OrderStatusEnum;
};

export type MembershipTicket = {
    defaultTicketOptionId: number;
    ignoredValueCardIds: number[];
    ignoredValueCardProductIds: number[];
    ignoredValueCardProductTypeIds: number[];
    bookableId?: number;
};

export type MembershipIntent = {
    consumer: MembershipConsumer;
    reservation: TimeslotReservation;
    checkoutUrl: string;
};

export type MembershipIntentResponse = {
    reservation: {
        projectId: number;
        timeslotIds: number[];
        tickets: [
            {
                ticketTypeKey: string;
                ignoredValueCardProductIds: number[];
            },
        ];
    };
    paymentPlan: {
        tickets: MembershipPaymentPlan[];
    };
};

export type MembershipPaymentPlan = {
    ticketName: string;
    ticket: MembershipTicket;
    valueCardUsages: MembershipPaymentPlanValueCardUsage[];
    currencyCost: null | MembershipCurrencyCost;
    relevantValueCardIds: number[];
    promoData: PromoDataForPaymentPlan | undefined;
};

export type PromoDataForPaymentPlan = {
    name: string;
    amount: number;
    promoType: string;
    promoValue: number;
};

export type MembershipCurrencyCost = {
    netPrice: number;
    price: number;
    vatAmount: number;
    rates: MembershipPaymentPlanCurrencyCostRate[];
};

export type MembershipPaymentPlanCurrencyCostRate = {
    name: string;
    originalPrice: number;
    price: number;
    taxRate: number;
    taxAmount: number;
};

export type MembershipPaymentPlanValueCardUsage = {
    valueCardId: number;
    valueCardProductId: number;
    valueCardProductTypeId: number;
    creditCost: number;
    priceReductionPercentage: number;
};

export type MembershipReserveResponse = {
    orderReference: string;
    paymentId: string;
};

export type MembershipBooking = {
    id: number;
    bilberryReference: string;
    startAt: string;
    endAt: string;
    cancellationDeadlineAt: string;
    numberOfTickets: number;
    siteKey: string;
    orderId: number;
    orderReference: string;
    qrReference: string;
    status: string;
};

export type TimeslotReservation = {
    productId: number;
    timeslotIds: number[];
    tickets: MembershipTicket[];
};

export type MembershipMultiReservation = {
    activityReservations?: MembershipMultiReservationTours[];
    timeslotReservations?: MembershipMultiReservationTimeslot[];
};
export type MembershipMultiReservationTours = {
    tours?: MembershipTour[];
    termsUrl?: string;
    packages: BilberryPackageReservation[];
    // eslint-disable-next-line camelcase
    external_data: any[];
    accommodationReservation?: BilberryAccommodationReservationRequest;
};

export type MembershipMultiReservationTimeslot = {
    reservation: TimeslotReservation;
    paymentPlan: MembershipPaymentPlan;
};

export type MembershipTour = {
    id: number | string;
    productCatalogId?: number;
    pax?: MembershipTourPax[];
};

export type MembershipTourPax = {
    id: number | string;
    quantity?: number;
};

export type MembershipIntentMultiResponse = {
    timeslotReservations: Array<{
        reservation: TimeslotReservation;
        paymentPlan: {
            productName: string;
            startAt: string;
            endAt: string;
            tickets: MembershipPaymentPlan[];
        };
    }>;
    activityReservations: Array<{
        reservation: {
            tours: MembershipTour[];
            termsUrl: string;
        };
        paymentPlanByTourId: {
            [key: string]: {
                productName: string;
                startAt: string;
                endAt: string;
                tickets: MembershipPaymentPlan[];
            };
        };
    }>;
};
