import { ReactComponent as CreditsIcon } from 'src/assets/icons/credits.svg';
import CheckboxPanel from 'src/components/common/timeslots-checkbox-panel/CheckboxPanel';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import Text from 'src/components/common/timeslots-typography/Text';
import Title from 'src/components/common/timeslots-typography/Title';
import { useLocale } from '@repo/i18n';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { sumBy } from 'lodash-es';
import { SummaryForValueCardUsages } from './SummaryForValueCardUsages';
import { ValueCardUsageSummary } from '@repo/widget-utils/price-helper';
import { Chip } from '@mui/material';

type CreditsPaymentProps = {
    text: string;
    creditsIds: number[];
    subtext?: string;
    variant?: 'radio' | 'checkbox';
    isChecked?: boolean;
    onClick: () => void;
    disabled?: boolean;
    valueCardUsageSummary?: ValueCardUsageSummary;
};

export default function CreditsPayment({
    text,
    creditsIds,
    subtext,
    variant = 'radio',
    isChecked = false,
    onClick,
    disabled = false,
    valueCardUsageSummary,
}: CreditsPaymentProps) {
    const { t } = useLocale();
    const { valueCardsForUser } = useMemberContext();

    const valueCards = valueCardsForUser.filter((vc) => creditsIds.includes(vc.id));
    const creditsLeft = sumBy(valueCards, (vc) => (vc.balance?.creditsAvailable ?? 0) / 100);

    const valueCardUsages = valueCardUsageSummary
        ? creditsIds.flatMap((creditsId) => valueCardUsageSummary[creditsId]?.usages ?? [])
        : [];

    const totalCreditCost = sumBy(valueCardUsages, (usage) => usage.creditCost) / 100;

    const subTextNode = !isChecked ? (
        subtext
    ) : (
        <Chip
            color="warning"
            label={`${totalCreditCost} ${t.credit.num(totalCreditCost).toLowerCase()}`}
            size="small"
        ></Chip>
    );

    return variant === 'radio' ? (
        <RadioPanel
            isChecked={isChecked}
            Icon={CreditsIcon as any}
            text={text}
            subtext={subTextNode}
            onChange={onClick}
            disabled={disabled}
        >
            <Title text={creditsLeft.toString()}></Title>
            <Text>{t.creditsLeft.toUpperCase()}</Text>
            {valueCardUsages.length > 0 && (
                <SummaryForValueCardUsages usages={valueCardUsages}></SummaryForValueCardUsages>
            )}
        </RadioPanel>
    ) : (
        <CheckboxPanel
            isChecked={isChecked}
            subtext={subTextNode}
            Icon={CreditsIcon as any}
            text={text}
            onChange={onClick}
            disabled={disabled}
        >
            <Title text={creditsLeft.toString()}></Title>
            <Text>{t.creditsLeft.toUpperCase()}</Text>
            {valueCardUsages.length > 0 && (
                <SummaryForValueCardUsages usages={valueCardUsages}></SummaryForValueCardUsages>
            )}
        </CheckboxPanel>
    );
}
