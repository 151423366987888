/* eslint-disable camelcase */

import {
    BASE_MEMBERSHIP_API_URL,
    SERVICE_PROXY_URL,
    USE_SERVICE_PROXY,
} from '@repo/widget-env/__autogen/env';
import { localeAtom } from '@repo/i18n';
import {
    Company,
    MembershipMultiReservationTours,
    MembershipOrder,
    MembershipReserveResponse,
    MembershipUser,
    MembershipConsumer,
    TimeslotReservation,
    UpdateValueCardSubscriptionResponse,
} from '@repo/types';
import { getBilberryLanguageFromLocale } from '../utils/api-client-common';
import { mutate } from 'swr';
import { BilberryApiError } from '../utils/BilberryApiError';

const API_URL = USE_SERVICE_PROXY ? SERVICE_PROXY_URL : BASE_MEMBERSHIP_API_URL;
const API_VERSION = 'v1';

export const apiUrl = () => API_URL + '/' + API_VERSION;

// Before using the api client, make sure to initialize the token with the value from the authentication provider.
let token: string | null = null;
export function setMembershipApiToken(newToken: string | null) {
    token = newToken;
}

export async function get<T = any>(url: string, errorMessage?: string): Promise<T> {
    const response = await fetch(url, {
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-type': 'application/json',
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error(errorMessage);

    return response.json();
}

export async function post(url: string, body?: any, errorMessage?: string) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        const body = await response.json();
        throw new Error(errorMessage + '. Server message: ' + body.title);
    }
    try {
        return await response.json();
    } catch (error) {
        return null;
    }
}

async function put(url: string, body?: any, errorMessage?: string) {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error(errorMessage);
    return response.json();
}

async function del(
    url: string,
    body?: any,
    // errorMessage?: string
) {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const message = response.statusText;
        throw new BilberryApiError(message, response);
    }

    try {
        return await response.json();
    } catch (error) {
        return null;
    }
}

export function getUrlWithParams(
    url: string,
    locale: string,
    queryParams: Record<string, any> = {},
) {
    const langQueryParam = `lang=${getBilberryLanguageFromLocale(locale)}`.toLowerCase();

    let fullUrl = `${url}?${langQueryParam}`;

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString.length > 0) fullUrl += `&${queryString}`;
    return fullUrl;
}

export function updateUser(user: MembershipUser) {
    const { locale } = localeAtom.subject.value;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const url = apiUrl() + getUrlWithParams('/Users/me', locale, {});
    return mutate(url, putUser(user), true);
}

export async function getUser() {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    const response = await get<MembershipUser>(url);
    return response;
}

export async function putUser(
    consumer: Omit<MembershipUser, 'receiveNewsletter'>,
): Promise<MembershipUser> {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    return put(url, consumer);
}

export async function getCompanyConfig(key: string, siteKey: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Config/company/${key}/site/${siteKey}`, locale, {});

    const response = await get<Company>(
        url,
        localeAtom.subject.value.t.couldntGetCompanyInformation,
    );
    return response;
}

export type MembershipIntentMultiRequest = {
    consumer: any;
    timeslotReservations: any;
    activityReservations: any;
    checkoutUrl: string;
    coupon_code?: string;
};

export async function postMultiReservation(
    siteKey: string,
    consumer: MembershipConsumer,
    reservation: {
        timeslotReservations: TimeslotReservation[];
        activityReservations: MembershipMultiReservationTours[];
        giftcardReferences: string[];
        coupon_code?: string;
        checkoutUrl: string;
    },
) {
    const locale = localeAtom.subject.value;
    const url = getUrlWithParams(
        apiUrl() + `/Bookings/site/${siteKey}/reserve-multi`,
        locale.locale,
        {},
    );

    const response = await post(url, {
        consumer,
        ...reservation,
    });
    return response as MembershipReserveResponse;
}

export async function cancelReservation(orderReference: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/order/${orderReference}/cancel`, locale, {});
    await post(url);
}

export async function cancelMembership(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/cancel`,
        locale,
        {},
    );
    const cancellation = await post(url);
    return cancellation as MembershipReserveResponse;
}

export async function getOrder(orderId: number) {
    const { locale } = localeAtom.subject.value;
    const url = getUrlWithParams(apiUrl() + `/Orders/${orderId}`, locale, {});
    const response = await get(url);
    return response;
}

export async function postValueCardReservation(
    consumer: MembershipUser,
    valueCardProductId: number,
    siteKey: string,
    checkoutUrl: string,
    giftcardReferences: string[],
    promoCodeReference: string | null,
    validFrom?: string,
    campaignId?: number,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Purchase/valueCard`, locale, {});
    const response = await post(url, {
        valueCardProductId,
        validFrom: validFrom === '' ? new Date().toISOString() : validFrom,
        siteKey,
        checkoutUrl,
        consumer,
        campaignId,
        giftcardReferences,
        promoCodeReference,
    });
    return response as MembershipReserveResponse;
}

export async function retrySubscriptionPayment(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/retry-payment`,
        locale,
        {},
    );
    const response = await post(url);
    return response as MembershipOrder;
}

export async function deleteUserProfile() {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    await del(url);
}

export async function updateValueCardSubscription(valueCardId: number, checkoutUrl: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/update`,
        locale,
        {},
    );
    const response = await post(url, {
        checkoutUrl,
    });
    return response as UpdateValueCardSubscriptionResponse;
}
