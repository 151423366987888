import { ProductCardButton } from 'src/components/domain/product-card-2/ProductCardLandscape';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { useSmartEvents } from '@repo/widget-utils/services/hooks/product';
import ProductCardListContainer from '../product/product-list-2/product-card-list-2/ProductCardListContainer';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import ProductCardListItem from '../product/product-list-2/product-card-list-2/ProductCardListItem';
import { ProductCard2 } from 'src/components/domain/product-card-2/ProductCard2';
import {
    ProductCardInfoContainer,
    ProductCardInfoContent,
} from 'src/components/domain/product-card-2/ProductCardInfo';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    urls: string[];
    ids: string[];
};

export function ListOfSmartEvents(props: Props) {
    const {
        scroll,
        numElements,
        backgroundColors,
        textColors,
        primaryColors,
        primaryTextColors,
        accentColors,
        accentTextColors,
        urls,
        ids,
    } = props;

    const { events } = useSmartEvents(ids);
    const customizations = useCustomizations();

    const listOfSmartEvents = events.map((event, i) => {
        const url = urls[i] ?? '#';
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={event.id}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={events.length}
                >
                    <ProductCard2
                        url={url}
                        image={{ url: event?.media.image?.url ?? '' }}
                        body={
                            <ProductCardInfoContainer>
                                <ProductCardInfoContent
                                    url={url}
                                    hideFromLabel
                                    title={event?.title}
                                    description={event?.lead}
                                    icons={null}
                                />
                                <ProductCardButton url={url} />
                            </ProductCardInfoContainer>
                        }
                    />
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {listOfSmartEvents.length > 0 && listOfSmartEvents}
        </ProductCardListContainer>
    );
}
