import { BilberryPromoCodeStatus, CartItem } from '@repo/types';
import { createIntentMultiRequest } from '@repo/widget-utils/booking/createIntent';
import {
    removeInvalidCartItems,
    shouldUsePaymentPlansForCart,
} from '@repo/widget-utils/cart/cartUtils';
import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useDebounce } from 'src/hooks/common/useDebounce';
import { IgnoredValueCards } from 'src/widgets/CartContext';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function useIntentMultiRequest(
    isCompanyContact: boolean,
    cartItems: CartItem[],
    appliedPromoCode: BilberryPromoCodeStatus | null,
    ignoredValueCards: IgnoredValueCards,
) {
    const { currentSite } = useMemberContext();

    const isUsingPaymentPlan = useMemo(() => {
        const validCartItems = removeInvalidCartItems(cartItems);
        return shouldUsePaymentPlansForCart(validCartItems, currentSite, isCompanyContact);
    }, [cartItems, currentSite, isCompanyContact]);

    const request = useMemo(() => {
        const validCartItems = removeInvalidCartItems(cartItems);
        return isUsingPaymentPlan
            ? createIntentMultiRequest(
                  validCartItems,
                  ignoredValueCards.valueCardIdsToIgnore,
                  ignoredValueCards.valueCardProductIdsToIgnore,
                  ignoredValueCards.valueCardProductTypeIdsToIgnore,
                  appliedPromoCode?.coupon_code,
              )
            : null;
    }, [isUsingPaymentPlan, cartItems, appliedPromoCode, ignoredValueCards]);

    const debouncedRequest = useDebounce(request, 500);

    return {
        isUsingPaymentPlan,
        request: debouncedRequest,
        isRequestChanging: !isEqual(request, debouncedRequest),
    };
}
