import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Badge, Box, Button, darken } from '@mui/material';
import { createRef, Fragment, useEffect, useState } from 'react';
import { useLocale } from '@repo/i18n';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useCartContext } from 'src/widgets/CartContext';

export enum BasketIconButtonVariant {
    DEFAULT,
    DENSE,
}

export default function BasketIconButton(props: {
    size: 's' | 'm' | 'l';
    xsSize: 's' | 'm' | 'l';
    smallOnMobile: boolean;
    variant: BasketIconButtonVariant;
}) {
    const { t } = useLocale();
    const [buttonVisible, setButtonVisible] = useState(false);
    const customizations = useCustomizations();
    const { cartItems } = useCartContext();
    const ref = createRef<HTMLButtonElement>();

    useEffect(() => {
        if (!buttonVisible && cartItems.length > 0) {
            setButtonVisible(true);
        } else if (buttonVisible && cartItems.length === 0) {
            setButtonVisible(false);
        }
    }, [cartItems, buttonVisible]);

    return (
        <Fragment>
            {buttonVisible && (
                <Button
                    sx={(theme) => ({
                        transform:
                            props.size === 'l'
                                ? 'scale(1.75)'
                                : props.size === 'm'
                                  ? 'scale(1.25)'
                                  : 'scale(1)',
                        backgroundColor: customizations.basketIconColor,
                        color: customizations.basketIconTextColor,
                        borderColor: customizations.basketIconBorderColor,
                        borderRadius: customizations.borderRadius > 0 ? 500 : 0,
                        borderWidth: '2px',
                        margin:
                            props.variant === BasketIconButtonVariant.DENSE
                                ? `calc(${theme.spacing(1)} / 4)`
                                : theme.spacing(1),
                        paddingLeft:
                            props.variant === BasketIconButtonVariant.DENSE
                                ? `calc(${theme.spacing(1)} / 2)`
                                : theme.spacing(2),
                        paddingRight:
                            props.variant === BasketIconButtonVariant.DENSE
                                ? `calc(${theme.spacing(1)} / 2)`
                                : theme.spacing(2),
                        paddingTop:
                            props.variant === BasketIconButtonVariant.DENSE
                                ? `calc(${theme.spacing(1)} / 4)`
                                : theme.spacing(1),
                        paddingBottom:
                            props.variant === BasketIconButtonVariant.DENSE
                                ? `calc(${theme.spacing(1)} / 4)`
                                : theme.spacing(1),
                        '&:hover': {
                            backgroundColor: darken(customizations.basketIconColor, 0.2),
                            color: darken(customizations.basketIconTextColor, 0.2),
                            borderColor: customizations.basketIconBorderColor,
                            borderWidth: '2px',
                        },
                        [theme.breakpoints.down('sm')]: {
                            transform:
                                props.xsSize === 'l'
                                    ? 'scale(1.75)'
                                    : props.xsSize === 'm'
                                      ? 'scale(1.25)'
                                      : 'scale(1)',
                        },
                    })}
                    variant="outlined"
                    onClick={() =>
                        showBasketAtom.update({
                            visible: true,
                            refocusElementOnClose: ref,
                        })
                    }
                    ref={ref}
                >
                    <Badge
                        badgeContent={cartItems.length}
                        color="primary"
                        sx={() => ({
                            '& .MuiBadge-colorPrimary': {
                                color: customizations.basketIconCountColor,
                                backgroundColor: customizations.basketIconTextColor,
                            },
                            '& .MuiBadge-anchorOriginTopRightRectangular': {
                                transform: 'scale(0.75) translate(50%)',
                            },
                            '& .MuiSvgIcon-root': {
                                color: customizations.basketIconTextColor,
                            },
                        })}
                    >
                        <ShoppingBasketOutlinedIcon />
                    </Badge>
                    <Box
                        component="span"
                        sx={(theme) => ({
                            width: '1px',
                            height: '1.25em',
                            backgroundColor: customizations.basketIconTextColor,
                            margin: theme.spacing(0, 1, 0, 2),
                            ...(props.smallOnMobile
                                ? {
                                      [theme.breakpoints.down('sm')]: {
                                          display: 'none',
                                      },
                                  }
                                : {}),
                        })}
                    />
                    <Box
                        component="span"
                        sx={(theme) => ({
                            ...(props.smallOnMobile
                                ? {
                                      [theme.breakpoints.down('sm')]: {
                                          display: 'none',
                                      },
                                  }
                                : {}),
                        })}
                    >
                        {capitalize(t.your_cart)}
                    </Box>
                </Button>
            )}
        </Fragment>
    );
}
