import WarningRounded from '@mui/icons-material/WarningRounded';
import { FormControl, InputLabel, Stack, TextField, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import isNil from 'lodash-es/isNil';
import { createRef, Fragment, useCallback, useEffect, useState } from 'react';
import { useLocale } from '@repo/i18n';
import { ProductInstance, TicketOptionWithQuantity, Timeslot, Translations } from '@repo/types';
import { positionInputLabelAboveField } from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import {
    findFirstAvailableProduct,
    formatTime,
    tryFindFirstTimeslot,
} from '@repo/widget-utils/DateHelpers';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { getInputCallbackProps, getInputLayoutProps } from './common/calendar-props';
import CalendarPopover from './subcomponents/CalendarPopover';
import { EndIcon } from './subcomponents/EndIcon';

interface IProps {
    availabilityData: ProductInstance[];
    selectedTimeSlot?: TimeSlotType;
    onSelectTimeSlot?: (timeslot: TimeSlotType | undefined) => void;
    setSelectedProducts?: (product: ProductInstance[] | undefined) => void;
    availabilitySearchPeriod: { startDay: TZDate | null; endDay: TZDate | null };
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }) => void;
    hasChosenDate: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    attemptedBooking: boolean;
    onSelectDateRange?: (dateRange: DateRange<TZDate>) => void;
    selectedDateRange?: DateRange<TZDate>;
    dateRangeVariant?: 'days' | 'nights';
    minDate?: TZDate;
    id?: string;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    variant?: 'filled' | 'outlined';
    quantities: TicketOptionWithQuantity[];
    availableTimeslots?: Timeslot[];
    border?: string;
}

export default function Calendar(props: IProps): JSX.Element {
    const {
        selectedTimeSlot,
        onSelectTimeSlot,
        setSelectedProducts,
        selectedDateRange,
        onSelectDateRange,
        availabilityData,
        setAvailabilitySearchPeriod,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        dateRangeVariant,
        minDate = TZDate.now(),
        id = '',
        labelColor,
        backgroundColor,
        color,
        variant,
        quantities,
        availableTimeslots,
        border,
    } = props;

    const { t, locale } = useLocale();
    const config = useConfigurations();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    const [displayTimeslot, setDisplayTimeslot] = useState(selectedTimeSlot);
    const [displayDate, setDisplayDate] = useState<TZDate | null>(
        selectedTimeSlot ? selectedTimeSlot.product.start : null,
    );

    const customizations = useCustomizations();
    const theme = useTheme();
    const refocusElement = createRef<HTMLInputElement>();

    const showErrorDate = attemptedBooking && !hasChosenDate;

    const updateDisplayDate = useCallback(
        (date: TZDate | null) => {
            setDisplayDate(date);
            setDisplayTimeslot(tryFindFirstTimeslot(date, availabilityData, quantities));
        },
        [availabilityData, quantities],
    );

    useEffect(() => {
        if (!isNil(displayTimeslot)) return;
        const firstAvailableProduct = findFirstAvailableProduct(availabilityData);

        if (firstAvailableProduct) {
            updateDisplayDate(firstAvailableProduct.start);
        }
    }, [availabilityData, config.timezone, displayTimeslot, updateDisplayDate]);

    useEffect(() => {
        if (selectedTimeSlot) {
            setDisplayTimeslot(selectedTimeSlot);
            setDisplayDate(
                selectedTimeSlot.products && selectedTimeSlot.products.length > 0
                    ? selectedTimeSlot.products[0].start
                    : selectedTimeSlot.product.start,
            );
        } else {
            setDisplayTimeslot(undefined);
            setDisplayDate(null);
        }
    }, [selectedTimeSlot, config.timezone]);

    const selectedDateTimeText =
        dateRangeVariant && selectedDateRange
            ? getSelectedRangeText(selectedDateRange, locale, t, dateRangeVariant)
            : getSelectedDateTimeText(selectedTimeSlot, locale);

    const inputId = `bilberry-calendar-input-${id}`;

    const endIcon = (
        <EndIcon
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            showErrorDate={showErrorDate}
        />
    );

    return (
        <Fragment>
            <FormControl fullWidth>
                <InputLabel
                    id={`calendar-label-${id}`}
                    htmlFor={inputId}
                    sx={{
                        color: labelColor ?? customizations.bookingWidgetColorContrast,
                        ...positionInputLabelAboveField,
                    }}
                >
                    {capitalize(t.when_are_you_going)}
                </InputLabel>
                <TextField
                    id={inputId}
                    inputRef={refocusElement}
                    error={showErrorDate}
                    helperText={
                        showErrorDate ? (
                            <Stack direction="row" alignItems="center">
                                <WarningRounded
                                    sx={{
                                        width: '0.75em',
                                        height: '0.75em',
                                        color:
                                            labelColor ?? customizations.bookingWidgetColorContrast,
                                    }}
                                />
                                &nbsp;{capitalize(t.please_select_date_and_time)}
                            </Stack>
                        ) : (
                            ''
                        )
                    }
                    value={
                        hasChosenDate
                            ? selectedDateTimeText
                            : dateRangeVariant
                              ? t.select_dates
                              : t.select_date
                    }
                    {...getInputLayoutProps(
                        t,
                        theme,
                        customizations,
                        showErrorDate,
                        endIcon,
                        backgroundColor,
                        color,
                        labelColor,
                        variant,
                        border,
                    )}
                    {...getInputCallbackProps(
                        hasChosenDate,
                        setHasChosenDate,
                        deselectTextInput,
                        setAnchorEl,
                    )}
                />
            </FormControl>
            <CalendarPopover
                dateRangeVariant={dateRangeVariant}
                selectedDateRange={selectedDateRange}
                onSelectDateRange={onSelectDateRange}
                minDate={minDate}
                displayDate={displayDate}
                displayTimeslot={displayTimeslot}
                setDisplayTimeslot={setDisplayTimeslot}
                anchorEl={anchorEl}
                availabilityData={availabilityData}
                updateDisplayDate={updateDisplayDate}
                setAnchorEl={setAnchorEl}
                onSelectTimeSlot={onSelectTimeSlot}
                setSelectedProducts={setSelectedProducts}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                id={inputId}
                quantities={quantities}
                setHasChosenDate={setHasChosenDate}
                availableTimeslots={availableTimeslots}
            />
        </Fragment>
    );
}

function getSelectedDateTimeText(selectedTimeSlot: TimeSlotType | undefined, locale: string) {
    if (selectedTimeSlot) {
        const date = tzdate(
            selectedTimeSlot.products && selectedTimeSlot.products.length > 0
                ? selectedTimeSlot.products[0].start
                : selectedTimeSlot.product.start,
        );
        const dayString = date.format('L');
        return `${dayString} - ${formatTime(date)}`;
    }

    return '';
}

function getSelectedRangeText(
    selectedDateRange: DateRange<TZDate>,
    locale: string,
    t: Translations,
    dateRangeVariant?: 'days' | 'nights',
) {
    if (selectedDateRange[0] && selectedDateRange[1]) {
        return `${selectedDateRange[0].format('L')} - ${selectedDateRange[1].format('L')}`;
    } else if (dateRangeVariant === 'days' && selectedDateRange[0]) {
        return selectedDateRange[0].format('L');
    } else return t.select_dates;
}
