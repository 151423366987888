import { Box, Typography, useTheme } from '@mui/material';
import { TZDate } from '@repo/tzdate';
import { useEffect, useState } from 'react';
import UpcomingDaySelector from 'src/components/domain/upcoming-tours/DaySelector/UpcomingDaySelector';
import { getCustomOrDefaultText, useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useUpcomingTours } from '@repo/widget-utils/services/hooks/product';
import { ProductInstance, UserTextCustomizations } from '@repo/types';

export type UpcomingToursProps = {
    useMonths: boolean;
    productCatalogIds: number[];
    productCatalogUrls: string[];
    numberOfDaysToDisplay: number;
    fewLeft: number;
    titleTextCustomizationKey?: keyof UserTextCustomizations;
    disableTitle: boolean;
    disableTogglebuttons: boolean;
    showRemaining?: boolean;
    startDate?: TZDate;
    daySelectorStyle: string;
    toursList: (
        fewLeft: number,
        upcomingTours: ProductInstance[],
        showRemaining?: boolean,
    ) => JSX.Element;
};

type UpcomingToursContentProps = Omit<
    UpcomingToursProps,
    'productCatalogIds' | 'productCatalogUrls' | 'numberOfDaysToDisplay' | 'disableTitle'
> & {
    tours: ProductInstance[];
    title?: string;
};

export function UpcomingToursBase(props: UpcomingToursProps) {
    const {
        productCatalogIds,
        productCatalogUrls,
        numberOfDaysToDisplay,
        titleTextCustomizationKey,
        disableTitle,
        startDate = TZDate.now(),
    } = props;

    const config = useConfigurations();
    const { t, locale } = useLocale();
    const endDate = startDate.add(numberOfDaysToDisplay, 'day');

    const { data: tours } = useUpcomingTours(
        productCatalogIds,
        startDate,
        endDate,
        productCatalogUrls,
    );

    return (
        <UpcomingToursContent
            {...props}
            tours={tours}
            title={
                disableTitle
                    ? undefined
                    : capitalize(
                          getCustomOrDefaultText(
                              config.textCustomizations,
                              titleTextCustomizationKey,
                              locale,
                              t.upcoming_tours_title,
                          ),
                      )
            }
        ></UpcomingToursContent>
    );
}

function UpcomingToursContent({
    tours,
    useMonths,
    fewLeft,
    title,
    disableTogglebuttons,
    showRemaining,
    toursList,
    daySelectorStyle,
}: UpcomingToursContentProps) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const [shownTours, setShownTours] = useState<ProductInstance[]>(tours);
    const [days, setDays] = useState<TZDate[]>([]);
    const [day, setDay] = useState(TZDate.now());

    useEffect(() => {
        const days = tours
            .map((x) => x.start)
            .sort((a, b) => (a.isAfter(b) ? 1 : -1))
            .reduce<TZDate[]>((unique: TZDate[], day, index) => {
                if (index > 0) {
                    if (unique[unique.length - 1].isSame(day, useMonths ? 'month' : 'day'))
                        return unique;
                }
                unique.push(day);
                return unique;
            }, []);

        setDays(days);

        const day = days.length > 0 ? days[0] : TZDate.now();
        setDay(day);

        setShownTours(
            disableTogglebuttons
                ? tours
                : tours.filter((x) => x.start.isSame(day, useMonths ? 'month' : 'day')),
        );
    }, [disableTogglebuttons, tours, useMonths]);

    const onChangeDay = (day: TZDate) => {
        setDay(day);
        setShownTours(
            disableTogglebuttons
                ? tours
                : tours.filter((x) => x.start.isSame(day, useMonths ? 'month' : 'day')),
        );
    };

    return (
        <Box
            color="white"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={theme.spacing(1)}
            sx={{
                backgroundColor: customizations.upcomingToursBackgroundColor,
            }}
        >
            {title && title.length > 0 && (
                <Typography
                    color={{ color: customizations.upcomingToursTitleTextColor }}
                    variant="h2"
                >
                    {title}
                </Typography>
            )}
            {!disableTogglebuttons && (
                <UpcomingDaySelector
                    day={day}
                    onDayChange={onChangeDay}
                    days={days}
                    useMonths={useMonths}
                    daySelectorStyle={daySelectorStyle}
                ></UpcomingDaySelector>
            )}
            {toursList(fewLeft, shownTours, showRemaining)}
        </Box>
    );
}
