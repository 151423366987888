import RadioButton from 'src/components/common/timeslots-buttons/radio-button/RadioButton';
import GridLayout from 'src/components/common/timeslots-containers/layouts/grid-layout/GridLayout';
import { useLocale } from '@repo/i18n';
import { Timeslot } from '@repo/types';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { formatTime } from '@repo/widget-utils/DateHelpers';

type TimeslotButtonGroupProps = {
    rows?: number;
    cols?: number;
    spacing?: 'dense' | 'normal' | 'wide';
    sessions: Timeslot[][];
    timeslotsInSession: number;
    borderColor?: string;
    selectedTimeslot?: TimeSlotType;
    onSelectTimeslot: (timeslot: TimeSlotType) => void;
    participants: number;
};

export default function TimeslotButtonGroup(props: TimeslotButtonGroupProps) {
    const {
        rows,
        cols,
        spacing = 'normal',
        timeslotsInSession,
        sessions = [],
        borderColor,
        participants,
        onSelectTimeslot,
        selectedTimeslot,
    } = props;

    const { t } = useLocale();

    return (
        <GridLayout
            rows={rows || Math.floor(Math.sqrt(sessions.length))}
            cols={cols || Math.ceil(Math.sqrt(sessions.length))}
            spacing={spacing}
            role="radiogroup"
        >
            {sessions.length &&
                sessions.map((session, i) => {
                    if (session.length < timeslotsInSession) return <></>;

                    const [firstTimeslot] = session;
                    const finalTimeslot = session[session.length - 1];
                    const isFinalSessionToday =
                        i === sessions.length - 1 && finalTimeslot.start.isSame(new Date(), 'day');
                    const disabled = session.some(
                        (s) =>
                            (s.capacity < participants ||
                                s.closed ||
                                s.start.subtract(s.cutoffTime, 'minutes').isBefore(Date.now())) &&
                            !isFinalSessionToday,
                    );

                    return (
                        <RadioButton
                            key={`timeslot-button-${firstTimeslot.id}`}
                            text={formatTime(firstTimeslot.start)}
                            subtext={formatTime(finalTimeslot.end)}
                            onClick={() =>
                                onSelectTimeslot({
                                    label: '',
                                    product: firstTimeslot.productInstance,
                                    products: session,
                                })
                            }
                            selected={
                                disabled
                                    ? undefined
                                    : selectedTimeslot?.products?.[0].id === firstTimeslot.id
                            }
                            disabled={disabled}
                            size="large"
                            borderColor={borderColor}
                            aria-label={`${t.select_time} ${formatTime(firstTimeslot.start)} - ${formatTime(finalTimeslot.end)}`}
                        ></RadioButton>
                    );
                })}
        </GridLayout>
    );
}
