import { useEffect, useState, useMemo } from 'react';
import { Product, ProductInstance } from '@repo/types';
import {
    useNextProductAvailability,
    useAvailabilities,
} from '@repo/widget-utils/services/hooks/product';
import { TZDate, tzdate } from '@repo/tzdate';
import { upcomingSelectedTourAtom } from 'src/state/upcomingSelectedTour.atom';
import { useAtom } from 'ximple';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

export default function useManageTourAndAvailability(
    product: Product | null,
    setSelectedTimeslot: (timeslot: TimeSlotType | undefined) => void,
    setSelectedProducts: (products: ProductInstance[] | undefined) => void,
    setHasChosenDate: (hasChosenDate: boolean) => void,
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void,
    productCatalogId: string,
    selectedProducts: ProductInstance[] | undefined,
) {
    const configurations = useConfigurations();
    const [selectedTour, setSelectedTour] = useAtom(upcomingSelectedTourAtom);

    const { data: nextProductAvailability } = useNextProductAvailability(product?.id);

    const nextProductAvailabilityStart = useMemo(() => {
        return nextProductAvailability ? tzdate(nextProductAvailability.next) : undefined;
    }, [nextProductAvailability]);

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>(() => {
        let startDay: TZDate | null = null;
        let endDay: TZDate | null = null;

        if (selectedTour && selectedTour.product?.id === productCatalogId) {
            startDay = tzdate(selectedTour.start).startOf('month');
        } else if (nextProductAvailabilityStart) {
            startDay = nextProductAvailabilityStart.startOf('month');
        }

        if (startDay) {
            endDay = startDay.add(1, 'month');
        }

        return { startDay, endDay };
    });

    const { data: availabilities } = useAvailabilities(
        product,
        availabilitySearchPeriod.startDay,
        availabilitySearchPeriod.endDay,
    );

    useEffect(() => {
        if (configurations.openingDate) {
            setAvailabilitySearchPeriod({
                startDay: tzdate(configurations.openingDate),
                endDay: tzdate(configurations.openingDate).add(1, 'month'),
            });
        }
    }, [configurations.openingDate]);

    useEffect(() => {
        if (
            selectedTour &&
            selectedTour.product?.id === productCatalogId &&
            !selectedProducts?.length &&
            availabilities
        ) {
            const avail = availabilities?.find(
                (avail) =>
                    avail.id === selectedTour.id || avail.collectedIds.includes(selectedTour.id),
            );
            if (!avail) return;
            setSelectedTimeslot({
                product: avail,
                label: avail.product?.title ?? '',
            });
            setSelectedProducts([avail]);
            setHasChosenDate(true);
            onToggleVisible(true, 'keyboard');
            setSelectedTour(null);
        }
    }, [
        selectedTour,
        setSelectedTimeslot,
        setSelectedProducts,
        setHasChosenDate,
        onToggleVisible,
        setSelectedTour,
        productCatalogId,
        selectedProducts,
        availabilities,
    ]);

    return {
        nextProductAvailability,
        nextProductAvailabilityStart,
        availabilities,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod,
    };
}
