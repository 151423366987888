import { createRoot } from 'react-dom/client';
import { UpcomingTours } from 'src/components/domain/upcoming-tours/UpcomingTours';
import { tzdate } from '@repo/tzdate';
import { getCsvAttribute, getYesAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { UserTextCustomizations } from '@repo/types';

class UpcomingToursElement extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const productCatalogIds = getCsvAttribute(this, 'product-catalog-ids', false).map(Number);
        const productCatalogUrls = getCsvAttribute(this, 'product-catalog-urls', true);

        const numberOfDays = this.hasAttribute('number-of-days')
            ? parseInt(this.getAttribute('number-of-days')!)
            : 7;

        const fewLeft = this.hasAttribute('few-left')
            ? parseInt(this.getAttribute('few-left')!)
            : 5;

        const showRemaining = getYesAttribute(this, 'show-remaining');

        const groupBy = this.getAttribute('group-by');
        const titleTextCustomizationKey = this.getAttribute(
            'title-text-customization-key',
        ) as keyof UserTextCustomizations;

        const disableTitle = getYesAttribute(this, 'disable-title');
        const disableGrouping = getYesAttribute(this, 'disable-grouping');

        const startDate = this.hasAttribute('start-date')
            ? tzdate(this.getAttribute('start-date')!, false)
            : undefined;

        const daySelectorStyle = this.getAttribute('day-selector-style') ?? 'auto';

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <UpcomingTours
                    productCatalogIds={productCatalogIds}
                    productCatalogUrls={productCatalogUrls}
                    numberOfDaysToDisplay={numberOfDays}
                    fewLeft={fewLeft}
                    useMonths={groupBy === 'month' ? true : false}
                    titleTextCustomizationKey={titleTextCustomizationKey ?? undefined}
                    disableTitle={disableTitle}
                    disableTogglebuttons={disableGrouping}
                    showRemaining={showRemaining}
                    startDate={startDate}
                    daySelectorStyle={daySelectorStyle}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-upcoming-tours', UpcomingToursElement);
}
