import { Box, useTheme } from '@mui/material';
import EditActivityButton from 'src/components/domain/summary/edit-activity/EditActivity';
import { CartItem } from '@repo/types';
import { SummaryInfoFromQuantities } from './SummaryInfoFromQuantities';

interface IProps {
    cartItem: CartItem;
    invertedColorsClass?: Record<string, unknown>;
    onEditClick?: (e: React.MouseEvent) => void;
    isLoadingPrices: boolean;
}

export default function SummaryPriceDetailsForCartItem(props: IProps): JSX.Element {
    const theme = useTheme();

    const isAccommodation = props.cartItem.products[0]?.product?.type === 'accommodation';

    const ticketOptions = isAccommodation
        ? props.cartItem.ticketOptions.slice(0, 1)
        : props.cartItem.ticketOptions;

    return (
        <Box m={theme.spacing(0.5, 0, 2, 0)}>
            <SummaryInfoFromQuantities
                quantities={ticketOptions}
                ticketType={props.cartItem.ticketType}
                invertedColorsClass={props.invertedColorsClass}
                isDetailed={true}
                isLoadingPrices={props.isLoadingPrices}
            />
            {props.onEditClick && (
                <EditActivityButton
                    cartItem={props.cartItem}
                    invertedColorsClass={props.invertedColorsClass}
                    onEditClick={props.onEditClick}
                />
            )}
        </Box>
    );
}
