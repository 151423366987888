import { groupBy, mapValues, sumBy } from 'lodash-es';
import {} from 'react';
import { localeAtom } from '@repo/i18n';
import { TicketOptionWithQuantity, Translations } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';

function getNumberOfTravelers(quantities: TicketOptionWithQuantity[]) {
    return sumBy(quantities, getNumberOfTravelersForTicketOption);
}

function isTicketOptionCollectionMode(ticketOption: TicketOptionWithQuantity) {
    return ticketOption.productInstances.some((x) => x.productInstanceCollectionId);
}

function isTicketOptionJoinedTrip(ticketOption: TicketOptionWithQuantity) {
    const firstProductInstance =
        ticketOption.productInstances.length > 0 ? ticketOption.productInstances[0] : null;
    return firstProductInstance &&
        firstProductInstance.productInstanceCollectionMinEntrants !== null
        ? true
        : false;
}

function getNumberOfTravelersForTicketOption(ticketOption: TicketOptionWithQuantity) {
    return ticketOption.quantity * ticketOption.occupancy;
}

function getNumberOfTravelersPerProductInstance(ticketOptions: TicketOptionWithQuantity[]) {
    return mapValues(
        groupBy(ticketOptions, (to) => to.productInstances[0]?.id),
        (to) => getNumberOfTravelers(to),
    );
}

function calculateMaxTravellersForCollectionMode(
    numberOfTravellersForTicketOption: number,
    totalTravellersToConsider: number,
    maxTravellersPerBooking: number | null,
    productInstanceCapacity: number | null,
) {
    const maxProductInstance = productInstanceCapacity ?? Infinity;

    if (!maxTravellersPerBooking) return maxProductInstance;

    const maxTravellersPerBookingConsideringCapacity = Math.min(
        maxTravellersPerBooking,
        maxProductInstance,
    );

    const numberOfTravellersForOtherTicketOptions =
        totalTravellersToConsider - numberOfTravellersForTicketOption;

    const maxProductCollection =
        maxTravellersPerBookingConsideringCapacity - numberOfTravellersForOtherTicketOptions;
    const max = Math.min(maxProductInstance, maxProductCollection);
    return Math.max(0, max);
}

function calculateMaxBaseCase(
    capacity: number,
    maxEntrantsProductInstance: number | null,
    quantity: number,
    totalTravelers: number,
) {
    const max = Math.min(maxEntrantsProductInstance ?? Infinity, capacity);
    const maxProductInstance = max - (totalTravelers !== quantity ? totalTravelers - quantity : 0);
    return Math.min(max, maxProductInstance);
}

function calculateMin(
    minEntrants: number | null,
    productInstances: TicketOptionWithQuantity['productInstances'],
) {
    if (minEntrants) return minEntrants;
    return productInstances.reduce((min, pi) => Math.max(min, pi.minEntrants ?? 0), 0);
}

export function mapPriceQuantitiesToMultipleNumberInputValueType(
    t: Translations,
    quantities: TicketOptionWithQuantity[],
    defaultQuantities: TicketOptionWithQuantity[],
    errors: QuantityError[],
) {
    const totalTravelers = getNumberOfTravelers(quantities);
    const multipleNumberInputValues = quantities.map((q) => {
        return getMultipleNumberInputValueForTicketOption(q, t, totalTravelers, errors, quantities);
    });

    const defaultMultipleNumberInputValues = defaultQuantities.map((q) => {
        return getDefaultMultipleNumberInputValueForTicketOption(q, t, errors);
    });

    return { multipleNumberInputValues, defaultMultipleNumberInputValues };
}

function getCollectionModeMaxTravellersPerBookingForTicketOption(
    ticketOption: TicketOptionWithQuantity,
) {
    const productInstanceWithMaxEntantsPerBooking = ticketOption.productInstances.find(
        (x) => x.productInstanceCollectionMaxEntrantsPerBooking !== null,
    );
    return (
        productInstanceWithMaxEntantsPerBooking?.productInstanceCollectionMaxEntrantsPerBooking ??
        null
    );
}

function getCollectionModeMaxTravellersForTicketOption(ticketOption: TicketOptionWithQuantity) {
    const productInstanceWithMaxEntrantsPerBooking = ticketOption.productInstances.find(
        (x) => x.productInstanceCollectionMaxEntrants !== null,
    );
    return productInstanceWithMaxEntrantsPerBooking?.productInstanceCollectionMaxEntrants ?? null;
}

function getProductInstanceCapacityForTicketOption(ticketOption: TicketOptionWithQuantity) {
    const lastProductInstance =
        ticketOption.productInstances[ticketOption.productInstances.length - 1];

    return lastProductInstance && lastProductInstance.capacity !== Number.MAX_VALUE
        ? lastProductInstance.capacity
        : null;
}

function getMultipleNumberInputValueForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    t: Translations,
    totalTravelers: number,
    errors: QuantityError[],
    allTicketOptions: TicketOptionWithQuantity[],
) {
    const max = getMaxTravellersForTicketOption(ticketOption, totalTravelers, allTicketOptions);
    const maxConsideringOccupancy = Math.floor(max / ticketOption.occupancy);

    const min = getMinTravellersForTicketOption(ticketOption);

    return {
        name: ticketOption.name,
        value: ticketOption.quantity,
        subText: getSubText(t, ticketOption),
        id: ticketOption.ticketCategoryId as unknown as number,
        // disabled: q.shouldDisablePrice, // TODO: Find a way to handle this
        disabledText: t.some_tickets_are_unavailable_the_selected_day,
        min,
        max: maxConsideringOccupancy,
        error: errors.find((e) => e.id === ticketOption.ticketCategoryId),
    };
}

function getMaxTravellersForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    totalTravelers: number,
    allTicketOptions: TicketOptionWithQuantity[],
) {
    const collectionModeMaxTravellersPerBooking =
        getCollectionModeMaxTravellersPerBookingForTicketOption(ticketOption);
    const productInstanceCapacity = getCapacityForTicketOption(ticketOption, allTicketOptions);

    const isCollectionMode = isTicketOptionCollectionMode(ticketOption);

    const numberOfTravellersForTicketOption = getNumberOfTravelersForTicketOption(ticketOption);

    if (isCollectionMode) {
        const totalTravellersToConsider =
            collectionModeMaxTravellersPerBooking !== null
                ? totalTravelers
                : numberOfTravellersForTicketOption;

        const maxTravellersPerBooking =
            collectionModeMaxTravellersPerBooking !== null
                ? collectionModeMaxTravellersPerBooking
                : productInstanceCapacity;

        const maxTravellersForTicketOption = calculateMaxTravellersForCollectionMode(
            numberOfTravellersForTicketOption,
            totalTravellersToConsider,
            maxTravellersPerBooking,
            productInstanceCapacity,
        );

        // never more than the capacity returned by Bilberry
        const maxTravellersForTicketOptionConsideringCapacity = Math.min(
            ticketOption.capacity,
            maxTravellersForTicketOption,
        );

        return maxTravellersForTicketOptionConsideringCapacity;
    }

    return calculateMaxBaseCase(
        ticketOption.capacity,
        productInstanceCapacity,
        numberOfTravellersForTicketOption,
        totalTravelers,
    );
}

function getMinTravellersForTicketOption(ticketOption: TicketOptionWithQuantity) {
    return calculateMin(ticketOption.minEntrants, ticketOption.productInstances);
}

function getDefaultMultipleNumberInputValueForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    t: Translations,
    errors: QuantityError[],
) {
    const min = calculateMin(ticketOption.minEntrants, ticketOption.productInstances);

    return {
        name: ticketOption.name,
        value: ticketOption.quantity,
        subText: getSubText(t, ticketOption),
        id: ticketOption.ticketCategoryId as unknown as number,
        // disabled: q.shouldDisablePrice, // TODO: Find a way to handle this
        disabledText: t.some_tickets_are_unavailable_the_selected_day,
        min: min === Infinity ? 0 : min,
        max: Math.floor(ticketOption.capacity / ticketOption.occupancy),
        error: errors.find((e) => e.id === ticketOption.ticketCategoryId),
    };
}

export function getSubText(t: Translations, ticketOptions: TicketOptionWithQuantity | undefined) {
    if (!ticketOptions) return undefined;
    return ticketOptions.fromAge && ticketOptions.toAge
        ? `(${capitalize(t.age)} ${ticketOptions.fromAge}-${ticketOptions.toAge})`
        : undefined;
}

export function onChangeQuantity(
    id: number,
    quantity: number,
    setHasChangedQuantities: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setQuantities: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>,
) {
    setQuantities((prevState) => {
        const index = prevState?.findIndex((x) => x.ticketCategoryId === id.toString());

        if (prevState[index].quantity !== quantity) {
            const newQuantities = [
                ...prevState.slice(0, index),
                { ...prevState[index], quantity: quantity },
                ...prevState.slice(index + 1),
            ];

            if (newQuantities[index].quantity > newQuantities[index].capacity) {
                if (quantity > prevState[index].quantity) {
                    return prevState;
                }
            }
            return newQuantities;
        }
        return prevState;
    });
    setHasChangedQuantities?.(true);
}

export type QuantityError = {
    id: string;
    error: 'minEntrants' | 'capacity';
    value: number;
    type: 'individual' | 'total';
};

function validateMinEntrantsForProductInstanceCollection(
    ticketOptions: TicketOptionWithQuantity[],
    errors: QuantityError[],
) {
    const productInstanceCollectionMinEntrants =
        ticketOptions[0].productInstances.find(
            (pi) => typeof pi.productInstanceCollectionMinEntrants === 'number',
        )?.productInstanceCollectionMinEntrants ?? 0;
    const numberOfTravelers = ticketOptions.reduce(
        (acc, cur) => acc + cur.quantity * cur.occupancy,
        0,
    );

    if (numberOfTravelers < productInstanceCollectionMinEntrants) {
        errors.push({
            id: ticketOptions[0].productInstances[0].productInstanceCollectionId ?? '',
            error: 'minEntrants',
            value: productInstanceCollectionMinEntrants,
            type: 'total',
        });
    }
}

function validateCapacityForJoinedMode(
    ticketOptions: TicketOptionWithQuantity[],
    numberOfTravelers: number,
    errors: QuantityError[],
) {
    const [firstTicketOption] = ticketOptions;

    const capacity = getCapacityForJoinedMode(ticketOptions);

    if (!capacity || numberOfTravelers > capacity) {
        errors.push({
            id: firstTicketOption.productInstances[0]?.productInstanceCollectionId ?? '',
            error: 'capacity',
            value: capacity ?? 0,
            type: 'total',
        });
    }
}

function getCapacityForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    allTicketOptions: TicketOptionWithQuantity[],
) {
    const isJoinedTrip = isTicketOptionJoinedTrip(ticketOption);

    if (isJoinedTrip) {
        return getCapacityForJoinedMode(allTicketOptions);
    }

    return getProductInstanceCapacityForTicketOption(ticketOption);
}

function getCapacityForJoinedMode(ticketOptions: TicketOptionWithQuantity[]) {
    const [firstTicketOption] = ticketOptions;

    const maxTravellersPerBooking =
        getCollectionModeMaxTravellersPerBookingForTicketOption(firstTicketOption);

    const maxTravellers = getCollectionModeMaxTravellersForTicketOption(firstTicketOption);

    const maxCapacity = maxTravellersPerBooking ?? maxTravellers ?? 0;

    // Figure out how many travellers have already been reserved by summarizing the currently consumed capacity for all ticket options
    const currentlyConsumedCapacity = sumBy(
        ticketOptions,
        (ticketOption) => ticketOption.currentConsumedCapacity,
    );

    // The current capacity is the global max capacity minus the travellers that have already booked
    return maxCapacity - currentlyConsumedCapacity;
}

function validateIndividualProductRequirements(
    ticketOptions: TicketOptionWithQuantity[],
    numberOfTravelersPerProductInstance: Record<string, number>,
    requireExplicitlyAllowedZeroQuantity: boolean,
    errors: QuantityError[],
) {
    ticketOptions.forEach((to) => {
        const quantity = to.quantity * to.occupancy;
        if (quantity === 0 && !requireExplicitlyAllowedZeroQuantity) return;

        const hasMinEntrantsForTicketOption =
            to.minEntrants !== null && typeof to.minEntrants === 'number';

        const isCurrentQuantityLessThanMinEntrantsForTicketOption =
            hasMinEntrantsForTicketOption && quantity < to.minEntrants;

        if (isCurrentQuantityLessThanMinEntrantsForTicketOption) {
            errors.push({
                id: to.ticketCategoryId,
                error: 'minEntrants',
                type: 'individual',
                value: to.minEntrants,
            });
        } else {
            to.productInstances.forEach((pi) => {
                const requiredNumberOfTravellersForThisInstance = pi.minEntrants ?? 0;
                const currentTravellersForThisInstance = numberOfTravelersPerProductInstance[pi.id];
                const hasTravellersForThisProductInstance = currentTravellersForThisInstance > 0;

                const validateRequiredNumberOfTravellers =
                    hasTravellersForThisProductInstance || requireExplicitlyAllowedZeroQuantity;
                const hasLessTravellersThanRequired =
                    currentTravellersForThisInstance < requiredNumberOfTravellersForThisInstance;

                const hasError =
                    validateRequiredNumberOfTravellers && hasLessTravellersThanRequired;

                if (hasError) {
                    errors.push({
                        id: to.ticketCategoryId,
                        error: 'minEntrants',
                        type: 'individual',
                        value: pi.minEntrants ?? 0,
                    });
                }
            });
        }
    });
}

function checkCapacityConstraints(
    ticketOptions: TicketOptionWithQuantity[],
    numberOfTravelers: number,
    isJoinedMode: boolean,
    errors: QuantityError[],
) {
    if (isJoinedMode) {
        validateCapacityForJoinedMode(ticketOptions, numberOfTravelers, errors);
    }

    ticketOptions.forEach((to) => {
        let travelers = numberOfTravelers;
        const isProductInstanceCollection = to.productInstances.some(
            (p) => p.productInstanceCollectionId !== null,
        );

        if (isProductInstanceCollection) {
            travelers = to.quantity * to.occupancy;
        }

        to.productInstances.forEach((pi) => {
            if (travelers > pi.capacity) {
                errors.push({
                    id: to.ticketCategoryId,
                    error: 'capacity',
                    type: isProductInstanceCollection ? 'individual' : 'total',
                    value: pi.capacity,
                });
            }

            if (
                typeof pi.productInstanceCollectionMaxEntrantsPerBooking === 'number' &&
                travelers > pi.productInstanceCollectionMaxEntrantsPerBooking
            ) {
                errors.push({
                    id: to.ticketCategoryId,
                    error: 'capacity',
                    type: 'total',
                    value: pi.productInstanceCollectionMaxEntrantsPerBooking,
                });
            }
        });

        if (to.capacity !== Number.MAX_VALUE && travelers > to.capacity) {
            errors.push({
                id: to.ticketCategoryId,
                error: 'capacity',
                type: isProductInstanceCollection ? 'individual' : 'total',
                value: to.capacity,
            });
        }
    });
}

export function verifyQuantities(priceQuantities: TicketOptionWithQuantity[]) {
    const errors: QuantityError[] = [];
    const groupedTicketOptions = groupBy(priceQuantities, (pq) =>
        pq.productInstances.map((pi) => pi.productInstanceCollectionId ?? pi.id).join(''),
    );

    Object.values(groupedTicketOptions).forEach((ticketOptions) => {
        const [firstTicketOption] = ticketOptions;

        const isJoinedTrip = isTicketOptionJoinedTrip(firstTicketOption);
        getNumberOfTravelers;
        const numberOfTravelers = getNumberOfTravelers(ticketOptions);
        const numberOfTravelersPerProductInstance =
            getNumberOfTravelersPerProductInstance(ticketOptions);

        const isProjectCollectionMode = isTicketOptionCollectionMode(firstTicketOption);

        if (isProjectCollectionMode) {
            validateMinEntrantsForProductInstanceCollection(ticketOptions, errors);
        }

        const projectCollectionRequireAllValid = ticketOptions.some((t) =>
            t.productInstances.some((pi) => pi.productInstanceCollectionValidateAll),
        );

        const skipIndividualProductRequirementValidation =
            isProjectCollectionMode && isJoinedTrip && !projectCollectionRequireAllValid;
        const requireIndividialProductRequirementValidation =
            !isProjectCollectionMode || !skipIndividualProductRequirementValidation;
        if (requireIndividialProductRequirementValidation) {
            const requireExplicitlyAllowedZeroQuantity =
                isJoinedTrip && projectCollectionRequireAllValid;
            validateIndividualProductRequirements(
                ticketOptions,
                numberOfTravelersPerProductInstance,
                requireExplicitlyAllowedZeroQuantity,
                errors,
            );
        }

        checkCapacityConstraints(ticketOptions, numberOfTravelers, isJoinedTrip, errors);
    });

    return errors;
}

export function verifyQuantitiesValid(
    priceQuantities: TicketOptionWithQuantity[],
    capacityWarningLabel?: string,
    hasChosenDate?: boolean,
    attemptedBooking?: boolean,
) {
    const errors: QuantityError[] = verifyQuantities(priceQuantities);

    function getWarningLabel() {
        if (!hasChosenDate) return undefined;
        const { t } = localeAtom.subject.value;
        if (errors.some((e) => e.error === 'minEntrants')) {
            return t.a_minimum_of_participants_is_required_to_book_this_product;
        } else if (errors.some((e) => e.error === 'capacity')) {
            return capacityWarningLabel ?? t.no_available_capacity_for_this_tour;
        }
    }

    if (!priceQuantities.some((q) => q.quantity > 0) && attemptedBooking && errors.length === 0) {
        errors.push({
            id: 'totalMinEntrants',
            error: 'minEntrants',
            value: 1,
            type: 'total',
        });
    }

    const quantityErrors = errors.filter((q) => q.type === 'individual');
    const totalErrors = errors.filter((q) => q.type === 'total');

    return {
        disableBookButton: (!hasChosenDate && attemptedBooking) || errors.length > 0,
        quantityErrors,
        totalErrors,
        getWarningLabel,
    };
}
