import { Grid, SxProps } from '@mui/material';

interface IProps {
    size: string;
    children: any;
    sx?: SxProps;
}

export function SmallerTextInline(props: IProps) {
    const { children } = props;
    return (
        <Grid component="span" fontSize={props.size} sx={props.sx}>
            {children}
        </Grid>
    );
}
