import { UpcomingToursBase, UpcomingToursProps } from './UpcomingToursBase';
import {
    ProductCardContainerLandscape,
    ProductCardInfoContentLandscape,
} from '../product-card-2/ProductCardLandscape';
import { TourProductCardBookingForm } from '../tour-product-card/TourProductCardForm';
import { Box, Button, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { ProductInstance } from '@repo/types';
import { InlineSummary } from '../summary/InlineSummary';
import { useState } from 'react';
import TourAvailability from './tour-availability/TourAvailability';
import {
    CalendarMonthRounded,
    KeyboardArrowUpRounded,
    LocationOnRounded,
} from '@mui/icons-material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { BookingContextProvider, useBookingContext } from 'src/widgets/BookingContext';

export function UpcomingToursBookable(
    props: Omit<UpcomingToursProps, 'toursList'> & {
        hideDescription?: boolean;
        hideReadMore?: boolean;
    },
) {
    const theme = useTheme();
    return (
        <UpcomingToursBase
            {...props}
            toursList={(fewLeft, upcomingTours, showRemaining) => {
                return (
                    <Stack
                        width="100%"
                        gap={2}
                        sx={{
                            containerType: 'inline-size',
                            containerName: 'upcoming-tours',
                        }}
                    >
                        {upcomingTours.map((tour) => {
                            const products = [tour];

                            return (
                                <BookingContextProvider
                                    key={tour.id}
                                    initialProducts={products}
                                    initialTicketOptions={[]}
                                >
                                    <ProductCardContainerLandscape p={{ mdContainer: 2 }}>
                                        {false && props.fewLeft && (
                                            <Box
                                                borderRadius={1.5}
                                                zIndex={2}
                                                justifyContent="center"
                                                position="absolute"
                                                top={0}
                                                right={0}
                                                sx={{
                                                    transform: 'rotateZ(45deg) translateX(30%)',
                                                    background: theme.palette.grey[800],
                                                    '& > .MuiTypography-root': {
                                                        color: theme.palette.common.white,
                                                        whiteSpace: 'nowrap',
                                                    },
                                                }}
                                            >
                                                <TourAvailability
                                                    fewLeft={props.fewLeft}
                                                    capacity={
                                                        tour.isClosedForBooking ? 0 : tour.capacity
                                                    }
                                                    showRemaining={props.showRemaining}
                                                    px={3}
                                                />
                                            </Box>
                                        )}
                                        <Box
                                            position="relative"
                                            borderRadius={2}
                                            overflow="hidden"
                                            flex={2}
                                            sx={{
                                                '@container (max-width: 600px)': {
                                                    aspectRatio: '16/9',
                                                    width: '100%',
                                                    height: '100%',
                                                },
                                            }}
                                        >
                                            <Box
                                                position="absolute"
                                                component="img"
                                                src={tour.product?.coverImage.src}
                                                alt={tour.product?.coverImage.alt}
                                                width="100%"
                                                height="100%"
                                                sx={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                }}
                                            />
                                        </Box>
                                        <FlipableBookingForm
                                            products={products}
                                            hideDescription={props.hideDescription}
                                            hideReadMore={props.hideReadMore}
                                            fewLeft={fewLeft}
                                            showRemaining={showRemaining}
                                        />
                                    </ProductCardContainerLandscape>
                                </BookingContextProvider>
                            );
                        })}
                    </Stack>
                );
            }}
        />
    );
}

function FlipableBookingForm(props: {
    products: ProductInstance[];
    hideDescription?: boolean;
    hideReadMore?: boolean;
    fewLeft: number;
    showRemaining?: boolean;
}) {
    const { products } = props;
    const [flipped, setFlipped] = useState(false);
    const theme = useTheme();
    const customizations = useCustomizations();
    const { isLoadingPrices } = useBookingContext();

    return (
        <Stack
            flex={3}
            position="relative"
            justifyContent="stretch"
            alignItems="flex-start"
            width="100%"
            sx={{
                '.MuiCollapse-root': {
                    width: '100%',
                },
            }}
        >
            <Collapse
                in={!flipped}
                sx={{
                    display: 'block',
                    width: '100%',
                }}
            >
                <Stack
                    flex={1}
                    gap={0.5}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    width="100%"
                    minHeight={150}
                >
                    <ProductCardInfoContentLandscape
                        url={products[0].product?.url ?? ''}
                        maxWidth="100%"
                        description={
                            props.hideDescription
                                ? null
                                : (products[0].product?.shortDescription ?? '')
                        }
                        title={
                            <Stack
                                direction="row"
                                gap={2}
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Typography
                                    variant="h3"
                                    color={customizations.productCardTextColor}
                                    fontSize={theme.typography.fontSize * 1.5}
                                    lineHeight={1.1}
                                    fontWeight="bold"
                                >
                                    {products[0].product?.title ?? ''}
                                </Typography>

                                <TourAvailability
                                    fewLeft={props.fewLeft}
                                    capacity={
                                        products[0].isClosedForBooking ? 0 : products[0].capacity
                                    }
                                    showRemaining={props.showRemaining}
                                    px={2}
                                    disableMinWidth
                                />
                            </Stack>
                        }
                        readMoreProductId={products[0].product?.id}
                        readMoreLinkVariant={!props.hideReadMore ? 'popup' : undefined}
                        hideImage
                    />

                    <InlineSummary
                        onBook={() => setFlipped(true)}
                        quantities={[]}
                        fromPrice={products[0].product?.fromPrice ?? null}
                        hasCapacity={products.some((product) => product.capacity > 0)}
                        bookingDisabled={!products.some((product) => product.capacity > 0)}
                        totalPriceFontSize={
                            props.hideDescription ? theme.typography.fontSize * 1.15 : undefined
                        }
                        fromLabelFontSize={
                            props.hideDescription ? theme.typography.fontSize * 0.9 : undefined
                        }
                        additionalLabel={
                            products[0].product?.location?.city ? (
                                <Box>
                                    <Stack alignItems="flex-end">
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <Typography
                                                lineHeight={1}
                                                fontSize={`calc(${customizations.fontSize} * 0.8)`}
                                            >
                                                {products[0].product?.location?.city}
                                            </Typography>
                                            <LocationOnRounded />
                                        </Stack>

                                        <Stack
                                            direction="row"
                                            alignItems="flex-end"
                                            pt={0.5}
                                            gap={1}
                                        >
                                            <Typography
                                                fontSize={`calc(${customizations.fontSize} * 0.8)`}
                                                lineHeight={0.9}
                                                textAlign="right"
                                            >
                                                {products[0].start.format('L LT')}
                                            </Typography>
                                            <CalendarMonthRounded />
                                        </Stack>
                                    </Stack>
                                </Box>
                            ) : undefined
                        }
                        isLoadingPrices={isLoadingPrices}
                    />
                </Stack>
            </Collapse>

            <Collapse
                in={flipped}
                sx={{
                    display: 'block',
                    width: '100%',
                }}
            >
                <Stack width="100%" gap={2} alignItems="flex-start">
                    <Button
                        variant="text"
                        onClick={() => setFlipped(false)}
                        sx={{ fontSize: `calc(${customizations.fontSize} * 0.9)`, pl: 0 }}
                    >
                        <KeyboardArrowUpRounded sx={{ mr: 1 }} />
                        Show information
                    </Button>
                    <TourProductCardBookingForm products={products} />
                </Stack>
            </Collapse>
        </Stack>
    );
}
