import { useEffect } from 'react';
import useNewCartItem from './useNewCartItem';
import { useBookingContext } from 'src/widgets/BookingContext';
import { isEqual } from 'lodash-es';

export default function useUpdateNewCartItem(
    newItemDisablePaymentPlans: boolean,
    newItemRequiresPaymentPlans: boolean,
) {
    const { setNewCartItem, newCartItem, selectedProducts, quantities, selectedTimeSlot } =
        useBookingContext();
    const selectedNewCartItem = useNewCartItem(
        selectedProducts,
        quantities,
        selectedTimeSlot?.products,
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
    );
    useEffect(() => {
        if (isEqual(selectedNewCartItem, newCartItem)) return;

        if (selectedNewCartItem) {
            setNewCartItem(selectedNewCartItem);
        } else {
            setNewCartItem(null);
        }
    }, [selectedNewCartItem, setNewCartItem, newCartItem]);
}
