import { useEffect } from 'react';
import { useAtom } from 'ximple';
import { checkoutInfoAtom, setInit } from './checkoutInfoAtom';
import { cartAtom } from '../cart/cartAtom';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export function useCheckoutInfoEffects() {
    const { loggedInUser } = useMemberContext();
    const [cart] = useAtom(cartAtom);

    useEffect(() => {
        checkoutInfoAtom.update(setInit({ cart, loggedInUser }));
    }, [cart, loggedInUser]);
}
