import useSWR from 'swr';
import { localeAtom } from '@repo/i18n';
import {
    MembershipBooking,
    MembershipIntentMultiResponse,
    MembershipOrder,
    MembershipUser,
    MembershipUserValueCard,
    MembershipValueCardProduct,
} from '@repo/types';
import {
    getUrlWithParams,
    apiUrl,
    post,
    get,
    MembershipIntentMultiRequest,
} from '../../fetchers/membership';

export function useLatestValueCardSubscriptionOrder(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/latest-order`,
        locale,
        {},
    );
    const { data, error, isLoading, mutate } = useSWR<MembershipOrder>(url, get);

    return {
        order: data ?? undefined,
        error: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useLoggedInUser(apiToken: string | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + '/Users/me', locale);
    const key = apiToken ? url : null;
    const { data, error, isLoading, mutate } = useSWR<MembershipUser>(
        key,
        async () => {
            const response = await get(url);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useAvailableValueCardProductsForUser(apiToken: string | null, siteKey?: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/availableValueCardProducts/site/${siteKey}`,
        locale,
    );
    const key = apiToken && siteKey ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipValueCardProduct[]>(
        key,
        async () => {
            const response = await get(url, localeAtom.subject.value.t.couldntGetValueCardProducts);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useValueCardsForUser(apiToken: string | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me/valueCards`, locale);
    const key = apiToken ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipUserValueCard[]>(
        key,
        async () => {
            const response = await get(url, localeAtom.subject.value.t.couldntGetValueCards);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useBookingsForUser(user: MembershipUser | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me/bookings`, locale);
    const key = user ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipBooking[]>(
        key,
        async () => {
            const response = await get(url);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useIntentMulti(
    request: MembershipIntentMultiRequest | null,
    user: MembershipUser | null,
    siteKey?: string,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/site/${siteKey}/intent-multi`, locale, {});

    const keyIfFetch = {
        userId: user?.id,
        activityReservations: request?.activityReservations,
        timeslotReservations: request?.timeslotReservations,
        coupon_code: request?.coupon_code,
    };

    const key = siteKey && request ? keyIfFetch : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipIntentMultiResponse>(
        key,
        async () => {
            const response = await post(url, request);
            return response;
        },
        {
            revalidateOnFocus: false,
            dedupingInterval: 1000 * 10, // We'll assume the prices are valid for at least 10 seconds.
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}
