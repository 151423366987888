import ActivateBookingWidget from 'src/widgets/activities/activate-booking-widget/ActivateBookingWidget';
import ActivityBookingInline from 'src/widgets/activities/booking-inline/BookingInline';
import BasketIcon from 'src/widgets/activities/basket-icon/BasketIcon';
import Basket from 'src/widgets/activities/basket/Basket';
import BookingSearchFormWidget from 'src/widgets/activities/booking-search/BookingSearchFormWidget';
import BookingSearchWidget from 'src/widgets/activities/booking-search/BookingSearchWidget';
import BookingToggler from 'src/widgets/activities/booking-toggler/BookingToggler';
import Checkout from 'src/widgets/activities/checkout/Checkout';
import LeadsFormWidget from 'src/widgets/activities/leads-form/LeadsFormWidget';
import ProductFeatured from 'src/widgets/activities/product/product-featured/ProductFeatured';
import ProductGallery2Widget from 'src/widgets/activities/product/product-gallery-2/ProductGallery2Widget';
import ProductImageWidget from 'src/widgets/activities/product/product-image/ProductImageWidget';
import ProductList2 from 'src/widgets/activities/product/product-list-2/ProductList2';
import ProductOverview2Widget from 'src/widgets/activities/product/product-overview-2/ProductOverview2Widget';
import ProductPage2 from 'src/widgets/activities/product/product-page-2/ProductPage2Widget';
import RequestTourWidget from 'src/widgets/activities/request-tour/RequestTourWidget';
import AccommodationGalleryWidget from './widgets/activities/accommodation-gallery/AccommodationGalleryWidget';
import AccommodationProductOverviewWidget from './widgets/activities/accommodation-product/AccommodationProductOverviewWidget';
import AccommodationSearchWidget from './widgets/activities/accommodation-search/AccommodationSearchWidget';
import CreateGiftcardButton from './widgets/activities/create-gift-card-button/CreateGiftcardButton';
import ProductListBookableWidget from './widgets/activities/product/product-list-bookable/ProductListBookableWidget';
import SmartEventDetailsWidget from './widgets/activities/smart-events/SmartEventDetailsWidget';
import SmartEventListWidget from './widgets/activities/smart-events/SmartEventListWidget';
import TourListBookableWidget from './widgets/activities/tour/TourListBookableWidget';
import UpcomingToursWidget from './widgets/activities/upcoming-tours/UpcomingToursWidget';
import PackageItinerary from './widgets/activities/packages/PackageItineraryWidget';
import PackageBooking from './widgets/activities/packages/PackageBookingWidget';
import PackageList from './widgets/activities/packages/PackageListWidget';
import { initSentry } from 'src/utils/widget/sentryConfigurations';
import { setupPaymentProvider } from 'src/widgets/initPayments';
import ModalWidget from 'src/widgets/activities/modal-widget/ModalWidget';
import { initFromSettings } from './utils/widget/initFromSettings';
import ProductDetailsWidget from 'src/widgets/activities/product/product-details/ProductDetailsWidget';
import GlobalSnackbar from 'src/widgets/common/GlobalSnackbar';
import 'src/utils/widget/error-handling';
import 'src/utils/widget/setup';
import 'src/events/connect-analytics';
import BookingWidget from './widgets/activities/activity-booking/BookingWidget';
import { loadCartFromQueryParameters } from '@repo/widget-utils/sharing/serializeCart';
import AccommodationCalendarSearchWidget from './widgets/activities/accommodation-calendar-search/AccommodationSearchWidget';
import 'src/utils/widget/overrideFunctions';
import { getBodyNode } from '@repo/widget-utils/dom-helper';
import UpcomingToursBookableWidget from 'src/widgets/activities/upcoming-tours/UpcomingToursBookableWidget';
import ListOfSmartEventsWidget from './widgets/activities/smart-events/ListOfSmartEventsWidget';
import PackageBookingInlineWidget from './widgets/activities/packages/PackageBookingInlineWidget';
import { cartAtom } from './state/cart/cartAtom';
import { showBasketAtom } from './state/ui/showBasket.atom';
import { findBrefValue } from './state/localstorage';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';

initSentry();

ProductDetailsWidget();
ModalWidget();

initFromSettings();

const checkout = document.createElement('bilberry-checkout');
const modal = document.createElement('bilberry-modal');

setupPaymentProvider(checkout);

BookingToggler();
BasketIcon();
Basket();
ActivityBookingInline();
Checkout();
ActivateBookingWidget();
ProductImageWidget();
LeadsFormWidget();
ProductList2();
BookingSearchWidget();
BookingSearchFormWidget();
ProductOverview2Widget();
ProductGallery2Widget();
ProductPage2();
RequestTourWidget();
ProductFeatured();
CreateGiftcardButton();
AccommodationSearchWidget();
AccommodationProductOverviewWidget();
AccommodationGalleryWidget();
UpcomingToursWidget();
ProductListBookableWidget();
SmartEventListWidget();
SmartEventDetailsWidget();
TourListBookableWidget();
GlobalSnackbar();
PackageItinerary();
PackageBooking();
PackageList();
BookingWidget();
AccommodationCalendarSearchWidget();
UpcomingToursBookableWidget();
ListOfSmartEventsWidget();
PackageBookingInlineWidget();
const basket = document.createElement('bilberry-basket');
const snackbar = document.createElement('bilberry-snackbar');

// This try / catch is needed because the loading of resources is more volatile when
// deployed in production than when developing, and because the DOMContentLoaded event
// appears to trigger before this code is executed, hence the code inside is not run when in dev.
try {
    const body = getBodyNode();
    body.appendChild(checkout);
    body.appendChild(modal);
    body.appendChild(basket);
    body.appendChild(snackbar);
    // Load cart if it exists in query parameters as a shared link
    restoreCartFromQueryParameters();
    loadBrefIntoConfig();
} catch (_e) {
    window.addEventListener('DOMContentLoaded', () => {
        const body = getBodyNode();
        body.appendChild(basket);
        body.appendChild(checkout);
        body.appendChild(modal);
        body.appendChild(snackbar);

        // Load cart if it exists in query parameters as a shared link
        restoreCartFromQueryParameters();
        loadBrefIntoConfig();
    });
}

async function restoreCartFromQueryParameters() {
    const cartItems = await loadCartFromQueryParameters(BilberryWidgetsGlobal);

    if (cartItems.length > 0) {
        cartAtom.update({
            type: 'ADD_OR_REPLACE_CART_ITEMS',
            data: cartItems,
        });

        showBasketAtom.update({ visible: true });
    }
}

function loadBrefIntoConfig() {
    const bref = findBrefValue();
    if (bref) {
        configurationAtom.update({
            ...configurationAtom.subject.value,
            referralCode: bref,
        });
    }
}
