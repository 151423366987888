import { SxProps, Typography } from '@mui/material';

type PanelTitleProps = {
    children: React.ReactNode[] | React.ReactNode;
    variant?: 'h2' | 'h3' | 'h4' | 'h6';
    centered?: boolean;
    sx?: SxProps;
};

export default function PanelTitle({
    children,
    variant = 'h6',
    centered = false,
    sx = {},
}: PanelTitleProps) {
    return (
        <Typography
            variant={
                variant === 'h6'
                    ? 'h6'
                    : variant === 'h4'
                      ? 'h4'
                      : variant === 'h3'
                        ? 'h3'
                        : variant === 'h2'
                          ? 'h2'
                          : 'h6'
            }
            sx={{ textAlign: centered ? 'center' : 'left', ...sx }}
        >
            {children}
        </Typography>
    );
}
