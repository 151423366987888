import * as Sentry from '@sentry/react';
import { makeFetchTransport, defaultStackParser } from '@sentry/browser';
import { VERSION, BUILD_VERSION } from '@repo/widget-env/__autogen/env';

export function initSentry() {
    // If sentry is disabled in configuration, don't init.
    if (((window as any).BilberryWidgetsGlobal as typeof BilberryWidgetsGlobal)?.disableSentry)
        return;

    let releaseName: string;
    if ((BUILD_VERSION as any) === 'production') {
        releaseName = `bilberry-widgets-${VERSION}`;
    } else if ((BUILD_VERSION as any) === 'latest-test') {
        releaseName = 'bilberry-widgets-latest-test';
    } else {
        releaseName = BUILD_VERSION;
    }

    const allowedFilePatterns = [/bilberry-widgets\.b-cdn\.net/, /data\.kraftlauget\.no/];

    Sentry.init({
        release: releaseName,
        dsn: 'https://260695fe8b2b443595c86c8704993efa@o1299999.ingest.sentry.io/4504196337238016',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.25, // Sample a quarter of all errors to reduce volume
        tracePropagationTargets: [/^https:\/\/.*\.bilberry\.app\//],
        beforeSend(event) {
            // Filter out custom element definition errors
            if (
                event.exception?.values?.some(
                    (exception) =>
                        exception.type === 'NotSupportedError' &&
                        (exception.value?.includes('custom element') ||
                            exception.value?.includes('CustomElementRegistry')),
                )
            ) {
                return null;
            }

            if (event.exception?.values) {
                // Sentry may appear at the bottom of legitimate stack traces due to error instrumentation.
                // However, if there are no frames from our widget code (matching allowedPatterns),
                // then this error likely originated from unrelated JavaScript on the client's page
                // and should be filtered out.
                const hasAllowedFrames = event.exception.values.some((exception) => {
                    return exception.stacktrace?.frames?.some((frame) =>
                        frame.filename
                            ? allowedFilePatterns.some((pattern) =>
                                  pattern.test(frame.filename as string),
                              ) && !frame.filename.includes('@sentry')
                            : false,
                    );
                });

                if (!hasAllowedFrames) {
                    return null;
                }
            }
            return event;
        },
        transport: makeFetchTransport,
        stackParser: defaultStackParser,
        replaysSessionSampleRate: 0.001,
        replaysOnErrorSampleRate: 0.05,
    });
    Sentry.setTag('domain', window.location.host);
}
