import { Box, Button, useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useEffect, useMemo, useCallback } from 'react';
import { GiftcardPaymentInfo, PaymentInfo } from '@repo/types';
import { useLocale } from '@repo/i18n';
import NetsPayment from './NetsPayment';

interface IProps {
    paymentInfo: PaymentInfo | GiftcardPaymentInfo;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
}

const FOURTEEN_MINUTES_THIRTY_SECONDS = 1000 * 60 * 14.5;

export default function NetsPaymentWrapper(props: IProps): JSX.Element {
    const { t } = useLocale();
    const timeOfMount = useMemo(() => Date.now(), []);
    const theme = useTheme();

    const goBackClicked = useCallback(() => {
        props.onPaymentCancelled();
    }, [props]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (Date.now() - timeOfMount > FOURTEEN_MINUTES_THIRTY_SECONDS) {
                alert(t.your_reservation_timed_out);
                goBackClicked();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [timeOfMount, goBackClicked, t.your_reservation_timed_out]);

    return (
        <Box
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            pt={0}
            minWidth="300px"
            width="100%"
            sx={{
                '& > *': {
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                },

                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(6),
                    maxWidth: '100%',
                },

                '& #dibs-complete-checkout': {
                    display: 'flex',
                    justifyContent: 'center',

                    '& #nets-checkout-iframe': {
                        width: '100%',
                    },
                },
            }}
        >
            <NetsPayment
                context={props.paymentInfo}
                onPaymentCompleted={props.onPaymentCompleted}
            />
            <Button variant="outlined" color="primary" onClick={goBackClicked}>
                {t.go_back}
            </Button>
        </Box>
    );
}
