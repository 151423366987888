import { useMemo } from 'react';
import TourProductCardList from 'src/components/domain/tour-product-card/TourProductCardList';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { groupToursByProduct } from '@repo/widget-utils/activities/tours';
import { useSmartEvent } from '@repo/widget-utils/services/hooks/product';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    id: number;
    hideReadMore?: boolean;
};

export default function SmartEventList(props: Props) {
    const { event } = useSmartEvent(props.id);

    const tours = useMemo(() => {
        return groupToursByProduct(event?.tours || []);
    }, [event?.tours]);

    return <TourProductCardList tours={tours} {...props} />;
}
