import { ReactComponent as MembershipIcon } from 'src/assets/icons/membership.svg';
import CheckboxPanel from 'src/components/common/timeslots-checkbox-panel/CheckboxPanel';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { SummaryForValueCardUsages } from './SummaryForValueCardUsages';
import { ValueCardUsageSummaryItem } from '@repo/widget-utils/price-helper';

type MembershipCardPaymentProps = {
    text: string;
    subtext: string;
    valueCardDescription: string;
    variant?: 'radio' | 'checkbox';
    isChecked?: boolean;
    onClick: () => void;
    disabled?: boolean;
    membership: { startDate: string; endDate: string };
    usageSummaryForValueCard?: ValueCardUsageSummaryItem;
};

export default function MembershipCardPayment({
    text,
    subtext,
    valueCardDescription,
    variant = 'radio',
    isChecked = false,
    disabled = false,
    membership,
    onClick,
    usageSummaryForValueCard,
}: MembershipCardPaymentProps) {
    const { t } = useLocale();

    return variant === 'radio' ? (
        <RadioPanel
            isChecked={isChecked}
            Icon={MembershipIcon as any}
            text={t.membershipCard}
            onChange={onClick}
        >
            <Panel alignment="center">
                <Text>{t.validForThePeriod}</Text>

                <Text>
                    <b>{`${membership.startDate} - ${membership.endDate}`}</b>
                </Text>
            </Panel>

            <Text alignment="center">{valueCardDescription}</Text>

            {usageSummaryForValueCard && (
                <SummaryForValueCardUsages
                    usages={usageSummaryForValueCard.usages}
                ></SummaryForValueCardUsages>
            )}
        </RadioPanel>
    ) : (
        <CheckboxPanel
            isChecked={isChecked}
            disabled={disabled}
            Icon={MembershipIcon as any}
            text={text}
            subtext={subtext}
            onChange={onClick}
        >
            <Panel alignment="center">
                <Text>{t.validForThePeriod}</Text>

                <Text>
                    <b>{`${membership.startDate} - ${membership.endDate}`}</b>
                </Text>
            </Panel>

            <Text alignment="center">{valueCardDescription}</Text>
            {usageSummaryForValueCard && (
                <SummaryForValueCardUsages
                    usages={usageSummaryForValueCard.usages}
                ></SummaryForValueCardUsages>
            )}
        </CheckboxPanel>
    );
}
