import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

export default function EmptyCartView() {
    const theme = useTheme();
    const { t } = useLocale();
    const customizations = useCustomizations();
    const config = useConfigurations();

    const logoString = config.logo !== '' ? config.logo : customizations.iconUrl;

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box
                sx={{
                    backgroundColor: customizations.checkoutHeaderColor,
                    color: customizations.checkoutHeaderColorContrast,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '100%',
                    padding: theme.spacing(2),
                    flexShrink: 0,
                }}
            >
                {logoString && (
                    <img
                        src={logoString}
                        alt={t.company}
                        style={{
                            cursor: 'pointer',
                            maxHeight: theme.spacing(10),
                        }}
                        onClick={() => history.back()}
                    />
                )}
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.spacing(4),
                    backgroundColor: '#fff',
                }}
            >
                <Stack
                    alignItems="center"
                    spacing={4}
                    sx={{
                        textAlign: 'center',
                        maxWidth: '400px',
                    }}
                >
                    <ShoppingBasketOutlinedIcon
                        sx={{
                            fontSize: 60,
                            color: theme.palette.text.secondary,
                        }}
                    />
                    <Typography variant="h4" color="textSecondary">
                        {t.no_items_in_cart}
                    </Typography>
                    <Button
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        onClick={() => history.back()}
                        size="large"
                    >
                        {t.continue_shopping}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
