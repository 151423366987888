import TodayIcon from '@mui/icons-material/Today';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, useTheme } from '@mui/material';
import { TZDate } from '@repo/tzdate';

import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';

export function CalendarHeader({
    selectedDate,
    onClick,
}: {
    selectedDate: TZDate | null;
    onClick?: () => void;
}): JSX.Element {
    const theme = useTheme();
    const { t } = useLocale();

    return (
        <Panel variant="shaded" sx={{ border: 'none', borderRadius: 0 }}>
            <Box>
                <Text>{selectedDate ? selectedDate.year() : TZDate.now().year()}</Text>
                <Box
                    component={onClick ? 'button' : undefined}
                    onClick={onClick}
                    sx={{
                        width: 'fit-content',
                        background: 'none',
                        border: 0,
                        cursor: onClick ? 'pointer' : undefined,
                    }}
                    tabIndex={onClick ? 0 : undefined}
                >
                    <Layout alignItems="center">
                        {onClick !== undefined && <ChevronLeft />}
                        <PanelTitle>
                            {selectedDate
                                ? capitalize(selectedDate.format('dddd D. MMMM'))
                                : t.noSelectedDate}
                        </PanelTitle>
                        <TodayIcon />
                    </Layout>
                </Box>
            </Box>
        </Panel>
    );
}
