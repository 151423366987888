import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { createRef, RefObject, useState } from 'react';
import { route } from 'src/components/common/router/Router';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import AccommodationImage from 'src/components/domain/accommodation-image/AccommodationImage';
import {
    getCustomOrDefaultText,
    localeAtom,
    useLocale,
    getLocaleNumberFormatNoDecimals,
} from '@repo/i18n';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { GuestInfo, ProductInstance, TicketType, UserTextCustomizations } from '@repo/types';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { configurationAtom, useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import AccommodationOverviewFacilityIcons from '../accommodation-overview/subcomponents/AccommodationOverviewFacilityIcons';
import AccommodationOverviewModal from './accommodation-overview-modal/AccommodationOverviewModal';

interface IProps {
    accommodation: ProductInstance[];
    guestInfo?: GuestInfo;
    nights: number;
    url?: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    bookDirectly: boolean;
    numberOfCards: number;
    onSelectRoom: (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
    ) => void;
    dateRange: DateRange<TZDate>;
    isUnavailable: boolean;
    selected?: boolean;
}

export default function AccommodationCard(props: IProps) {
    const { t } = useLocale();
    const configurations = useConfigurations();
    const customizations = useCustomizations();

    const {
        accommodation,
        bookDirectly,
        onSelectRoom,
        selected = false,
        isUnavailable,
        dateRange,
        nights,
        guestInfo,
    } = props;
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const theme = useTheme();
    const boxRef = createRef<HTMLDivElement>();
    const shouldShowBasketOnBook = !configurations.skipBasketOnBook;
    const numberPersons = (guestInfo?.adults ?? 1) + (guestInfo?.children.length ?? 0);

    const allTicketTypes = accommodation[0].ticketOptions.map((to) => to.ticketTypes) ?? [];
    const ticketTypes = allTicketTypes
        .reduce((acc, cur, _i, collection) => {
            cur.forEach((c) => {
                if (acc.find((t) => t.id === c.id)) return;
                if (collection.every((types) => types.some((t) => t.id === c.id))) {
                    acc.push(c);
                }
            });
            return acc;
        }, [] as TicketType[])
        .sort((a, b) => (a.price > b.price ? 1 : -1));
    const [selectedTicketType, setSelectedTicketType] = useState<TicketType | null>(
        ticketTypes[0] ?? null,
    );

    const product = accommodation[0]?.product;
    if (!product) return null;

    function handleOpenInfoOverlay() {
        setIsOverlayOpen(true);
    }

    function handleCloseInfoOverlay() {
        setIsOverlayOpen(false);
    }

    return (
        <Grid
            container
            direction="column"
            borderRadius={`${customizations.borderRadius}px`}
            sx={[
                {
                    boxSizing: 'border-box',
                    position: 'relative',
                    height: '100%',
                    width: 'auto',
                    border: `1px solid`,
                    borderColor: theme.palette.grey[400],
                    color: theme.palette.common.black,
                    maxWidth: 420,
                },
                selected && {
                    borderColor: customizations.primaryColor,
                    borderWidth: '2px',
                    border: 'solid',
                },
            ]}
            wrap="nowrap"
        >
            <Box sx={{ position: 'relative', height: '230px', margin: theme.spacing(0) }}>
                <AccommodationImage url={product.coverImage.src} faded={isUnavailable} />
            </Box>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                padding={theme.spacing(2.5)}
                bgcolor={theme.palette.grey[50]}
                flexGrow={1}
                borderRadius={`${customizations.borderRadius}px`}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                <Typography align="center" variant="h4" mb={theme.spacing(1)}>
                    <Box component="span" fontWeight="bold">
                        {product.title}
                    </Box>
                </Typography>
                <Typography
                    align="center"
                    sx={{
                        paddingBottom: theme.spacing(2.5),
                        flexGrow: 1,
                        overflow: 'hidden',
                        // get ellipsis when text extends 3 lines
                        display: '-webkit-box !important',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    <Box>{parseHtmlToMui(product.shortDescription ?? '')}</Box>
                </Typography>
                <AccommodationOverviewFacilityIcons accommodation={product} numberOfIcons={3} />
                {!isUnavailable || selected ? (
                    ticketTypes.length > 1 ? (
                        <Stack
                            direction="row"
                            gap={1}
                            justifyContent="space-between"
                            width="100%"
                            pt={2}
                        >
                            <Dropdown
                                sx={{ width: '100%' }}
                                value={selectedTicketType}
                                options={ticketTypes}
                                getOptionLabel={(opt) => opt?.name ?? t.select_price_type}
                                onChange={setSelectedTicketType}
                                label={t.select_price_type}
                            />
                            <PriceInfoSelectedTicketType
                                selectedPrice={selectedTicketType ?? ticketTypes[0]}
                                numberPersons={numberPersons}
                                nights={nights}
                            />
                        </Stack>
                    ) : (
                        <PriceInfo
                            selectedPrice={ticketTypes[0]}
                            numberPersons={numberPersons}
                            nights={nights}
                        />
                    )
                ) : (
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        position="relative"
                        mt={theme.spacing(2)}
                    >
                        <Typography variant="h4" color="error">
                            {t.unavailable}
                        </Typography>
                        <Typography color="error">This room is sold out!</Typography>
                    </Grid>
                )}

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="stretch"
                    wrap="wrap"
                    position="relative"
                    mt={theme.spacing(2)}
                    sx={{ gap: 1 }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            paddingLeft: 0,
                            paddingRight: 0,
                            color: theme.palette.getContrastText('#FFF'),
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            width: 'auto',
                            flexGrow: '1',
                        }}
                        onClick={handleOpenInfoOverlay}
                    >
                        {t.room_info.toUpperCase()}
                    </Button>
                    {(!isUnavailable || selected) && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(1),
                                paddingLeft: theme.spacing(5),
                                paddingRight: theme.spacing(5),
                                width: 'auto',
                                flexGrow: '1',
                            }}
                            onClick={() =>
                                handleCardSelected(
                                    bookDirectly,
                                    accommodation,
                                    selectedTicketType,
                                    guestInfo,
                                    shouldShowBasketOnBook,
                                    boxRef,
                                    onSelectRoom,
                                )
                            }
                        >
                            {getButtonText(bookDirectly, props.buttonCustomizationKey)}
                        </Button>
                    )}
                </Grid>
            </Grid>
            {isOverlayOpen && (
                <AccommodationOverviewModal
                    accommodation={product}
                    onCloseRoomInfoOverlay={handleCloseInfoOverlay}
                    dateRange={dateRange}
                    guests={numberPersons}
                />
            )}
        </Grid>
    );
}

function getButtonText(
    bookDirectly: boolean,
    buttonCustomizationKey?: keyof UserTextCustomizations,
) {
    const { t, locale } = localeAtom.subject.value;
    const configurations = configurationAtom.subject.value;

    if (bookDirectly) {
        return getCustomOrDefaultText(
            configurations.textCustomizations,
            buttonCustomizationKey,
            locale,
            t.book_now,
        ).toUpperCase();
    } else {
        return getCustomOrDefaultText(
            configurations.textCustomizations,
            buttonCustomizationKey,
            locale,
            t.select_room,
        ).toUpperCase();
    }
}

async function handleCardSelected(
    bookDirectly: boolean,
    accommodation: ProductInstance[],
    selectedTicketType: TicketType | null,
    guestInfo: GuestInfo | undefined,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onSelectRoom: (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
    ) => void,
) {
    if (!selectedTicketType) return;
    if (bookDirectly) {
        const ticketOptions = accommodation[0].ticketOptions.map((to) => ({
            ...to,
            quantity: to.id === '0' ? (guestInfo?.adults ?? 1) : (guestInfo?.children.length ?? 0),
            productInstances: accommodation,
        }));
        await cartAtom.update(
            createAddProductsToCartEvent(
                accommodation,
                ticketOptions,
                true, // Accomodation is not supported by membership api, so this item will disable payment plans
                false, // Obviously, it then doesn't require payment plans
                undefined,
                selectedTicketType,
            ),
        );

        if (shouldShowBasketOnBook)
            await showBasketAtom.update({
                visible: true,
                refocusElementOnClose: boxRef,
            });
        else {
            route('/checkout');
        }
    } else {
        onSelectRoom(accommodation, selectedTicketType);
    }
}

type IPriceInfoProp = {
    selectedPrice: TicketType;
    numberPersons: number;
    nights: number;
};

function PriceInfo(props: IPriceInfoProp): JSX.Element {
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const theme = useTheme();
    const [currency] = useAtom(currencyAtom);

    return (
        <Grid
            container
            item
            justifyContent="space-between"
            wrap="nowrap"
            position="relative"
            mt={theme.spacing(2)}
            sx={{ gap: 1, height: '69px' }}
        >
            <Grid
                container
                display="inline-flex"
                wrap="nowrap"
                direction="row"
                alignItems="flex-end"
                sx={{ width: 'auto' }}
                pb={1}
            >
                <Typography color={customizations.productCardTextColor} lineHeight={1}>
                    {capitalize(
                        t.price_one_night_one_guest.parsed(props.nights, props.numberPersons),
                    )}
                </Typography>
            </Grid>

            <Stack direction="row" alignItems="flex-end" pb={1} flexWrap="nowrap">
                <Typography
                    variant="h4"
                    color="textSecondary"
                    fontSize={28}
                    fontWeight={700}
                    mb={-1} // alignment fix because of different sizes. Might break with different fonts, but seems ok on open sans and helvetica
                >
                    {getLocaleNumberFormatNoDecimals(locale, props.selectedPrice?.price ?? 0)}
                </Typography>
                <Typography variant="h6" lineHeight={1}>
                    &nbsp;{currency.currency}
                </Typography>
            </Stack>
        </Grid>
    );
}

function PriceInfoSelectedTicketType(props: IPriceInfoProp) {
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);

    return (
        <Stack gap={1} justifyContent="center">
            <Typography
                color={customizations.productCardTextColor}
                fontSize="0.85rem"
                pt={0.5}
                textAlign="end"
                whiteSpace="nowrap"
            >
                {capitalize(t.one_night_one_guest.parsed(props.nights, props.numberPersons))}
            </Typography>

            <Stack direction="row" alignItems="flex-end">
                <Typography
                    variant="h4"
                    color="textSecondary"
                    fontSize={28}
                    fontWeight={700}
                    mb={-1} // alignment fix because of different sizes. Might break with different fonts, but seems ok on open sans and helvetica
                >
                    {getLocaleNumberFormatNoDecimals(locale, props.selectedPrice?.price ?? 0)}
                </Typography>
                <Typography variant="h6" lineHeight={1}>
                    &nbsp;{currency.currency}
                </Typography>
            </Stack>
        </Stack>
    );
}
