import { Box, useTheme } from '@mui/material';

import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { useProduct } from '@repo/widget-utils/services/hooks/product';
import { ProductIcon } from '../product-overview-2/overview/OverviewIconRow';
import {
    Prices,
    StartTime,
    Departure,
    Terms,
} from '../product-overview-2/overview/ProductOverview2';

const Container = (props: { children: any }) => {
    const theme = useTheme();
    return (
        <Box
            p={theme.spacing(0, 2, 4, 0)}
            sx={{
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(0, 0, 4, 0),
                },
            }}
        >
            {props.children}
        </Box>
    );
};

export default function ProductDetailsWidgetRoot({
    productCatalogId,
    detail,
    textCustomizationKey,
}: {
    productCatalogId: string;
    detail: string;
    textCustomizationKey?: Parameters<typeof ProductIcon>[0]['textCustomizationKey'];
}) {
    const { data: product } = useProduct(productCatalogId);

    if (!product) return null;

    const map: [string, string][] = [
        ['age-limit', 'age'],
        ['price-from', 'price'],
    ];
    const detailValue = map.find(([from]) => from === detail)?.[1] || detail;

    switch (detailValue) {
        case 'description':
        case '':
            return (
                <>
                    <Container>{parseHtmlToMui(product.longDescription ?? '')}</Container>
                    {product.additionalInfo && (
                        <Container>{parseHtmlToMui(product.additionalInfo)}</Container>
                    )}
                </>
            );
        case 'duration':
        case 'difficulty':
        case 'age':
        case 'price':
        case 'icons':
            return <ProductIcon {...{ product, type: detailValue, textCustomizationKey }} />;
        case 'prices':
            return <Prices {...{ product, textCustomizationKey }} />;
        case 'start-time':
            return <StartTime {...{ product, textCustomizationKey }} />;
        case 'departure':
            return <Departure {...{ product, textCustomizationKey }} />;
        case 'terms':
            return <Terms {...{ product }} />;
        default:
            throw new Error(`Invalid detail "${detail}"`);
    }
}
