import { BilberryWidgetsGlobal } from '@repo/types';
import { BASE_SERVICE_URL, USE_SERVICE_PROXY } from '@repo/widget-env/__autogen/env';
import { BilberryApiError } from './BilberryApiError';

async function executeRequest(url: string, options: RequestInit) {
    const response = await fetch(url, options);

    if (!response.ok) {
        throw new BilberryApiError(`API Error: ${response.status}`, response);
    }

    return await response.json();
}

export async function fetcher<T>(fullUrl: string, headers: any): Promise<T> {
    const options: RequestInit = {
        method: 'GET',
        headers,
    };

    return await executeRequest(fullUrl, options);
}

export async function post(url: string, headers: Headers, data: any) {
    const options: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    };

    return await executeRequest(url, options);
}

export async function patch(url: string, headers: Headers, data: any) {
    const options: RequestInit = {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
    };

    return await executeRequest(url, options);
}

export function getUrlWithParams(
    url: string,
    locale: string,
    queryParams: Record<string, any> = {},
    config?: BilberryWidgetsGlobal,
) {
    const langQueryParam = `lang=${getBilberryLanguageFromLocale(locale)}`.toLowerCase();

    if (config && config.referralCode) {
        queryParams.bref = config.referralCode;
    }

    if (config && config.enableGodMode) {
        queryParams['god-mode'] = 1;
    }

    let fullUrl = `${url}?${langQueryParam}`;

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString.length > 0) fullUrl += `&${queryString}`;
    return fullUrl;
}

export function getBilberryLanguageFromLocale(locale: string) {
    return locale.split('-')[0];
}

export async function wait(ms: number) {
    return await new Promise((res) => {
        setTimeout(() => res(true), ms);
    });
}

function getApiBaseUrl(config: BilberryWidgetsGlobal) {
    const baseUrlFromGlobal = config.bilberryBaseApiUrl;
    if (baseUrlFromGlobal) return baseUrlFromGlobal;

    // Needed for local development through proxy
    return USE_SERVICE_PROXY ? '' : BASE_SERVICE_URL;
}

function getApiAccessToken(config: BilberryWidgetsGlobal) {
    return config.bilberryAccessToken;
}

function getApiHeaders(config: BilberryWidgetsGlobal, additionalHeaders: Record<string, any> = {}) {
    const accessToken = getApiAccessToken(config);

    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    });

    Object.entries(additionalHeaders).forEach(([key, val]) => {
        if (val !== undefined) {
            headers.set(key, val);
        }
    });

    return headers;
}

export function getRequest(
    subdirectory: string,
    locale: string,
    config: BilberryWidgetsGlobal,
    queryParams: Record<string, any> = {},
    additionalHeaders?: Record<string, any>,
) {
    const endpoint = `${getApiBaseUrl(config)}${subdirectory}`;
    const sanitizedQueryParams = Object.entries(queryParams).reduce((acc, [key, val]) => {
        if (val !== undefined) {
            acc[key] = val;
        }
        return acc;
    }, {} as any);
    const fullUrl = getUrlWithParams(endpoint, locale, sanitizedQueryParams, config);
    return {
        url: fullUrl,
        headers: getApiHeaders(config, additionalHeaders),
    };
}
