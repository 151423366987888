import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { localeAtom } from '@repo/i18n';
import {
    BilberryPackage,
    BilberryProduct,
    BilberryProductCatalog,
    BilberryPackageAvailability,
    BilberrySmartEvent,
    BilberryReservation,
    BilberryBooking,
    BilberrySettings,
    BilberryLeadFormData,
    BilberryCreateGiftcardRequest,
    BilberryGiftcard,
    BilberryGiftcardStatus,
    BilberryPromoCodeStatus,
    BilberryProductCollection,
    BilberryAccommodation,
    BilberryPaginationAccommodation,
    BilberryTimeslot,
    BilberryTimeslotsProject,
    Product,
    Package,
    ProductInstance,
    ProductSearch,
    BilberryNextProductAvailability,
    GuestInfo,
} from '@repo/types';
import {
    allToursAsProductInstanceFromBilberryPackageAvailbility,
    groupProductInstancesByResourceCollectionId,
    packageFromBilberryPackage,
    productFromBilberryAccommodation,
    productFromBilberryTimeslot,
    productFromProductCatalog,
    productInstanceFromBilberryAccommodation,
    productInstanceFromBilberryProduct,
    productInstanceFromBilberrySmartEventPlan,
    productInstanceFromBilberryTimeslotsProject,
    productInstancesFromBilberryPackageAvailbility,
    productsFromProductCollection,
    timeslotFromBilberryTimeslot,
} from '../../ProductMapper';
import { configurationAtom } from '../../widgetsConfiguration';
import { fetcher, getRequest, post } from '../utils/api-client-common';
import {
    getDefaultHotelRequest,
    hotelDateParams,
    getDefaultHotelHeaders,
} from '../utils/bilberry-hotels-api-helpers';
import { errorLog } from '@repo/common-utils/Logger';

function getProductCatalogRequest(id?: string) {
    const subdirectory = `/api/product-catalogs/${id}`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    return {
        subdirectory,
        url,
        headers,
        mapper: (data?: { data: BilberryProductCatalog }) =>
            data?.data ? productFromProductCatalog(data.data) : null,
    };
}

function getTimeslotProductRequest(id?: string) {
    const subdirectory = `/api/v2/membership/products/${id}`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    return {
        subdirectory,
        url,
        headers,
        mapper: (data?: { data: BilberryTimeslot }) =>
            data?.data ? productFromBilberryTimeslot(data.data) : null,
    };
}

function getAccommodationProductRequest(
    id?: string,
    dateRange?: DateRange<TZDate>,
    guests?: number,
) {
    const queryParams = {
        ...(dateRange ? hotelDateParams(dateRange) : {}),
        guests,
        units: 1,
    };

    const { url, headers } = getDefaultHotelRequest(
        `/accommodations/${id}`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    return {
        queryParams,
        url,
        headers,
        mapper: (data?: BilberryAccommodation) =>
            data ? productFromBilberryAccommodation(data) : null,
    };
}

export function getProductRequest(
    id: string | undefined,
    type?: 'accommodation' | 'timeslot',
    dateRange?: DateRange<TZDate> | DateRange<null>,
    guests?: number,
) {
    const { headers, mapper, url } =
        type === 'accommodation'
            ? getAccommodationProductRequest(id, dateRange, guests ?? 1)
            : type === 'timeslot'
              ? getTimeslotProductRequest(id)
              : getProductCatalogRequest(id);
    return {
        url,
        headers,
        mapper,
    };
}

export async function getProduct(
    id: string | undefined,
    type?: 'accommodation' | 'timeslot',
    dateRange?: DateRange<TZDate> | DateRange<null>,
    guests?: number,
) {
    const { headers, mapper, url } = getProductRequest(id, type, dateRange, guests);
    const res = await fetcher<any>(url, headers);
    try {
        return mapper(res);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

const isCollection = (d: any): d is BilberryProductCollection => 'products' in d;
const isTimeslotProducts = (d: any[]): d is BilberryTimeslot[] =>
    d.some((x: any) => 'default_ticket_options' in x);

export function getProductsRequest(ids: string[], collectionId?: string, isTimeslots?: boolean) {
    const queryParams = !collectionId ? { ids: ids.join(',') } : {};
    const subdirectory = isTimeslots
        ? '/api/v2/membership/products'
        : collectionId
          ? `/api/external/product-collections/${collectionId}`
          : '/api/product-catalogs';
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );
    const mapper = (
        json:
            | { data: BilberryProductCatalog[] }
            | { data: BilberryProductCollection }
            | { data: BilberryTimeslot[] },
    ) => {
        return isCollection(json.data)
            ? productsFromProductCollection(json.data)
            : isTimeslotProducts(json.data)
              ? json.data.map(productFromBilberryTimeslot)
              : json.data.map(productFromProductCatalog);
    };

    return {
        url,
        mapper,
        headers,
    };
}

export async function getProducts(ids: string[], collectionId?: string, isTimeslots?: boolean) {
    const { mapper, url, headers } = getProductsRequest(ids, collectionId, isTimeslots);
    const json = await fetcher<
        | { data: BilberryProductCatalog[] }
        | { data: BilberryProductCollection }
        | { data: BilberryTimeslot[] }
    >(url, headers);
    try {
        return mapper(json);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getPackageProduct(id: string) {
    const subdirectory = `/api/packages/${id}`;
    const packageRequest = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const subdirectoryVat = `/api/packages/${id}/availability`;
    const packageVatRequest = getRequest(
        subdirectoryVat,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const [pkgData, availData] = await Promise.all([
        fetcher<{ data: BilberryPackage }>(packageRequest.url, packageRequest.headers),
        fetcher<{ data: BilberryPackageAvailability[] }>(
            packageVatRequest.url,
            packageVatRequest.headers,
        ),
    ]);

    const productIds = Array.from(
        new Set(
            pkgData.data.ticket_options?.flatMap((x) =>
                x.products.map((y) => y.product_id.toString()),
            ),
        ),
    );

    const productCatalogRequest = getRequest(
        `/api/product-catalogs`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        { ids: productIds.join(',') },
    );

    const productsResponse: { data: BilberryProductCatalog[] } = await fetcher(
        productCatalogRequest.url,
        productCatalogRequest.headers,
    );

    try {
        const products = productsResponse.data.map(productFromProductCatalog);

        const pkg = packageFromBilberryPackage(pkgData.data, products, availData.data);
        return pkg;
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getPackageProducts(ids?: string[]) {
    const subdirectory = '/api/packages';
    const { headers, url } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        {
            ids: ids ? ids.join(',') : undefined,
        },
    );
    const response = await fetcher<any>(url, headers);
    try {
        return (
            response.data.map((pkg: BilberryPackage) => packageFromBilberryPackage(pkg, [], [])) ??
            []
        );
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export function getAvailableProductsRequest(
    product: Product | null,
    from?: TZDate | null,
    to?: TZDate | null,
    guests = 1,
) {
    const requestGetter =
        product?.type === 'timeslot'
            ? getAvailableProductsRequestTimeslots
            : product?.type === 'accommodation'
              ? getAvailableProductsRequestAccommodations
              : getAvailableProductsRequestProductCatalog;

    return product
        ? requestGetter(product, from, to, guests)
        : ({} as ReturnType<typeof requestGetter>);
}

export function getAvailableInstancesByProductsRequest(
    products: Product[],
    productType: 'timeslot' | 'timepoint' | 'days' | 'nights' | 'accommodation',
    from?: TZDate | null,
    to?: TZDate | null,
    guests = 1,
) {
    const requestGetter =
        productType === 'timeslot'
            ? getAvailabilityByProductsForTimeslots
            : productType === 'accommodation'
              ? getAvailabilityByProductsForAccommodations
              : getAvailabilityByProductsForProductCatalog;

    return requestGetter(products, from, to, guests);
}

export async function getAvailableProducts(
    product: Product | null,
    from?: TZDate | null,
    to?: TZDate | null,
    guests = 1,
) {
    if (!product) return [];
    const request = getAvailableProductsRequest(product, from, to, guests);
    const json = await fetcher<any>(request.url, request.headers);
    try {
        return request.mapper(json);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getAvailableInstancesForMultipleProducts(
    products: Product[],
    from?: TZDate | null,
    to?: TZDate | null,
    guests = 1,
): Promise<ProductInstance[][]> {
    if (products.length === 0) return [];
    const [firstProduct] = products;

    // Bilberry only supports fetching availability for multiple products when it can use the "normal"
    // product catalogs endpoint. If the first product is a timeslot or (visbook) accommodation, we need to fetch
    // the availability for each product individually.
    if (firstProduct.type === 'accommodation' || firstProduct.type === 'timeslot') {
        const allAvails = await Promise.all(
            products?.map((product) => getAvailableProducts(product, from, to, guests)) ?? [],
        );
        return allAvails;
    }

    const request = getAvailableInstancesByProductsRequest(
        products,
        firstProduct.type,
        from,
        to,
        guests,
    );
    const json = await fetcher<any>(request.url, request.headers);
    try {
        return request.mapper(json);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export function getAvailableProductsRequestAccommodations(
    product: Product | null,
    from?: TZDate | null,
    to?: TZDate | null,
    guests = 1,
) {
    const queryParams = {
        ...hotelDateParams([from ?? TZDate.now(), to ?? TZDate.now().add(1, 'month')]),
        guests,
        units: 1,
    };

    const { url, headers } = getDefaultHotelRequest(
        `/accommodations/${product?.id}/availabilities`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    return {
        url,
        headers,
        mapper: (data?: Record<string, BilberryAccommodation>) => {
            return data
                ? Object.entries(data as Record<string, BilberryAccommodation>).map(
                      ([date, accommodation]) => {
                          const dayjsDate = tzdate(date, false);
                          const product = productFromBilberryAccommodation(accommodation);
                          const productInstance = productInstanceFromBilberryAccommodation(
                              accommodation,
                              dayjsDate,
                              product,
                          );
                          return productInstance;
                      },
                  )
                : [];
        },
    };
}

export function getAvailableProductsRequestProductCatalog(
    product: Product | null,
    from?: TZDate | null,
    to?: TZDate | null,
) {
    const subdirectory = `/api/product-catalogs/${product?.id}/availability`;
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {};
    if (from && to) {
        queryParams.start = from.format(dateFormat);
        queryParams.end = to.format(dateFormat);
    }

    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    return {
        url,
        headers,
        mapper: (data?: { [id: string]: BilberryProduct[] }) => {
            return data
                ? groupProductInstancesByResourceCollectionId(
                      Object.values(data)
                          .flat(1)
                          .filter((x) => x.closed !== 1)
                          .map((x) => productInstanceFromBilberryProduct(x, product!)),
                  )
                : [];
        },
    };
}

export function getAvailableProductsRequestTimeslots(
    product: Product | null,
    from?: TZDate | null,
    to?: TZDate | null,
) {
    const subdirectory = `/api/v2/membership/products/${product?.id}/projects`;
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {};
    if (from && to) {
        queryParams.start = from.format(dateFormat);
        queryParams.end = to.format(dateFormat);
    }
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    const mapper = productInstanceFromBilberryTimeslotsProject.bind(null, product!);
    return {
        url,
        headers,
        mapper: (data?: { data?: BilberryTimeslotsProject[] }) => {
            return data?.data
                ? groupProductInstancesByResourceCollectionId(data.data.map(mapper))
                : [];
        },
    };
}

export type AvailabilityByProductsReturnType = {
    url: string;
    headers: Headers;
    mapper: (data?: any) => ProductInstance[][];
};

export function getAvailabilityByProductsForProductCatalog(
    products: Product[],
    from?: TZDate | null,
    to?: TZDate | null,
): AvailabilityByProductsReturnType {
    const subdirectory = `/api/product-catalogs/availability`;
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {
        ids: products.map((x) => x.id).join(','),
    };
    if (from && to) {
        queryParams.start = from.format(dateFormat);
        queryParams.end = to.format(dateFormat);
    }

    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    return {
        url,
        headers,
        mapper: (datas?: Record<string, Record<string, BilberryProduct[]>>) => {
            if (!datas) return [];

            const mapped = Object.entries(datas)
                .filter(([, entries]) => Object.keys(entries).length > 0)
                .map(([productId, entries]) => {
                    const dataValues = Object.values(entries).flat(1);

                    const product = products.find((x) => x.id === productId);
                    return groupProductInstancesByResourceCollectionId(
                        dataValues
                            .filter((x) => x.closed !== 1)
                            .filter((x) => x.prices.length > 0)
                            .map((x) => productInstanceFromBilberryProduct(x, product!)),
                    );
                });
            return mapped;
        },
    };
}

export function getAvailabilityByProductsForAccommodations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    products: Product[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from?: TZDate | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    to?: TZDate | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    guests = 1,
): AvailabilityByProductsReturnType {
    // TODO: implement this for efficiency
    throw new Error('getAvailabilityByProductsForAccommodations: Not implemented');
}

export function getAvailabilityByProductsForTimeslots(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    products: Product[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from?: TZDate | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    to?: TZDate | null,
): AvailabilityByProductsReturnType {
    // TODO: implement this for efficiency
    throw new Error('getAvailabilityByProductsForTimeslots: Not implemented');
}

export async function getAvailableTimeslots(productInstance?: ProductInstance) {
    const subdirectory = `/api/v2/membership/projects/${productInstance?.id}/timeslots`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );
    const res = await fetcher<{ data?: BilberryTimeslot[] }>(url, headers);
    try {
        return (
            res?.data?.map((timeslot) =>
                timeslotFromBilberryTimeslot(productInstance!, timeslot),
            ) ?? []
        );
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getPackageAvailability(
    pkg: Package | null,
    from: TZDate | null,
    to: TZDate | null,
) {
    const subdirectory = pkg ? `/api/packages/${pkg.id}/availability-multi` : '';
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {};

    if (from && to) {
        queryParams.start = from.format(dateFormat);
        queryParams.end = to.format(dateFormat);
    }

    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    const data = await fetcher<{ data: BilberryPackageAvailability[] }>(url, headers);
    if (!pkg || !data) return [];

    try {
        const availabilities: ProductInstance[] = productInstancesFromBilberryPackageAvailbility(
            data.data,
            pkg,
        );
        return availabilities;
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getAvailablePackageProducts(pkg: Package | null, date: TZDate | null) {
    const subdirectory = pkg ? `/api/packages/${pkg.id}/availability-multi` : '';
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {};
    queryParams.start = date?.forceLocalTz().format(dateFormat);
    queryParams.end = date?.forceLocalTz().format(dateFormat);

    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );
    const data = await fetcher<{ data: BilberryPackageAvailability[] }>(url, headers);
    if (!pkg || !data) return [];

    try {
        const availabilities: ProductInstance[] =
            allToursAsProductInstanceFromBilberryPackageAvailbility(data.data, pkg, date);
        return availabilities;
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export function getProductSearchRequest(filters: ProductSearch | null) {
    const locale = localeAtom.subject.value;
    const config = configurationAtom.subject.value;
    const dateFormat = 'YYYY-MM-DD';
    const subdirectory = `/api/product-catalogs`;

    const ids = (
        filters?.products && filters?.excludeProducts.length === 0
            ? filters.products
            : (filters?.excludeProducts ?? [])
    ).join(',');
    const exclude = filters?.excludeProducts && filters?.excludeProducts.length > 0;

    const queryParamsUnfiltered = {
        start: filters?.start?.format(dateFormat),
        end: filters?.end?.format(dateFormat),
        difficulty: filters?.difficulty,
        capacity:
            filters?.adults || filters?.children
                ? (filters?.adults ?? 0) + (filters?.children ?? 0)
                : undefined,
        minDuration: filters?.minDuration ?? undefined,
        maxDuration: filters?.maxDuration ?? undefined,
        ids,
        exclude: exclude ? 1 : undefined,
        location: filters?.location,
        page: '1',
        all: '1',
    };

    const removeNonExistentKeys = ([, v]: [string, any]) => v;
    const queryParams = Object.fromEntries(
        Object.entries(queryParamsUnfiltered).filter(removeNonExistentKeys),
    );

    return getRequest(subdirectory, locale.locale, config, queryParams);
}

export async function getProductSearch(filters: ProductSearch | null) {
    const { url, headers } = getProductSearchRequest(filters);
    const data = await fetcher<{ data: BilberryProductCatalog[] }>(url, headers);

    try {
        return (data?.data ?? []).map(productFromProductCatalog);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getSmartEvent(eventId: number) {
    const locale = localeAtom.subject.value;
    const config = configurationAtom.subject.value;
    const { url, headers } = getRequest(`/api/webevents/${eventId}`, locale.locale, config);

    // Omit<BilberrySmartEvent, 'tours'> & { tours: ProductInstance[] }
    const json: { data: BilberrySmartEvent } = await fetcher(url, headers);

    const queryParams = { ids: json.data.tours.map((tour) => tour.product_id).join(',') };
    const productsRequest = getRequest(
        `/api/product-catalogs`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );
    const productsResponse = await fetcher<{ data: any }>(
        productsRequest.url,
        productsRequest.headers,
    );

    try {
        const products: Product[] = productsResponse?.data
            ? productsResponse.data.map(productFromProductCatalog)
            : [];

        const tours = json.data.tours.map((tour) =>
            productInstanceFromBilberrySmartEventPlan(
                tour,
                products.find((product) => product.id === tour.product_id.toString())!,
            ),
        );
        return { ...json.data, tours } as Omit<BilberrySmartEvent, 'tours'> & {
            tours: ProductInstance[];
        };
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function getSmartEvents(eventIds: string[]) {
    const locale = localeAtom.subject.value;
    const config = configurationAtom.subject.value;
    const { url, headers } = getRequest(
        `/api/webevents${eventIds.length > 0 ? `?ids=${eventIds.join(',')}` : ''}`,
        locale.locale,
        config,
    );

    const json: { data: BilberrySmartEvent[] } = await fetcher(url, headers);
    return json;
}

export async function getProductInstances(ids: string[]) {
    if (ids.length === 0) return [];

    const queryParams = { ids: ids.join(',') };

    const { url, headers } = getRequest(
        '/api/products',
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    return await fetcher(url, headers);
}

export async function getProductInstanceByIds(ids: string[]) {
    if (ids.length === 0) return [];

    const queryParams = { ids: ids.join(',') };

    const { url, headers } = getRequest(
        '/api/products',
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    const json: { data: BilberryProduct[] } = await fetcher(url, headers);

    const queryParamsProduct = { ids: json.data.map((tour) => tour.product_catalog_id).join(',') };
    const productsRequest = getRequest(
        `/api/product-catalogs`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParamsProduct,
    );
    const productsResponse = await fetcher<{ data: any }>(
        productsRequest.url,
        productsRequest.headers,
    );

    try {
        const products: Product[] = productsResponse?.data
            ? productsResponse.data.map(productFromProductCatalog)
            : [];

        const productInstances = json.data.map((tour) =>
            productInstanceFromBilberryProduct(
                tour,
                products.find((product) => product.id === tour.product_catalog_id.toString())!,
            ),
        );

        return groupProductInstancesByResourceCollectionId(productInstances);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export function getUpcomingToursRequest(
    productCatalogIds: number[],
    startDate: TZDate,
    endDate: TZDate,
) {
    const dateFormat = 'YYYY-MM-DD';
    const queryParams =
        startDate !== null && endDate !== null
            ? {
                  start: startDate.format(dateFormat),
                  end: endDate.format(dateFormat),
                  ids: productCatalogIds,
              }
            : null;
    return getRequest(
        `/api/upcoming-tours/`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams ?? {},
    );
}

export async function getUpcomingTours(
    productCatalogIds: number[],
    startDate: TZDate,
    endDate: TZDate,
    productCatalogUrls?: string[],
) {
    const { url, headers } = getUpcomingToursRequest(productCatalogIds, startDate, endDate);

    const json: { data: BilberryProduct[] } = await fetcher(url, headers);

    const queryParamsProducts = { ids: json.data.map((tour) => tour.product_catalog_id).join(',') };
    const productsRequest = getRequest(
        `/api/upcoming-tours/product-catalogs`,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParamsProducts,
    );
    const productsResponse = await fetcher<{ data: any }>(
        productsRequest.url,
        productsRequest.headers,
    );

    try {
        const products: Product[] = productsResponse?.data
            ? productsResponse.data.map(productFromProductCatalog).map((p: Product) => {
                  const indexOfId = productCatalogIds.indexOf(Number(p.id));
                  const url = productCatalogUrls?.[indexOfId];
                  if (!url) return p;
                  return { ...p, url };
              })
            : [];

        const productInstances = json.data.map((tour) =>
            productInstanceFromBilberryProduct(
                tour,
                products.find((product) => product.id === tour.product_catalog_id.toString())!,
            ),
        );
        return groupProductInstancesByResourceCollectionId(productInstances);
    } catch (e: any) {
        errorLog(e);
        throw e;
    }
}

export async function createReservation(
    reservation: BilberryReservation,
    paymentMethod?: 'invoice',
    contactType?: 'person' | 'company',
) {
    const queryParams = {
        quick_checkout: configurationAtom.subject.value.quickCheckout,
        payment_method: paymentMethod,
        contact_type: contactType,
    };

    const { url, headers } = getRequest(
        '/api/reserve',
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
        {
            'X-Bilberry-Backoffice-Authorization':
                configurationAtom.subject.value.backoffice_authorize,
        },
    );

    const response = await post(url, headers, reservation);
    return response.data as BilberryBooking;
}

export async function getBilberrySettings() {
    const { locale } = localeAtom.subject.value;
    const config = configurationAtom.subject.value;
    const { url, headers } = getRequest('/api/settings', locale, config);

    const body = await fetcher<{ data: BilberrySettings }>(url, headers);
    return body?.data as BilberrySettings;
}

export async function postLeadForm(lead: BilberryLeadFormData) {
    const { url, headers } = getRequest(
        '/api/leads',
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const response = await post(url, headers, lead);
    return response.data as any;
}

export async function createGiftcard(giftcard: BilberryCreateGiftcardRequest) {
    const { url, headers } = getRequest(
        '/api/giftcards',
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const response = await post(url, headers, giftcard);
    return response.data as BilberryGiftcard;
}

export async function getGiftcardStatus(giftcardId: string) {
    const subdirectory = `/api/giftcards/${giftcardId}`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const response = await fetcher<{ data: BilberryGiftcardStatus }>(url, headers);
    return response.data;
}

export async function getPromoCode(promoCode: string) {
    const subdirectory = `/api/v2/promo-code/${promoCode}`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    const res = await fetcher<{ data: BilberryPromoCodeStatus }>(url, headers);
    return res.data;
}

export async function getNextProductAvailability(productCatalogId: string) {
    const subdirectory = `/api/product-catalogs/${productCatalogId}/next`;
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    try {
        const res = await fetcher<BilberryNextProductAvailability>(url, headers);
        return res;
    } catch (e: any) {
        return null;
    }
}

export async function getNextProductAvailabilityByProductIds(productIds: string[]) {
    const subdirectory = `/api/product-catalogs/next`;
    const queryParams = { ids: productIds.join(',') };
    const { url, headers } = getRequest(
        subdirectory,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
        queryParams,
    );

    try {
        const res = await fetcher<BilberryNextProductAvailability[]>(url, headers);
        return res;
    } catch (e: any) {
        return null;
    }
}

export async function getAccommodationProducts(
    ids: string[],
    dateRange: DateRange<TZDate>,
    accommodationsInfo: GuestInfo[],
    onlyAvailable = false,
) {
    const urls = accommodationsInfo.map((accommodation: GuestInfo) => {
        const guestCount = accommodation.adults + accommodation.children.length;

        let queryParams: any = {
            units: 1,
            onlyAvailableAccommodations: onlyAvailable,
        };
        if (dateRange[0] && dateRange[1]) {
            queryParams = { ...queryParams, ...hotelDateParams(dateRange) };
        }
        if (guestCount > 0) {
            queryParams = { ...queryParams, guests: guestCount };
        }

        const { url } = getDefaultHotelRequest(
            `/accommodations`,
            localeAtom.subject.value.locale,
            configurationAtom.subject.value,
            queryParams,
        );
        return url;
    });

    const fetchers = urls.map(async (url) => {
        const data = await fetcher<BilberryPaginationAccommodation>(
            url,
            getDefaultHotelHeaders(
                localeAtom.subject.value.locale,
                configurationAtom.subject.value,
            ),
        );
        const res = data._embedded.accommodations
            .filter((f) => ids.length === 0 || ids.includes(f.id.toString()))
            .map((a) => {
                const product = productFromBilberryAccommodation(a);
                // We need to count including both the start and end date so we can get the to-date when we create the reservation!
                const days = (dateRange[1]?.diff(dateRange[0], 'days') ?? 1) + 1;
                const productInstances = new Array(days)
                    .fill(null)
                    .map((_, i) =>
                        productInstanceFromBilberryAccommodation(
                            a,
                            (dateRange[0] ?? TZDate.now()).startOf('day').add(i, 'days'),
                            product,
                        ),
                    );
                return productInstances;
            });
        return res;
    });
    const res = await Promise.all(fetchers);
    return res;
}
