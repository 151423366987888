import { Box, Stack, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { tzdate } from '@repo/tzdate';
import { UsageOnTicket } from '@repo/widget-utils/price-helper';
import { groupBy, sumBy } from 'lodash-es';
import Text from 'src/components/common/timeslots-typography/Text';
import { SummaryPriceLineData } from '../summary/summary-mapping';
import { SummaryPriceLine } from '../summary/SummaryPriceLine';

export function SummaryForValueCardUsages(props: { usages: UsageOnTicket[] }) {
    const theme = useTheme();
    const usagesByProductNameAndTime = groupBy(
        props.usages,
        (x) => `${x.productName}|${x.startAt}|${x.endAt}`,
    );

    return (
        <>
            {Object.values(usagesByProductNameAndTime).map((usages, index) => {
                const [firstUsagesByProductNameAndTime] = usages;
                const byAggregateKey = groupBy(
                    usages,
                    (x) =>
                        `${x.ticketName}|${x.creditCost}|${x.priceReductionPercentage}|${x.ticketCurrencyCost?.price}`,
                );

                const aggregatedUsage = Object.values(byAggregateKey).map((usages) => {
                    const [firstUsage] = usages;

                    const creditCostSum = sumBy(usages, (x) => x.creditCost);
                    const currencyCostSum = sumBy(usages, (x) => x.ticketCurrencyCost?.price ?? 0);

                    const isFree = creditCostSum === 0 && currencyCostSum === 0;

                    return {
                        ticketQuantity: usages.length,
                        ticketName: firstUsage.ticketName,
                        creditCost: creditCostSum,
                        priceReductionPercentage: firstUsage.priceReductionPercentage,
                        isFree,
                    };
                });

                return (
                    <Box key={index} sx={{ alignSelf: 'flex-start', mt: 2, width: '100%' }}>
                        <Box borderBottom="1px solid" borderColor={theme.palette.grey['500']}>
                            <Text fontWeight="bold">
                                {firstUsagesByProductNameAndTime.productName}
                            </Text>
                            <Text>
                                {tzdate(firstUsagesByProductNameAndTime.startAt).format('LLL')}
                            </Text>
                        </Box>

                        <Stack direction="column" alignSelf={'flex-start'} sx={{ mt: 1 }}>
                            {aggregatedUsage.map((usage, index) => {
                                return (
                                    <ValueCardUsageOnTicket
                                        key={index}
                                        ticketQuantity={usage.ticketQuantity}
                                        ticketName={usage.ticketName}
                                        creditCost={usage.creditCost}
                                        priceReductionPercentage={usage.priceReductionPercentage}
                                        isFree={usage.isFree}
                                    ></ValueCardUsageOnTicket>
                                );
                            })}
                        </Stack>
                    </Box>
                );
            })}
        </>
    );
}

function ValueCardUsageOnTicket(props: {
    ticketQuantity: number;
    ticketName: string;
    creditCost: number;
    priceReductionPercentage: number;
    isFree: boolean;
}) {
    const { ticketQuantity, ticketName, creditCost, priceReductionPercentage, isFree } = props;
    const { t } = useLocale();

    const ticketDetail =
        creditCost > 0
            ? `${creditCost / 100} ${t.credit.num(creditCost)}`
            : priceReductionPercentage > 0
              ? `${priceReductionPercentage / 100}% ${t.discount}`
              : isFree
                ? t.free
                : '';
    const priceLineData: SummaryPriceLineData = {
        ticketName: `${ticketQuantity}x ${ticketName}`,
        priceText: '',
        ticketDetail: ticketDetail,
        ticketDetailStrikeThrough: false,
    };
    return <SummaryPriceLine data={priceLineData}></SummaryPriceLine>;
}
