import { WarningRounded } from '@mui/icons-material';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import QuantityPicker from 'src/components/common/quantity-picker/QuantityPicker';
import { VisibilityControllable } from 'src/hooks/common/ui/visibility-control';
import { useLocale } from '@repo/i18n';
import { bookingWidgetInputStyle } from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';

export type MultipleNumberInputValueType = {
    name: string | ((num: number) => string);
    value: number;
    id: number;
    subText?: string;
    disabled?: boolean;
    disabledText?: string;
    error?: { id: string; error: 'minEntrants' | 'capacity'; value: number };
    min?: number;
    max?: number;
};

type IProps = VisibilityControllable & {
    values: MultipleNumberInputValueType[];
    defaultValues: MultipleNumberInputValueType[];
    onChange(id: number, value: number): void;
    enablePopoverBorder?: boolean;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    buttonColor?: string;
    variant?: 'outlined' | 'filled';
    maxEntrants?: number;
    labelText?: string;
    onConfirm?: () => void;
    error?: { id: string; error: 'minEntrants' | 'capacity'; value: number };
    padding?: number;
    border?: string;
};

export default function MultipleNumberInput(props: IProps): JSX.Element {
    const {
        values,
        defaultValues,
        onChange,
        maxEntrants,
        error,
        backgroundColor,
        color,
        padding = 0,
        border,
        labelColor,
        labelText,
    } = props;

    const theme = useTheme();
    const { t } = useLocale();
    const customizations = useCustomizations();

    const maxReached =
        !!maxEntrants && maxEntrants <= values.reduce((acc, cur) => acc + cur.value, 0);

    const errors =
        error?.error === 'minEntrants'
            ? t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                  error.value.toString(),
              )
            : error?.error === 'capacity'
              ? t.number_of_travelers_exceeded.parsed(error.value)
              : undefined;

    const colors = bookingWidgetInputStyle(customizations, backgroundColor, color);

    const disabled = defaultValues.find((x) => !values.find((y) => y.id === x.id));
    return (
        <Stack gap={0.5}>
            <Stack>
                {(labelText === undefined || labelText.length > 0) && (
                    <Typography fontSize="calc(1rem * 0.85)" color={labelColor}>
                        {labelText ?? t.how_many_are_going}
                    </Typography>
                )}
                <Paper
                    elevation={0}
                    sx={{
                        py: theme.spacing(padding),
                        px: 0,
                        transition: 'all 0.5s ease-in-out',
                        background: colors.backgroundColor,
                        color: colors.color,
                        border: border,
                        borderRadius: customizations.borderRadius + 'px',
                        overflow: 'hidden',
                        width: '100%',
                    }}
                >
                    <Stack justifyContent="center" alignItems="center">
                        {errors && (
                            <Stack
                                direction="row"
                                px={1.5}
                                pt={1.5}
                                pb={1}
                                gap={1}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <WarningRounded
                                    sx={{ fill: colors.color, width: 18, height: 18 }}
                                />
                                <Typography
                                    lineHeight={1}
                                    pb="2px"
                                    color={colors.color}
                                    fontSize={14}
                                >
                                    {errors}
                                </Typography>
                            </Stack>
                        )}
                        <Inputs
                            values={values}
                            defaultValues={defaultValues}
                            onChange={onChange}
                            maxReached={maxReached}
                            maxEntrants={maxEntrants}
                            color={props.color}
                            backgroundColor={props.backgroundColor}
                            buttonColor={props.buttonColor}
                        />
                    </Stack>
                </Paper>
            </Stack>
            {!!disabled && (
                <Typography
                    color={customizations.bookingWidgetColorContrast}
                    fontSize={14}
                    fontStyle="italic"
                >
                    {disabled.disabledText}
                </Typography>
            )}
        </Stack>
    );
}

function Inputs({
    values,
    defaultValues,
    onChange,
    maxReached = false,
    maxEntrants,
    color,
    backgroundColor,
    buttonColor,
}: {
    values: MultipleNumberInputValueType[];
    defaultValues: MultipleNumberInputValueType[];
    onChange: (id: number, value: number) => void;
    maxReached: boolean;
    maxEntrants?: number;
    color?: string;
    backgroundColor?: string;
    buttonColor?: string;
}) {
    const { t } = useLocale();
    return useMemo(
        () => (
            <>
                {defaultValues.map((defaultVal) => {
                    const actualValue = values.find((x) => x.id === defaultVal.id);
                    const val = actualValue ?? { ...defaultVal };
                    if (!actualValue) {
                        val.value = 0;
                    }
                    const disabled = !actualValue || actualValue.disabled; // Disable if default price is not available for selected product, or if the option is disabled

                    const maxErrorMessage =
                        val.value > 0 && val.error?.error === 'capacity'
                            ? t.number_of_travelers_exceeded.parsed(val.max ?? 0)
                            : undefined;
                    const minErrorMessage =
                        val.error?.error === 'minEntrants'
                            ? t.too_few_travelers_quantity.parsed(val.min ?? 0)
                            : undefined;
                    return (
                        <QuantityPicker
                            key={`quantity-picker-${
                                typeof val.name === 'function'
                                    ? capitalize(val.name(defaultVal.value))
                                    : capitalize(val.name)
                            }`}
                            value={actualValue?.value ?? val.value}
                            name={
                                typeof val.name === 'function'
                                    ? capitalize(val.name(val.value))
                                    : capitalize(val.name)
                            }
                            subText={val.subText}
                            onChange={(num) => {
                                onChange(val.id, num);
                            }}
                            maxReached={maxReached}
                            maxValue={val.max ?? maxEntrants ?? Infinity}
                            minValue={0}
                            disabled={disabled}
                            error={maxErrorMessage ?? minErrorMessage}
                            color={color}
                            backgroundColor={backgroundColor}
                            buttonColor={buttonColor}
                        />
                    );
                })}
            </>
        ),
        [
            defaultValues,
            values,
            maxReached,
            onChange,
            maxEntrants,
            t,
            color,
            backgroundColor,
            buttonColor,
        ],
    );
}
