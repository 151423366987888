import { useMemo } from 'react';
import TourProductCardList from 'src/components/domain/tour-product-card/TourProductCardList';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { groupToursByProduct } from '@repo/widget-utils/activities/tours';
import { useProductInstancesByIds } from '@repo/widget-utils/services/hooks/product';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    ids: string[];
    hideReadMore?: boolean;
    orientation?: 'landscape' | 'portrait';
    hideImage?: boolean;
    hideDescription?: boolean;
    hideTitle?: boolean;
};

export default function TourListBookable(props: Props) {
    const { data: products } = useProductInstancesByIds(props.ids);

    const tours = useMemo(() => {
        const toursWithId = products ?? [];
        return groupToursByProduct(toursWithId);
    }, [products]);

    return <TourProductCardList tours={tours} {...props} />;
}
