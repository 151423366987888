import { Button as MuiButton, Theme, useTheme } from '@mui/material';

export interface RadioButtonProps {
    text: string;
    subtext?: string;
    selected?: boolean;
    onClick: () => void;
    disabled?: boolean;
    color?: 'primary' | 'secondary';
    variant?: 'contained' | 'outlined';
    size?: 'small' | 'normal' | 'large';
    borderColor?: string;
    'aria-label'?: string;
}

export default function RadioButton(props: RadioButtonProps): JSX.Element {
    const {
        text,
        subtext,
        onClick,
        selected = false,
        disabled = false,
        color = 'primary',
        variant = 'outlined',
        size = 'normal',
        borderColor,
    } = props;

    const theme = useTheme();
    const backgroundColor = getBackgroundColor(variant, color, selected, theme);
    const textColor = getTextColor(variant, color, selected, theme);
    const hoverBackgroundColor = getHoverBackgroundColor(variant, color, theme);
    const padding = getPadding(size, theme);

    return (
        <MuiButton
            role="radio"
            aria-label={props['aria-label']}
            color={color}
            disabled={disabled}
            onClick={onClick}
            variant={variant}
            sx={{
                color: textColor,
                padding: padding,
                borderColor: selected ? borderColor : '#E9E9E9',
                backgroundColor: backgroundColor,
                borderWidth: 2,
                '&:hover': {
                    borderWidth: 2,
                    borderColor: borderColor,
                    backgroundColor: hoverBackgroundColor,
                },
            }}
        >
            <span style={{ lineHeight: subtext ? 1 : 1.5 }}>
                <span style={{ fontSize: 'small' }}>{text}</span>
                <br />
                {subtext && <span style={{ fontSize: 'x-small' }}>{subtext}</span>}
            </span>
        </MuiButton>
    );
}

function getHoverBackgroundColor(
    variant: 'contained' | 'outlined',
    color: 'primary' | 'secondary',
    theme: Theme,
) {
    if (variant === 'contained')
        return color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main;

    return undefined;
}

function getBackgroundColor(
    variant: 'contained' | 'outlined',
    color: 'primary' | 'secondary',
    selected: boolean,
    theme: Theme,
) {
    if (variant === 'contained') {
        if (color === 'primary')
            return selected ? theme.palette.secondary.main : theme.palette.primary.main;
        return selected ? theme.palette.primary.main : theme.palette.secondary.main;
    }
    return selected ? undefined : '#E9E9E9';
}

function getTextColor(
    variant: 'contained' | 'outlined',
    color: 'primary' | 'secondary',
    selected: boolean,
    theme: Theme,
) {
    if (variant === 'contained') {
        if (color === 'primary')
            return selected
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.contrastText;
        return selected ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText;
    }
    return '#000';
}

function getPadding(size: string, theme: Theme) {
    if (size === 'small') return theme.spacing(0.25, 0.5);
    else if (size === 'normal') return theme.spacing(0.5, 1);
    else return theme.spacing(1, 1.5);
}
