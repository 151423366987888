import { RefObject, useContext, useEffect, useMemo, useState } from 'react';
import { MountPointContext } from '@repo/common-utils/mountPoint';

type FocusTrapHandle = {
    deactivate: () => void;
    reactivate: () => void;
};

function getFocusableElements(focusableContent: NodeListOf<HTMLElement>) {
    let firstFocusableElementIndex = focusableContent.length - 1;
    let lastFocusableElement = 0;

    for (let i = 0; i < focusableContent.length; i++) {
        if (
            !(focusableContent[i] as HTMLButtonElement)?.disabled &&
            i < firstFocusableElementIndex
        ) {
            firstFocusableElementIndex = i;
        }

        const reverseI = focusableContent.length - i - 1;
        if (
            !(focusableContent[reverseI] as HTMLButtonElement)?.disabled &&
            reverseI > lastFocusableElement
        ) {
            lastFocusableElement = reverseI;
        }
    }

    return {
        firstFocusableElement: focusableContent[firstFocusableElementIndex],
        lastFocusableElement: focusableContent[lastFocusableElement],
    };
}

function focusTrap(modal: HTMLElement, shadowRoot: ShadowRoot): FocusTrapHandle {
    // add all the elements inside modal which you want to make focusable
    const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = modal.querySelectorAll(focusableElements) as NodeListOf<HTMLElement>;

    function listener(e: KeyboardEvent) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        const { firstFocusableElement, lastFocusableElement } =
            getFocusableElements(focusableContent);

        if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (shadowRoot.activeElement === firstFocusableElement) {
                lastFocusableElement?.focus(); // add focus for the last focusable element
                e.preventDefault();
            }
        } else {
            // if tab key is pressed
            if (shadowRoot.activeElement === lastFocusableElement) {
                // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstFocusableElement?.focus(); // add focus for the first focusable element
                e.preventDefault();
            }
        }
    }
    const { firstFocusableElement } = getFocusableElements(focusableContent);

    firstFocusableElement?.focus();

    document.addEventListener('keydown', listener);

    return {
        deactivate: () => document.removeEventListener('keydown', listener),
        reactivate: () => document.addEventListener('keydown', listener),
    };
}

let focusTrapIdGen = 1;

type FocusTrapState = {
    deactivate: (timeout?: number) => void;
    active: boolean;
    id: number;
    trap: FocusTrapHandle;
};

const defaultTrapValue: FocusTrapState = {
    deactivate: () => null,
    active: false,
    id: -1,
    trap: {
        deactivate: () => null,
        reactivate: () => null,
    },
};

/**
 * Use this to trap the focus in a modal (required as per accessibility standards).
 * See Calendar.tsx for example usage.
 *
 * Needed to roll our own version in order for this to work with ShadowRoot. (MUI and focus-trap) did not work.
 *
 * @param element
 * @param active
 * @param elementToFocusOnDeactivate
 */
export function useFocusTrap(
    element: string | RefObject<HTMLElement>,
    active: boolean,
    elementToFocusOnDeactivate?: string | RefObject<HTMLElement>,
    shadowRoot?: ShadowRoot,
) {
    const mountPoint = useContext(MountPointContext);
    const [trap, setTrap] = useState<FocusTrapState>(defaultTrapValue);

    const refocusCorrectElem = useMemo(() => {
        if (!elementToFocusOnDeactivate) {
            return () => {
                // eslint-disable-next-line no-console
                console.log('No element to refocus');
            };
        }

        return () => {
            // Need the timeout when resolving "el" in order to hit the timings with react mount / update
            let el: HTMLElement | null = null;
            setTimeout(() => {
                el = getElement(elementToFocusOnDeactivate, shadowRoot ?? mountPoint.shadowRoot);
                el?.focus();
            });
        };
    }, [elementToFocusOnDeactivate, mountPoint.shadowRoot, shadowRoot]);

    useEffect(() => {
        if (!active || trap.active) return;

        const el = getElement(element, shadowRoot ?? mountPoint.shadowRoot);

        if (el) {
            const t = focusTrap(el, shadowRoot ?? mountPoint.shadowRoot);
            const id = ++focusTrapIdGen;
            setTrap({
                deactivate: (timeout?: number) => {
                    setTrap(defaultTrapValue);
                    t.deactivate();
                    trap.active = false;
                    if (timeout) setTimeout(refocusCorrectElem, 150);
                    else refocusCorrectElem();
                },
                active: true,
                id,
                trap: t,
            });
        }
    }, [active, element, mountPoint.shadowRoot, refocusCorrectElem, shadowRoot, trap, trap.active]);

    return trap;
}

function getElement(
    element: string | RefObject<HTMLElement>,
    shadowRoot: ShadowRoot,
): HTMLElement | null {
    let el =
        typeof element === 'string'
            ? (shadowRoot.querySelector(element) as HTMLElement)
            : element instanceof HTMLElement
              ? element
              : element.current;
    if (!el && typeof element === 'string') {
        el = document.querySelector(element);
    }

    return el;
}
