import { atom } from 'ximple/atoms';
import { LocalStorageKey } from '../localstorage';
import { VERSION } from '@repo/widget-env/__autogen/env';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { CartItem } from '@repo/types';
import { CartEvents, reduceCartState } from './cart.reducer';
import { decycle, retrocycle } from '@repo/common-utils/cycle';
import { dayjsReplacer, dayjsReviver } from '@repo/common-utils/serialize';
import { localeAtom } from '@repo/i18n';
import { debugLog, errorLog } from '@repo/common-utils/Logger';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { flow } from 'lodash-es';
import { removeInvalidCartItems, getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { verifyQuantities } from '@repo/widget-utils/booking/bookingHelpers';
import { displaySnackbarAtom } from '../ui/globalSnackbar.atom';

type State = CartItem[];

export const cartAtom = atom<State, CartEvents>({
    initialValue: [],
    persistKey: LocalStorageKey.BILBERRY_CART,
    appVersion: VERSION,
    update: flow(checkPaymentPlanCompatibility, verifyCapacityBeforeAdding, updateCartAtom),
    transformOnSerialize: (obj) => decycle(obj, dayjsReplacer),
    transformOnDeserialize: async (obj) => {
        await new Promise((resolve) =>
            configurationAtom.subject.subscribe((config) => {
                if (config.__settingsLoaded) {
                    resolve(null);
                }
            }),
        );
        return removeInvalidCartItems(retrocycle(obj, dayjsReviver));
    },
});

// Validate the cart every 10 seconds (unless empty)
setInterval(() => {
    if (cartAtom.subject.value.length === 0) return;

    cartAtom.update({ type: 'VALIDATE' });
}, 10 * 1000);

function verifyCapacityBeforeAdding([state, action]: [CartItem[], CartEvents]): [
    CartItem[],
    CartEvents,
] {
    if (action.type !== 'ADD') return [state, action];
    try {
        const actionId = getCartItemId(action.data);
        const willOverwriteElement = state.some((item) => getCartItemId(item) === actionId);

        if (willOverwriteElement) {
            return [state, action];
        }

        // Check if the product is an accommodation or "night", and in so case, check the capacity without considering ticketOptions.
        if (
            action.data.products?.[0]?.product &&
            (action.data.products[0].product.type === 'accommodation' ||
                action.data.products[0].product.type === 'nights')
        ) {
            const countExisting = state.filter((item) =>
                item.products.some((p) => !!action.data.products.find((ap) => ap.id === p.id)),
            ).length;
            const lowestCapacityInRange = Math.min(...action.data.products.map((p) => p.capacity));
            if (countExisting + 1 > lowestCapacityInRange) {
                displaySnackbarAtom.update({
                    message:
                        localeAtom.subject.value.t.thereIsNotEnoughCapacityForTheItemsInThisCart,
                    severity: 'error',
                    visible: true,
                });
                return [
                    state,
                    {
                        type: 'NOOP' as any,
                    },
                ];
            } else {
                return [state, action];
            }
        }

        // find all overlapping products
        const allItemsOverlapping = state.filter((item) =>
            item.products.some((p) => !!action.data.products.find((ap) => ap.id === p.id)),
        );

        // if no overlapping products, we can skip the rest
        if (allItemsOverlapping.length === 0) return [state, action];

        // combine the quantities of the ticketOptions and verify the new total quantity in the cart
        const combinedTicketOptionQuantity = action.data.ticketOptions.map((q) => {
            const allMatching = allItemsOverlapping.map((item) =>
                item.ticketOptions.find((x) => x.id === q.id),
            );
            const totalQuantity = allMatching.reduce((acc, x) => acc + (x?.quantity ?? 0), 0);
            return { ...q, quantity: q.quantity + totalQuantity };
        });

        const errors = verifyQuantities(combinedTicketOptionQuantity);
        if (errors.length > 0) {
            displaySnackbarAtom.update({
                message: localeAtom.subject.value.t.thereIsNotEnoughCapacityForTheItemsInThisCart,
                severity: 'error',
                visible: true,
            });
            return [
                state,
                {
                    type: 'NOOP' as any,
                },
            ];
        }
    } catch (e) {
        // If there is an error, log it, and continue as if nothing happened.
        errorLog('Error in verifyCapacityBeforeAdding', e);
        return [state, action];
    }

    return [state, action];
}

function updateCartAtom([state, action]: [CartItem[], CartEvents]) {
    const result = reduceCartState(state, action);

    dispatchWidgetEvents(action, state);

    return result;
}

let lastLocale = localeAtom.subject.value.locale;
localeAtom.subject.subscribe(({ locale }) => {
    if (lastLocale !== locale) {
        debugLog(`Changing locale from ${lastLocale} to ${locale}. Clearing cart.`);
        cartAtom.update({ type: 'CLEAR' });
    }
    lastLocale = locale;
});

function dispatchWidgetEvents(action: CartEvents, currentCartItems: CartItem[]) {
    switch (action.type) {
        case 'ADD':
            dispatchWidgetEvent({ eventType: 'addToCart', cartItems: [action.data] });
            break;
        case 'REMOVE':
            dispatchWidgetEvent({
                eventType: 'removeFromCart',
                cartItems: [currentCartItems[action.data as unknown as number]],
            });
            break;
    }
}

function checkPaymentPlanCompatibility(state: CartItem[], action: CartEvents) {
    if (action.type === 'ADD') {
        const cartItemToAdd = action.data;

        const { requiresPaymentPlans: newItemRequiresPaymentPlans } = cartItemToAdd;

        const { disablePaymentPlans: newItemDisablePaymentPlans } = cartItemToAdd;

        if (
            checkIfNewCartItemCausesCompatibilityIssue(
                newItemDisablePaymentPlans,
                newItemRequiresPaymentPlans,
                state,
            )
        ) {
            displaySnackbarErrorMessage(
                'Sorry, this product cannot be mixed with products already in the cart. Please purchase separately.',
            );
            return ignoreAction(state);
        }
    }
    return [state, action];
}

function displaySnackbarErrorMessage(message: string) {
    displaySnackbarAtom.update({
        message: message,
        severity: 'error',
        visible: true,
    });
}

function ignoreAction(state: CartItem[]) {
    return [
        state,
        {
            type: 'NOOP' as any,
        },
    ];
}

export function checkIfNewCartItemCausesCompatibilityIssue(
    newItemDisablePaymentPlans: boolean,
    newItemRequiresPaymentPlans: boolean,
    state: CartItem[],
) {
    const cartHasItemWithDisabledPaymentPlans = state.some((item) => item.disablePaymentPlans);
    const newItemCannotBeAddedDueToRequiringPaymentPlan =
        newItemRequiresPaymentPlans && cartHasItemWithDisabledPaymentPlans;

    const cartHasItemRequiringPaymentPlans = state.some((item) => item.requiresPaymentPlans);
    const newItemCannotBeAddedDueToDisablingPaymentPlan =
        newItemDisablePaymentPlans && cartHasItemRequiringPaymentPlans;

    return (
        newItemCannotBeAddedDueToRequiringPaymentPlan ||
        newItemCannotBeAddedDueToDisablingPaymentPlan
    );
}

export const selectValidItems = (state: State) => removeInvalidCartItems(state);

export const selectIsProductInCart = (state: State, productId: string) =>
    selectValidItems(state).some((cartItem) => cartItem.products[0]?.product?.id === productId);
