import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';
import { VERSION } from '@repo/widget-env/__autogen/env';
import { BilberryBrefData } from './bref-data-types';
import { BilberryWidgetsMeta } from './widgets-meta-types';
import dayjs from 'dayjs';
import { debugLog } from '@repo/common-utils/Logger';

export enum LocalStorageKey {
    BILBERRY_CART = 'no.bilberry.widgets-cart-new',
    BILBERRY_WIDGETS_META = 'no.bilberry.widgets-meta',
    BILBERRY_CHECKOUT_INFO = 'no.bilberry.widgets-checkout-info',
    BILBERRY_CHECKOUT_INFO_NEW = 'no.bilberry.widgets-checkout-info-new',
    BILBERRY_SEARCH_FORM_DATA = 'no.bilberry.search-form-data',
    BILBERRY_PURCHASE = 'no.bilberry.purchase',
    BILBERRY_BREF = 'no.bilberry.bref',
    BILBERRY_PREVIOUS_PATH = 'no.bilberry-timeslots.previous-path',
}

const TTL = 24; // in hours

declare const window: any;

function checkIfHasPreviousPath(): boolean {
    const previousPath = localStorage.getItem(LocalStorageKey.BILBERRY_PREVIOUS_PATH);
    const hasPreviousPath = !!previousPath && previousPath !== '{}'; // '{}' is the default value for the previous path
    return hasPreviousPath;
}

export function assertVersionAndTTLValid(): void {
    const stored = loadWidgetsMeta();

    if (!stored) return;

    const isVersionChanged = VERSION !== stored.version;
    const isTTLExpired = dayjs(stored.lastUpdated) < dayjs().subtract(TTL, 'hours');
    const isSiteChanged =
        window.BilberryWidgetsGlobal.siteKey &&
        window.BilberryWidgetsGlobal?.siteKey !== stored.siteKey;

    // Don't clear if we have a previous path.
    // In this case, the widgets will redirect to the previous path and the next page load will decide on whether or not to clear the local storage.
    const hasPreviousPath = checkIfHasPreviousPath();
    const shouldClear = !hasPreviousPath && (isVersionChanged || isTTLExpired || isSiteChanged);

    if (shouldClear) {
        debugLog('Clearing local storage', {
            isVersionChanged,
            isTTLExpired,
            isSiteChanged,
            hasPreviousPath,
        });

        localStorage.removeItem(LocalStorageKey.BILBERRY_WIDGETS_META);
        clearUserInputData();
    }
}

export function clearUserInputData() {
    clearCart();
    clearSearchFormData();
    clearCheckoutInfo();
}

export function clearSearchFormData() {
    localStorage.removeItem(LocalStorageKey.BILBERRY_SEARCH_FORM_DATA);
}

export function clearCart() {
    localStorage.removeItem(LocalStorageKey.BILBERRY_CART);
}

export function clearCheckoutInfo() {
    localStorage.removeItem(LocalStorageKey.BILBERRY_CHECKOUT_INFO);
    localStorage.removeItem(LocalStorageKey.BILBERRY_CHECKOUT_INFO_NEW);
}

export function loadWidgetsMeta() {
    return loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_WIDGETS_META,
    ) as BilberryWidgetsMeta | null;
}

export function loadDataFromLocalStorage(storageKey: string) {
    const serialized = localStorage.getItem(storageKey);
    if (serialized) {
        return JSON.parse(serialized) as any;
    } else {
        return null;
    }
}

export function updateWidgetsMeta() {
    // In case we have a previous path,
    // the widgets will redirect to the previous path and the next page load will handle the below in the context of the correct page.
    const hasPreviousPath = checkIfHasPreviousPath();
    const previousPath = localStorage.getItem(LocalStorageKey.BILBERRY_PREVIOUS_PATH);
    if (hasPreviousPath) return;

    const newSiteKey = window.BilberryWidgetsGlobal?.siteKey ?? null;
    const newWidgetsMeta: BilberryWidgetsMeta = {
        version: VERSION,
        lastUpdated: new Date(),
        siteKey: newSiteKey ?? null,
    };

    debugLog('Updating widgets meta data', {
        newSiteKey,
        previousPath,
        hasPreviousPath,
        href: window?.location?.href,
    });

    localStorage.setItem(LocalStorageKey.BILBERRY_WIDGETS_META, JSON.stringify(newWidgetsMeta));
}

function getBrefValueFromLocalStorage(): string {
    const brefData = loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_BREF,
    ) as BilberryBrefData | null;

    if (!brefData) return '';

    const now = new Date();

    if (now > brefData.expireAt) return '';

    return brefData.value;
}

function updateBrefData(newBrefData: BilberryBrefData) {
    localStorage.setItem(LocalStorageKey.BILBERRY_BREF, JSON.stringify(newBrefData));
}

export function findBrefValue(): string {
    const brefFromQueryParam = findUrlQueryParam('bref') ?? '';
    if (brefFromQueryParam) {
        const brefTTL = window.BilberryWidgetsGlobal?.brefTTL
            ? parseInt(window.BilberryWidgetsGlobal.brefTTL)
            : 14;

        updateBrefData({
            value: brefFromQueryParam,
            expireAt: dayjs().add(brefTTL, 'day').toDate(),
        });
    }

    return getBrefValueFromLocalStorage();
}
