import {
    Box,
    Button,
    capitalize,
    Collapse,
    Grid,
    Paper,
    Skeleton,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { RefObject, useRef, useState } from 'react';
import { useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { Product, ProductInstance, TicketType, Translations } from '@repo/types';
import { smoothScrollTo } from '@repo/common-utils/smoothScrollTo';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import NoAccommodationFoundOverlay from 'src/widgets/activities/accommodation-search/accommodation-search-form/subcomponents/NoAccommodationFoundOverlay';
import { useAtom } from 'ximple';
import AccommodationCardList from '../accommodation-card-list/AccommodationCardList';
import AccommodationImage from '../accommodation-image/AccommodationImage';
import { GuestInfo } from '../MultipleRoomSearch/guestInfo';

interface Props {
    adults: number;
    numberOfChildren: number;
    roomNumber: number;
    guestInfo: GuestInfo;
    hotelRooms: ProductInstance[][];
    selectedRoom?: ProductInstance[];
    selectedTicketType?: TicketType;
    onSelectRoom: (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
    ) => void;
    dateRange: DateRange<TZDate>;
    nights: number;
}

export default function RoomsSelection(props: Props): JSX.Element {
    const [expanded, setExpanded] = useState(false);
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const customizations = useCustomizations();
    const theme = useTheme();
    const isMediumPlusScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const scrollToTarget = useRef<HTMLElement | null>(null);

    const selectedProduct = props.selectedRoom?.[0]?.product;

    return (
        <Grid
            component={Paper}
            variant="outlined"
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            margin={theme.spacing(1, 0, 1, 0)}
        >
            <span ref={scrollToTarget} />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                    handleExpandClick(
                        getButtonText(expanded, t, selectedProduct),
                        setExpanded,
                        t,
                        scrollToTarget,
                        customizations,
                    )
                }
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="flex-end"
                    >
                        <Typography variant="h5">
                            {capitalize(t.rooms.num(1)) + ' ' + props.roomNumber}
                        </Typography>
                        <Divider />
                        <Typography>
                            {getGuests(props.adults, props.numberOfChildren, t)}
                        </Typography>
                        <Divider />
                        <Typography>
                            {props.selectedTicketType
                                ? t.from +
                                  ' ' +
                                  formatCurrencyNoDecimalsIfPossible(
                                      locale,
                                      currency,
                                      props.selectedTicketType.price,
                                  )
                                : t.from +
                                  ' ' +
                                  formatCurrencyNoDecimalsIfPossible(
                                      locale,
                                      currency,
                                      props.hotelRooms
                                          .flatMap((accommodation) =>
                                              accommodation[0]?.ticketOptions.flatMap((to) =>
                                                  to.ticketTypes.map((tt) => tt.price),
                                              ),
                                          )
                                          .sort((a, b) => (a > b ? 1 : -1))[0],
                                  )}
                        </Typography>
                    </Grid>
                </Grid>
                {(!isSmallScreen || !props.selectedRoom) && (
                    <Grid
                        item
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginTop: theme.spacing(2),
                            },
                        }}
                    >
                        <Button variant="contained" color="secondary" size="large">
                            {getButtonText(expanded, t, selectedProduct)}
                        </Button>
                    </Grid>
                )}
            </Grid>
            {selectedProduct && (
                <Grid>
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        alignItems="center"
                        width={isMediumPlusScreen ? '60%' : '100%'}
                        mt={!isMediumPlusScreen ? theme.spacing(2) : 0}
                    >
                        {selectedProduct.coverImage ? (
                            <Box
                                height="100px"
                                width="150px"
                                m={0}
                                sx={{
                                    [theme.breakpoints.down('xs')]: {
                                        height: `fit-content`,
                                    },
                                    '& > .MuiSkeleton-root': {
                                        minHeight: 300,
                                    },
                                }}
                            >
                                <AccommodationImage url={selectedProduct.coverImage.src} />
                            </Box>
                        ) : (
                            <Box>
                                <Skeleton variant="rectangular" width="180px" height="100px" />
                            </Box>
                        )}

                        <Grid item>
                            <Grid container direction="column" ml={theme.spacing(2)}>
                                <Typography variant="h5">{selectedProduct.title}</Typography>
                                <Typography>{selectedProduct.title}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {isSmallScreen && (
                        <Grid
                            item
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                    marginTop: theme.spacing(2),
                                },
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() =>
                                    handleExpandClick(
                                        getButtonText(expanded, t, selectedProduct),
                                        setExpanded,
                                        t,
                                        scrollToTarget,
                                        customizations,
                                    )
                                }
                            >
                                {getButtonText(expanded, t, selectedProduct)}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box mt={theme.spacing(4)}>
                    {props.hotelRooms.length > 0 && (
                        <AccommodationCardList
                            accommodationIds={props.hotelRooms.map(
                                (room) => room[0]?.product?.id ?? '-1',
                            )}
                            accommodationUrls={props.hotelRooms.map(() => '/')}
                            accommodations={props.hotelRooms}
                            guestInfo={props.guestInfo}
                            bookDirectly={false}
                            onSelectRoom={(selectedAccommodation, selectedTicketType) => {
                                props.onSelectRoom(selectedAccommodation, selectedTicketType);
                                handleExpandClick(
                                    getButtonText(expanded, t, selectedProduct),
                                    setExpanded,
                                    t,
                                    scrollToTarget,
                                    customizations,
                                );
                            }}
                            dateRange={props.dateRange}
                            nights={props.nights}
                            selectedRoomId={selectedProduct?.id}
                        />
                    )}

                    {props.hotelRooms.length === 0 && <NoAccommodationFoundOverlay />}
                </Box>
            </Collapse>
        </Grid>
    );
}

function Divider(): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            width="2px"
            height="16px"
            margin={theme.spacing(0.5, 1, 0.5, 1)}
            sx={{
                backgroundColor: theme.palette.secondary.main,
            }}
        />
    );
}

function getButtonText(expanded: boolean, t: Translations, roomSelected?: Product | null) {
    let text = '';

    if (expanded) {
        text = t.close.toUpperCase();
    } else if (roomSelected && roomSelected.title !== '') {
        text = t.change_room.toUpperCase();
    } else {
        text = t.select_room.toUpperCase();
    }

    return text;
}

function handleExpandClick(
    buttonText: string,
    setExpanded: (expanded: boolean) => void,
    t: Translations,
    targetRef: RefObject<HTMLElement | null>,
    customizations: ThemeType,
) {
    smoothScrollTo(targetRef, -customizations.baseUnit);
    buttonText === t.select_room.toUpperCase() && setExpanded(true);
    buttonText === t.change_room.toUpperCase() && setExpanded(true);
    buttonText === t.close.toUpperCase() && setExpanded(false);
}

function getGuests(adults: number, children: number, t: Translations) {
    let guests = '';
    guests += adults + ' ' + t.adult.num(adults);
    children !== 0 && (guests += ' + ' + children + ' ' + t.children.num(children));
    return guests;
}
