import { Box, Modal, ModalProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';

import { useContext } from 'react';
import { Product } from '@repo/types';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useProduct } from '@repo/widget-utils/services/hooks/product';
import AccommodationOverview from '../../accommodation-overview/AccommodationOverview';

type AccommodationOverviewModalProps = {
    accommodation: Product;
    onCloseRoomInfoOverlay: () => void;
    dateRange: DateRange<TZDate>;
    guests: number;
};

const ModalFixedType = Modal as (
    props: Omit<ModalProps, 'children'> & { children: JSX.Element },
) => JSX.Element;

export default function AccommodationOverviewModal(props: AccommodationOverviewModalProps) {
    const customizations = useCustomizations();
    const mountPointContext = useContext(MountPointContext);

    const { data: accommodation } = useProduct(
        props.accommodation.id.toString(),
        'accommodation',
        props.dateRange,
        props.guests,
    );

    return (
        <ModalFixedType
            open={true}
            container={mountPointContext.popover}
            onClose={props.onCloseRoomInfoOverlay}
        >
            <Box
                sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    boxSizing: 'border-box',
                    padding: theme.spacing(2.5),
                    overflowY: 'auto',
                    background: theme.palette.common.white,
                    color: theme.palette.common.black,
                    [theme.breakpoints.up('sm')]: {
                        width: '100%',
                        height: '80vh',
                        top: '10vh',
                        maxWidth: '960px',
                        margin: '0 auto',
                        padding: theme.spacing(4),
                        paddingTop: theme.spacing(4),
                        borderRadius: `${customizations.borderRadius}px`,
                    },
                })}
            >
                <AccommodationOverview
                    accommodation={accommodation ?? props.accommodation}
                    onClickClose={props.onCloseRoomInfoOverlay}
                    dateRange={props.dateRange}
                    guests={props.guests}
                />
            </Box>
        </ModalFixedType>
    );
}
