import { createRoot } from 'react-dom/client';
import { Cart } from 'src/widgets/activities/checkout/cart/Cart';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { GiftcardCart } from '../giftcard-checkout/cart/GiftcardCart';
import { Route, Router } from 'src/components/common/router/Router';
import { CartCheckoutContextProvider } from './cart/CartCheckoutContext';

class BilberryCheckoutNew extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <Router>
                    <Route path="/checkout">
                        <CartCheckoutContextProvider>
                            <Cart />
                        </CartCheckoutContextProvider>
                    </Route>
                    <Route path="/gift-card-checkout">
                        <GiftcardCart />
                    </Route>
                </Router>
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-checkout', BilberryCheckoutNew);
}
