import { createRoot } from 'react-dom/client';
import { getCsvAttribute, getYesAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BookingSearchFormWidgetRoot from 'src/widgets/activities/booking-search/search-form/SearchFormWidgetRoot';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { errorLog } from '@repo/common-utils/Logger';

class BilberryBookingSearchForm extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const resultUrl = this.getAttribute('url') ?? '#';

        const hideDifficulty = getYesAttribute(this, 'hide-difficulty');
        const hideDuration = getYesAttribute(this, 'hide-duration');
        const hideQuantities = getYesAttribute(this, 'hide-quantities');
        const limitToProducts = getCsvAttribute(this, 'limit-to-products', true);
        let excludeProducts = getCsvAttribute(this, 'exclude-products', true);
        const locations = getCsvAttribute(this, 'locations', true);

        if (limitToProducts.length > 0 && excludeProducts.length > 0) {
            errorLog(
                // eslint-disable-next-line max-len
                'Both limit-to-products and exclude-products attributes are set. The exclude-products attribute will be ignored.',
            );
            excludeProducts = [];
        }

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BookingSearchFormWidgetRoot
                    resultUrl={resultUrl}
                    hideDifficulty={hideDifficulty}
                    hideDuration={hideDuration}
                    hideQuantities={hideQuantities}
                    limitToProducts={limitToProducts}
                    locations={locations}
                    excludeProducts={excludeProducts}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-search-form', BilberryBookingSearchForm);
}
