import { Box, Skeleton, useTheme } from '@mui/material';

import { useState } from 'react';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getConvertedImageUrl } from '@repo/widget-utils/ImageUrlConverter';

export default function ProductImage(props: {
    url: string | null | undefined;
    size?: 's' | 'm' | 'l';
    marginBottom?: number;
    disableShadows?: boolean;
    format?: '4_3' | '16_9';
    stretchToFit?: boolean;
    maxHeight?: number;
    height?: string;
}): JSX.Element {
    const {
        url,
        size = 's',
        marginBottom = 0,
        disableShadows,
        format = '4_3',
        stretchToFit,
        maxHeight,
        height = 'auto',
    } = props;
    const customizations = useCustomizations();
    const theme = useTheme();
    const convUrl = getConvertedImageUrl(url, size, format);
    const [shouldUseDefaultUrl, setShouldUseDefaultUrl] = useState(!convUrl);

    const onImageError = () => {
        setShouldUseDefaultUrl(true);
    };

    return (
        <Box
            width="100%"
            height={height}
            maxHeight={maxHeight}
            m={theme.spacing(0, 0, marginBottom, 0)}
            sx={[
                {
                    cursor: 'pointer',
                    [theme.breakpoints.down('sm')]: {
                        height: height ?? 'fit-content',
                    },
                },
            ]}
        >
            {url ? (
                <Box
                    component="img"
                    display="block"
                    width="100%"
                    height={height}
                    borderRadius={0}
                    sx={[
                        {
                            aspectRatio: format.replace('_', '/'),
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                        customizations.imageShadow &&
                            !disableShadows && { boxShadow: theme.shadows[2] },
                        stretchToFit ? { objectFit: 'cover !important' as any } : {},
                    ]}
                    src={shouldUseDefaultUrl ? url : (convUrl ?? '')}
                    onError={onImageError}
                    alt="image"
                    loading="lazy"
                />
            ) : (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="auto"
                    sx={{ aspectRatio: '16/9' }}
                />
            )}
        </Box>
    );
}
