import { Box, Button, useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useEffect, useMemo, useCallback } from 'react';
import { GiftcardPaymentInfo, PaymentInfo } from '@repo/types';
import { useLocale } from '@repo/i18n';
import {
    stripePaymentAtom,
    useStripePayment as useStripePayment,
} from 'src/state/payment/stripePayment.atom';
import { isGiftcardPaymentInfo } from '@repo/widget-utils/payment-types-helper';

interface IProps {
    paymentInfo: PaymentInfo | GiftcardPaymentInfo;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
}

const TEN_MINUTES = 1000 * 60 * 14.5;

export default function StripePaymentWrapper(props: IProps): JSX.Element {
    const { t } = useLocale();
    const timeOfMount = useMemo(() => Date.now(), []);
    const theme = useTheme();
    const { paymentStatus } = useStripePayment();

    const goBackClicked = useCallback(() => {
        props.onPaymentCancelled();
    }, [props]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (Date.now() - timeOfMount > TEN_MINUTES) {
                alert(t.your_reservation_timed_out);
                goBackClicked();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [timeOfMount, goBackClicked, t.your_reservation_timed_out]);

    useEffect(() => {
        switch (paymentStatus) {
            case 'succeeded': {
                props.onPaymentCompleted(props.paymentInfo, paymentStatus);
                break;
            }

            default:
                break;
        }
    }, [paymentStatus, props]);

    useEffect(() => {
        const fn = async () => {
            const { stripeKey, clientSecret } = getPaymentInfo(props.paymentInfo);

            if (!stripeKey || !clientSecret) return;

            await stripePaymentAtom.update({
                ...stripePaymentAtom.subject.value,
                stripeKey,
                stripeClientSecret: clientSecret,
            });
        };
        fn();

        return () => {
            const fn = async () => await stripePaymentAtom.update({ paymentStatus: '' });
            fn();
        };
    }, [props.paymentInfo]);

    return (
        <Box
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            pt={0}
            minWidth="300px"
            width="100%"
            maxWidth="50%"
            sx={{
                '& > *': {
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                },

                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(6),
                    maxWidth: '100%',
                },
            }}
        >
            <Box>
                <slot name="stripe-payment-slot" />
            </Box>
            <Button
                variant="outlined"
                color="primary"
                onClick={goBackClicked}
                sx={{ mt: theme.spacing(-8.5) }}
            >
                {t.go_back}
            </Button>
        </Box>
    );
}

function getPaymentInfo(context: PaymentInfo | GiftcardPaymentInfo) {
    if (isGiftcardPaymentInfo(context))
        return {
            stripeKey: context.giftcard.stripe_key,
            clientSecret: context.giftcard.stripe_client_secret,
        };
    else
        return {
            stripeKey: context.booking?.stripe_key,
            clientSecret: context.booking?.stripe_client_secret,
        };
}
