import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';
import {
    Box,
    Button,
    Collapse,
    Fade,
    FormControl,
    Grid,
    IconButton,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { Fragment, useState, useEffect } from 'react';
import { DateRangePicker } from 'src/components/common/date-range-picker/DateRangePicker';
import { DifficultyPicker } from 'src/components/common/difficulty-picker/DifficultyPicker';
import {
    DurationSlider,
    DurationType,
    MarkType,
} from 'src/components/common/duration-slider/DurationSlider';
import { MultipleNumberInputValueType } from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { MultipleNumberPicker } from 'src/components/common/MultipleNumberInput/MultipleNumberPicker';
import { useLocale } from '@repo/i18n';
import { DifficultyEnum, ProductSearch, Translations } from '@repo/types';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getDurationMarks } from 'src/widgets/activities/booking-search/search-form/durationMarks';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';

type IProps = {
    onClick(formData: ProductSearch): void;
    minDuration: number;
    maxDuration: number;
    defaultFormData?: ProductSearch | null;
    hideDifficulty?: boolean;
    hideDuration?: boolean;
    hideQuantities?: boolean;
    locations: string[];
    products: string[];
    excludeProducts: string[];
};

function useQuantities(defaultFormData?: ProductSearch | null) {
    const { t } = useLocale();
    const [quantities, setQuantities] = useState<MultipleNumberInputValueType[]>(
        getDefaultQuantities(t, defaultFormData),
    );
    useEffect(() => {
        setQuantities(getDefaultQuantities(t, defaultFormData));
    }, [t, defaultFormData]);

    return [quantities, setQuantities] as const;
}

export function SearchForm(props: IProps) {
    const {
        onClick,
        minDuration,
        maxDuration,
        defaultFormData,
        locations,
        products,
        hideDifficulty,
        hideDuration,
        hideQuantities,
        excludeProducts,
    } = props;
    const { t } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const [visible, setVisible] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange<TZDate>>([
        defaultFormData?.start ?? null,
        defaultFormData?.end ?? null,
    ]);
    const [difficulty, setDifficulty] = useState<DifficultyEnum>(
        defaultFormData ? defaultFormData.difficulty : DifficultyEnum.Any,
    );
    const [location, setLocation] = useState<string>(defaultFormData?.location ?? '');

    const [durationRange, setDurationRange] = useState<DurationType>(
        defaultFormData
            ? { min: defaultFormData.minDuration, max: defaultFormData.maxDuration }
            : { min: null, max: null },
    );

    const [quantities, setQuantities] = useQuantities(defaultFormData);

    const durationMarks = getFilteredMarks(t, minDuration, maxDuration);

    const isMediumPlusScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const onChangeQuantities = (id: number, value: number) => {
        setQuantities((prevState) => {
            const index = prevState.findIndex((x) => x.id === id);
            return [
                ...quantities.slice(0, index),
                { ...quantities[index], value },
                ...quantities.slice(index + 1),
            ];
        });
    };

    return (
        <Fragment>
            <Grid
                container
                justifyContent="center"
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: customizations.bookingSearchFormColor,
                    '& .MuiInputBase-root': {
                        border: 'none',
                    },
                    '& .MuiFilledInput-root':
                        TextFieldStyleUtils.bookingSearchFormInputStyle(customizations),
                }}
            >
                {!isMediumPlusScreen && (
                    <IconButton
                        sx={{
                            backgroundColor: customizations.bookingSearchFormColor,
                            color: customizations.bookingSearchFormColorContrast,
                            position: 'absolute',
                            bottom: '-1em',
                            marginBottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 2,
                            '&:hover, &:active': {
                                backgroundColor: customizations.bookingSearchFormColor,
                                color: customizations.bookingSearchFormColorContrast,
                            },
                            '& .MuiSvgIcon-root': {
                                color: customizations.bookingSearchFormColorContrast,
                            },
                        }}
                        color="primary"
                        onClick={() => setVisible(!visible)}
                        aria-label={
                            visible ? t.aria_close_find_activity : t.aria_open_find_activity
                        }
                    >
                        {visible ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                    </IconButton>
                )}
                {!isMediumPlusScreen && (
                    <Collapse
                        in={!visible}
                        sx={{ width: '100%', height: '100%', position: 'relative' }}
                    >
                        <Fade in={!visible}>
                            <Typography
                                variant="h4"
                                padding={theme.spacing(3, 2)}
                                boxSizing="border-box"
                                textAlign="center"
                                width="100%"
                                sx={{
                                    color: customizations.bookingSearchFormColorContrast,
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setVisible(true)}
                            >
                                {t.find_activities}
                            </Typography>
                        </Fade>
                    </Collapse>
                )}
                <Collapse in={visible || isMediumPlusScreen}>
                    <Box
                        boxSizing="border-box"
                        p={theme.spacing(2.5, 2, 2.5, 4)}
                        maxWidth="1280px"
                        width="100%"
                        sx={{
                            [theme.breakpoints.down('md')]: {
                                padding: theme.spacing(2.5, 0, 3, 2),
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="center"
                            alignItems="stretch"
                            height="100%"
                            flexWrap="wrap"
                            sx={{
                                '& > *': {
                                    flex: '1 0 180px',
                                    marginRight: `${theme.spacing(2)} !important`,
                                },
                            }}
                        >
                            {!hideQuantities && (
                                <MultipleNumberPicker
                                    quantities={quantities}
                                    defaultQuantities={getDefaultQuantities(t)}
                                    labelColor={customizations.bookingSearchFormColorContrast}
                                    onChangeQuantities={onChangeQuantities}
                                    variant="filled"
                                />
                            )}

                            <Box flex={'1 0 auto'}>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    onSelectDateRange={setDateRange}
                                    label={capitalize(t.when_are_you_going)}
                                    showError={false}
                                    labelColor={customizations.bookingSearchFormColorContrast}
                                />
                            </Box>
                            {locations.length > 0 && (
                                <FormControl fullWidth={true}>
                                    <Dropdown
                                        label={t.location}
                                        options={locations}
                                        value={location}
                                        defaultValue={t.pleaseChoose}
                                        onChange={setLocation}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                border: 'none',
                                            },
                                            '& .MuiFilledInput-root':
                                                TextFieldStyleUtils.bookingSearchFormInputStyle(
                                                    customizations,
                                                ),
                                            '& .MuiInputLabel-root': {
                                                color: customizations.bookingSearchFormColorContrast,
                                            },
                                        }}
                                    />
                                </FormControl>
                            )}
                            {!hideDifficulty && (
                                <FormControl fullWidth={true}>
                                    <DifficultyPicker
                                        value={difficulty}
                                        onChange={setDifficulty}
                                        labelColor={customizations.bookingSearchFormColorContrast}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            {!hideDuration && (
                                <DurationSlider
                                    value={durationRange}
                                    onChange={setDurationRange}
                                    marks={durationMarks}
                                    label={t.duration}
                                    textColor={customizations.bookingSearchFormColorContrast}
                                    backgroundColor={customizations.bookingSearchFormColor}
                                />
                            )}
                            <Box
                                flex="0 1 auto"
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-end"
                                pt={theme.spacing(1)}
                            >
                                <FormControl
                                    margin="dense"
                                    sx={{ height: '100%', maxHeight: '39px' }}
                                >
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: '100%',
                                            fontWeight: customizations.boldFontWeight,
                                            borderColor:
                                                customizations.bookingSearchFormColorContrast,
                                            color: customizations.bookingSearchFormColorContrast,
                                            '&:hover': {
                                                backgroundColor: alpha(
                                                    theme.palette.common.black,
                                                    0.1,
                                                ),
                                            },
                                        }}
                                        onClick={() => {
                                            onClickFindActivities(
                                                durationMarks,
                                                durationRange,
                                                dateRange,
                                                difficulty,
                                                quantities,
                                                products,
                                                location,
                                                excludeProducts,
                                                onClick,
                                            );
                                        }}
                                    >
                                        {t.find_activities}
                                    </Button>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Collapse>
            </Grid>
        </Fragment>
    );
}

function onClickFindActivities(
    durationMarks: {
        value: number;
        minuteValue: number;
        label: string;
        labelValue: string;
        compareValue: number;
    }[],
    durationRange: DurationType,
    dateRange: DateRange<TZDate>,
    difficulty: DifficultyEnum,
    quantities: MultipleNumberInputValueType[],
    products: string[],
    location: string | null,
    excludeProducts: string[],
    onClick: (formData: ProductSearch) => void,
) {
    const marks = getPostableDurations(durationMarks, durationRange);
    const formData: ProductSearch = {
        start: dateRange[0],
        end: dateRange[1] ?? dateRange[0],
        difficulty,
        adults: quantities[0].value,
        children: quantities[1].value,
        minDuration: marks.min,
        maxDuration: marks.max,
        products,
        excludeProducts,
        location,
    };
    onClick(formData);
}

function getFilteredMarks(t: Translations, minDuration: number, maxDuration: number) {
    const allMarks = getDurationMarks(t);
    const maxDurationMark = allMarks[allMarks.length - 1];

    return allMarks
        .filter((mark, i, marks) => {
            const markIsLargerThanMinLessThanMax =
                mark.compareValue >= minDuration && mark.compareValue <= maxDuration;
            const smallestMarkWhichIsLargerThanMaxDuration =
                mark.compareValue > maxDuration &&
                i > 0 &&
                marks[i - 1]?.compareValue < maxDuration;
            const shouldIncludeMaxMark =
                mark === maxDurationMark && maxDuration >= maxDurationMark.compareValue;
            return (
                markIsLargerThanMinLessThanMax ||
                smallestMarkWhichIsLargerThanMaxDuration ||
                shouldIncludeMaxMark
            );
        })
        .map((mark, i, arr) => {
            if (i === 0) {
                return { ...mark, value: -1, minuteValue: -1 };
            } else if (i === arr.length - 1) {
                return { ...mark, value: Number.MAX_VALUE, minuteValue: Number.MAX_VALUE };
            } else {
                return { ...mark, value: i };
            }
        });
}

/**
 * Process the durations that will be posted.
 *
 * If the maxMark === minMark and minMark is the catch-all (-1) mark, use the labelValue of the
 * minMark as the maxMark.
 *
 * If the minMark === maxMark and maxMark is the catch-all (Number.MAX_VALUE) mark, use the compareValue of the
 * maxMark as the minMark.
 *
 * Interpret -1 for minMark as null (do not filter on min)
 * Interpret Number.MAX_VALUE for maxMark as null (do not filter on max)
 *
 * @param marks
 * @param range
 */
function getPostableDurations(marks: MarkType[], range: DurationType) {
    const minMark = marks.find((x) => x.minuteValue === range.min);
    const maxMark = marks.find((x) => x.minuteValue === range.max);

    let min = minMark?.minuteValue ?? null;
    if (minMark?.minuteValue === Number.MAX_VALUE && maxMark?.minuteValue === Number.MAX_VALUE)
        min = maxMark.compareValue;
    else if (minMark?.minuteValue === -1) min = null;

    let max = maxMark?.minuteValue ?? null;
    if (minMark?.minuteValue === -1 && maxMark?.minuteValue === -1)
        max = parseInt(minMark.labelValue);
    else if (maxMark?.minuteValue === Number.MAX_VALUE) max = null;

    return { min, max };
}

export function getDefaultQuantities(
    t: Translations,
    defaultFormData?: ProductSearch | null,
): MultipleNumberInputValueType[] {
    return [
        {
            name: t.adult.num,
            value: defaultFormData?.adults ?? 0,
            id: 1,
        },
        {
            name: t.children.num,
            value: defaultFormData?.children ?? 0,
            id: 2,
        },
    ];
}
