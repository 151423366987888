import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';

export default function InCartIndicator({
    variant = 'primary',
}: {
    variant?: 'primary' | 'white';
}): JSX.Element {
    const theme = useTheme();
    const { t } = useLocale();

    return (
        <Box component="div" display="flex" gap={theme.spacing(1)} alignItems="center">
            <CheckCircleOutlineOutlined sx={{ color: variant, fontSize: 32 }} />
            <Typography color={variant} component="span" fontSize={18}>
                {t.in_your_cart}
            </Typography>
        </Box>
    );
}
