import { TodayRounded } from '@mui/icons-material';
import { InputAdornment, useTheme } from '@mui/material';

export function EndIcon(props: {
    hasChosenDate: boolean;
    setHasChosenDate: (hasChosen: boolean) => void;
    showErrorDate: boolean;
}): JSX.Element {
    const theme = useTheme();
    const { hasChosenDate, setHasChosenDate, showErrorDate } = props;

    return (
        <InputAdornment position="end" onClick={() => !hasChosenDate && setHasChosenDate(true)}>
            <TodayRounded
                sx={
                    showErrorDate
                        ? {
                              color: theme.palette.error.main,
                              '& > *': {
                                  color: theme.palette.error.main,
                              },
                          }
                        : {}
                }
            />
        </InputAdornment>
    );
}
