import { TZDate } from '@repo/tzdate';
import { RefObject, useCallback } from 'react';
import { route } from 'src/components/common/router/Router';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import {
    Package,
    PackageTicketOption,
    PackageTicketOptionWithQuantity,
    ProductInstance,
} from '@repo/types';

export function useOnClickBookPackage(
    pkg: Package | null,
    quantities: PackageTicketOptionWithQuantity[],
    packagePrices: PackageTicketOption[],
    attemptedBooking: boolean,
    onBookingAttempt: React.Dispatch<React.SetStateAction<boolean>>,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onQuantitiesChange: React.Dispatch<React.SetStateAction<PackageTicketOptionWithQuantity[]>>,
    hasChosenDate: boolean,
    selectedDate: TZDate | null,
    setSelectedDate: (value: TZDate | null) => void,
) {
    return useCallback(
        (selectedProducts: ProductInstance[] = []) => {
            const fn = async () => {
                if (!attemptedBooking) onBookingAttempt(true);
                if (hasChosenDate && pkg && selectedDate) {
                    await cartAtom.update(
                        createAddProductsToCartEvent(
                            selectedProducts,
                            quantities,
                            true, // Packages is not supported by membership api, so this item will disable payment plans
                            false, // Obviously, it then doesn't require payment plans
                            {
                                pkg,
                                date: selectedDate,
                            },
                        ),
                    );

                    if (shouldShowBasketOnBook)
                        showBasketAtom.update({
                            visible: true,
                            refocusElementOnClose: boxRef,
                        });

                    toggleBookingAtom.update({ visible: false });
                    setSelectedDate(null);
                    onQuantitiesChange(updateQuantityData(packagePrices, quantities));

                    if (!shouldShowBasketOnBook) route('/checkout');
                }
            };
            fn();
        },
        [
            pkg,
            attemptedBooking,
            onBookingAttempt,
            hasChosenDate,
            quantities,
            boxRef,
            packagePrices,
            shouldShowBasketOnBook,
            onQuantitiesChange,
            selectedDate,
            setSelectedDate,
        ],
    );
}

function updateQuantityData(
    defaultPrices: PackageTicketOption[],
    quantities: PackageTicketOptionWithQuantity[],
): PackageTicketOptionWithQuantity[] {
    return defaultPrices.map((price) => {
        const existingQuantity = quantities.find(
            (quantity) => quantity.ticketCategoryId === price.ticketCategoryId,
        );

        return {
            ...price,
            quantity: existingQuantity?.quantity ?? 0,
        };
    });
}
