import { alpha, Box, Button, darken, Grid, Paper, useTheme } from '@mui/material';
import { RefObject } from 'react';
import { TicketOptionWithQuantity, CurrencyType } from '@repo/types';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import {
    ensurePixelValue,
    findValueOrCustomBreakpointValue,
} from 'src/components/utils/styleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getPrimaryColors } from 'src/components/utils/theme/getPrimaryColors';
import { zIndex } from 'src/utils/widget/zIndex';
import { WarningMessage } from '../booking-base/BookingCardWarningMessage';
import { BookingHeader } from './booking-header/BookingHeader';

type Props = {
    visible: boolean;
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void;
    title: string;
    justifyTitle?: 'space-between' | 'center';
    toggleButton?: JSX.Element;
    rightButtonLabel: string;
    onClickRightButton(): void;
    hideLeftButton?: boolean;
    hideRightButton?: boolean;
    onClickLeftButton?(): void;
    leftButtonLabel?: string;
    quantities?: TicketOptionWithQuantity[];
    disableFixedPosition?: boolean;
    fromPrice?: number | null;
    currencyType?: CurrencyType;
    children: any;
    addMarginRightToHeader?: boolean;
    bookingCardRef?: RefObject<HTMLDivElement>;
    expandArrowInside: boolean;
    disableBookButton?: boolean;
    positionOffscreen: boolean;
    getWarningMessage?: () => string | undefined;
    hideExpandedPrice?: boolean;
    isLoadingPrices: boolean;
};

export default function BookingWidgetCard(props: Props): JSX.Element {
    const {
        visible,
        onToggleVisible,
        children,
        title,
        toggleButton,
        hideLeftButton = false,
        hideRightButton = false,
        leftButtonLabel,
        onClickLeftButton,
        onClickRightButton,
        rightButtonLabel,
        justifyTitle,
        quantities,
        currencyType,
        fromPrice,
        addMarginRightToHeader,
        disableFixedPosition = false,
        bookingCardRef,
        expandArrowInside,
        disableBookButton,
        positionOffscreen,
        getWarningMessage,
        hideExpandedPrice,
        isLoadingPrices,
    } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    const defaultClosedPosition = positionOffscreen
        ? {
              transform: `translateY(100%) translateZ(0)`,
          }
        : {
              transform: `translateY(calc(100% - calc(${ensurePixelValue(
                  theme.typography.h4.fontSize,
              )} + ${theme.spacing(props.expandArrowInside ? 7 : 5)} ))) translateZ(0)`,
          };

    const warningMessage = getWarningMessage?.();
    return (
        <Grid
            component={'div'}
            sx={[
                {
                    transition: 'ease .4s',
                    '& .MuiInputBase-root': {
                        '&:hover,&:focus,&:active,&.Mui-focused': {
                            boxShadow: `0px 0px 2px 2px ${
                                getPrimaryColors(
                                    customizations.bookingWidgetColor,
                                    customizations.bookingWidgetPrimaryColor,
                                    customizations.bookingWidgetColor,
                                    true,
                                ).backgroundColor
                            }`,
                        },
                    },
                    '& .MuiFilledInput-input': {
                        padding: theme.spacing(1.5),
                        borderRadius: customizations.borderRadius,
                        color: customizations.bookingWidgetInputTextColor,
                    },
                    '& .MuiFilledInput-root':
                        TextFieldStyleUtils.bookingWidgetInputStyle(customizations),
                    '& textarea': {
                        marginBottom: theme.spacing(1.5),
                    },
                },
                !disableFixedPosition && {
                    position: 'fixed',
                    width: '100%',
                    maxWidth: '450px',
                    zIndex: zIndex.alwaysVisible,
                    right: theme.spacing(6),
                    bottom: findValueOrCustomBreakpointValue(customizations.popupBottomMargin, 0),
                    backgroundColor: 'transparent',
                    [theme.breakpoints.only('xl')]: {
                        bottom: findValueOrCustomBreakpointValue(
                            customizations.popupBottomMargin,
                            undefined,
                            'xl',
                        ),
                    },
                    [theme.breakpoints.only('lg')]: {
                        bottom: findValueOrCustomBreakpointValue(
                            customizations.popupBottomMargin,
                            undefined,
                            'lg',
                        ),
                    },
                    [theme.breakpoints.only('md')]: {
                        bottom: findValueOrCustomBreakpointValue(
                            customizations.popupBottomMargin,
                            undefined,
                            'md',
                        ),
                    },
                    [theme.breakpoints.only('sm')]: {
                        bottom: findValueOrCustomBreakpointValue(
                            customizations.popupBottomMargin,
                            undefined,
                            'sm',
                        ),
                    },
                    [theme.breakpoints.only('xs')]: {
                        bottom: findValueOrCustomBreakpointValue(
                            customizations.popupBottomMargin,
                            undefined,
                            'xs',
                        ),
                    },
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '100%',
                        width: '100%',
                        right: 0,
                    },
                },
                visible ? { transform: 'translateY(0%) translateZ(0)' } : defaultClosedPosition,
            ]}
            ref={bookingCardRef}
        >
            <Paper
                elevation={5}
                sx={{
                    backgroundColor: customizations.bookingWidgetColor,
                    color: customizations.bookingWidgetColorContrast,
                    borderRadius: `calc(${ensurePixelValue(theme.shape.borderRadius)} * 2)`,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    padding: 0,
                }}
            >
                <BookingHeader
                    title={title}
                    onClick={() => onToggleVisible(!visible, 'mouse')}
                    toggleButton={toggleButton}
                    justifyTitle={justifyTitle}
                    fromPrice={fromPrice}
                    currencyType={currencyType}
                    quantities={quantities}
                    addMarginRightToHeader={addMarginRightToHeader}
                    expandArrowInside={expandArrowInside}
                    hidePrice={visible && hideExpandedPrice}
                    isLoadingPrices={isLoadingPrices}
                />
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    p={theme.spacing(0, 3, 3, 3)}
                    sx={{
                        '& > *': {
                            marginTop: theme.spacing(3),
                        },
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        p={`0 ${theme.spacing(1)}`}
                        gap={theme.spacing(1)}
                        maxHeight="50vh"
                        sx={{ overflowY: 'auto' }}
                    >
                        {children}
                    </Box>
                    <Box>
                        {disableBookButton && warningMessage && (
                            <Box pb={2}>
                                <WarningMessage label={warningMessage} />
                            </Box>
                        )}
                        <Grid
                            container
                            justifyContent={leftButtonLabel ? 'space-between' : 'center'}
                            alignItems="center"
                        >
                            {!hideLeftButton && (
                                <Button
                                    sx={{
                                        flex: '0 0 auto',
                                        marginBottom: theme.spacing(1),
                                        backgroundColor: customizations.bookingWidgetColor,
                                        color: customizations.bookingWidgetSecondaryButtonColor
                                            ? customizations.bookingWidgetSecondaryButtonColor
                                            : customizations.bookingWidgetPrimaryColor,
                                        borderColor:
                                            customizations.bookingWidgetSecondaryButtonColor
                                                ? customizations.bookingWidgetSecondaryButtonColor
                                                : customizations.bookingWidgetPrimaryColor,
                                        '&:hover': {
                                            backgroundColor:
                                                customizations.bookingWidgetSecondaryButtonColor
                                                    ? alpha(
                                                          customizations.bookingWidgetSecondaryButtonColor,
                                                          0.2,
                                                      )
                                                    : darken(
                                                          customizations.bookingWidgetColor,
                                                          0.2,
                                                      ),
                                            color: customizations.bookingWidgetSecondaryButtonColor
                                                ? customizations.bookingWidgetSecondaryButtonColor
                                                : darken(
                                                      customizations.bookingWidgetPrimaryColor,
                                                      0.2,
                                                  ),
                                        },
                                    }}
                                    onClick={onClickLeftButton}
                                    aria-label={leftButtonLabel}
                                    variant={customizations.secondaryButtonStyle}
                                >
                                    {capitalize(leftButtonLabel)}
                                </Button>
                            )}
                            {!hideRightButton && (
                                <Button
                                    sx={{
                                        flex: '0 0 auto',
                                        marginBottom: theme.spacing(1),
                                        backgroundColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? customizations.bookingWidgetPrimaryColor
                                                : 'transparent',
                                        color:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? customizations.bookingWidgetPrimaryColorContrast
                                                : customizations.bookingWidgetPrimaryColor,
                                        borderColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? 'transparent'
                                                : customizations.bookingWidgetPrimaryColor,
                                        '&:hover': {
                                            backgroundColor:
                                                customizations.primaryButtonStyle === 'contained'
                                                    ? darken(
                                                          customizations.bookingWidgetPrimaryColor,
                                                          0.2,
                                                      )
                                                    : alpha(
                                                          customizations.bookingWidgetPrimaryColor,
                                                          0.2,
                                                      ),
                                            color:
                                                customizations.primaryButtonStyle === 'contained'
                                                    ? darken(
                                                          customizations.bookingWidgetPrimaryColorContrast,
                                                          0.2,
                                                      )
                                                    : undefined,
                                        },
                                        '&:focus': {
                                            boxShadow: `0 0 0 2px ${customizations.bookingWidgetPrimaryColorContrast}, \
                                                        0 0 0 3px ${customizations.bookingWidgetPrimaryColor}`,
                                        },
                                        '&:disabled': {
                                            backgroundColor:
                                                customizations.primaryButtonStyle === 'contained'
                                                    ? darken(
                                                          customizations.bookingWidgetPrimaryColor,
                                                          0.5,
                                                      )
                                                    : 'transparent',
                                            color:
                                                customizations.primaryButtonStyle === 'contained'
                                                    ? customizations.bookingWidgetColor
                                                    : customizations.bookingWidgetPrimaryColor,
                                            borderColor:
                                                customizations.primaryButtonStyle === 'contained'
                                                    ? 'transparent'
                                                    : darken(
                                                          customizations.bookingWidgetPrimaryColor,
                                                          0.5,
                                                      ),
                                        },
                                    }}
                                    onClick={onClickRightButton}
                                    aria-label={rightButtonLabel}
                                    variant={customizations.primaryButtonStyle}
                                    color="primary"
                                    disabled={disableBookButton}
                                >
                                    {capitalize(rightButtonLabel)}
                                </Button>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Paper>
        </Grid>
    );
}
