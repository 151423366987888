import Person from '@mui/icons-material/Person';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { capitalize } from 'lodash-es';
import { Fragment, ReactNode } from 'react';
import { useLocale } from '@repo/i18n';
import { CartItem } from '@repo/types';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { getCartItemDisplayTitle } from '@repo/widget-utils/display-helper';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import {
    checkoutInfoAtom,
    setGuestListQuestionnaire,
    setCopyFromContactPerson,
    setOrderQuestionnaire,
} from 'src/state/checkout-info/checkoutInfoAtom';
import Questionnaire from './Questionnaire';

type Props = {
    cartItem: CartItem;
    showErrors: boolean;
    isCompany: boolean;
};

export default function CheckoutInfoQuestionnaires(props: Props): ReactNode {
    const { t } = useLocale();
    const { cartItem, isCompany } = props;
    const [checkoutInfo, dispatchCheckoutInfo] = useAtomReducer(checkoutInfoAtom);
    const numberOfTravelers = cartItem.ticketOptions.reduce((acc, cur) => acc + cur.quantity, 0);

    if (!checkoutInfo) return null;

    const cartItemId = getCartItemId(cartItem);
    const guestListQuestionnaireEntry = Object.entries(
        checkoutInfo.guestListQuestionnaire[cartItemId] ?? {},
    );
    const activityQuestionEntries = Object.entries(
        checkoutInfo.orderQuestionnaire[cartItemId] ?? {},
    );

    // TODO: Fix the check of whether we should show the header
    return (
        <Fragment>
            {(guestListQuestionnaireEntry.some(
                ([, questionnaire]) => Object.entries(questionnaire).length > 0,
            ) ||
                activityQuestionEntries.length > 0) && (
                <Header
                    title={getCartItemDisplayTitle(cartItem)}
                    numberOfTravelers={numberOfTravelers}
                />
            )}

            {guestListQuestionnaireEntry.length > 0 &&
                guestListQuestionnaireEntry.map(([key, questionnaire], i) => (
                    <Questionnaire
                        key={key}
                        title={`${capitalize(t.traveler)} ${i + 1}`}
                        showCopy={i === 0 && !isCompany}
                        showErrors={props.showErrors}
                        questionnaire={questionnaire}
                        onChange={(newQuestionnaire, copyFromContactPerson) => {
                            if (copyFromContactPerson !== undefined)
                                dispatchCheckoutInfo(
                                    setCopyFromContactPerson(copyFromContactPerson),
                                );

                            dispatchCheckoutInfo(
                                setGuestListQuestionnaire({
                                    cartItemId,
                                    guestKey: key,
                                    questionnaire: newQuestionnaire,
                                }),
                            );
                        }}
                        contactPerson={checkoutInfo.contactPerson}
                        copyFromContactPerson={checkoutInfo.copyFromContactPerson}
                    />
                ))}

            {activityQuestionEntries.length > 0 && (
                <Questionnaire
                    title={capitalize(t.additional_information)}
                    showCopy={!isCompany}
                    showErrors={props.showErrors}
                    questionnaire={checkoutInfo.orderQuestionnaire[cartItemId]}
                    onChange={(newQuestionnaire) => {
                        dispatchCheckoutInfo(
                            setOrderQuestionnaire({
                                cartItemId,
                                questionnaire: newQuestionnaire,
                            }),
                        );
                    }}
                />
            )}
        </Fragment>
    );
}

type HeaderProps = {
    title: string;
    numberOfTravelers: number;
};

function Header(props: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                backgroundColor: theme.palette.grey[50],
                marginTop: theme.spacing(3),
                marginBottom: 0,
            }}
            variant="outlined"
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={9}>
                    <Typography color="textSecondary" variant="h6">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end" alignItems="center">
                    <Typography color="textSecondary" variant="h6" mr={1}>
                        {props.numberOfTravelers}x
                    </Typography>
                    <Person sx={{ fontSize: '1.6rem' }} color="secondary" />
                </Grid>
            </Grid>
        </Paper>
    );
}
