import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import {
    BilberryWidgetEventsContentful,
    BilberryWidgetEventSimple,
    Product,
} from './events.types.ts';
import {
    mapBilberryGiftcardToWidgetEvent,
    mapBilberryGiftcardUsageToWidgetEvent,
    mapCartItemToWidgetEvent,
    mapMembershipBookingToWidgetEvent,
    mapMembershipValueCardProductToWidgetEvent,
    mapPackageToWidgetEvent,
    mapProductToWidgetEvent,
} from './mapper.ts';
import { history } from 'src/utils/widget/setup.ts';
import {
    DispatchAuthenticationEventProps,
    DispatchCartEventProps,
    DispatchGiftcardEventProps,
    DispatchInteractionEventProps,
    DispatchMembershipEventProps,
} from './eventDispatcher.types.ts';
import { cartAtom } from 'src/state/cart/cartAtom';

function dispatchCartEvent({
    eventType,
    cartItems,
    reference,
    checkoutStep,
    promoCode,
    giftCards,
}: DispatchCartEventProps) {
    const widgetEvents = cartItems.map((item) => mapCartItemToWidgetEvent(item, promoCode));

    if (giftCards) {
        widgetEvents.push(mapBilberryGiftcardUsageToWidgetEvent(giftCards));
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products: widgetEvents.flatMap((x) => x.products),
        currency: currencyAtom.subject.value.currency,
        priceIncVat: widgetEvents.reduce((acc, cur) => acc + cur.priceIncVat, 0),
        reference: reference ?? undefined,
        checkoutStep,
        promoCode: widgetEvents[0]?.promoCode,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

function dispatchInteractionEvent({ eventType, product }: DispatchInteractionEventProps) {
    let products: Product[];

    if (product && 'type' in product && product.type === 'package') {
        ({ products } = mapPackageToWidgetEvent(product));
    } else if (product && 'productTypeId' in product) {
        ({ products } = mapMembershipValueCardProductToWidgetEvent(product));
    } else if (product && 'recipient_first_name' in product) {
        ({ products } = mapBilberryGiftcardToWidgetEvent(product));
    } else {
        ({ products } = mapProductToWidgetEvent(product));
    }

    const eventBody: BilberryWidgetEventsContentful = {
        type: eventType,
        products,
        currency: currencyAtom.subject.value.currency,
        priceIncVat: products.reduce((acc, cur) => acc + cur.priceIncVat, 0),
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchMembershipEvent({
    purchaseProduct,
    checkoutStep,
    eventType,
    giftCards,
    promoCode,
}: DispatchMembershipEventProps) {
    let widgetEvent: Omit<BilberryWidgetEventsContentful, 'type'> | null = null;

    if (purchaseProduct) {
        widgetEvent = mapMembershipBookingToWidgetEvent(purchaseProduct, promoCode);
    }

    if (giftCards) {
        widgetEvent?.products.push(...mapBilberryGiftcardUsageToWidgetEvent(giftCards).products);
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products: widgetEvent?.products ?? [],
        currency: currencyAtom.subject.value.currency,
        priceIncVat: (widgetEvent?.products ?? []).reduce((acc, cur) => acc + cur.priceIncVat, 0),
        reference: purchaseProduct?.reservation?.orderReference,
        checkoutStep,
        promoCode: widgetEvent?.promoCode,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchGiftCardEvent({
    giftCard,
    checkoutStep,
    eventType,
}: DispatchGiftcardEventProps) {
    let products: Product[] = [];

    if (giftCard) {
        ({ products } = mapBilberryGiftcardToWidgetEvent(giftCard));
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products,
        currency: currencyAtom.subject.value.currency,
        priceIncVat: products.reduce((acc, cur) => acc + cur.priceIncVat, 0),
        checkoutStep,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchAuthenticationEvent({ eventType }: DispatchAuthenticationEventProps) {
    const eventBody: BilberryWidgetEventSimple = {
        type: eventType,
    };
    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });
    window.dispatchEvent(event);
}

export function dispatchWidgetEvent(
    props:
        | DispatchInteractionEventProps
        | DispatchCartEventProps
        | DispatchMembershipEventProps
        | DispatchGiftcardEventProps
        | DispatchAuthenticationEventProps,
) {
    if ('cartItems' in props) {
        dispatchCartEvent(props);
    } else if ('purchaseProduct' in props) {
        dispatchMembershipEvent(props);
    } else if ('giftCard' in props) {
        dispatchGiftCardEvent(props);
    } else if (props.eventType === 'viewItem') {
        dispatchInteractionEvent(props);
    } else {
        dispatchAuthenticationEvent(props);
    }
}

// Listen for "start checkout event" for activities.
// This is the only checkout that uses navigates using /checkout route
history.listen((e) => {
    if (e.location.pathname === '/checkout') {
        dispatchWidgetEvent({
            eventType: 'startCheckout',
            cartItems: Object.values(cartAtom.subject.value),
        });
    }
});

import './membership-event-stream.ts';
