import { alpha, Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { useEffect, useState } from 'react';
import { WarningMessage } from 'src/components/domain/booking-base/BookingCardWarningMessage';
import {
    ProductCardContainer,
    ProductCardHeader,
} from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfoContainer } from 'src/components/domain/product-card-2/ProductCardInfo';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import useBookingState from 'src/hooks/domain/useBookingState';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { getProductMaxEntrants } from '@repo/widget-utils/activities/bilberryProductEntrants';
import { verifyQuantitiesValid } from '@repo/widget-utils/booking/bookingHelpers';
import { getInitialQuantityData, updateQuantityData } from '@repo/widget-utils/price-helper';
import ProductCardListContainer from '../product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from '../product-list-2/product-card-list-2/ProductCardListItem';
import { useAvailabilities, useProducts } from '@repo/widget-utils/services/hooks/product';
import { ActivityProductCardInfoContent } from 'src/components/domain/product-card-2/ActivityProductCard';
import { Product, TicketOptionWithQuantity } from '@repo/types';
import { BookingBaseForm } from 'src/components/domain/booking-base/BookingBase';
import { getDateRangeVariant } from '../../activity-booking/booking/Booking';
import { InlineSummary } from 'src/components/domain/summary/InlineSummary';
import { ProductCard2Landscape } from 'src/components/domain/product-card-2/ProductCardLandscape';
import {
    checkIfNewCartItemCausesCompatibilityIssue,
    selectIsProductInCart,
    cartAtom,
} from 'src/state/cart/cartAtom';
import { useAtomSelector } from 'src/hooks';
import {
    productDisablePaymentPlans,
    productInstancesHasDepositPrices,
    productRequiresPaymentPlans,
} from '@repo/widget-utils/cart/cartUtils';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { MembershipBookingDisabledWarning } from 'src/components/domain/booking-base/MembershipBookingDisabledWarning';
import { CartCompatibilityWarning } from 'src/components/domain/booking-base/CartCompatibilityWarning';
import { useCartContext } from 'src/widgets/CartContext';
import useTicketTypesWithinProduct from 'src/hooks/domain/cart/useTicketTypesWithinProduct';
import usePriceQuantities from 'src/hooks/domain/cart/usePriceQuantities';
import useUpdateNewCartItem from 'src/hooks/domain/cart/useUpdateNewCartItem';
import { BookingContextProvider, useBookingContext } from 'src/widgets/BookingContext';

import InCartIndicator from 'src/components/domain/cart/InCartIndicator';
import useUpdateAvilabilitySearchPeriod from 'src/hooks/domain/cart/useUpdateAvilabilitySearchPeriod';

type PropsLandscape = {
    productCollectionId: string | null;
    productCatalogIds?: string[];
    productCatalogUrls?: string[];
    hideReadMore?: boolean;
    hideImageIfMissing?: boolean;
    cardBorder?: string;
    cardBorderRadius?: number;
    orientation: 'landscape';
};

type PropsPortrait = Omit<PropsLandscape, 'orientation'> & {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    orientation?: 'portrait';
};

export function ProductListBookable(props: PropsPortrait | PropsLandscape): JSX.Element {
    if (props.orientation === 'landscape') {
        return <ProductListBookableLandscape {...props} />;
    }
    return <ProductListBookablePortrait {...props} />;
}

export function ProductListBookableLandscape(props: PropsLandscape): JSX.Element {
    const {
        productCollectionId,
        productCatalogIds,
        productCatalogUrls = [],
        hideImageIfMissing,
        cardBorder,
        cardBorderRadius,
    } = props;
    const { data: products } = useProducts({
        ids: productCatalogIds ?? [],
        collectionId: productCollectionId?.toString(),
    });

    const productCardList = products.map((product, i) => {
        return (
            <BookingContextProvider key={product.id} initialTicketOptions={[]}>
                <ProductCard2Landscape
                    product={product}
                    url={productCatalogUrls[i] ?? product.url ?? '#'}
                    body={<ProductCardBookingForm product={product} />}
                    hideImage={hideImageIfMissing && !product.coverImage.src}
                    readMoreProductId={product.id}
                    readMoreLinkVariant={props.hideReadMore ? 'none' : 'href'}
                    hidePriceIcon
                    hideIcons={!!product.icons && product.icons.length === 0}
                    border={cardBorder}
                    borderRadius={cardBorderRadius}
                />
            </BookingContextProvider>
        );
    });

    return (
        <Stack gap={3} width="100%">
            {productCardList}
        </Stack>
    );
}

export function ProductListBookablePortrait(props: PropsPortrait) {
    const {
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        productCollectionId,
        productCatalogIds,
        productCatalogUrls = [],
        hideImageIfMissing,
    } = props;
    const customizations = useCustomizations();

    const { data: products } = useProducts({
        ids: productCatalogIds ?? [],
        collectionId: productCollectionId?.toString(),
    });

    const productCardList = products.map((product, i) => {
        const url = productCatalogUrls[i] ?? product.url ?? '#';
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
            bookingWidgetColorContrast: textColors[cur] ?? customizations.productCardTextColor,
        };

        return (
            <BookingContextProvider key={product.id} initialTicketOptions={[]}>
                <CustomizationsContext.Provider value={alternatingCustomizations}>
                    <ProductCardListItem
                        scroll={scroll}
                        numElements={numElements}
                        numProductCatalogs={products.length}
                    >
                        <ProductCardContainer>
                            {!(hideImageIfMissing && !product.coverImage.src) && (
                                <ProductCardHeader imageUrl={product.coverImage.src} url={url} />
                            )}
                            <ProductCardInfoContainer>
                                <ActivityProductCardInfoContent
                                    hideFromLabel={hideImageIfMissing && !product.coverImage.src}
                                    product={product}
                                    url={url}
                                    readMoreLinkVariant={props.hideReadMore ? 'none' : 'href'}
                                    readMoreProductId={product.id}
                                />
                                <ProductCardBookingForm product={product} />
                            </ProductCardInfoContainer>
                        </ProductCardContainer>
                    </ProductCardListItem>
                </CustomizationsContext.Provider>
            </BookingContextProvider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}

function ProductCardBookingForm(props: { product: Product }) {
    const { product } = props;
    const {
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
    } = useBookingState();
    const customizations = useCustomizations();
    const configuration = useConfigurations();

    const { cartItems } = useCartContext();
    const inCart = useAtomSelector(cartAtom, selectIsProductInCart, product.id);
    const {
        quantities,
        setQuantities,
        selectedProducts,
        setSelectedProducts,
        selectedTimeSlot,
        setSelectedTimeslot,
        selectedTicketType,
        setSelectedTicketType,
        isLoadingPrices,
    } = useBookingContext();

    // TODO: Refactor this so it is reused between Booking and ProductCardBookingForm

    const [dateRange, setDateRange] = useState<DateRange<TZDate>>([null, null]);

    const { nextProductAvailabilityStart, availabilitySearchPeriod, setAvailabilitySearchPeriod } =
        useUpdateAvilabilitySearchPeriod(product);

    const { data: availabilities } = useAvailabilities(
        product,
        availabilitySearchPeriod.startDay,
        availabilitySearchPeriod.endDay,
    );

    const hasDepositPrices = selectedProducts
        ? productInstancesHasDepositPrices(selectedProducts)
        : false;
    const newItemDisablePaymentPlans = productDisablePaymentPlans(
        product,
        false,
        hasDepositPrices,
        configuration.disableMembershipBooking,
    );
    const newItemRequiresPaymentPlans = productRequiresPaymentPlans(product);
    const showCartCompatibilityWarning = checkIfNewCartItemCausesCompatibilityIssue(
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
        cartItems,
    );
    const showMembershipBookingDisabledWarning =
        newItemRequiresPaymentPlans && newItemDisablePaymentPlans;

    const showWarning = showCartCompatibilityWarning || showMembershipBookingDisabledWarning;

    useUpdateNewCartItem(newItemDisablePaymentPlans, newItemRequiresPaymentPlans);

    const ticketTypes = useTicketTypesWithinProduct(product);
    const priceQuantities = usePriceQuantities(product, selectedProducts, quantities);

    function noExtendSetDateRange(newRange: DateRange<TZDate>) {
        // Removes the ability to extend range in both directions in mui calendar
        if (dateRange[0]?.format('YYYY-MM-DD') !== newRange[0]?.format('YYYY-MM-DD')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    useEffect(() => {
        setQuantities((quantities) =>
            updateQuantityData(selectedProducts ?? [], product?.ticketOptions ?? [], quantities),
        );
        if (ticketTypes.length === 1) {
            setSelectedTicketType(ticketTypes[0]);
        } else if (ticketTypes.length > 0) {
            setSelectedTicketType((prev) =>
                prev ? ticketTypes.find((t) => t.id === prev.id) : undefined,
            );
        }
    }, [selectedProducts, product, ticketTypes, setQuantities, setSelectedTicketType]);

    const onClickBook = useOnClickBook(
        priceQuantities,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        setHasChosenDate,
        selectedProducts,
        setSelectedProducts,
        setSelectedTimeslot,
        product,
        setDateRange,
        setSelectedTicketType,
        selectedTicketType,
        selectedTimeSlot,
    );

    const { disableBookButton, quantityErrors, totalErrors, getWarningLabel } =
        verifyQuantitiesValid(priceQuantities, undefined, hasChosenDate, attemptedBooking);

    return (
        <Stack alignItems="center" gap={2} textAlign="left" width="100%">
            {!showWarning && (
                <>
                    <Stack gap={1} width="100%">
                        <BookingBaseForm
                            inputFieldBorder={`1px solid ${alpha(
                                customizations.productCardTextColor,
                                0.2,
                            )}`}
                            setSelectedProducts={setSelectedProducts}
                            travelerQuantities={quantities}
                            defaultQuantities={getInitialQuantityData(
                                product?.ticketOptions ?? [],
                                product?.minEntrants,
                            )}
                            setQuantities={
                                setQuantities as unknown as React.Dispatch<
                                    React.SetStateAction<TicketOptionWithQuantity[]>
                                >
                            }
                            selectedTimeSlot={selectedTimeSlot}
                            onSelectTimeSlot={setSelectedTimeslot}
                            availabilityData={availabilities}
                            availabilitySearchPeriod={availabilitySearchPeriod}
                            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                            hasChosenDate={hasChosenDate}
                            setHasChosenDate={setHasChosenDate}
                            attemptedBooking={attemptedBooking}
                            quantityErrors={quantityErrors}
                            totalErrors={totalErrors}
                            productCapacity={getProductMaxEntrants(selectedProducts)}
                            onSelectDateRange={noExtendSetDateRange}
                            dateRangeVariant={getDateRangeVariant(product)}
                            selectedDateRange={dateRange}
                            selectedTicketType={selectedTicketType}
                            setSelectedTicketType={setSelectedTicketType}
                            ticketTypes={ticketTypes}
                            variant="filled"
                            backgroundColor={customizations.productCardColor}
                            color={customizations.productCardTextColor}
                            labelColor={customizations.productCardTextColor}
                            id={product.id}
                            minDate={nextProductAvailabilityStart}
                        />
                    </Stack>
                    <WarningMessage label={getWarningLabel()} />

                    <InlineSummary
                        onBook={onClickBook}
                        quantities={priceQuantities}
                        fromPrice={product.fromPrice}
                        bookingDisabled={disableBookButton}
                        hasCapacity={getProductMaxEntrants(selectedProducts) !== 0}
                        isLoadingPrices={isLoadingPrices}
                    />
                    {inCart && <InCartIndicator />}
                </>
            )}

            {showMembershipBookingDisabledWarning && (
                <MembershipBookingDisabledWarning></MembershipBookingDisabledWarning>
            )}
            {!showMembershipBookingDisabledWarning && showCartCompatibilityWarning && (
                <CartCompatibilityWarning></CartCompatibilityWarning>
            )}
        </Stack>
    );
}
