import { darken, getContrastRatio } from '@mui/material/styles';
import { ThemeType } from 'src/components/utils/theme/ThemeType';

export const positionInputLabelAboveField: Record<string, string> = {
    position: 'relative',
    transform: ` translate(0px, 4px) scale(0.85) !important`,
    paddingBottom: '1px',
};

export const positionInputLabelBelowField: Record<string, string> = {
    position: 'relative',
    transform: ` scale(0.85) !important`,
    paddingBottom: '1px',
    textAlign: 'center',
    transformOrigin: 'center center',
    maxWidth: '100%',
};

export function bookingWidgetInputStyle(
    customizations: ThemeType,
    backgroundColor?: string,
    color?: string,
) {
    const useDarken =
        getContrastRatio('#ffffff', backgroundColor ?? customizations.bookingWidgetColor) >
        getContrastRatio('#ffffff', color ?? customizations.bookingWidgetColorContrast);
    const modifier = useDarken ? (color: string) => darken(color, 0.25) : () => '#FFF';
    return {
        backgroundColor: `${modifier(
            backgroundColor ?? customizations.bookingWidgetColor,
        )} !important`,
        color: `${color ?? customizations.bookingWidgetColorContrast} !important`,
        border: 'none',
        transition: '0.1s ease-in-out all',
        '&:hover,&:focus,&:active': {
            backgroundColor: `${modifier(
                backgroundColor ?? customizations.bookingWidgetColor,
            )} !important`,
            color: `${color ?? customizations.bookingWidgetColorContrast} !important`,
        },
        '&:focus': {
            boxShadow: `0 0 0 2px ${customizations.bookingWidgetColor}, \
                        0 0 0 3px ${customizations.bookingWidgetColorContrast}`,
        },
        '&.Mui-focused': {
            boxShadow: `0 0 0 2px ${customizations.bookingWidgetColor}, \
                        0 0 0 3px ${customizations.bookingWidgetColorContrast}`,
        },
    };
}

export function bookingSearchFormInputStyle(customizations: ThemeType): Record<string, any> {
    return {
        backgroundColor: `${customizations.bookingSearchFormInputColor} !important`,
        color: `${customizations.bookingSearchFormInputTextColor} !important`,
        border: 'none',
        transition: '0.1s ease-in-out all',
        '&:hover,&:active': {
            backgroundColor: `${darken(
                customizations.bookingSearchFormInputColor,
                0.1,
            )} !important`,
            color: `${customizations.bookingSearchFormInputTextColor} !important`,
        },
        '&.Mui-focused': {
            boxShadow: `0 0 0 2px ${customizations.bookingSearchFormColor}, \
                        0 0 0 3px ${customizations.bookingSearchFormColorContrast} !important`,
        },
    };
}

export default {
    positionInputLabelAboveField,
    positionInputLabelBelowField,
    bookingWidgetInputStyle,
    bookingSearchFormInputStyle,
};
