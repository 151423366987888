import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import enGB from 'dayjs/locale/en-gb';
import nb from 'dayjs/locale/nb';
import nn from 'dayjs/locale/nn';
import de from 'dayjs/locale/de';
import nl from 'dayjs/locale/nl';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import enCA from 'dayjs/locale/en-ca';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

/**
 * A utility-wrapper around the dayjs library.
 * Since it is a "thin" wrapper, this means that it works in much the same way as dayjs does,
 * and is also immutable, meaning any change will return a new object instead of modifying
 * the previous object.
 *
 * You should never initialize the TZDate-class directly, but instead use it through the
 * exported tzdate() function.
 *
 * You should never use Dayjs directly but instead stick with the tzdate wrapper.
 *
 * By default, when initializing a date through the tzdate() function, it will force/convert
 * the date into the "default" timezone (TZDate.timezone), which means it will change the
 * offset/timezone but keep localtime (hours/minutes/seconds/date unchanged).
 * This means that the underlying UTC-time will be changed on the tzdate object.
 * The default timezone is the bilberry instance-timezone.
 *
 * In order to avoid the timezone force/convert, we use the option `keepLocalTime = false`,
 * which will only change the format/display of the time, but not change the underlying
 * UTC-time.
 *
 * This wrapper also works with multiple timezones, in case where you want more timezones
 * than just the instance timezone and the browser localtime.
 * If you want a third (or more!) timezone you simply pass it as the `tz` argument.
 * When passing the `tz` argument it will also respect the `keepLocalTime` argument, and
 * use it to determine if we are forcing timezone/UTC or simply changing the format/display.
 *
 * You may also look at the test-spec file to see some usage examples, it contains detailed
 * comments of what is happening.
 *
 * An explanation of the basic concept:
 *
 * 1. When working with dates, we are in the timezone of the Bilberry instance, referred to
 *    as the "default" (TZDate._timezone) timezone. Dates are moved to the default timezone
 *    when created, keeping the local time.
 *
 * 2. All dates loaded from the server is already in the instances's timezone, and should
 *    not be moved, so make sure to use `keepLocalTime = false` when creating these dates.
 *
 * 3. Some dates, such as expiry of value cards, gift cards etc. should be showing in the
 *    user's timezone, so use `keepLocalTime = false` here as well.
 *
 * One caveat to using TZDate is that before using it with MUI calendars, you must extract
 * the internal Dayjs date object instead.
 * Do this by calling TZDate.forceLocalTz().getDayjsDate. This moves returns the Dayjs object
 * in the timezone of the user, so that the date is what the calendar expects it to be
 * (since the calendar is in the user's timezone). This is safe to do without memoizing.
 *
 * @export
 * @class TZDate
 */
export class TZDate {
    // Locale for formatting of the date. If overriding the locale of the application,
    // remember to set this as well.
    public static locale = 'en';

    // Timezone for the date. If overriding the timezone of the application, remember
    // to set this as well.
    public static timezone = 'UTC';

    private _date: Dayjs; // Dayjs object
    private _timezone: string; // Timezone
    private _locale: string; // Locale

    // A cache for timezone convertion methods, in order to guarantee that you get
    // the same identical object every time, instead of generating a new object every time.
    // This will allow us to use these methods in react props with the guarantee that
    // the object returned is identical and will prevent re-renders.
    private _cache: {
        [key: string]: TZDate;
    } = {};

    // A cache for the storage-object, guarantees identical object for `toLocalStorage()` method.
    private _cacheStorage?: {
        $dayjs: {
            value: string;
            timezone: string;
            locale: string;
        };
    };

    constructor(
        date?: Parameters<typeof dayjs>[0],
        keepLocalTime = true,
        tz?: string,
        locale?: string,
    ) {
        this._timezone = tz ?? TZDate.timezone;
        this._locale = locale ?? TZDate.locale;
        const isUTC = this._timezone.toLowerCase() === 'utc';

        if (date instanceof Date) this._date = dayjs(date).tz(this._timezone, keepLocalTime);
        else if (date instanceof dayjs) this._date = date.tz(this._timezone, keepLocalTime);
        else
            this._date = dayjs(date === null ? undefined : date).tz(
                tz === 'local' ? dayjs.tz.guess() : this._timezone,
                keepLocalTime,
            );

        this._date = this._date.locale(getTZDateLocale(this._locale));

        // In case timezone is set to UTC, we must set timezone a second time.
        // If we don't do it, time is not displayed in the correct timezone in a few cases.
        // Some tests will break if you comment this line out.
        if (isUTC) this._date = this._date.tz(this._timezone);
    }

    private _lookup(key: string): TZDate | undefined {
        return this._cache[key];
    }

    private _store(key: string, value: TZDate) {
        this._cache[key] = value;

        return value;
    }

    public tz(): string;
    public tz(timezone: string): TZDate;
    public tz(timezone?: string): string | TZDate {
        if (!timezone) return this._timezone;

        const key = `tz.${timezone}`;
        return this._lookup(key) ?? this._store(key, tzdate(this._date, false, timezone));
    }

    public defaultTz() {
        const key = `defaultTz.${TZDate.timezone}`;
        return this._lookup(key) ?? this._store(key, this.tz(TZDate.timezone));
    }

    public localTz() {
        const guess = dayjs.tz.guess();
        const key = `localTz.${guess}`;
        return this._lookup(key) ?? this._store(key, this.tz(guess));
    }

    public forceTz(timezone: string) {
        const key = `forceTz.${timezone}`;
        return this._lookup(key) ?? this._store(key, tzdate(this._date, true, timezone));
    }

    public forceDefaultTz() {
        const key = `forceDefaultTz.${TZDate.timezone}`;
        return this._lookup(key) ?? this._store(key, this.forceTz(TZDate.timezone));
    }

    public forceLocalTz() {
        const guess = dayjs.tz.guess();
        const key = `forceLocalTz.${guess}`;
        return this._lookup(key) ?? this._store(key, this.forceTz(guess));
    }

    public add(amount: number, unit?: Parameters<Dayjs['add']>[1]) {
        const newDate = tzdate(this);
        newDate._date = newDate._date.add(amount, unit);

        return newDate;
    }

    public subtract(amount: number, unit?: Parameters<Dayjs['subtract']>[1]) {
        const newDate = tzdate(this);
        newDate._date = newDate._date.subtract(amount, unit);

        return newDate;
    }

    public startOf(unit: Parameters<Dayjs['startOf']>[0]) {
        const newDate = tzdate(this);
        newDate._date = newDate._date.startOf(unit);

        return newDate;
    }
    public endOf(unit: Parameters<Dayjs['endOf']>[0]) {
        const newDate = tzdate(this);
        newDate._date = newDate._date.endOf(unit);

        return newDate;
    }

    public date(): number;
    public date(value: number): TZDate;
    public date(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.date();
        const newDate = tzdate(this);
        newDate._date = newDate._date.date(value);

        return newDate;
    }

    public month(): number;
    public month(value: number): TZDate;
    public month(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.month();
        const newDate = tzdate(this);
        newDate._date = newDate._date.month(value);

        return newDate;
    }

    public year(): number;
    public year(value: number): TZDate;
    public year(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.year();
        const newDate = tzdate(this);
        newDate._date = newDate._date.year(value);

        return newDate;
    }
    public hour(): number;
    public hour(value: number): TZDate;
    public hour(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.hour();
        const newDate = tzdate(this);
        newDate._date = newDate._date.hour(value);

        return newDate;
    }

    public minute(): number;
    public minute(value: number): TZDate;
    public minute(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.minute();
        const newDate = tzdate(this);
        newDate._date = newDate._date.minute(value);

        return newDate;
    }

    public second(): number;
    public second(value: number): TZDate;
    public second(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.second();
        const newDate = tzdate(this);
        newDate._date = newDate._date.second(value);

        return newDate;
    }

    public weekday(): number {
        return this._date.weekday();
    }

    public day(): number {
        return this._date.day();
    }

    public locale(): string;
    public locale(locale: string): TZDate;
    public locale(locale?: string): string | TZDate {
        if (!locale) return this._locale;

        const key = `locale.${locale}`;
        return (
            this._lookup(key) ??
            this._store(key, new TZDate(this._date, false, this._timezone, locale))
        );
    }

    public diff(date?: TZDate | null, unit?: Parameters<Dayjs['diff']>[1]) {
        return this._date.diff(date?._date, unit);
    }

    public isBefore(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isBefore']>[1],
    ) {
        if (date === null && date === undefined) return this._date.isBefore();
        return this._date.isBefore(date instanceof TZDate ? date._date : date, unit);
    }

    public isAfter(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isAfter']>[1],
    ) {
        if (date === null && date === undefined) return this._date.isAfter();
        return this._date.isAfter(date instanceof TZDate ? date._date : date, unit);
    }

    public isSame(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isSame']>[1],
    ) {
        if (date === null && date === undefined) return true;
        return this._date.isSame(date instanceof TZDate ? date._date : date, unit);
    }

    public unix() {
        return this._date.unix();
    }

    /**
     * Format a date in the chosen timezone
     *
     * @param {string} [fmt] Identical to formats in Dayjs
     * @return {string} The formatted date string
     * @memberof TZDate
     */
    public format(fmt?: string) {
        return this._date.format(fmt);
    }

    /**
     * Get the internal Dayjs date in the timezone of the Bilberry instance
     *
     * @return {Dayjs} The internal Dayjs date
     * @memberof TZDate
     */
    public getDayjsDate() {
        return this._date;
    }

    /**
     * Return a small object containing an iso 8601 string with separate
     * attributes for timezone and locale. Ready to be stored in
     * localstorage and similar, recreated by providing the object
     * to the tzdate() function.
     *
     * @return {object} { value: iso-8601-string, timezone: string, locale: string }
     * @memberof TZDate
     */
    public toLocalStorage() {
        if (!this._cacheStorage) {
            this._cacheStorage = {
                $dayjs: {
                    value: this._date.toISOString(),
                    timezone: this._timezone,
                    locale: this._locale,
                },
            };
        }

        return this._cacheStorage;
    }

    public toString() {
        return this._date.toString();
    }

    public toISOString() {
        return this._date.toISOString();
    }

    public toDate() {
        return this._date.toDate();
    }

    public valueOf() {
        return this._date.valueOf();
    }

    public isValid() {
        return this._date.isValid();
    }

    /**
     * Returns a TZDate representing the current moment in time.
     * The timezone is moved to that of the Bilberry instance, but localtime is not kept.
     *
     * Use this to provide minimum dates / validations relevant for checking the current moment etc.
     *
     * @static
     * @return {TZDate} A TZDate representing right now.
     * @memberof TZDate
     */
    public static now() {
        return tzdate(null, false);
    }
}

type LocalStorage = ReturnType<TZDate['toLocalStorage']>;

type DayjsParams = Parameters<typeof dayjs>[0];

/**
 * This is the entry-point to the TZDate wrapper-class.
 * You should never initialize TZDate directly, so make sure to
 * do it through this function.
 *
 * @export
 * @param {(DayjsParams | TZDate | LocalStorage)} [arg]
 * @param {boolean} [keepLocalTime=true]
 * @return {*}
 */
export function tzdate(
    arg?: DayjsParams | TZDate | LocalStorage,
    keepLocalTime = true,
    tz?: string,
) {
    let storage: LocalStorage | null = null;

    if (
        arg &&
        typeof arg === 'object' &&
        Object.keys(arg as { $dayjs?: unknown }).includes('$dayjs')
    ) {
        storage = arg as LocalStorage;
    } else if (arg instanceof TZDate) {
        storage = arg.toLocalStorage();
    }

    if (storage) {
        const { value, timezone, locale } = storage.$dayjs;
        const date = new TZDate(value, false, timezone);

        return locale && date.locale() !== locale ? date.locale(locale) : date;
    }

    return new TZDate(arg as DayjsParams, keepLocalTime, tz);
}

function getTZDateLocale(locale: string) {
    // Norwegian bokmål
    const norwegianNbLocales = ['nb-NO', 'no-NO', 'no', 'nb'];

    // Norwegian nynorsk
    const norwegianNnLocales = ['nn-NO', 'nn'];

    if (locale === 'en-US') {
        return 'en';
    } else if (locale === 'en-CA') {
        return enCA;
    } else if (norwegianNbLocales.includes(locale)) {
        return nb;
    } else if (norwegianNnLocales.includes(locale)) {
        return nn;
    } else if (locale === 'de') {
        return de;
    } else if (locale === 'nl') {
        return nl;
    } else if (locale === 'es') {
        return es;
    } else if (locale === 'fr') {
        return fr;
    } else if (locale === 'it') {
        return it;
    }

    return enGB;
}
