import { Button, useTheme } from '@mui/material';
import { alpha, darken } from '@mui/material/styles';
import { getCustomOrDefaultText, useLocale } from '@repo/i18n';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';
import { UserTextCustomizations } from '@repo/types';

export default function BookNow(props: {
    size: 's' | 'm' | 'l';
    xsSize: 's' | 'm' | 'l';
    buttonTextCustomizationKey?: keyof UserTextCustomizations;
}): JSX.Element {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const configurations = useConfigurations();
    const [toggleBookingState] = useAtom(toggleBookingAtom);

    const handleBookNowClicked = () =>
        toggleBookingAtom.update({ visible: !toggleBookingState.visible, interaction: 'mouse' });
    const handleBookNowEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            toggleBookingAtom.update({
                visible: !toggleBookingState.visible,
                interaction: 'keyboard',
            });
        }
    };

    return (
        <Button
            variant={customizations.primaryButtonStyle}
            color="primary"
            onKeyDown={handleBookNowEnter}
            onMouseDown={handleBookNowClicked}
            sx={{
                color: customizations.bookingButtonTextColor,
                backgroundColor:
                    customizations.bookingButtonStyle === 'contained'
                        ? customizations.bookingButtonColor
                        : 'transparent',
                borderColor:
                    customizations.bookingButtonStyle === 'contained'
                        ? 'transparent'
                        : customizations.bookingButtonTextColor,
                transform:
                    props.size === 'l'
                        ? 'scale(1.75)'
                        : props.size === 'm'
                          ? 'scale(1.25)'
                          : 'scale(1)',

                [theme.breakpoints.down('sm')]: {
                    transform:
                        props.xsSize === 'l'
                            ? 'scale(1.75)'
                            : props.xsSize === 'm'
                              ? 'scale(1.25)'
                              : 'scale(1)',
                },
                boxShadow:
                    customizations.bookingButtonStyle === 'contained'
                        ? theme.shadows[1]
                        : undefined,
                '&:hover, &:focus, &:active': {
                    boxShadow:
                        customizations.bookingButtonStyle === 'contained'
                            ? theme.shadows[3]
                            : undefined,
                    color: customizations.bookingButtonTextColor,
                    borderColor:
                        customizations.bookingButtonStyle === 'contained'
                            ? 'transparent'
                            : customizations.bookingButtonTextColor,
                    backgroundColor:
                        customizations.bookingButtonStyle === 'contained'
                            ? darken(customizations.bookingButtonColor, 0.2)
                            : alpha(customizations.bookingButtonTextColor, 0.3),
                },
            }}
            aria-label={t.aria_book_now_button}
        >
            {capitalize(
                getCustomOrDefaultText(
                    configurations.textCustomizations,
                    props.buttonTextCustomizationKey,
                    locale,
                    t.book_now,
                ),
            )}
        </Button>
    );
}
