import { useCallback, useMemo, useState } from 'react';
import { initialDateRangeState } from 'src/components/common/date-range-picker/DateRangePicker';
import { BilberryLeadFormData, Translations } from '@repo/types';
import { MultipleNumberInputValueType } from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import {
    validateDateRange,
    validateEmail,
    validateTextField,
} from '@repo/common-utils/validation-helper';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { useLocale } from '@repo/i18n';
import { postLeadForm } from '@repo/widget-utils/services/fetchers/product';

function getLeadFormDateString(dayjsDate: TZDate | null, locale: string) {
    return dayjsDate ? dayjsDate.forceLocalTz().format('YYYY-MM-DD') : '';
}

export default function useLeadsState(hideRequestDate = false) {
    const { locale, t } = useLocale();
    const [quantities, setQuantities] = useState<MultipleNumberInputValueType[]>(
        getDefaultQuantities(t),
    );
    const [description, setDescription] = useState('');
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [dateRange, setDateRange] = useState<DateRange<TZDate>>(
        hideRequestDate ? [TZDate.now(), null] : initialDateRangeState,
    );
    const [dateRangeValid, setDateRangeValid] = useState(true);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const [dateRangeStart, dateRangeEnd] = dateRange;

    const onClickBook = useCallback(() => {
        const fn = async () => {
            const formValidation = validateFormData(dateRange, description, name, email);
            setDateRangeValid(formValidation.dateRange);
            setDescriptionValid(formValidation.description);
            setNameValid(formValidation.name);
            setEmailValid(formValidation.email);
            if (!formValidation.formValid) return;

            const title = document.title ?? window.location.href;

            const leadsFormData: BilberryLeadFormData = {
                title,
                name,
                email,
                description,
                phone: phone ? phone : undefined,
                group_size: quantities.reduce((acc, cur) => acc + cur.value, 0),
                start: getLeadFormDateString(dateRangeStart, locale),
                end: getLeadFormDateString(dateRangeEnd ?? dateRangeStart, locale),
            };

            try {
                await postLeadForm(leadsFormData);
            } catch (e) {
                setShowSubmitError(true);
            }

            setShowConfirm(true);
        };
        fn().then();
    }, [
        dateRange,
        description,
        name,
        email,
        phone,
        quantities,
        dateRangeStart,
        locale,
        dateRangeEnd,
    ]);

    const onClickGotIt = useCallback(() => {
        setQuantities(getDefaultQuantities(t));
        setDateRange(initialDateRangeState);
        setDescription('');
        setName('');
        setPhone('');
        setEmail('');
        setDateRangeValid(true);
        setDescriptionValid(true);
        setNameValid(true);
        setEmailValid(true);
        setShowConfirm(false);
        setShowSubmitError(false);
    }, [t]);

    const onChangeQuantities = useCallback(
        (id: number, value: number) => {
            setQuantities((prevState) => {
                const index = prevState.findIndex((x) => x.id === id);
                return [
                    ...quantities.slice(0, index),
                    { ...quantities[index], value },
                    ...quantities.slice(index + 1),
                ];
            });
        },
        [quantities],
    );

    const defaultQuantities = useMemo(() => getDefaultQuantities(t), [t]);

    return {
        quantities,
        defaultQuantities,
        onChangeQuantities,
        description,
        setDescription,
        descriptionValid,
        dateRange,
        setDateRange,
        dateRangeValid,
        name,
        setName,
        nameValid,
        phone,
        setPhone,
        email,
        setEmail,
        emailValid,
        showConfirm,
        showSubmitError,
        onClickBook,
        onClickGotIt,
    };
}

function getDefaultQuantities(t: Translations): MultipleNumberInputValueType[] {
    return [
        {
            name: t.adult.num,
            value: 1,
            id: 1,
        },
        {
            name: t.children.num,
            value: 0,
            id: 2,
        },
    ];
}

function validateFormData(
    dateRange: DateRange<TZDate>,
    description: string,
    name: string,
    email: string,
) {
    const res = {
        dateRange: validateDateRange(dateRange),
        description: validateTextField(description),
        name: validateTextField(name),
        email: validateEmail(email),
        formValid: true,
    };
    res.formValid = Object.values(res).every(Boolean);
    return res;
}
