import { Grid, Typography, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';
import { useLocale } from '@repo/i18n';
import { searchFormDataAtom } from 'src/state/search-form-data/searchFormDataAtom';
import { DifficultyEnum, ProductSearch } from '@repo/types';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { SearchForm } from 'src/widgets/activities/booking-search/search-form/SearchForm';
import { useAtom } from 'ximple';
import { SearchResult } from './search-result/SearchResult';
import { useProductSearch } from '@repo/widget-utils/services/hooks/product';

type IProps = {
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    hideDifficulty: boolean;
    hideDuration: boolean;
    hideQuantities: boolean;
    limitToProducts: string[];
    locations: string[];
    excludeProducts: string[];
};

export default function BookingSearch(props: IProps): JSX.Element {
    const { numElements } = props;
    const { t } = useLocale();
    const [storedSearchFormData, setStoredSearchFormData] = useAtom(searchFormDataAtom);
    const [searchFormData, setSearchFormData] = useState<ProductSearch | null>(
        storedSearchFormData,
    );
    const theme = useTheme();
    const {
        productCatalogsFiltered = [],
        isLoading,
        isError,
    } = useProductSearch(
        searchFormData
            ? {
                  ...searchFormData,
                  products: props.limitToProducts,
                  excludeProducts: props.excludeProducts,
              }
            : {
                  difficulty: DifficultyEnum.Any,
                  products: props.limitToProducts,
                  excludeProducts: props.excludeProducts,
              },
    );
    const { minDuration, maxDuration } = useMinMaxDurations(
        props.hideDuration,
        props.limitToProducts,
        props.excludeProducts,
    );

    useEffect(() => {
        setStoredSearchFormData(null);
    }, [setStoredSearchFormData]);

    const onClick = (formData: ProductSearch) => {
        setSearchFormData(formData);
    };

    const noResults = !isLoading && !isError && productCatalogsFiltered.length === 0;

    return (
        <Grid container direction="column">
            <SearchForm
                onClick={onClick}
                minDuration={minDuration}
                maxDuration={maxDuration}
                defaultFormData={searchFormData}
                hideDifficulty={props.hideDifficulty}
                hideDuration={props.hideDuration}
                hideQuantities={props.hideQuantities}
                locations={props.locations}
                products={props.limitToProducts}
                excludeProducts={props.excludeProducts}
            />
            {!noResults && (
                <SearchResult
                    products={productCatalogsFiltered}
                    numElements={numElements}
                    backgroundColors={props.backgroundColors}
                    textColors={props.textColors}
                    primaryColors={props.primaryColors}
                    primaryTextColors={props.primaryTextColors}
                    accentColors={props.accentColors}
                    accentTextColors={props.accentTextColors}
                />
            )}
            {noResults && (
                <Typography align="center" variant="h4" p={theme.spacing(6, 2)}>
                    {t.noSearchResults}
                </Typography>
            )}
        </Grid>
    );
}

function useMinMaxDurations(
    hideDuration: boolean,
    limitToProducts: string[],
    excludeProducts: string[],
) {
    const [minMaxDurations, setMinMaxDurations] = useState({ minDuration: -1, maxDuration: -1 });
    const { productCatalogsFiltered = [] } = useProductSearch(
        {
            products: limitToProducts,
            difficulty: DifficultyEnum.Any,
            excludeProducts,
        },
        hideDuration,
    );

    useEffect(() => {
        if (
            productCatalogsFiltered.length > 0 &&
            minMaxDurations.minDuration === -1 &&
            minMaxDurations.maxDuration === -1
        ) {
            const minMax = productCatalogsFiltered.reduce(
                (acc, cur) => {
                    acc.minDuration =
                        cur.duration < acc.minDuration ? cur.duration : acc.minDuration;
                    acc.maxDuration =
                        cur.duration > acc.maxDuration ? cur.duration : acc.maxDuration;
                    return acc;
                },
                { minDuration: Number.MAX_VALUE, maxDuration: -1 },
            );
            setMinMaxDurations(minMax);
        }
    }, [productCatalogsFiltered, minMaxDurations]);

    return minMaxDurations;
}
