import { Box, Grid, Skeleton, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SmallerTextInline } from 'src/components/common/smaller-text-inline/SmallerTextInline';
import { SummaryPriceLineData } from './summary-mapping';

export function SummaryPriceLine(props: { data: SummaryPriceLineData; typographySx?: SxProps }) {
    const theme = useTheme();

    const ticketDetailNode = props.data.ticketDetail ? (
        props.data.ticketDetailStrikeThrough ? (
            <s>{props.data.ticketDetail}</s>
        ) : (
            <>{props.data.ticketDetail}</>
        )
    ) : undefined;

    return (
        <Grid container justifyContent="space-between" wrap="nowrap" pt={theme.spacing(1)}>
            <Typography align="left" sx={props.typographySx}>
                <Stack component="span">
                    <Box component="span">
                        {props.data.ticketName}

                        {ticketDetailNode && (
                            <>
                                &nbsp;
                                <SmallerTextInline size="75%">
                                    ({ticketDetailNode})
                                </SmallerTextInline>
                            </>
                        )}
                    </Box>
                    {props.data.depositMessage && (
                        <SmallerTextInline size="75%" sx={{ fontStyle: 'italic' }}>
                            {props.data.depositMessage}
                        </SmallerTextInline>
                    )}
                </Stack>
            </Typography>
            <Typography align="right" sx={props.typographySx}>
                {props.data.priceText}
            </Typography>
        </Grid>
    );
}

export function SummaryPriceLineSkeleton() {
    const theme = useTheme();

    return (
        <Grid container justifyContent="space-between" wrap="nowrap" pt={theme.spacing(1)}>
            <Skeleton
                component="span"
                variant="text"
                width="100%"
                sx={{ backgroundColor: 'white', opacity: 0.3 }}
                animation="wave"
            ></Skeleton>
        </Grid>
    );
}
