import { useMemo, useState, useEffect } from 'react';
import { Atom, useAtom } from 'ximple';

// Utility-type to get all array elements except the first in an array-tuple.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AllExceptFirst<T extends any[]> = T extends [infer A, ...infer R] ? R : never;

export const useSelector = <T, S extends any[], R>(
    state: T,
    selector: (state: T, ...args: S) => R,
    ...args: AllExceptFirst<Parameters<typeof selector>>
) => {
    const [argsCache, setArgsCache] = useState(args);

    useEffect(() => {
        if (argsCache === args) return;

        if (args.length !== argsCache.length) {
            setArgsCache(args);
            return;
        }

        for (let i = 0; i < args.length; i++) {
            if (Object.is(args[i], argsCache[i])) continue;

            setArgsCache(args);
            break;
        }
    }, [args, argsCache]);

    return useMemo(
        () => selector(state, ...(argsCache as unknown as S)),
        [selector, state, argsCache],
    );
};

export const useAtomSelector = <T, A, S extends any[], R>(
    atom: Atom<T, A>,
    selector: (state: (typeof atom)['subject']['value'], ...args: S) => R,
    ...args: AllExceptFirst<Parameters<typeof selector>>
) => {
    const [state] = useAtom(atom);

    return useSelector(state, selector, ...args);
};
