import { useState, useMemo } from 'react';
import { Product } from '@repo/types';
import { useNextProductAvailability } from '@repo/widget-utils/services/hooks/product';
import { TZDate, tzdate } from '@repo/tzdate';

export default function useAvailabilitySearchPeriod(product: Product | null) {
    const { data: nextProductAvailability } = useNextProductAvailability(product?.id);

    const nextProductAvailabilityStart = useMemo(() => {
        return nextProductAvailability ? tzdate(nextProductAvailability.next) : undefined;
    }, [nextProductAvailability]);

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>(() => {
        let startDay: TZDate | null = null;
        let endDay: TZDate | null = null;

        if (nextProductAvailabilityStart) {
            startDay = nextProductAvailabilityStart.startOf('month');
            endDay = startDay.add(1, 'month');
        }

        return { startDay, endDay };
    });

    return {
        nextProductAvailabilityStart,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod,
    };
}
