import AccessTime from '@mui/icons-material/AccessTime';
import { Box, FormControlLabel, Grid, Radio, SxProps, Typography, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import RadioChecked from 'src/components/common/radio-button-icons/RadioChecked';
import RadioUnchecked from 'src/components/common/radio-button-icons/RadioUnchecked';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';

type Props = {
    icon: React.ReactNode;
    iconText: string;
    time?: string;
    description: string;
    isSelected: boolean;
    onSelect: (arg0: string) => any;
};

export default function GiftTypeSelectionEntry(props: Props): JSX.Element {
    const customization = useCustomizations();
    const theme = useTheme();
    const [hover, setHover] = useState(false);

    function handleExpandClick() {
        props.isSelected ? props.onSelect('') : props.onSelect(props.iconText);
    }

    const typographyStyles = (otherStyles?: {
        marginBottom?: string;
        fontSize?: string;
        marginRight?: string;
    }): SxProps => [
        props.isSelected && {
            color: theme.palette.secondary.contrastText,
        },
        !props.isSelected && {
            color: theme.palette.primary.main,
        },
        { ...otherStyles },
    ];

    return (
        <Box
            sx={[
                {
                    padding: theme.spacing(2, 0),
                    borderStyle: 'solid',
                    borderWidth: theme.spacing(0.2),
                    borderRadius: `${theme.shape.borderRadius}px`,
                    borderColor: theme.palette.grey[300],
                    marginBottom: theme.spacing(1),
                    '&:hover': {
                        borderColor: theme.palette.grey[500],
                    },
                    cursor: 'pointer',
                },
                props.isSelected
                    ? {
                          backgroundColor: theme.palette.primary.main,
                      }
                    : { backgroundColor: theme.palette.grey[50] },
            ]}
            onClick={handleExpandClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Grid container item direction="row" wrap="nowrap" alignItems="center">
                <Grid item>
                    <FormControlLabel
                        value={props.iconText}
                        label=""
                        sx={{ margin: '0px', color: theme.palette.background.paper }}
                        tabIndex={0}
                        onKeyDown={(e: React.KeyboardEvent) =>
                            e.key === 'Enter' && handleExpandClick()
                        }
                        control={
                            <Radio
                                tabIndex={-1}
                                checkedIcon={RadioChecked({
                                    color: customization.primaryColor,
                                })}
                                icon={RadioUnchecked({
                                    color: customization.primaryColor,
                                    hover: hover,
                                })}
                                checked={props.isSelected}
                            />
                        }
                    />
                </Grid>
                <Grid item mr={theme.spacing(2)}>
                    <Grid container direction="column" alignItems="center" width="75px">
                        {props.icon}
                        <Typography fontWeight="bold" sx={typographyStyles()}>
                            {props.iconText.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography
                        sx={typographyStyles({
                            marginBottom: theme.spacing(1),
                        })}
                    >
                        {capitalize(props.description)}
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                        {props.time && (
                            <Fragment>
                                <AccessTime
                                    sx={typographyStyles({
                                        fontSize: '0.85rem',
                                        marginRight: theme.spacing(0.5),
                                    })}
                                />
                                <Typography
                                    sx={typographyStyles({
                                        fontSize: '0.85rem',
                                        marginRight: theme.spacing(0.5),
                                    })}
                                >
                                    {props.time}
                                </Typography>
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
