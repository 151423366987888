import { ProductInstance, TicketOptionWithQuantity, Timeslot } from '@repo/types';
import { createNewCartItem } from '@repo/widget-utils/cart/cartUtils';
import { useMemo } from 'react';

export default function useNewCartItem(
    selectedProducts: ProductInstance[] | undefined,
    ticketOptions: TicketOptionWithQuantity[],
    timeslots: Timeslot[] | undefined,
    newItemDisablePaymentPlans: boolean,
    newItemRequiresPaymentPlans: boolean,
) {
    return useMemo(() => {
        const isMissingProducts = !selectedProducts || selectedProducts.length === 0;

        const isMissingTimeslots = timeslots && timeslots.length === 0;

        const isMissingQuantities =
            ticketOptions.length === 0 || ticketOptions.every((x) => x.quantity === 0);

        const missingSelectionsForCompleteCartItem =
            isMissingProducts || isMissingTimeslots || isMissingQuantities;

        return !missingSelectionsForCompleteCartItem
            ? createNewCartItem(
                  selectedProducts ?? [],
                  ticketOptions,
                  newItemDisablePaymentPlans,
                  newItemRequiresPaymentPlans,
                  timeslots,
              )
            : null;
    }, [
        selectedProducts,
        ticketOptions,
        timeslots,
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
    ]);
}
