import { useEffect, useMemo, useState } from 'react';
import { useLocaleAndConfigCacheBuster } from '@repo/widget-utils/services/hooks/product';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import useSWR from 'swr';
import {
    getAvailableInstancesForMultipleProducts,
    getAvailableProductsRequestAccommodations,
    getAccommodationProducts,
} from '@repo/widget-utils/services/fetchers/product';
import { fetcher } from '@repo/widget-utils/services/utils/api-client-common';
import { Product, ProductInstance } from '@repo/types';

export function useAvailabilities(
    type: 'visbook' | 'bilberry',
    ids: string[],
    dates: DateRange<TZDate>,
    bilberryProviderProducts: Product[],
) {
    const {
        data: accommodationAvailabilities,
        isLoading: isLoadingAccommodationAvailabilities,
        isValidating: isValidatingAccommodationAvailabilities,
    } = useVisbookAccommodationAvailabilities(type, dates, ids);

    const { data: bilberryAvailabilities, isLoading: isLoadingBilberryAvailabilities } =
        useBilberryAccommodationAvailabilities(dates, bilberryProviderProducts);

    return useMemo(
        () => ({
            data: type === 'visbook' ? accommodationAvailabilities : bilberryAvailabilities,
            isLoading:
                isLoadingAccommodationAvailabilities ||
                isLoadingBilberryAvailabilities ||
                isValidatingAccommodationAvailabilities,
        }),
        [
            bilberryAvailabilities,
            isLoadingBilberryAvailabilities,
            accommodationAvailabilities,
            isLoadingAccommodationAvailabilities,
            type,
            isValidatingAccommodationAvailabilities,
        ],
    );
}

function useBilberryAccommodationAvailabilities(
    dates: DateRange<TZDate>,
    bilberryProviderProducts: Product[],
) {
    const [allAvailabilities, setAllAvailabilities] = useState<ProductInstance[][] | null>(null);

    const cacheKey =
        (bilberryProviderProducts?.length ?? 0) > 0
            ? 'bilberry-availabilities' + dates[0]?.unix() + dates[1]?.unix()
            : null;

    const { data, isLoading, isValidating } = useSWR<ProductInstance[][]>(cacheKey, {
        fetcher: async () => {
            const allAvails = await getAvailableInstancesForMultipleProducts(
                bilberryProviderProducts,
                ...dates,
            );
            return allAvails;
        },
        revalidateOnFocus: false,
        keepPreviousData: true,
        dedupingInterval: 5 * 60 * 1000,
    });

    useEffect(() => {
        if (data) {
            setAllAvailabilities((prev) =>
                mergeAvailabilities(
                    prev,
                    data,
                    (existingInstances, newInstances) =>
                        existingInstances[0]?.product?.id === newInstances[0]?.product?.id,
                ),
            );
        }
    }, [data]);

    return useMemo(
        () => ({
            data: allAvailabilities !== null ? allAvailabilities : data,
            isLoading: isLoading || isValidating,
        }),
        [allAvailabilities, isLoading, isValidating, data],
    );
}

function useVisbookAccommodationAvailabilities(
    type: 'visbook' | 'bilberry',
    dates: DateRange<TZDate>,
    ids: string[],
) {
    const cacheBuster = useLocaleAndConfigCacheBuster();

    const [allAvailabilities, setAllAvailabilities] = useState<ProductInstance[][] | null>(null);

    const cacheKey =
        type === 'visbook' ? 'accommodation-availability-products' + cacheBuster : null;

    const {
        data: accommodations,
        error: accommodationsError,
        isLoading: accommodationsIsLoading,
        isValidating: accommodationsIsValidating,
    } = useSWR<ProductInstance[][][]>(cacheKey, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        keepPreviousData: true,
        fetcher: () => {
            return getAccommodationProducts(
                ids,
                [null, null],
                [{ adults: 0, children: [], id: 0 }],
            );
        },
    });

    const accommodationIds = useMemo(() => {
        return (
            accommodations?.flatMap((a) => a.flatMap((a2) => a2[0]?.product?.id ?? '')) ?? []
        ).join('-');
    }, [accommodations]);

    const { data, isLoading, isValidating, error } = useSWR<ProductInstance[][]>(
        type === 'visbook' && dates[0] && dates[1] && accommodations
            ? cacheBuster +
                  dates[0].format('YYYY-MM-DD') +
                  dates[1].format('YYYY-MM-DD') +
                  accommodationIds
            : null,
        {
            fetcher: async () => {
                const reqs =
                    accommodations
                        ?.flatMap((a) => a.map((a2) => a2[0]?.product))
                        .filter(Boolean)
                        .map((accommodation) =>
                            getAvailableProductsRequestAccommodations(
                                accommodation,
                                dates[0]?.subtract(1, 'day'),
                                dates[1],
                                1,
                            ),
                        ) ?? [];
                const all = await Promise.all(
                    reqs.map(async (req) => {
                        const data = await fetcher<any>(req.url, req.headers);
                        return req.mapper(data);
                    }),
                );
                return all;
            },
            revalidateOnFocus: false,
            keepPreviousData: true,
            dedupingInterval: 5 * 60 * 1000,
        },
    );

    useEffect(() => {
        if (data) {
            setAllAvailabilities((prev) =>
                mergeAvailabilities(prev, data, (existingInstances, newInstances) =>
                    existingInstances.some(
                        (existingInstance) =>
                            !!newInstances.find(
                                (newInstance) => newInstance.id === existingInstance.id,
                            ),
                    ),
                ),
            );
        }
    }, [setAllAvailabilities, data]);

    return useMemo(() => {
        return {
            data: allAvailabilities,
            isLoading: isLoading || accommodationsIsLoading,
            isValidating: isValidating || accommodationsIsValidating,
            error: error || accommodationsError,
        };
    }, [
        allAvailabilities,
        isLoading,
        accommodationsIsLoading,
        isValidating,
        accommodationsIsValidating,
        error,
        accommodationsError,
    ]);
}

function mergeAvailabilities(
    prev: ProductInstance[][] | null,
    data: ProductInstance[][],
    compareEqualityFunc: (a: ProductInstance[], b: ProductInstance[]) => boolean,
): ProductInstance[][] {
    if (!prev || prev.length === 0) return data;
    const all = prev.slice();
    data.forEach((d) => {
        const findExistingIdx = all.findIndex((a) => compareEqualityFunc(a, d));
        if (findExistingIdx === -1) {
            all.push(d);
        } else {
            const existing = all[findExistingIdx];
            const newOnes = d.filter(
                (newOne) => !existing.some((existingOne) => existingOne.id === newOne.id),
            );
            all[findExistingIdx] = existing.concat(newOnes);
        }
    });
    return all;
}
