import { route } from 'src/components/common/router/Router';
import Summary from 'src/components/domain/summary/Summary';
import { useLocale } from '@repo/i18n';

export default function BasketPopUp(props: { onCloseModal: () => void }): JSX.Element {
    const { t } = useLocale();

    const handleGoToCheckoutClicked = () => {
        props.onCloseModal();
        route('/checkout');
    };

    return (
        <Summary
            heading={t.your_cart}
            invertedColors={true}
            isCheckoutSummary={true}
            goToCheckout={handleGoToCheckoutClicked}
            onCloseModal={props.onCloseModal}
            hideGoPay={true}
            showShare
            activeTab={null}
        />
    );
}
