import { Stack, Typography } from '@mui/material';
import Calendar, { ActivityInCalendar } from './Calendar';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useAvailabilities } from '../hooks/availabilities';
import { BREAKPOINT_MD } from '../utils';
import { MultipleNumberInputValueType } from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useDebounce } from 'src/hooks/common/useDebounce';
import { Product, ProductInstance } from '@repo/types';
import { useMemo } from 'react';

export function Availabilities(props: {
    provider: 'visbook' | 'bilberry';
    ids: string[];
    selectedDateRange: DateRange<TZDate>;
    setSelectedDateRange: (value: DateRange<TZDate>) => void;
    guestsInRoom: MultipleNumberInputValueType[][];
    searchDateRange: DateRange<TZDate>;
    setSearchDateRange: (value: DateRange<TZDate>) => void;
    bilberryProviderProducts: Product[];
    minSearchDate?: TZDate;
}) {
    const {
        provider,
        ids,
        selectedDateRange,
        setSelectedDateRange,
        guestsInRoom,
        searchDateRange,
        setSearchDateRange,
        bilberryProviderProducts,
    } = props;

    const searchDateRangeDebounced = useDebounce(searchDateRange, 1000);

    const { data, isLoading } = useAvailabilities(
        provider,
        ids,
        searchDateRangeDebounced,
        bilberryProviderProducts,
    );

    const activitiesInCalendar = useMemo(() => {
        if (!data) return [];
        return data.flat(1).map(mapInstanceToActivityInCalendar);
    }, [data]);

    return (
        <Stack>
            <Typography variant="h3" m={0}>
                &nbsp;
            </Typography>
            <Typography
                pt={2}
                fontSize="calc(1rem * 0.85)"
                sx={{
                    display: 'none',
                    [`@container (width > ${BREAKPOINT_MD})`]: {
                        display: 'block',
                    },
                }}
            >
                &nbsp;
            </Typography>
            <Calendar
                isLoading={isLoading}
                minDate={props.minSearchDate}
                activities={activitiesInCalendar}
                value={selectedDateRange}
                onChange={setSelectedDateRange}
                guests={guestsInRoom}
                onChangeMonth={(value) => {
                    const date = value;
                    if (searchDateRange[0]?.isSame(date, 'month')) return;
                    const start = date.isBefore(TZDate.now()) ? TZDate.now() : date;
                    setSearchDateRange([start, date.add(1, 'month')]);
                }}
                expectPrice={provider === 'bilberry'}
            />
        </Stack>
    );
}

export function mapInstanceToActivityInCalendar(instance: ProductInstance): ActivityInCalendar {
    return {
        start: instance.start,
        id: instance.id,
        capacity: instance.capacity,
        personsPerUnit: instance.personsPerUnit,
        firstTicketOptionPrice: instance.ticketOptions[0]?.price ?? null, // We are assuming that the first ticket option is the cheapest?
    };
}
