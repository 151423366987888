import {
    Box,
    Button,
    capitalize,
    CircularProgress,
    darken,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';

import { useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';
import { Translations } from '@repo/types';
import { useCallback } from 'react';
import useValueCardUsageSummary from 'src/hooks/domain/cart/useValueCardUsageSummary';
import { sumBy } from 'lodash-es';

interface IProps {
    goPayClicked?: () => void;
    goPayClickedInvoice?: () => void;
    isMakingReservation?: boolean;
    goToCheckout?: () => void;
    hideGoPay: boolean;
    nextButtonText?: string;
    totalPrice: number;
    priceToPayLater: number;
    invertedColorsClass?: Record<string, unknown>;
    onCloseModal?: () => void;
    isConfirmationPage?: boolean;
    enableGoPay: boolean;
}

export default function SummaryPayment(props: IProps): JSX.Element {
    const { t } = useLocale();
    const {
        totalPrice,
        enableGoPay,
        isMakingReservation,
        goPayClicked,
        goPayClickedInvoice,
        priceToPayLater,
    } = props;
    const customizations = useCustomizations();
    const theme = useTheme();
    const valueCardUsageSummary = useValueCardUsageSummary();

    const rebookReturnUrl = findUrlQueryParam('rebookReturnUrl');

    const priceToPayNow = totalPrice - priceToPayLater;
    const creditsToPayNow = valueCardUsageSummary
        ? sumBy(
              Object.values(valueCardUsageSummary).flatMap((x) => x.usages),
              (x) => x.creditCost / 100,
          )
        : 0;

    return (
        <Grid container direction="column" justifyContent="space-between" wrap="nowrap">
            <Grid container item justifyContent={'flex-end'} sx={{ mt: 2 }}>
                <Grid item xs={10}>
                    <PriceToPayTable
                        totalPrice={totalPrice}
                        priceToPayLater={priceToPayLater}
                        priceToPayNow={priceToPayNow}
                        creditsToPayNow={creditsToPayNow}
                        invertedColorsClass={props.invertedColorsClass}
                    ></PriceToPayTable>
                </Grid>
            </Grid>

            {props.isConfirmationPage && (
                <Grid item>
                    <Button
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        onClick={() => window.print()}
                        className="no-print"
                    >
                        {t.print}
                    </Button>
                </Grid>
            )}

            {props.onCloseModal ? (
                <Grid container justifyContent="space-between" mt={theme.spacing(4)}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: customizations.bookingWidgetPrimaryColor,
                            backgroundColor: customizations.bookingWidgetColor,
                            border: `1px solid ${customizations.bookingWidgetPrimaryColor}`,
                            marginBottom: theme.spacing(1),
                            '&:hover': {
                                backgroundColor:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? darken(theme.palette.primary.main, 0.2)
                                        : 'rgba(0, 0, 0, 30%) ',
                            },

                            '& .MuiButton-label': {
                                color: customizations.bookingWidgetPrimaryColor,
                            },
                        }}
                        onClick={props.onCloseModal}
                    >
                        {t.continue_shopping}
                    </Button>
                    <Button
                        color="button"
                        sx={{
                            backgroundColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? `${customizations.bookingWidgetPrimaryColor} !important`
                                    : 'transparent !important',
                            color:
                                customizations.primaryButtonStyle === 'contained'
                                    ? `${customizations.bookingWidgetColor} !important`
                                    : `${customizations.bookingWidgetPrimaryColor} !important`,
                            marginBottom: theme.spacing(1),

                            borderColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? 'transparent'
                                    : customizations.bookingWidgetPrimaryColor,
                            '&:hover': {
                                backgroundColor:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? `${darken(
                                              customizations.bookingWidgetPrimaryColor,
                                              0.2,
                                          )} !important`
                                        : 'rgba(0, 0, 0, 30%) !important',
                                color:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? `${darken(
                                              customizations.bookingWidgetColor,
                                              0.2,
                                          )} !important`
                                        : undefined,
                            },
                            '&:focus': {
                                boxShadow: `0 0 0 2px ${customizations.bookingWidgetColor}, \
                                            0 0 0 3px ${customizations.bookingWidgetPrimaryColor} !important`,
                            },
                        }}
                        variant={customizations.primaryButtonStyle}
                        onClick={props.goToCheckout}
                    >
                        {capitalize(t.checkout)}
                    </Button>
                </Grid>
            ) : (
                !props.hideGoPay &&
                !props.isConfirmationPage && (
                    <Stack direction="row" justifyContent="flex-end" gap={2} mt={theme.spacing(4)}>
                        {goPayClickedInvoice && (
                            <Button
                                color="primary"
                                variant={customizations.primaryButtonStyle}
                                disabled={isMakingReservation || !enableGoPay}
                                onClick={goPayClickedInvoice}
                            >
                                {t.pay_with_invoice}
                            </Button>
                        )}
                        <Button
                            sx={{ minWidth: goPayClickedInvoice ? undefined : '50%' }}
                            color="primary"
                            variant={customizations.primaryButtonStyle}
                            disabled={isMakingReservation || !enableGoPay}
                            onClick={goPayClicked}
                        >
                            {buttonDisplay(t, props.nextButtonText, isMakingReservation)}
                        </Button>
                    </Stack>
                )
            )}
            {rebookReturnUrl && (
                <Grid item display="flex" justifyContent="end">
                    <Button
                        sx={{ mt: theme.spacing(2) }}
                        color="warning"
                        variant={customizations.primaryButtonStyle}
                        href={rebookReturnUrl}
                    >
                        {t.continueWithRebooking}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

function buttonDisplay(
    t: Translations,
    nextButtonText?: string,
    isMakingReservation?: boolean,
): JSX.Element {
    if (isMakingReservation) {
        return (
            <Box>
                <CircularProgress
                    color="primary"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                <span>
                    {t.next} - {nextButtonText ?? t.go_and_pay}
                </span>
            </Box>
        );
    } else {
        return (
            <Box>
                <span>
                    {t.next} - {nextButtonText ?? t.go_and_pay}
                </span>
            </Box>
        );
    }
}

function PriceToPayTable(props: {
    totalPrice: number;
    priceToPayLater: number;
    priceToPayNow: number;
    creditsToPayNow: number;
    invertedColorsClass?: Record<string, unknown>;
}) {
    const { t } = useLocale();
    const { locale } = useLocale();
    const [currency] = useAtom(currencyAtom);

    const formatPrice = useCallback(
        (price: number) => {
            return formatCurrencyNoDecimalsIfPossible(locale, currency, price);
        },
        [locale, currency],
    );

    return (
        <>
            <Table aria-label={t.aria_price_to_pay_summary}>
                <TableBody>
                    {props.priceToPayLater > 0 && (
                        <>
                            <PriceToPayTableRow
                                label={t.payNow.toUpperCase()}
                                value={formatPrice(props.priceToPayNow)}
                                invertedColorsClass={props.invertedColorsClass}
                            ></PriceToPayTableRow>
                            <PriceToPayTableRow
                                label={t.payLater.toUpperCase()}
                                value={formatPrice(props.priceToPayLater)}
                                invertedColorsClass={props.invertedColorsClass}
                            ></PriceToPayTableRow>
                        </>
                    )}

                    <PriceToPayTableRow
                        label={t.total.toUpperCase()}
                        value={formatPrice(props.totalPrice)}
                        invertedColorsClass={props.invertedColorsClass}
                    ></PriceToPayTableRow>

                    {props.creditsToPayNow > 0 && (
                        <PriceToPayTableRow
                            label={''}
                            value={`+ ${props.creditsToPayNow} ${t.credit.num(props.creditsToPayNow).toLowerCase()}`}
                            invertedColorsClass={props.invertedColorsClass}
                        ></PriceToPayTableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}

function PriceToPayTableRow(props: {
    label: string;
    value: string;
    invertedColorsClass?: Record<string, unknown>;
}) {
    const customizations = useCustomizations();

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" align="right" sx={{ p: 0, border: 'none' }}>
                    <Typography
                        variant="h6"
                        align="right"
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {props.label}
                    </Typography>
                </TableCell>
                <TableCell align="right" sx={{ p: 0, border: 'none' }}>
                    <Typography
                        variant="h4"
                        align="right"
                        fontSize={22}
                        fontWeight={customizations.boldFontWeight}
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {props.value}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
}
