import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { getCsvAttribute, getYesAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import { getCsvColorsAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import BookingSearch from 'src/widgets/activities/booking-search/BookingSearch';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { errorLog } from '@repo/common-utils/Logger';

class BilberryBookingSearch extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const colors = getCsvColorsAttributes(this);

        const numElements = getMediaQuerySizesFromAttributes(this);

        const hideDifficulty = getYesAttribute(this, 'hide-difficulty');
        const hideDuration = getYesAttribute(this, 'hide-duration');
        const hideQuantities = getYesAttribute(this, 'hide-quantities');
        const limitToProducts = getCsvAttribute(this, 'limit-to-products', true);
        let excludeProducts = getCsvAttribute(this, 'exclude-products', true);
        const locations = getCsvAttribute(this, 'locations', true);

        if (limitToProducts.length > 0 && excludeProducts.length > 0) {
            errorLog(
                // eslint-disable-next-line max-len
                'Both limit-to-products and exclude-products attributes are set. The exclude-products attribute will be ignored.',
            );
            excludeProducts = [];
        }

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BookingSearch
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    hideDifficulty={hideDifficulty}
                    hideDuration={hideDuration}
                    hideQuantities={hideQuantities}
                    limitToProducts={limitToProducts}
                    excludeProducts={excludeProducts}
                    locations={locations}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-search', BilberryBookingSearch);
}
