import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    useTheme,
    Paper,
} from '@mui/material';
import { Stack, SxProps } from '@mui/system';
import groupBy from 'lodash-es/groupBy';
import { useLocale } from '@repo/i18n';
import { PackageTicketOptionWithQuantity, ProductInstance } from '@repo/types';
import { ProductCard2Landscape } from 'src/components/domain/product-card-2/ProductCardLandscape';
import { TourSelectionBookingForm } from './TourSelectionBookingForm';
import { ExpandMoreRounded } from '@mui/icons-material';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';

type TourSelectionContainerProps = {
    products: ProductInstance[];
    selectedAvailabilities: ProductInstance[];
    setSelectedAvailabilities: (val: ProductInstance[]) => void;
    ticketOptionsWithTours: {
        ticketOption: PackageTicketOptionWithQuantity;
        tours: ProductInstance[];
    }[];
    initiallyExpanded?: boolean;
    sxAccordion?: SxProps;
};

export default function TourSelectionContainer({
    selectedAvailabilities,
    setSelectedAvailabilities,
    ticketOptionsWithTours,
    initiallyExpanded,
    sxAccordion,
}: TourSelectionContainerProps) {
    const theme = useTheme();
    const { t } = useLocale();
    const customizations = useCustomizations();
    const [expanded, setExpanded] = React.useState<boolean>(initiallyExpanded ?? false);

    const byTicketOptions = ticketOptionsWithTours.map(({ ticketOption, tours }) => {
        const accommodations = Object.values(
            groupBy(
                tours.filter((p) => p.product?.type === 'nights'),
                (p) => p.product?.pkgProductId ?? -1,
            ),
        )
            .map((tours) => tours.toSorted((a, b) => (a.start.isBefore(b.start) ? -1 : 1))) // sort products in one pkgProduct by start date
            .toSorted((a, b) => (a[0].start.isBefore(b[0].start) ? -1 : 1)); // sort pkgProducts by start date

        const activities = Object.values(
            groupBy(
                tours.filter((p) => p.product?.type !== 'nights'),
                (p) => p.product?.pkgProductId ?? -1,
            ),
        )
            .map((tours) => tours.toSorted((a, b) => (a.start.isBefore(b.start) ? -1 : 1))) // sort products in one pkgProduct by start date
            .toSorted((a, b) => (a[0].start.isBefore(b[0].start) ? -1 : 1)); // sort pkgProducts by start date
        return {
            ticketOption,
            accommodations,
            activities,
        };
    });

    return (
        <Stack
            gap={1}
            sx={{
                minWidth: '300px',
                width: '100%',
            }}
        >
            <CustomizationsContext.Provider
                value={{
                    ...customizations,
                    productCardColor: theme.palette.common.white,
                    productCardTextColor: theme.palette.common.black,
                    productCardPrimaryColor: theme.palette.primary.main,
                    productCardPrimaryColorContrast: theme.palette.primary.contrastText,
                }}
            >
                {byTicketOptions.map(({ ticketOption, accommodations, activities }) => (
                    <Accordion
                        key={ticketOption.id}
                        component={Paper}
                        variant="outlined"
                        sx={{
                            boxShadow: 'none',
                            backgroundColor: customizations.productCardColor,
                            color: customizations.productCardTextColor,
                            borderRadius: `${customizations.borderRadius}px !important`,
                            borderTopLeftRadius: `${customizations.borderRadius}px !important`,
                            borderTopRightRadius: `${customizations.borderRadius}px !important`,
                            p: 1,
                            '&:before': {
                                display: 'none',
                            },
                            ...sxAccordion,
                        }}
                        expanded={expanded}
                        onChange={(_, expanded) => setExpanded(expanded)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                            <Typography
                                variant="h5"
                                fontSize={20}
                                fontWeight={700}
                                lineHeight={1}
                                m={0}
                            >
                                {ticketOption.quantity}x {ticketOption.name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack gap={3}>
                                {accommodations.some((x) => x.length > 0) && (
                                    <Stack>
                                        <Typography variant="h5" component="h2">
                                            {t.accommodations}
                                        </Typography>
                                        <TourSelectionPanelList
                                            isAccommodation
                                            productInstancesByPkgProductId={accommodations}
                                            {...{
                                                selectedAvailabilities,
                                                setSelectedAvailabilities,
                                            }}
                                        />
                                    </Stack>
                                )}
                                {activities.some((x) => x.length > 0) && (
                                    <Stack>
                                        <Typography variant="h5" component="h2">
                                            {t.activities}
                                        </Typography>
                                        <TourSelectionPanelList
                                            productInstancesByPkgProductId={activities}
                                            {...{
                                                selectedAvailabilities,
                                                setSelectedAvailabilities,
                                            }}
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </CustomizationsContext.Provider>
        </Stack>
    );
}

function TourSelectionPanelList(
    props: {
        productInstancesByPkgProductId: ProductInstance[][];
        isAccommodation?: boolean;
    } & Omit<
        TourSelectionContainerProps,
        'products' | 'ticketOptionsWithTours' | 'initiallyExpanded'
    >,
) {
    const customizations = useCustomizations();
    const findProduct = (products: ProductInstance[]) =>
        props.selectedAvailabilities.find((sa) =>
            products.find(
                (a) =>
                    a.product?.pkgTicketOptionId === sa.product?.pkgTicketOptionId &&
                    a.product?.pkgProductId === sa.product?.pkgProductId &&
                    a.id === sa.id,
            ),
        );

    return (
        <>
            {props.productInstancesByPkgProductId.map((availableProducts) => {
                const productsByPkgTicketOptionId = Object.values(
                    groupBy(availableProducts, (p) => p.product?.pkgTicketOptionId ?? ''),
                );
                return (
                    <Stack spacing={1} key={availableProducts[0]?.product?.pkgProductId}>
                        {productsByPkgTicketOptionId.map((products) => {
                            const selectedProduct = findProduct(products);
                            if (!selectedProduct?.product?.pkgTicketOptionId) return null;
                            const availabilities = products.filter(
                                (x) =>
                                    x.product?.pkgProductId ===
                                        selectedProduct.product?.pkgProductId &&
                                    x.product?.pkgTicketOptionId &&
                                    selectedProduct.product?.pkgTicketOptionId,
                            );

                            return (
                                <ProductCard2Landscape
                                    key={products.map((p) => p.id).join('-')}
                                    product={products[0].product!}
                                    infoAndImageAsLandscape={true}
                                    hideIcons
                                    borderRadius={customizations.borderRadius}
                                    body={
                                        <Stack width="100%" gap={1}>
                                            <TourSelectionBookingForm
                                                availableProducts={availabilities}
                                                selectedProduct={selectedProduct}
                                                setSelectedProduct={(val) => {
                                                    if (
                                                        !selectedProduct.product
                                                            ?.pkgTicketOptionId ||
                                                        !selectedProduct.product.pkgProductId
                                                    )
                                                        return;
                                                    const index =
                                                        props.selectedAvailabilities.findIndex(
                                                            (sa) =>
                                                                sa.product?.id ===
                                                                    selectedProduct.product?.id &&
                                                                sa.product?.pkgTicketOptionId ===
                                                                    selectedProduct.product
                                                                        ?.pkgTicketOptionId &&
                                                                sa.product?.pkgProductId ===
                                                                    selectedProduct.product
                                                                        ?.pkgProductId,
                                                        );
                                                    const newAvailabilities =
                                                        props.selectedAvailabilities.slice();
                                                    if (index === -1) newAvailabilities.push(val);
                                                    else {
                                                        newAvailabilities.splice(index, 1, val);
                                                    }
                                                    props.setSelectedAvailabilities(
                                                        newAvailabilities,
                                                    );
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            );
                        })}
                    </Stack>
                );
            })}
        </>
    );
}
