import { useMemo } from 'react';
import { CreateValueCardUsageSummary } from '@repo/widget-utils/price-helper';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

export default function useValueCardUsageSummary() {
    const { valueCardsForUser } = useMemberContext();
    const { paymentPlanResponse } = useCartContext();

    const valueCardUsageSummary = useMemo(() => {
        if (!paymentPlanResponse) return undefined;

        return CreateValueCardUsageSummary(paymentPlanResponse, valueCardsForUser);
    }, [paymentPlanResponse, valueCardsForUser]);

    return valueCardUsageSummary;
}
