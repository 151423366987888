import { FormControl, InputLabel, TextField, useTheme } from '@mui/material';
import { TZDate } from '@repo/tzdate';
import { createRef, Fragment, useState } from 'react';
import { useLocale } from '@repo/i18n';
import { positionInputLabelAboveField } from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getInputCallbackProps, getInputLayoutProps } from './common/calendar-props';
import { EndIcon } from './subcomponents/EndIcon';
import { ProductInstance } from '@repo/types';
import PackageCalendarPopover from './subcomponents/PackageCalendarPopover';

interface IProps {
    hasChosenDate: boolean;
    attemptedBooking: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    selectedDate: TZDate | null;
    setSelectedDate(value: TZDate | null): void;
    minDate?: TZDate;
    id?: string;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    variant?: 'filled' | 'outlined';
    availabilityData: ProductInstance[];
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }) => void;
    border?: string;
}

export default function PackageCalendar(props: IProps): JSX.Element {
    const {
        hasChosenDate,
        setHasChosenDate,
        selectedDate,
        setSelectedDate,
        attemptedBooking,
        minDate = TZDate.now(),
        id = '',
        labelColor,
        backgroundColor,
        color,
        variant = 'filled',
        availabilityData,
        setAvailabilitySearchPeriod,
        border,
    } = props;

    const { t } = useLocale();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    const customizations = useCustomizations();
    const theme = useTheme();
    const refocusElement = createRef<HTMLInputElement>();

    const showErrorDate = attemptedBooking && !hasChosenDate;
    const selectedDateText = getSelectedDateText(selectedDate);

    const inputId = `bilberry-calendar-input-${id}`;

    const endIcon = (
        <EndIcon
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            showErrorDate={showErrorDate}
        />
    );

    return (
        <Fragment>
            <FormControl fullWidth>
                <InputLabel
                    id={`calendar-label-${id}`}
                    htmlFor={inputId}
                    sx={{
                        color: labelColor ?? customizations.bookingWidgetColorContrast,
                        ...positionInputLabelAboveField,
                    }}
                >
                    {capitalize(t.pick_a_date)}
                </InputLabel>
                <TextField
                    id={inputId}
                    inputRef={refocusElement}
                    error={showErrorDate}
                    helperText={showErrorDate ? capitalize(t.please_select_date) : ''}
                    value={hasChosenDate ? selectedDateText : t.select_date}
                    {...getInputLayoutProps(
                        t,
                        theme,
                        customizations,
                        showErrorDate,
                        endIcon,
                        backgroundColor,
                        color,
                        labelColor,
                        variant,
                        border,
                    )}
                    {...getInputCallbackProps(
                        hasChosenDate,
                        setHasChosenDate,
                        deselectTextInput,
                        setAnchorEl,
                    )}
                />
            </FormControl>
            <PackageCalendarPopover
                minDate={minDate}
                displayDate={selectedDate}
                anchorEl={anchorEl}
                updateDisplayDate={setSelectedDate}
                setAnchorEl={setAnchorEl}
                id={inputId}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                availabilityData={availabilityData}
            />
        </Fragment>
    );
}

function getSelectedDateText(date: TZDate | null) {
    if (date) {
        const dayString = date.forceLocalTz().format('L');
        return `${dayString}`;
    }

    return '';
}
