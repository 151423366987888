import { WarningRounded } from '@mui/icons-material';
import AddRounded from '@mui/icons-material/AddRounded';
import RemoveRounded from '@mui/icons-material/RemoveRounded';
import {
    alpha,
    getContrastRatio,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { darken } from '@mui/material/styles';
import { useEffect, useState, useId } from 'react';
import { useLocale } from '@repo/i18n';
import { bookingWidgetInputStyle } from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';

interface IProps {
    value: number;
    maxValue: number;
    minValue?: number;
    name: string;
    subText?: string;
    onChange(value: number): void;
    maxReached?: boolean;
    disabled?: boolean;
    error?: string;
    color?: string;
    backgroundColor?: string;
    buttonColor?: string;
}

export default function QuantityPicker(props: IProps): JSX.Element {
    const {
        value,
        maxValue,
        minValue = 0,
        error,
        onChange,
        name,
        subText,
        maxReached,
        disabled,
        color,
        backgroundColor,
        buttonColor,
    } = props;
    const theme = useTheme();
    const customizations = useCustomizations();
    const [focused, setFocused] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState(value);
    const id = useId();

    useEffect(() => {
        setTextFieldValue(value);
    }, [value]);

    const onIncreaseQuantity = () => {
        if (maxValue) value < maxValue && onChange(value + 1);
        else onChange(value + 1);
    };
    const onDecreaseQuantity = () => {
        value > 0 && onChange(value - 1);
    };

    const colors = bookingWidgetInputStyle(customizations, backgroundColor, color);
    const useBlackColor =
        getContrastRatio(colors.backgroundColor, '#000000') >
        getContrastRatio(colors.backgroundColor, '#ffffff');

    return (
        <Stack
            width="100%"
            py={1}
            pl={1.5}
            pr={1}
            boxSizing="border-box"
            borderBottom={`1px solid ${alpha(colors.color.split(' ')[0], 0.35)}`}
            sx={{
                '&:last-of-type': { borderBottom: 'none' },
                backgroundColor: disabled
                    ? alpha(useBlackColor ? '#000000' : '#ffffff', 0.25)
                    : undefined,
            }}
        >
            <Stack
                direction="row"
                flexWrap="nowrap"
                width="100%"
                sx={{
                    opacity: disabled ? 0.5 : 1,
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        direction: 'column',
                        m: 0,
                    }}
                >
                    <Typography sx={{ wordBreak: 'break-word' }} color={colors.color}>
                        {capitalize(name)}
                    </Typography>
                    {subText && (
                        <Typography
                            fontSize={14}
                            lineHeight={1}
                            pb={1}
                            color={colors.color}
                            fontStyle="italic"
                        >
                            {subText}
                        </Typography>
                    )}
                    {!!error && (
                        <Stack direction="row" width="100%" alignItems="flex-end" pb={1} gap={0.5}>
                            <WarningRounded sx={{ fill: colors.color, width: 16, height: 16 }} />
                            <Typography lineHeight={1} pb="1px" color={colors.color} fontSize={12}>
                                {error}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <Stack direction="row" flexWrap="nowrap" alignItems="center" gap={0.5} pl={2}>
                    <PlusMinusButton
                        colors={colors}
                        buttonColor={buttonColor}
                        onChangeQuantity={onDecreaseQuantity}
                        variant="minus"
                        name={name}
                        inputId={id}
                        disabled={disabled || value === 0 || value <= minValue}
                    />

                    <TextField
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                            'aria-label': name,
                        }}
                        id={id}
                        sx={{
                            transition: 'all 0.1s ease-in',
                            width: focused ? '4rem' : '2.5rem',
                            m: 0,
                            p: 0,
                            fontSize: theme.typography.body1.fontSize,
                            color: colors.color,
                            '& .MuiInputBase-root': {
                                backgroundColor: 'transparent !important',
                                borderRadius: 0,
                                p: '0px !important',
                                border: 'none !important',
                                boxShadow: 'none !important',
                                color: colors.color,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderBottom: `2px dotted ${colors.color}`,
                                    color: `${colors.color} !important`,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                display: 'none',
                                color: colors.color,
                                '&.Mui-focused': {
                                    display: 'block',
                                    opacity: 0,
                                    zIndex: -1,
                                    '& legend': {
                                        width: '0',
                                        zIndex: -1,
                                    },
                                },
                            },

                            '& .Mui-focused': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: `2px solid ${colors.color}`,
                                    borderRadius: `${theme.shape.borderRadius}px`,
                                },
                            },
                            borderRadius: 0,
                            '& input': {
                                border: 'none !important',
                                boxShadow: 'none !important',
                                textAlign: 'center',
                                p: '4px !important',
                                '&:disabled': {
                                    color: colors.color,
                                    WebkitTextFillColor: colors.color,
                                    opacity: 0.75,
                                },
                            },
                            boxShadow: 'none !important',
                        }}
                        type="phone"
                        value={focused ? textFieldValue : value}
                        onChange={(e) => {
                            const newValue = parseInt(e.currentTarget.value);
                            setTextFieldValue(Number.isNaN(newValue) ? 0 : newValue);
                        }}
                        onBlur={(e) => {
                            const newValue = parseInt(e.currentTarget.value);
                            const allowedForMax =
                                !maxValue || newValue <= maxValue || newValue < value;
                            const allowedForMin =
                                !minValue || newValue >= minValue || newValue > value;
                            if (allowedForMax && allowedForMin) onChange(newValue);
                            else setTextFieldValue(value);
                            setFocused(false);
                        }}
                        name={name}
                        margin="dense"
                        onFocus={(e) => {
                            setFocused(true);
                            e.currentTarget.select();
                        }}
                    />

                    <PlusMinusButton
                        colors={colors}
                        buttonColor={buttonColor}
                        onChangeQuantity={onIncreaseQuantity}
                        variant="plus"
                        name={name}
                        inputId={id}
                        disabled={disabled || maxReached || value >= maxValue}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

function PlusMinusButton(props: {
    colors: { color: string; backgroundColor: string };
    buttonColor?: string;
    onChangeQuantity: any;
    disabled: boolean;
    variant: 'minus' | 'plus';
    name: string;
    inputId: string;
}) {
    const { onChangeQuantity, disabled, variant, colors, buttonColor, name, inputId } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const iconStyles = {
        width: '1.5rem',
        height: '1.5rem',
        fontSize: '1.5rem',
        borderRadius: theme.shape.borderRadius > 0 ? 500 : 0,
        padding: theme.spacing(0.25),
        color: colors.backgroundColor,
        backgroundColor: buttonColor ?? colors.color,
        '&:hover': {
            '@media (hover: hover)': {
                backgroundColor: buttonColor ?? colors.color,
                color: darken(colors.backgroundColor, 0.15),
            },
        },
    };
    return (
        <IconButton
            color={'inherit'}
            aria-controls={inputId}
            aria-label={`${variant === 'minus' ? t.aria_decrease : t.aria_increase} ${name}`}
            disabled={disabled}
            onClick={(e) => {
                onChangeQuantity(e);
                if (e.pageX > 0 && e.pageY > 0) (e.currentTarget as HTMLButtonElement).blur();
            }}
            sx={{
                padding: theme.spacing(0.75),
                fontSize: 0,
                '&:disabled *': {
                    filter: 'opacity(0.5)',
                },
            }}
        >
            {variant === 'minus' ? (
                <RemoveRounded sx={iconStyles} />
            ) : (
                <AddRounded sx={iconStyles} />
            )}
        </IconButton>
    );
}
