import { TZDate, tzdate } from '@repo/tzdate';
import produce from 'immer';
import { postValueCardReservation } from '@repo/widget-utils/services/fetchers/membership';
import { Company, MembershipReserveResponse, MembershipUser } from '@repo/types';
import { atom, Subject } from 'ximple';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';

type ValueCardCategory = 'membership' | 'credits' | '';
export type ValueCardPurchase = {
    typeId: number;
    category: ValueCardCategory;
    membership: {
        id: number;
        dateTime: {
            date: string;
            time: string;
        };
    };
    credits: {
        id: number;
        name: string;
    };
    reservation?: MembershipReserveResponse;
};

export const initialValueCardAtom: ValueCardPurchase = {
    typeId: -1,
    category: '',
    membership: {
        id: -1,
        dateTime: {
            date: '',
            time: '',
        },
    },
    credits: {
        id: -1,
        name: '',
    },
    reservation: undefined,
};

type Actions =
    | { type: 'RESET' }
    | { type: 'INITIALIZE'; value: ValueCardPurchase }
    | { type: 'UPDATE_DATE'; value: Date | null }
    | { type: 'UPDATE_TIME'; value: ValueCardPurchase['membership']['dateTime']['time'] }
    | { type: 'UPDATE_MEMBERSHIP_TYPE'; value: ValueCardPurchase['membership']['id'] }
    | { type: 'UPDATE_CREDITS_TYPE'; value: ValueCardPurchase['credits']['id'] }
    | { type: 'UPDATE_CREDITS_NAME'; value: ValueCardPurchase['credits']['name'] }
    | { type: 'SET_MEMBERSHIP_CATEGORY' }
    | { type: 'SET_CREDITS_CATEGORY' }
    | { type: 'SET_RESERVATION'; value: ValueCardPurchase['reservation'] }
    | { type: 'CLEAR_RESERVATION' }
    | { type: 'CANCEL' };

const valueCardReducer = produce((draft: ValueCardPurchase, action: Actions) => {
    switch (action.type) {
        case 'RESET':
        case 'CANCEL':
            return {
                ...initialValueCardAtom,
            };
        case 'INITIALIZE':
            return action.value as ValueCardPurchase;
        case 'UPDATE_DATE':
            draft.membership.dateTime.date = (action.value as Date | null)?.toISOString() ?? '';
            break;
        case 'UPDATE_TIME':
            draft.membership.dateTime.time = action.value;
            break;
        case 'SET_MEMBERSHIP_CATEGORY':
            draft.category = 'membership';
            break;
        case 'SET_CREDITS_CATEGORY':
            draft.category = 'credits';
            break;
        case 'UPDATE_MEMBERSHIP_TYPE':
            draft.membership.id = action.value;
            break;
        case 'UPDATE_CREDITS_TYPE':
            draft.credits.id = action.value;
            break;
        case 'UPDATE_CREDITS_NAME':
            draft.credits.name = action.value;
            break;
        case 'SET_RESERVATION':
            draft.reservation = action.value;
            break;
        case 'CLEAR_RESERVATION':
            draft.reservation = undefined;
            break;
        default:
            break;
    }
});

export const valueCardActionLog$ = new Subject<{
    state: ValueCardPurchase;
    newState: ValueCardPurchase;
    action: Actions;
}>();

function updateValueCard(state: ValueCardPurchase, action: Actions) {
    const newState = valueCardReducer(state, action);
    valueCardActionLog$.next({ state, newState, action });
    return newState;
}

export const valueCardAtom = atom<ValueCardPurchase, Actions>({
    initialValue: initialValueCardAtom,
    persistKey: 'no.bilberry-timeslots.value-card-checkout',
    update: updateValueCard,
});

export function getSelectedDateText(dateTime: { date: string; time: string }) {
    const timeString = dateTime.time ? ', ' + dateTime.time : '';
    const displayDate = dateTime.date !== '' ? tzdate(dateTime.date).format('ll') : '';
    return displayDate + ' ' + timeString;
}

export async function createValueCardReservation(
    valueCard: ValueCardPurchase,
    consumer: MembershipUser,
    company: Company,
    giftcardReferences: string[],
    promoCodeReference: string | null,
) {
    const cardValueTypeId =
        valueCard.membership.id === -1 ? valueCard.credits.id : valueCard.membership.id;

    if (cardValueTypeId === -1) return;

    const campaignId = company.company.valueCardProducts.find((x) => x.id === cardValueTypeId)
        ?.campaign?.id;

    // Set date automatically for credit value cards
    const creditsDate =
        configurationAtom.subject.value.openingDate &&
        tzdate(configurationAtom.subject.value.openingDate).isAfter(TZDate.now())
            ? tzdate(configurationAtom.subject.value.openingDate)
            : TZDate.now();

    return postValueCardReservation(
        consumer,
        cardValueTypeId,
        company.currentSite.key,
        window.location.origin + window.location.pathname,
        giftcardReferences,
        promoCodeReference,
        valueCard.credits.id !== -1
            ? creditsDate.format('YYYY-MM-DD')
            : valueCard.membership.dateTime.date,
        campaignId,
    );
}

export function isValueCardPurchaseValid() {
    const { category, membership, credits } = valueCardAtom.subject.value;

    if (category === 'membership') {
        if (typeof membership.id === 'string') {
            return membership.id !== '';
        }
        return membership.id >= 0;
    } else return credits.id >= 0;
}
