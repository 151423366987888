import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import {
    Box,
    Button,
    darken,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Popover,
    Slider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { createRef, Fragment, useContext, useState } from 'react';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { capitalize, deselectTextInput } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { zIndex } from 'src/utils/widget/zIndex';

export type MarkType = {
    label: string;
    labelValue: string;
    value: number;
    minuteValue: number;
    compareValue: number;
};

export type DurationType = { min?: number | null; max?: number | null };

type IProps = {
    value: DurationType;
    onChange(minutes: DurationType): void;
    marks: MarkType[];
    label: string;
    backgroundColor?: string;
    textColor?: string;
    showError?: boolean;
    required?: boolean;
};

export function DurationSlider(props: IProps) {
    const { value, onChange, label, showError = false, required = false, marks, textColor } = props;
    const { popover } = useContext(MountPointContext);
    const { t } = useLocale();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const customizations = useCustomizations();
    const theme = useTheme();
    const popoverRef = createRef<HTMLDivElement>();
    const refocusElement = createRef<HTMLInputElement>();
    const trap = useFocusTrap(popoverRef, !!anchorEl, '#bilberry-duration-slider-input');
    const marksWithoutLabel = marks.map((x) => ({
        ...x,
        label: '',
    }));
    const inputValue = getInputValue(marks, value);

    const onClose = () => {
        if (trap) trap.deactivate();
        setAnchorEl(null);
    };

    function onChangeDuration(_event: Event, value: number | number[]) {
        if (!(value instanceof Array)) return;
        const minValue = marks.find((x) => x.value === value[0])?.minuteValue ?? null;
        const maxValue = marks.find((x) => x.value === value[1])?.minuteValue ?? null;

        const min = minValue === -1 ? -1 : minValue;
        const max = maxValue === Number.MAX_VALUE ? Number.MAX_VALUE : maxValue;
        onChange({ min, max });
    }

    return (
        <Fragment>
            <FormControl fullWidth>
                <InputLabel
                    id="duration-slider-label"
                    sx={{
                        ...TextFieldStyleUtils.positionInputLabelAboveField,
                        color: textColor,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {label}
                </InputLabel>
                <TextField
                    inputRef={refocusElement}
                    id="bilberry-duration-slider-input"
                    error={showError}
                    helperText={
                        showError ? capitalize(t.please_fill_in_all_required_information) : ''
                    }
                    required={required}
                    sx={{
                        '& .MuiFilledInput-root.Mui-focused': {
                            borderColor: alpha(customizations.secondaryColor, 0.5),
                            boxShadow: `${darken(
                                theme.palette.primary.contrastText,
                                0.25,
                            )} 0 0 0 0.2rem`,
                        },
                        '& .MuiFilledInput-input': {
                            cursor: 'pointer',
                        },
                        '& .MuiInputBase-root': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.currentTarget instanceof HTMLElement && e.key === 'Enter')
                            setAnchorEl(e.currentTarget);
                    }}
                    onFocus={deselectTextInput}
                    variant="filled"
                    margin="dense"
                    size="small"
                    value={getRangeText(marks, inputValue)}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <AccessAlarmsRoundedIcon
                                    sx={[
                                        showError && {
                                            color: theme.palette.error.main,
                                            '& > *': {
                                                color: theme.palette.error.main,
                                            },
                                        },
                                    ]}
                                />
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        'aria-labelledby': 'duration-slider-label',
                    }}
                />
            </FormControl>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                sx={{
                    zIndex: `${zIndex.alwaysVisible} !important` as any,
                    '& > .MuiPaper-root': {
                        maxWidth: 325,
                        width: '100%',
                        padding: theme.spacing(2),
                    },
                }}
                container={popover}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                onClose={onClose}
                disableEnforceFocus={true}
                ref={popoverRef}
            >
                <Box p={theme.spacing(1, 1, 3, 1)}>
                    <Typography variant="h6" align="center" pb={theme.spacing(2)}>
                        {getRangeText(marks, inputValue)}
                    </Typography>
                    <Slider
                        value={inputValue}
                        marks={marksWithoutLabel}
                        onChange={onChangeDuration}
                        getAriaValueText={valueText.bind(null, marksWithoutLabel)}
                        aria-labelledby="range-slider"
                        step={1}
                        valueLabelDisplay="off"
                        min={0}
                        max={marks.length - 1}
                        size="small"
                    />
                    <Grid container justifyContent="space-between">
                        {getRangeLabels(marks, {
                            fontSize: '0.85rem',
                            color: theme.palette.grey[600],
                            marginTop: theme.spacing(-1),
                        })}
                    </Grid>
                </Box>
                <Grid container justifyContent="center">
                    <Button
                        sx={{
                            minWidth: 100,
                            '& button:hover': {
                                backgroundColor: () =>
                                    customizations.primaryButtonStyle === 'contained'
                                        ? darken(theme.palette.primary.main, 0.2)
                                        : 'rgba(0, 0, 0, 30%)',
                            },
                        }}
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        onClick={onClose}
                        role={capitalize(t.select_date)}
                    >
                        {t.ok}
                    </Button>
                </Grid>
            </Popover>
        </Fragment>
    );
}

function getInputValue(marks: MarkType[], value: DurationType) {
    const minValue = marks.find((x) => x.minuteValue === value.min);
    const maxValue = marks.find((x) => x.minuteValue === value.max);
    return [minValue?.value ?? 0, maxValue?.value ?? marks.length - 1];
}

function valueText(marks: MarkType[], value: number) {
    const mark = marks.find((x) => x.value === value);
    return `${mark?.labelValue} ${mark?.label}`;
}

function getRangeLabels(marks: MarkType[], sx: Record<string, string>) {
    const [start, end] = [0, marks.length - 1];
    const mark1 = marks[start];
    const mark2 = marks[end];

    return (
        <Fragment>
            <Typography sx={sx}>
                {mark1?.labelValue} {mark1?.label}
            </Typography>
            <Typography sx={sx}>
                {mark2?.labelValue} {mark2?.label}
            </Typography>
        </Fragment>
    );
}

function getRangeText(marks: MarkType[], value: number[] | null) {
    const [start, end] = value ?? [0, marks.length - 1];
    const mark1 = marks[start];
    const mark2 = marks[end];

    if (!mark1 || !mark2) return '';

    if (mark1.value === mark2.value) return `${mark2.labelValue} ${mark2.label}`;
    if (mark1.labelValue === '0') return `0 - ${mark2.labelValue} ${mark2.label}`;
    if (mark2.label.includes(mark1.label))
        return `${mark1.labelValue} - ${mark2.labelValue} ${mark2.label}`;
    return `${mark1.labelValue} ${mark1.label} - ${mark2.labelValue} ${mark2.label}`;
}
