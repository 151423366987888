import {
    MembershipPaymentPlan,
    Product,
    ProductInstance,
    TicketOptionWithQuantity,
    VatBreakdown,
} from '@repo/types';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { useMemo } from 'react';
import { useBookingContext } from 'src/widgets/BookingContext';

export default function usePriceQuantities(
    product: Product | null,
    selectedProducts: ProductInstance[] | undefined,
    quantities: TicketOptionWithQuantity[],
) {
    const { cartItems, newCartItem } = useBookingContext();

    const newCartItemFromContext = useMemo(() => {
        const newCartItemFromContext = newCartItem
            ? cartItems.find((item) => getCartItemId(item) === getCartItemId(newCartItem))
            : null;

        return newCartItemFromContext;
    }, [cartItems, newCartItem]);

    const paymentPlan = useMemo(() => {
        if (!newCartItemFromContext || !quantities || !selectedProducts) return undefined;

        const foundPaymentPlans = newCartItemFromContext.ticketOptions.flatMap(
            (to) => to.membership ?? [],
        );
        return foundPaymentPlans.length > 0 ? foundPaymentPlans : undefined;
    }, [newCartItemFromContext, quantities, selectedProducts]);

    return useMemo(() => {
        if (!selectedProducts) return [];

        if (selectedProducts.length < 2) {
            return mapForZeroOrOne(quantities, selectedProducts, paymentPlan);
        } else if (paymentPlan && paymentPlan.length > 0) {
            return mapForPaymentPlan(quantities, selectedProducts, paymentPlan);
        }

        return mapForMultipleWithoutPaymentPlan(quantities, selectedProducts, product);
    }, [paymentPlan, quantities, selectedProducts, product]);
}

function findProductInstancesForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    selectedProducts: ProductInstance[],
) {
    return selectedProducts.flatMap((p) =>
        p.ticketOptions
            .filter((to) => to.ticketCategoryId === ticketOption.ticketCategoryId)
            .flatMap((to) => to.productInstances),
    );
}

function findPaymentPlanForTicketOption(
    ticketOption: TicketOptionWithQuantity,
    paymentPlan: MembershipPaymentPlan[] | undefined,
) {
    return paymentPlan?.filter(
        (p) =>
            p.ticket.defaultTicketOptionId.toString() === ticketOption.defaultId ||
            p.ticket.defaultTicketOptionId.toString() === ticketOption.id,
    );
}

function findPaymentPlanForTicketOptionWithPaymentPlanAndSelectedProducts(
    ticketOption: TicketOptionWithQuantity,
    paymentPlan: MembershipPaymentPlan[],
    selectedProducts: ProductInstance[],
) {
    return paymentPlan.filter(
        (p) =>
            selectedProducts
                .flatMap((p) => p.ticketOptions)
                .find((to) => to.id === p.ticket.defaultTicketOptionId.toString())?.defaultId ===
            ticketOption.defaultId,
    );
}

function mapForZeroOrOne(
    quantities: TicketOptionWithQuantity[],
    selectedProducts: ProductInstance[],
    paymentPlan: MembershipPaymentPlan[] | undefined,
) {
    return quantities
        .map((ticketOption) => ({
            ...ticketOption,
            // Extract the product instances from the matching ticket option,
            // in case the ticket options are merged from multiple products (using projectColletionId)
            // and we need to link the correct product instances to the ticket option
            productInstances: findProductInstancesForTicketOption(ticketOption, selectedProducts),
            membership: findPaymentPlanForTicketOption(ticketOption, paymentPlan),
        }))
        .filter((ticketOption) => ticketOption.productInstances.length > 0);
}

function mapForPaymentPlan(
    quantities: TicketOptionWithQuantity[],
    selectedProducts: ProductInstance[],
    paymentPlan: MembershipPaymentPlan[],
) {
    return quantities.map((ticketOption) => ({
        ...ticketOption,
        // Extract the product instances from the matching ticket option,
        // in case the ticket options are merged from multiple products (using projectColletionId)
        // and we need to link the correct product instances to the ticket option
        productInstances: findProductInstancesForTicketOption(ticketOption, selectedProducts),
        membership: findPaymentPlanForTicketOptionWithPaymentPlanAndSelectedProducts(
            ticketOption,
            paymentPlan,
            selectedProducts,
        ),
    }));
}

function mapForMultipleWithoutPaymentPlan(
    quantities: TicketOptionWithQuantity[],
    selectedProducts: ProductInstance[],
    product: Product | null,
) {
    const applicableProducts =
        product?.type === 'nights'
            ? selectedProducts.slice(0, selectedProducts.length - 1)
            : selectedProducts;

    let newQuantities = quantities.slice();
    if (product?.type === 'accommodation') {
        newQuantities = [{ ...quantities[0], quantity: 1 }];
    }

    return newQuantities
        .map((q): TicketOptionWithQuantity => {
            return {
                ...q,
                productInstances: selectedProducts.flatMap((p) =>
                    p.ticketOptions
                        .filter((to) => to.ticketCategoryId === q.ticketCategoryId)
                        .flatMap((to) => to.productInstances),
                ),
                ...applicableProducts.reduce(
                    (acc, cur) => {
                        const ticketOption = cur.ticketOptions.find(
                            (to) => to.ticketCategoryId === q.ticketCategoryId,
                        );
                        acc.price += ticketOption?.price ?? 0;
                        acc.vatAmount += ticketOption?.vatAmount ?? 0;
                        acc.vatBreakdown =
                            ticketOption?.vatBreakdown.reduce((vbAcc, vbCur) => {
                                const matchingRateIdx = vbAcc.findIndex(
                                    (x) => x.rate === vbCur.rate,
                                );
                                if (matchingRateIdx === -1) {
                                    vbAcc.push(vbCur);
                                    return vbAcc;
                                } else {
                                    return [
                                        ...vbAcc.slice(0, matchingRateIdx),
                                        {
                                            ...vbAcc[matchingRateIdx],
                                            amount: vbAcc[matchingRateIdx].amount + vbCur.amount,
                                            vatAmount:
                                                vbAcc[matchingRateIdx].vatAmount + vbCur.vatAmount,
                                        },
                                        ...vbAcc.slice(matchingRateIdx + 1),
                                    ];
                                }
                            }, acc.vatBreakdown) ?? acc.vatBreakdown;
                        return acc;
                    },
                    {
                        price: 0,
                        vatAmount: 0,
                        vatBreakdown: [] as VatBreakdown[],
                    },
                ),
            };
        })
        .filter((q) => q.productInstances.length > 0);
}
