import { alpha, Box, Grid, Typography, useTheme } from '@mui/material';

import { TicketOptionWithQuantity, TicketType } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { SummaryInfoFromQuantities } from '../../summary/SummaryInfoFromQuantities';

export interface IProps {
    header: string;
    quantities: TicketOptionWithQuantity[];
    ticketType?: TicketType;
    numPrices: number;
    isLoadingPrices: boolean;
}

export function CostSummary(props: IProps): JSX.Element {
    const { quantities, header, numPrices, ticketType } = props;
    const { bookingWidgetColorContrast } = useCustomizations();
    const theme = useTheme();
    const numPaddedRows = Math.max(0, numPrices - quantities.filter((q) => q.quantity > 0).length);
    const customizations = useCustomizations();

    return (
        <Box>
            <Typography
                variant="h6"
                color={bookingWidgetColorContrast}
                mt={theme.spacing(2)}
                width={'100%'}
                borderBottom="1px solid"
                borderColor={alpha(bookingWidgetColorContrast, 0.2)}
            >
                {header}
            </Typography>
            <Grid mb={theme.spacing(3)} minHeight={`calc(2rem + ${theme.spacing(1)})`}>
                <SummaryInfoFromQuantities
                    quantities={quantities}
                    ticketType={ticketType}
                    invertedColorsClass={{
                        color: customizations.bookingWidgetColorContrast,
                    }}
                    isDetailed={false}
                    isLoadingPrices={props.isLoadingPrices}
                />
                {new Array(numPaddedRows).fill(false).map((_x, i) => (
                    <Grid container justifyContent="space-between" key={i} pt={theme.spacing(1)}>
                        <Typography>&nbsp;</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
