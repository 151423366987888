import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { useEffect, useMemo, useState } from 'react';
import { localeAtom } from '@repo/i18n';
import {
    BilberrySettings,
    BilberryPromoCodeStatus,
    GuestInfo,
    Product,
    Package,
    Timeslot,
    ProductInstance,
    ProductSearch,
    BilberryNextProductAvailability,
} from '@repo/types';
import useSWR, { SWRConfiguration } from 'swr';
import { useAtom } from 'ximple';
import { groupProductInstancesByResourceCollectionId } from '../../../ProductMapper';
import { configurationAtom } from '../../../widgetsConfiguration';
import { fetcher, getRequest } from '../../utils/api-client-common';
import { getDefaultHotelRequest, hotelDateParams } from '../../utils/bilberry-hotels-api-helpers';
import {
    getProduct,
    getProducts,
    getAvailableProducts,
    getUpcomingTours,
    getUpcomingToursRequest,
    getProductInstanceByIds,
    getSmartEvent,
    getSmartEvents,
    getProductSearch,
    getProductSearchRequest,
    getAvailablePackageProducts,
    getPackageAvailability,
    getAvailableTimeslots,
    getPackageProduct,
    getPackageProducts,
    getBilberrySettings,
    getPromoCode,
    getAccommodationProducts,
    getNextProductAvailabilityByProductIds,
    getNextProductAvailability,
} from '../../fetchers/product';
import { sortBy } from 'lodash-es';

export function useLocaleAndConfigCacheBuster() {
    const [{ locale }] = useAtom(localeAtom);
    const [config] = useAtom(configurationAtom);
    return (
        locale +
        (config.siteKey ?? '') +
        config.timezone +
        config.companyKey +
        config.bilberryBaseApiUrl +
        config.bilberryAccessToken
    );
}

export function useProduct(
    id: string | undefined,
    type?: 'accommodation' | 'timeslot',
    dateRange?: DateRange<TZDate> | DateRange<null>,
    guests?: number,
) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const url =
        '/api/products/' +
        id +
        type +
        dateRange?.[0]?.toISOString() +
        dateRange?.[1]?.toISOString() +
        guests +
        localeAndConfigCacheBuster;

    const { data, isLoading, error } = useSWR<Product | null>(
        id ? url : null,
        () => getProduct(id, type, dateRange, guests),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
            fetcher,
        },
    );

    return {
        data: data ?? null,
        isLoading,
        error,
    };
}

export function useProducts({
    ids,
    collectionId,
    isTimeslots,
}: {
    ids: string[];
    collectionId?: string;
    isTimeslots?: boolean;
}) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey =
        '/api/products/' + ids?.join('') + collectionId + isTimeslots + localeAndConfigCacheBuster;
    const { data, isLoading, error } = useSWR<Product[]>(
        ids.length > 0 || collectionId ? cacheKey : null,
        async () => getProducts(ids, collectionId, isTimeslots),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        data: data ?? [],
        isLoading,
        error,
    };
}

export function useAvailabilities(product: Product | null, from: TZDate | null, to: TZDate | null) {
    const [availabilities, setAvailabilities] = useState<ProductInstance[]>([]);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = product
        ? `/api/products/${
              product.id + product.type + product.title
          }/availability?${from?.toISOString()}${to?.toISOString()}${localeAndConfigCacheBuster}`
        : null;
    const { data, isLoading, error } = useSWR<ProductInstance[]>(
        cacheKey,
        () => getAvailableProducts(product, from, to),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
            refreshInterval: 0,
            revalidateIfStale: false,
            // Only do a simple strict equal when comparing, since the mapped value is too big/recursive and
            // can't use the default deep-equal check done by SWR (throws errors because of size).
            compare: (a, b) => a === b,
        },
    );

    useEffect(() => {
        if (data) {
            setAvailabilities((prev) => {
                const newArr = [...prev];
                for (const product of data) {
                    const index = newArr.findIndex((x) => x.id === product.id);
                    if (index !== -1) {
                        newArr[index] = product;
                    } else {
                        newArr.push(product);
                    }
                }
                const grouped = groupProductInstancesByResourceCollectionId(newArr);
                return sortBy(grouped, (x) => x.start);
            });
        }
    }, [data]);

    return {
        data: availabilities,
        isError: error,
        isLoading: isLoading,
    };
}

export function useAvailableTimeslots(
    productInstance?: ProductInstance,
    config: SWRConfiguration = {},
) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = `/api/v2/membership/projects/${productInstance?.id}/timeslots${localeAndConfigCacheBuster}`;

    const { data, error, isLoading } = useSWR<Timeslot[] | undefined>(
        productInstance ? cacheKey : null,
        () => getAvailableTimeslots(productInstance),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            ...config,
        },
    );

    return {
        data,
        error,
        isLoading,
    };
}

export function usePackageAvailability(
    pkg: Package | null,
    from: TZDate | null,
    to: TZDate | null,
    onSuccess?: (data: ProductInstance[]) => void,
) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = pkg
        ? `/api/packages/${
              pkg.id
          }/availability-multi${localeAndConfigCacheBuster}${from?.toISOString()}${to?.toISOString()}`
        : '';
    const { data, error, isLoading } = useSWR<ProductInstance[]>(
        cacheKey,
        () => getPackageAvailability(pkg, from, to),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
            onSuccess,
        },
    );

    return {
        data,
        error,
        isLoading,
    };
}

export function useAvailablePackageProducts(pkg: Package | null, date: TZDate | null) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = pkg
        ? 'available-package-products' + pkg.id + date?.toISOString() + localeAndConfigCacheBuster
        : null;
    const { data, error, isLoading } = useSWR<ProductInstance[]>(
        cacheKey,
        () => getAvailablePackageProducts(pkg, date),
        {
            shouldRetryOnError: false,
        },
    );

    return {
        data: data ?? [],
        isError: error,
        isLoading: isLoading,
    };
}

export function useProductSearch(filters: ProductSearch | null, doNotRun = false) {
    const { url } = getProductSearchRequest(filters);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();

    const { data, error } = useSWR<Product[]>(
        doNotRun ? null : url + localeAndConfigCacheBuster,
        () => getProductSearch(filters),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        productCatalogsFiltered: data,
        isError: error,
        isLoading: !error && !data,
    };
}

export function useSmartEvent(eventId: number) {
    const [locale] = useAtom(localeAtom);
    const [config] = useAtom(configurationAtom);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const { url } = getRequest(
        `/api/webevents/${eventId}${localeAndConfigCacheBuster}`,
        locale.locale,
        config,
    );

    const { data, error, isLoading } = useSWR(
        eventId ? url : null,
        async () => getSmartEvent(eventId),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        event: data,
        isLoading,
        error,
    };
}

export function useSmartEvents(eventIds: string[]) {
    const [locale] = useAtom(localeAtom);
    const [config] = useAtom(configurationAtom);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const { url } = getRequest(
        `/api/webevents?ids=${eventIds.join(',')}${localeAndConfigCacheBuster}`,
        locale.locale,
        config,
    );

    const { data, error, isLoading } = useSWR(url, async () => getSmartEvents(eventIds), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    return {
        events: data?.data ?? [],
        isLoading,
        error,
    };
}

export function useProductInstancesByIds(ids: string[]) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey =
        ids.length > 0 ? `/api/products?ids=${ids.join(',')}${localeAndConfigCacheBuster}` : null;
    const { data, error, isLoading } = useSWR<ProductInstance[]>(
        cacheKey,
        () => getProductInstanceByIds(ids),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        data: data ?? [],
        isLoading,
        error,
    };
}

export async function getExtraProductInstancesByIds(ids: string[]) {
    const data = await getProductInstanceByIds(ids);
    return data.map((x): ProductInstance => ({ ...x, isExtraProduct: true }));
}

export function useExtraProductInstancesByIds(ids: string[]) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey =
        ids.length > 0 ? `/api/products?ids=${ids.join(',')}${localeAndConfigCacheBuster}` : null;
    const { data, error, isLoading } = useSWR<ProductInstance[]>(
        cacheKey,
        () => getExtraProductInstancesByIds(ids),
        {
            shouldRetryOnError: false,
            // We prevent this hook from revalidating because the mapped object is too
            // big to be compared with the swr default-comparator and will cause an error.
            // This means that this hook will only fetch once for each cache-key.
            //
            // @todo: When we eventually refactor fetchers and move mappers to hooks instead,
            // we can allow revalidation again.
            revalidateOnFocus: false,
            revalidateIfStale: false,
        },
    );

    return {
        data: data ?? [],
        isLoading,
        error,
    };
}

export function useUpcomingTours(
    productCatalogIds: number[],
    startDate: TZDate,
    endDate: TZDate,
    productCatalogUrls?: string[],
) {
    const { url } = getUpcomingToursRequest(productCatalogIds, startDate, endDate);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();

    const { data, error, isLoading } = useSWR<ProductInstance[]>(
        url + localeAndConfigCacheBuster,
        async () => getUpcomingTours(productCatalogIds, startDate, endDate, productCatalogUrls),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        data: data ?? [],
        error,
        isLoading,
    };
}

export function useBilberrySettings() {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const { data, error } = useSWR<BilberrySettings>(
        '/api/settings' + localeAndConfigCacheBuster,
        () => getBilberrySettings(),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        bilberrySettings: data,
        isError: error,
        isLoading: !error && !data,
    };
}

export function usePromocodeStatus(
    promoCode: string | undefined,
    onError?: (error: { message: string }) => void,
    onSuccess?: (data: BilberryPromoCodeStatus) => void,
) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = `/api/v2/promo-code/${promoCode}${localeAndConfigCacheBuster}`;
    const { data, error, mutate } = useSWR<BilberryPromoCodeStatus>(
        promoCode ? cacheKey : null,
        () => getPromoCode(promoCode ?? ''),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            onError,
            onSuccess: (data) => onSuccess?.call(null, data),
        },
    );

    return {
        data,
        mutate,
        error,
    };
}

export function usePackageProduct(id: string) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = `/api/packages/${id}${localeAndConfigCacheBuster}`;
    const { data, isLoading, error } = useSWR<Package>(cacheKey, () => getPackageProduct(id), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    return {
        data,
        isLoading,
        error,
    };
}

export function usePackageProducts(ids?: string[]) {
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();
    const cacheKey = '/api/packages?' + 'ids=' + ids?.join(',') + '&' + localeAndConfigCacheBuster;
    const { data, error } = useSWR<Package[]>(cacheKey, () => getPackageProducts(ids));

    return {
        data: data ?? [],
        error,
    };
}

export function useNextProductAvailabilityByProductIds(productIds: string[]) {
    const cacheKey =
        productIds.length > 0
            ? `useNextProductAvailabilityByProductIds-${productIds.join(',')} `
            : null;
    const { data, isLoading, error } = useSWR<BilberryNextProductAvailability[] | null>(
        cacheKey,
        () => getNextProductAvailabilityByProductIds(productIds),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useNextProductAvailability(productId?: string) {
    const cacheKey = productId ? `useNextProductAvailability-${productId}` : null;
    const { data, isLoading, error } = useSWR<BilberryNextProductAvailability | null>(
        cacheKey,
        () => {
            return getNextProductAvailability(productId!);
        },
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

////////////////////////////
// Visbook accommodations //
////////////////////////////

export function useAccommodationProducts(
    ids: string[],
    dateRange: DateRange<TZDate>,
    accommodationsInfo: GuestInfo[],
    active = true,
    onlyAvailable = false,
) {
    const [locale] = useAtom(localeAtom);
    const [config] = useAtom(configurationAtom);
    const localeAndConfigCacheBuster = useLocaleAndConfigCacheBuster();

    const urls = useMemo(
        () =>
            accommodationsInfo.map((accommodation: GuestInfo) => {
                const guestCount = accommodation.adults + accommodation.children.length;

                const queryParams = {
                    ...hotelDateParams(dateRange),
                    guests: guestCount,
                    units: 1,
                    onlyAvailableAccommodations: onlyAvailable,
                };

                const { url } = getDefaultHotelRequest(
                    `/accommodations`,
                    locale.locale,
                    config,
                    queryParams,
                );
                return url;
            }),
        [dateRange, accommodationsInfo, locale, config, onlyAvailable],
    );

    const { data, error, isLoading, mutate } = useSWR<ProductInstance[][][]>(
        active ? urls + localeAndConfigCacheBuster : null,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            fetcher: () =>
                getAccommodationProducts(ids, dateRange, accommodationsInfo, onlyAvailable),
        },
    );

    const dataOrDefault = useMemo(() => data ?? [], [data]);

    return {
        data: dataOrDefault,
        error,
        isLoading,
        mutate,
    };
}
