import { Collapse, Grid, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { Fragment, memo, useEffect, useState } from 'react';
import AccommodationCardList from 'src/components/domain/accommodation-card-list/AccommodationCardList';
import HotelLoadingOverlay from 'src/components/domain/hotel-loading-overlay/HotelLoadingOverlay';
import RoomsSelection from 'src/components/domain/hotel-rooms-selection/RoomsSelection';
import HotelTotalPrice from 'src/components/domain/hotel-total-price/HotelTotalPrice';
import { GuestInfo } from 'src/components/domain/MultipleRoomSearch/guestInfo';
import { ProductInstance, TicketType } from '@repo/types';
import { useAccommodationProducts } from '@repo/widget-utils/services/hooks/product';
import NoAccommodationFoundOverlay from './NoAccommodationFoundOverlay';

type AccommodationListProps = {
    guestInfoSearchParameters: GuestInfo[];
    dateRange: DateRange<TZDate>;
    selectedAccommodations: ProductInstance[][];
    setSelectedAccommodations: (selectedAccommodations: ProductInstance[][]) => void;
    nights: number;
    clearRoomSelection: () => void;
    newSearch: boolean;
    setNewSearch: (b: boolean) => void;
};

function AccommodationListBase({
    dateRange,
    guestInfoSearchParameters,
    selectedAccommodations,
    setSelectedAccommodations,
    nights,
    clearRoomSelection,
    newSearch,
    setNewSearch,
}: AccommodationListProps) {
    const theme = useTheme();
    const [minDelayPassed, setMinDelayPassed] = useState(false);
    const { data: accommodations, isLoading } = useAccommodationProducts(
        [],
        dateRange,
        guestInfoSearchParameters,
    );

    const [selectedTicketTypes, setSelectedTicketTypes] = useState<TicketType[]>(
        new Array(guestInfoSearchParameters.length).fill(undefined),
    );

    useEffect(() => {
        setSelectedTicketTypes(new Array(guestInfoSearchParameters.length).fill(undefined));
    }, [guestInfoSearchParameters.length]);

    const onSelectSingleAccommodation = (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
    ) => {
        const newSelectedAccommodations = selectedAccommodations.slice(0);
        newSelectedAccommodations[0] = selectedAccommodation;
        setSelectedAccommodations(newSelectedAccommodations);
        setSelectedTicketTypes([selectedTicketType]);
    };

    const onSelectMultipleAccommodation = (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
        roomIdx: number,
    ) => {
        const newSelectedAccommodations = selectedAccommodations.slice(0);
        newSelectedAccommodations[roomIdx] = selectedAccommodation;
        setSelectedAccommodations(newSelectedAccommodations);

        const newSelectedTicketTypes = selectedTicketTypes.slice();
        newSelectedTicketTypes[roomIdx] = selectedTicketType;
        setSelectedTicketTypes(newSelectedTicketTypes);
    };

    useEffect(() => {
        if (newSearch) {
            setMinDelayPassed(false);
            setTimeout(function () {
                setMinDelayPassed(true);
            }, 300);
        }
        setNewSearch(false);
    }, [newSearch, setNewSearch]);

    const isSomeAvailableAccommodations = accommodations?.some(
        (x) => x.length > 0 && x.some((y) => y.every((z) => z.capacity > 0)),
    );

    return (
        <Fragment>
            {isLoading || !minDelayPassed ? (
                <Grid container justifyContent="center">
                    <Grid item width="50%" margin={theme.spacing(4)}>
                        <HotelLoadingOverlay />
                    </Grid>
                </Grid>
            ) : (
                <Collapse in={isSomeAvailableAccommodations}>
                    {isSomeAvailableAccommodations && (
                        <Fragment>
                            {guestInfoSearchParameters.length <= 1 && (
                                <Grid container justifyContent="center">
                                    <Grid
                                        container
                                        item
                                        justifyContent="center"
                                        mb={theme.spacing(16)}
                                        width="95%"
                                    >
                                        <Grid item width="100%">
                                            {isSomeAvailableAccommodations && (
                                                <AccommodationCardList
                                                    accommodationIds={accommodations[0].map(
                                                        (a) => a[0].product?.id ?? '',
                                                    )}
                                                    accommodationUrls={accommodations[0].map(
                                                        (a) => a[0].product?.url ?? '',
                                                    )}
                                                    accommodations={accommodations[0]}
                                                    guestInfo={guestInfoSearchParameters[0]}
                                                    nights={nights}
                                                    bookDirectly={true}
                                                    onSelectRoom={onSelectSingleAccommodation}
                                                    dateRange={dateRange}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Fragment>
                                {isSomeAvailableAccommodations &&
                                    guestInfoSearchParameters.length > 1 &&
                                    accommodations.length === guestInfoSearchParameters.length &&
                                    guestInfoSearchParameters.map((room, idx) => {
                                        return (
                                            <RoomsSelection
                                                key={idx}
                                                adults={room.adults}
                                                numberOfChildren={room.children.length}
                                                roomNumber={idx + 1}
                                                guestInfo={room}
                                                hotelRooms={accommodations[idx]}
                                                selectedRoom={selectedAccommodations[idx]}
                                                selectedTicketType={selectedTicketTypes[idx]}
                                                onSelectRoom={(
                                                    selectedAccommodation,
                                                    selectedTicketType,
                                                ) => {
                                                    onSelectMultipleAccommodation(
                                                        selectedAccommodation,
                                                        selectedTicketType,
                                                        idx,
                                                    );
                                                }}
                                                dateRange={dateRange}
                                                nights={nights}
                                            ></RoomsSelection>
                                        );
                                    })}
                                {guestInfoSearchParameters.length > 1 &&
                                    accommodations.length > 0 && (
                                        <Grid container direction="row-reverse">
                                            <HotelTotalPrice
                                                selectedAccommodations={selectedAccommodations}
                                                selectedTicketTypes={selectedTicketTypes}
                                                guestInfoList={guestInfoSearchParameters}
                                                clearRoomSelections={clearRoomSelection}
                                            ></HotelTotalPrice>
                                        </Grid>
                                    )}
                            </Fragment>
                        </Fragment>
                    )}
                </Collapse>
            )}
            {!isLoading && !isSomeAvailableAccommodations && <NoAccommodationFoundOverlay />}
        </Fragment>
    );
}

export const AccommodationList = memo(AccommodationListBase);
